import styled, { css } from 'styled-components';
import { H2 } from 'common/text';
import { mediaMax } from 'common/layout';

export const StyledRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  ${mediaMax(
    'small',
    css`
      flex-direction: column;
    `
  )}
`;

export const StyledName = styled(H2)`
  user-select: text;
  cursor: text;
  margin-bottom: 16px;
`;

export const StyledPersonImageContainer = styled.div`
  margin-right: 29px;
  ${mediaMax(
    'small',
    css`
      margin-right: 0;
      margin-bottom: 16px;
    `
  )}
`;
