import React from 'react';
import { notificationApi, useNotificationStore } from 'services/NotificationService';
import styled, { css } from 'styled-components';
import { zIndex } from 'styles';

const defaultPosition = css`
  top: 16px;
  right: 16px;
  transition: transform 0.15s ease;
`;

//const bottomPositionStyle = css`
//  ${center.horizontal()};
//  bottom: 32px;
//`;

const StyledNotifications = styled.div`
  position: absolute;
  z-index: ${zIndex.modalAndNotifications};
  ${defaultPosition}
`;

const Notifications = () => {
  const notification = useNotificationStore(state => state.notification);

  const onClick = () => {
    notificationApi.getState().closeNotification();
  };

  return <StyledNotifications onClick={onClick}>{notification}</StyledNotifications>;
};

export default Notifications;
