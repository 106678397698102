import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useOnboardingStore } from 'services/OnboardingService';
import { gsap, TimelineMax } from 'gsap';
import { CustomEase } from 'utilities/gsap-bonus/package/CustomEase';
import { Container, Gradient, CircleContainer, Circle, Point, Text, Centered } from './styles';
gsap.registerPlugin(CustomEase); // @TODO try perform this on startup in src/index.js

const fadeInAfterSeconds = 1.5;
const startAnimationAfterSeconds = 0.5;
const duration = 2.2;
const offset = '100px';

const easePoint = CustomEase.create('custom', 'M0,0,C0.538,0,0.498,1,1,1');
const easeCircle = CustomEase.create(
  'custom',
  'M0,0,C0,0,0,0,0.001,0,0.7,0,0.5,0.999,0.989,0.999,0.992,0.999,0.996,1,1,1'
);
export default function InstructionBox() {
  const [show, setShow] = useState(false);
  const [showGradient, setShowGradient] = useState(false);
  const introCompleted = useOnboardingStore(state => state.introCompleted);
  const [prevIntroCompleted] = useState(introCompleted);
  const circle = useRef();
  const point = useRef();

  const timeline = useMemo(() => new TimelineMax({ repeat: -1 }));

  useEffect(() => {
    const introWasVisible = prevIntroCompleted === false;
    if (introCompleted) {
      let secondTimeout;
      let initalTimeout;
      const thirdTimeout = setTimeout(
        () => {
          setShowGradient(true);
          initalTimeout = setTimeout(() => {
            setShow(true);
            secondTimeout = setTimeout(() => {
              timeline.clear();
              timeline.to(circle.current, duration, { transform: `translateX(${offset})`, ease: easeCircle }, 0);
              timeline.to(point.current, duration, { transform: `translateX(${offset})`, ease: easePoint }, 0);

              timeline.to(circle.current, duration, { transform: 'translateX(0)', ease: easeCircle }, duration);
              timeline.to(point.current, duration, { transform: 'translateX(0)', ease: easePoint }, duration);
            }, startAnimationAfterSeconds * 1000);
          }, fadeInAfterSeconds * 1000);
        },
        introWasVisible ? 2000 : 0
      );

      const onFirstInteraction = () => {
        window.removeEventListener('touchstart', onFirstInteraction);
        window.removeEventListener('mousedown', onFirstInteraction);
        window.removeEventListener('keydown', onFirstInteraction);
        clearTimeout(initalTimeout);
        if (secondTimeout) clearTimeout(secondTimeout);
        if (thirdTimeout) clearTimeout(thirdTimeout);
        setShow(false);
        setShowGradient(false);
      };
      window.addEventListener('touchstart', onFirstInteraction);
      window.addEventListener('mousedown', onFirstInteraction);
      window.addEventListener('keydown', onFirstInteraction);
      return onFirstInteraction;
    }
  }, [introCompleted, prevIntroCompleted]);

  return (
    <Container>
      <Gradient show={showGradient} />
      <Centered show={show}>
        <CircleContainer>
          <Circle ref={circle} />
          <Point ref={point} />
        </CircleContainer>
        <Text>Halten und Ziehen</Text>
      </Centered>
    </Container>
  );
}
