import React from 'react';
import ReactDOM from 'react-dom';
import Start from './components/Start';

import { profilingApi } from 'services/ProfilingService';
import { GlobalStyle } from './styles';

import moment from 'moment';

moment.tz.setDefault('Europe/Berlin');

profilingApi.getState().init();

const root = document.createElement('div');
root.className = 'application';
document.body.appendChild(root);

ReactDOM.render(
  <>
    <GlobalStyle />
    <Start />
  </>,
  root
);

export default Start;
