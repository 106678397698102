import React, { useState } from 'react';
import { StyledAccordion, StyledAccordionItem, StyledAccordionExpandedIcon } from './styles';
import { TransitionItem } from 'styles';

export default function Accordion({
  childComponents,
  headlineComponent,
  noPadding = false,
  useExpandedIcon = false,
  disabled = false,
  autoExpanded = false,
}) {
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const toggleAccordionExpanded = () => {
    if (autoExpanded || disabled) return;
    setAccordionExpanded(!accordionExpanded);
  };

  const headComponent = autoExpanded ? (
    <TransitionItem index={0}>{headlineComponent}</TransitionItem>
  ) : (
    headlineComponent
  );
  const childComponent = autoExpanded ? <TransitionItem index={1}>{childComponents}</TransitionItem> : childComponents;

  return (
    <StyledAccordion>
      <StyledAccordionItem
        isHeader
        useExpandedIcon={useExpandedIcon}
        noPadding={noPadding}
        onClick={toggleAccordionExpanded}
        disabled={disabled}
        autoExpanded={autoExpanded}
      >
        {headComponent}
        {useExpandedIcon && <StyledAccordionExpandedIcon>{accordionExpanded ? '-' : '+'} </StyledAccordionExpandedIcon>}
      </StyledAccordionItem>
      {(autoExpanded || accordionExpanded) && (
        <StyledAccordionItem noPadding={noPadding}>{childComponent}</StyledAccordionItem>
      )}
    </StyledAccordion>
  );
}
