export const vertexShader = `
#define CUSTOM_SHADER_NAME bokeh_particles

precision highp float;

attribute vec3 offset;
attribute float scale;
attribute float alpha;

uniform float ratio;

varying vec3 vPosition;
varying vec3 vPos;
varying vec2 vUv;
varying float vAlpha;

void main() {
  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
  vPos = mvPosition.xyz;
  vUv = uv;
  vAlpha = alpha;
  float s = abs(scale / 255.0);
  vec4 pos = projectionMatrix * mvPosition;
  vPosition = pos.xyz;
  float aspect = projectionMatrix[1][1] / projectionMatrix[0][0];
  gl_Position = vec4((offset.xy) + s * 0.5 * position.xy * vec2(1.0, aspect), 0.0,1.);
}
`;

export const fragmentShader = `
#define CUSTOM_SHADER_NAME Lensflare

precision highp float;

varying vec3 vPosition;
varying vec3 vPos;
varying vec2 vUv;
varying float vAlpha;

float circle(vec2 st, float r, float b) {
  vec2 d = st-vec2(0.5);
  return 1.0 - smoothstep(r-(r*b),r+(r*b),dot(d,d)*4.0);
}

void main() {
  float alpha = circle(vUv, 0.5, 1.0 - vAlpha) * pow(vAlpha, 0.75);
  vec3 color = vec3(0.98, 0.99, 1.2);
  gl_FragColor = vec4(color, alpha);
}
`;
