import styled, { css } from 'styled-components';
import { mediaMax, mediaMin } from 'common/layout';
import { baseColors } from 'styles';

export const StyledProfileMenu = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 4px;
  color: ${baseColors.white};
  height: calc(100%);
  ${mediaMin(
    'medium',
    css`
      padding-top: 40px;
      height: calc(100% - 46px);
    `
  )}
`;

export const Big = styled.div`
  flex-grow: 1;
`;

export const SmallRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  & > *:not(:first-child) {
    margin-left: 16px;
  }
`;

export const Small = styled.div`
  margin-right: 24px;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'column'};
  height: 100%;
`;

export const HFlex = styled(Flex)`
  flex-direction: row;
  ${mediaMax(
    'small',
    css`
      flex-direction: column;
    `
  )}
`;

export const Picture = styled.div`
  ${mediaMax(
    'small',
    css`
      margin-top: 16px;
      margin-bottom: 32px;
    `
  )}
`;
