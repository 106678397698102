import React from 'react';
import { StyledMenuButton, HoverEffect, Triangle } from './styles';

export default function MenuButton({ active, onClick, children, hoverLabel, handleMouseOver, handleMouseOut }) {
  return (
    <StyledMenuButton onClick={onClick} active={active} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {children}
      {!active && hoverLabel ? (
        <HoverEffect>
          <Triangle />
          {hoverLabel}
        </HoverEffect>
      ) : (
        ''
      )}
    </StyledMenuButton>
  );
}
