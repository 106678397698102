import React, { useState } from 'react';
import ChatContainer from 'components/Chat/ChatContainer';
import { StyledSubMenuHeader } from '../../styles';
import ChatTitle from 'components/Chat/ChatTitle';

export default function Chat() {
  const [createNewChat, setCreateNewChat] = useState(false);

  const onToggleNewChat = () => {
    setCreateNewChat(!createNewChat);
  };

  return (
    <>
      <StyledSubMenuHeader>
        <ChatTitle createNewChat={createNewChat} onToggleNewChat={onToggleNewChat} />
      </StyledSubMenuHeader>
      <ChatContainer createNewChat={createNewChat} onToggleNewChat={onToggleNewChat} />
    </>
  );
}
