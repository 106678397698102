import create from 'utilities/zustand/create';
import { socketApi } from 'services/SocketService';
import userStore from 'storage/user';
import axios from 'axios';

let socket = null;

export const [usePollStore, pollApi] = create(module, (set, get) => ({
  poll: null,
  open: false,
  didVote: false,
  lastPollViewTime: 0,
  lastPollUpdateTime: 0,
  pollIdDuringInit: 0,

  viewCurrentPoll() {
    set({ lastPollViewTime: Date.now() });
  },

  init: async managedSocket => {
    socket = managedSocket;

    await get().update();
    const { poll } = get();
    if (poll) {
      set({ pollIdDuringInit: poll.id });
    }

    socket.on('polls/setActivePoll', activePoll => {
      get().updatePoll(activePoll);
    });

    socket.on('polls/update', poll => {
      get().updatePoll(poll);
    });
  },

  async updatePoll(activePoll) {
    const { poll: prevPoll, didVote: prevDidVote } = get();
    let poll = null;
    let open = false;
    let didVote = false;
    const lastPollUpdateTime = Date.now();
    if (activePoll) {
      poll = activePoll.poll;
      open = activePoll.open;
      if (prevPoll && prevPoll.id === poll.id) {
        poll.answers = poll.answers.map((answer, index) => ({ ...answer, myVote: prevPoll.answers[index].myVote }));
        didVote = prevDidVote;
      }
    }
    set({ poll, open, didVote, lastPollUpdateTime });
  },

  async update() {
    const token = userStore.getToken();
    const restUrl = socketApi.getState().getRestUrl('strapi');
    let poll;
    let open;
    let didVote;
    try {
      const response = await axios.get(restUrl + 'polls/overview', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      poll = response.data.poll;
      open = response.data.open;
      didVote = response.data.didVote;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    set({ poll, open, didVote });
  },

  async answer(pollInstanceId, answerId) {
    const data = { pollInstanceId, answerId };
    try {
      socket.emit('polls/answer', data, response => {
        const poll = response.poll;
        const open = response.open;
        const didVote = response.didVote;

        if (poll) {
          set({ poll, open, didVote });
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  async observePoll() {
    try {
      await new Promise(resolve => {
        socket.emit('polls/observe', resolve);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  async unObservePoll() {
    try {
      await new Promise(resolve => {
        socket.emit('polls/unobserve', resolve);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
}));
