import create from 'utilities/zustand/create';
import { CONTENT_TYPE } from './contentTypes';
import { menuApi } from 'services/MenuService';
import { wispApi } from 'services/WispService';
import { twilioApi } from 'services/TwilioService';
import { trackEvent } from 'utilities/analytics';

export function createContentFromLibraryCategory(category) {
  return {
    type: CONTENT_TYPE.LIBRARY_CATEGORY,
    headline: category.headline,
  };
}

export function createArenaContent() {
  return {
    type: CONTENT_TYPE.ARENA,
  };
}

export function createLiveStreamContent() {
  return {
    type: CONTENT_TYPE.LIVESTREAM,
  };
}

export const [useContentStore, contentApi] = create(module, (set, get) => ({
  potentialContent: null,
  activeContent: null,

  setPotentialContent: content => {
    set({ potentialContent: content });
  },

  setActiveContent: content => {
    if (content.type.uiType === 'FULLSCREEN') {
      menuApi.getState().setActiveMenuComponent(null);
      twilioApi.getState().leave();
    }
    set({ activeContent: content });
    if (content.type.id === 'ARENA' || content.type.id === 'LIBRARY' || content.type.id === 'NETWORKING') {
      const wisps = wispApi.getState().wisps;
      const wispsInLocation = wisps.filter(w => w.activity === content.type.id);
      trackEvent('Navigation', 'Open', content.type.headline, wispsInLocation.length);
    } else if (content.type.id === 'LIVESTREAM') {
      trackEvent('Stage', 'Open Livestream');
    } else if (content.type.id === 'LIBRARY_CATEGORY') {
      trackEvent('Library', 'Open Category', content.headline);
    }
  },

  clearActiveContent: () => {
    const { activeContent } = get();
    set({ activeContent: null });
    if (activeContent) {
      if (activeContent.type.id === 'LIVESTREAM') {
        trackEvent('Stage', 'Close Livestream');
      } else if (activeContent.type.id === 'ARENA') {
        trackEvent('Stage', 'Back');
      } else if (activeContent.type.id === 'NETWORKING') {
        trackEvent('Network Corner', 'Back');
      } else if (activeContent.type.id === 'LIBRARY') {
        trackEvent('Library', 'Back');
      }
    }
  },

  reset: () => {
    set({ potentialContent: null });
    get().clearActiveContent();
  },
}));
