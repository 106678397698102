import React, { useMemo } from 'react';
import BackgroundEvents from 'components/Play/BackgroundEvents';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { contentApi, useContentStore } from 'services/ContentService';
import { useArenaStore } from 'services/ArenaService';
import { menuApi } from 'services/MenuService';
import useScene from '../useScene';
import { useDebugStore } from 'storage/debug';
import Screen from 'components/Play/Screen';
import HotSpotController from './HotSpotController';
import HubObject from './HubObject';

export default function Hub({ children, url: sceneUrl }) {
  const scene = useScene(sceneUrl);
  const content = useContentStore(state => state.activeContent);
  const debugStore = useDebugStore();
  const stream = useArenaStore(state => state.stream);

  const contentType = content && content.type.id;

  const renderable = scene ? scene.renderable : null;
  const arena = scene ? scene.arena : null;
  const library = scene ? scene.library : null;
  const networking = scene ? scene.networking : null;
  const use3d = renderable && !debugStore.get3dDisabled();

  useMemo(() => {
    renderable &&
      renderable.traverse(o => {
        if (o.name === 'BG_Ground' || o.name === 'BG_Accent' || o.name === 'dome') {
          o.visible = false;
        }
      });
  }, [renderable]);

  const openLiveStreamContent = () => {
    const liveStreamContent = {
      type: CONTENT_TYPE.LIVESTREAM,
      headline: 'Live Stream',
    };

    contentApi.getState().setActiveContent(liveStreamContent);
  };

  const openSchedule = () => {
    menuApi.getState().setActiveMenuComponent(menuApi.getState().schedule);
  };

  const isInArena = contentType === CONTENT_TYPE.ARENA.id;
  const onMainScreenClick = isInArena ? (stream ? openLiveStreamContent : openSchedule) : null;
  const contentScreen = scene.contentGroup.children.find(c => c.name.includes('ContentScreen'));

  return (
    <>
      <BackgroundEvents />
      {use3d && (
        <group>
          <HubObject object={arena} contentType={CONTENT_TYPE.ARENA} />
          <HubObject object={library} contentType={CONTENT_TYPE.LIBRARY} />
          <HubObject object={networking} contentType={CONTENT_TYPE.NETWORKING} />
          <Screen
            object={contentScreen}
            headline={'Arena'}
            subline={'View Live Talks'}
            location={'stage01'}
            stream={stream}
            onClick={onMainScreenClick}
            isInArena={isInArena}
          />
        </group>
      )}
      {/*{ring}*/}
      {children}
      {/*<axesHelper />*/}
      <HotSpotController />
    </>
  );
}
