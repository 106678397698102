import create from 'utilities/zustand/create';
import { socketApi } from 'services/SocketService';
import userStore from 'storage/user';
const axios = require('axios');

const limit = 100;

export function choosePeopleSearchText(/*event*/) {
  return 'Suche nach Name, Institution oder Fachgebiet...';
}

export const [usePeopleStore, peopleApi] = create(module, (set, get) => ({
  //updated only on result
  people: [],
  pending: false,

  //cleared if input (start or search) changes
  internalPeople: [],
  search: '',
  start: 0,

  init() {},

  requestPeople: () => {
    set({ start: 0, internalPeople: [], pending: false });
    get().update();
  },

  setSearchValue(search) {
    set({ search, start: 0, internalPeople: [], pending: false });
    get().update();
  },

  loadMore: () => {
    const { start, pending } = get();
    if (pending) {
      return;
    }
    set({ start: start + limit });
    get().update();
  },

  update: async () => {
    set({ pending: true });
    const { search, start } = get();
    const like = async () => {
      const restUrl = socketApi.getState().getRestUrl('strapi');
      const searchParam = search && search.length > 0 ? `&like=${encodeURIComponent(search)}` : '';
      const params = `_limit=${limit}&_start=${start}${searchParam}`;
      const token = userStore.getToken();
      const auth = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${restUrl}users/like?${params}`, auth);
      return response.data;
    };

    const users = await like();

    const newPeople = users.map(u => {
      return {
        id: u.id,
        role: u.role,
        thumbnail: u.thumbnail,
        activity: u.activity,
        title: u.title,
        forename: u.forename,
        surname: u.surname,
        speciality: u.speciality,
        company: u.company,
        isOnline: u.isOnline,
      };
    });

    const { search: newSearch } = get();
    if (search === newSearch) {
      const { internalPeople } = get();
      const mergedPeople = [...internalPeople, ...newPeople];
      set({ people: mergedPeople, internalPeople: mergedPeople, pending: false });
    }
  },

  reset() {
    set({ people: [], internalPeople: [], search: '', start: 0, pending: false });
  },
}));
