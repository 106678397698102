import React from 'react';
import styled from 'styled-components';
import { contentApi, createLiveStreamContent } from 'services/ContentService';
import Notification from 'components/Notification';
import { Body2, Overline2 } from 'common/text';
import { trackEvent } from 'utilities/analytics';

const StyledCTA = styled(Overline2)`
  margin-top: 20px;
`;

const AgendaNotification = ({ talk }) => {
  const { title, link, type } = talk;
  const isLiveStream = type === 'live_talk';
  const hasJoinButton = isLiveStream || link;

  const onClick = () => {
    if (isLiveStream) {
      const content = createLiveStreamContent();
      contentApi.getState().setActiveContent(content);
    } else if (link) {
      window.open(link);
    }
    trackEvent('Notification', 'Join', 'Schedule', talk.title);
  };

  const notificationProps = {
    onClick,
    headline: 'Die Präsentation beginnt gleich',
    body: (
      <>
        <Body2>{title}</Body2>
        {hasJoinButton && <StyledCTA>Jetzt beitreten</StyledCTA>}
      </>
    ),
  };
  return <Notification {...notificationProps} />;
};

export default AgendaNotification;
