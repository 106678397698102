import React, { useEffect, useMemo } from 'react';
import { useTalkStore, talkApi } from 'services/TalkService';
import { menuApi } from 'services/MenuService';
import { ScrollContent } from 'components/Menu/SubMenu/styles';
import ScheduleDatePicker from './ScheduleDatePicker';
import TalkComponent from 'common/components/TalkComponent';
import CloseButton from 'common/components/CloseButton';
import { StyledSubMenuHeader, StyledSubMenuHeadline } from '../../styles';
import { StyledTalkComponents } from './styles';
import { Body2 } from 'common/text';

export default function Schedule() {
  const state = useTalkStore(state => state.state);
  const talks = useTalkStore(state => state.talks);
  const filters = useTalkStore(state => state.filters);
  const showDatePicker = talks.length > 0 && state === 'ok';

  const headerRef = React.createRef();

  useEffect(() => {
    talkApi.getState().poll();
  }, []);

  const filteredTalks = useMemo(() => {
    return talkApi.getState().filterTalks(talks);
  }, [talks, filters]);

  const talkComponents =
    state === 'ok' &&
    filteredTalks.map((talk, i) => <TalkComponent key={talk.id} talk={talk} index={i} withAnimation />);

  return (
    <>
      <CloseButton
        onClick={() => {
          menuApi.getState().setActiveMenuComponent(null);
        }}
      />
      <StyledSubMenuHeader ref={headerRef} style={{ marginBottom: '8px' }}>
        <StyledSubMenuHeadline>Agenda</StyledSubMenuHeadline>
        <div>{showDatePicker && <ScheduleDatePicker />}</div>
      </StyledSubMenuHeader>
      <ScrollContent>
        {talks.length > 0 ? (
          <StyledTalkComponents>{talkComponents}</StyledTalkComponents>
        ) : (
          <StyledTalkComponents>
            <Body2>Die Agenda ist leer.</Body2>
          </StyledTalkComponents>
        )}
      </ScrollContent>
    </>
  );
}
