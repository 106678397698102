import { useFrame, useThree } from 'react-three-fiber';
import { useMemo } from 'react';
import {
  BackSide,
  LinearFilter,
  LinearMipMapLinearFilter,
  Mesh,
  OrthographicCamera,
  PlaneBufferGeometry,
  RepeatWrapping,
  Scene,
  ShaderMaterial,
  UniformsLib,
  Vector2,
  Vector3,
  WebGLRenderTarget,
} from 'three';
import { renderTextureFrag, renderTextureVert } from './renderTexture.glsl';
import { CONTENT_UI_TYPE } from 'services/ContentService/contentTypes';
import { useContentStore } from 'services/ContentService';
import { useWindowStore } from 'services/WindowService';

const rtSize = {
  x: 16,
  y: 16,
};

export const sharedTextures = {
  videoTexture: null,
  renderTexture: new WebGLRenderTarget(rtSize.x, rtSize.y, {}),
};

let frameIndex = 0;

export default function RenderTexture() {
  const { gl } = useThree();

  const content = useContentStore(state => state.activeContent);
  const windowFocus = useWindowStore(state => state.focus);

  const { rt, scene, cameraRTT, material } = useMemo(() => {
    const rt = new WebGLRenderTarget(rtSize.x, rtSize.y, {
      generateMipmaps: true,
      minFilter: LinearMipMapLinearFilter,
      magFilter: LinearFilter,
      wrapS: RepeatWrapping,
      wrapT: RepeatWrapping,
    });
    const cameraRTT = new OrthographicCamera(-rtSize.x / 2, rtSize.x / 2, -rtSize.y / 2, rtSize.y / 2, -10000, 10000);
    cameraRTT.position.z = 100;
    const scene = new Scene();
    const plane = new PlaneBufferGeometry(rtSize.x, rtSize.y, 1, 1);

    const material = new ShaderMaterial({
      vertexShader: renderTextureVert,
      fragmentShader: renderTextureFrag,

      side: BackSide,
      uniforms: {
        ...UniformsLib.common,
        video: { value: sharedTextures.videoTexture },
        color: { value: new Vector3(1.0, 0.0, 0.0) },
        resolution: { value: new Vector2(rtSize.x, rtSize.y) },
      },
    });
    material.needsUpdate = true;

    const quad = new Mesh(plane, material);
    quad.position.z = -100;
    scene.add(quad);
    return {
      rt,
      scene,
      cameraRTT,
      material,
    };
  }, [sharedTextures.videoTexture]);

  const isFullscreenContent = content ? content.type.uiType === CONTENT_UI_TYPE.FULLSCREEN : false;

  useFrame(() => {
    const windowOutOfFocus = !windowFocus && frameIndex++ % 4 !== 0;
    if (windowOutOfFocus || isFullscreenContent) return;
    sharedTextures.renderTexture = rt;
    gl.setRenderTarget(rt);
    material.uniforms['video'].value = sharedTextures.videoTexture;
    gl.clear();
    gl.render(scene, cameraRTT);
    gl.setRenderTarget(null);
  });

  return null;
}
