import React from 'react';
import styled from 'styled-components';

export const StyledCloseButton = styled.div`
  transform: none;
  background-color: transparent;
  pointer-events: all;
  cursor: pointer;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  > div {
    width: 1px;
    height: 25px;
  }
  > div {
    height: 25%;
    position: absolute;
    border-radius: 1.5px;
    background-color: white;
    transform: rotate(45deg);
    &:last-child {
      transform: rotate(-45deg);
    }
  }
`;

const CloseButton = ({ onClick }) => {
  return (
    <StyledCloseButton onClick={onClick}>
      <div />
      <div />
    </StyledCloseButton>
  );
};

export default CloseButton;
