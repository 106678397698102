import moment from 'moment-timezone';

const locales = {
  US: {
    date: 'MM/DD/YYYY',
    dateNoYear: 'MM/DD',
    time: 'h:mm A',
  },
  'DIN 1355-1': {
    date: 'DD.MM.YYYY',
    dateNoYear: 'DD.MM',
    time: 'H:mm',
  },
};

let locale = locales['DIN 1355-1'];

export function setTimeAndDateFormat(value) {
  locale = locales[value];
}

export default function format(utcString, format) {
  const pattern = locale[format];
  return moment(utcString).format(pattern);
}

export function isToday(utcString) {
  const now = moment(Date.now()).local();
  const time = moment(utcString).local();
  return now.isSame(time, 'day');
}
