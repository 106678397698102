import React, { useEffect, useRef } from 'react';
import { useContentStore } from 'services/ContentService';
import { createVideoMaterial, createImageMaterial } from 'utilities/content';
import { sharedTextures } from 'three/RenderTexture';
import { Color, MeshStandardMaterial } from 'three';
import { CONTENT_TYPE, CONTENT_UI_TYPE } from 'services/ContentService/contentTypes';
import { safeSelectFormatFromMedia } from '../../../../utilities/media';

const contentTypesWithInvisibleLivestream = ['NETWORKING', 'LIBRARY'];

export default function Teaser({ geometry, stream, createSharedTexture = false }) {
  const content = useContentStore(state => state.activeContent);
  const isFullscreenContent = content != null && content.type.uiType === CONTENT_UI_TYPE.FULLSCREEN;
  const livestreamInvisible =
    (content && content.type && contentTypesWithInvisibleLivestream.includes(CONTENT_TYPE.LIVESTREAM.id)) || false;
  const meshRef = useRef();

  useEffect(() => {
    let material;
    if (isFullscreenContent || livestreamInvisible) {
      material = new MeshStandardMaterial();
      material.color = new Color(0x000000);
    } else {
      if (!stream) {
        material = new MeshStandardMaterial();
        material.emissive = new Color(0x000000);
      } else {
        const component = stream.source[0];
        switch (component.__component) {
          case 'stream.hls-stream':
            material = createVideoMaterial(component.url).material;
            break;
          case 'stream.image':
            {
              const media = safeSelectFormatFromMedia(component.posterImage, 'large');
              material = createImageMaterial(media.url).material;
            }
            break;
          default:
            // eslint-disable-next-line no-console
            console.error('unknown stream component type');
            return;
        }

        if (createSharedTexture) sharedTextures.videoTexture = material.emissiveMap;
      }
    }
    meshRef.current.material = material;
    return () => {
      material.dispose();
    };
  }, [stream, isFullscreenContent, livestreamInvisible]);

  return <mesh geometry={geometry} ref={meshRef} />;
}
