import { buildApi } from 'services/BuildService';

export const trackEvent = (category, action, label, value) => {
  //console.log(category, "|", action, "|", label, "|", value);
  const { integration } = buildApi.getState();
  integration === 'PRODUCTION' &&
    window.gtag &&
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
};
