import styled, { css } from 'styled-components';
import { animations, baseColors } from 'styles';
import { H2 } from 'common/text';
import { mediaMax } from 'common/layout';

export const TransitionComponent = styled.div`
  opacity: 0;
  ${animations.showFromBottom};
  animation-delay: ${props => props.index * 0.15 + 0.15}s;
  animation-duration: 0.45s;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0, 0.98);
`;

export const StyledHeadline = styled(H2)`
  color: ${baseColors.white};
  margin: 0;
`;

export const StyledBackButtonContainer = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  ${mediaMax(
    'small',
    css`
      right: 24px;
      top: 24px;
    `
  )}
`;
