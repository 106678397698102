import React, { useMemo } from 'react';
import Teaser from 'components/Play/Screen/Teaser';
import Hover from 'components/Play/Screen/Hover';

export default function Screen(props) {
  const { object, stream, onClick } = props;
  const { geometry } = object;
  const { position, quaternion, scale } = useMemo(() => {
    const position = object.position;
    const quaternion = object.quaternion;
    const scale = object.scale;
    return { position, quaternion, scale };
  }, []);
  return (
    <group position={position} quaternion={quaternion} scale={scale}>
      <Teaser geometry={geometry} stream={stream} />
      <Hover geometry={geometry} disabled={false} onClick={onClick} />
    </group>
  );
}
