import create from 'utilities/zustand/create';
import userStore from 'storage/user';
import { activityApi } from 'services/ActivityService';
import { contentApi } from 'services/ContentService';
import { eventApi } from 'services/EventService';
import { peopleApi } from 'services/PeopleService';
import { socketApi } from 'services/SocketService';
import { districtApi } from 'services/DistrictService';
import { wispApi } from 'services/WispService';
import { chatApi } from 'services/ChatService';
import { emojiApi } from 'services/EmojiService';
import { arenaApi } from 'services/ArenaService';
import { talkApi } from 'services/TalkService';
import { menuApi } from 'services/MenuService';
import { modalApi } from 'services/ModalService';
import { soundApi } from 'services/SoundService';
import { pollApi } from 'services/PollService';
import { configureSession } from 'utilities/userSession';
import { WEBGL } from 'three/examples/jsm/WebGL';
import { profilingApi } from 'services/ProfilingService';
import { applicationApi } from 'services/ApplicationService';
import React from 'react';
import ModalProfile from 'components/ModalProfile';
import { twilioApi } from '../TwilioService';
import { trackEvent } from 'utilities/analytics';

let ms = 0;
let lastTime = Date.now();

function countFps() {
  const now = Date.now();
  ms += (now - lastTime - ms) * 0.1;
  lastTime = now;
  requestAnimationFrame(countFps);
}

countFps();

const webgl2 = WEBGL.isWebGL2Available();

export function assembleNameWithTitle(user) {
  const title = user.title || '';
  const forename = user.forename || '';
  const surname = user.surname || '';
  return (title ? title + ' ' : '') + forename + ' ' + surname;
}

let socket = null;
export const [useUserStore, userApi] = create(module, (set, get) => ({
  user: null,
  eventId: null,
  loginState: 'PENDING',
  message: null,
  // TODO: remove both?
  hasFocus: true,
  isIdle: false,

  init: async skipLogin => {
    const token = userStore.getToken();
    if (token && !skipLogin) {
      await get().login({ token });
    } else {
      set({ loginState: 'LOGGED_OUT' });
    }

    configureSession({
      update: properties => {
        const { focus: hasFocus, idle: isIdle } = properties;
        set({ hasFocus, isIdle });
      },
    });

    configureSession({
      update: properties => {
        const { focus: hasFocus, idle: isIdle } = properties;
        set({ hasFocus, isIdle });
      },
    });
  },

  resetMessage: () => {
    set({ message: null });
  },

  login: credentials => {
    if (applicationApi.getState().state === 'FAILED') {
      return;
    }

    set({ loginState: 'PENDING' });
    socket = socketApi.getState().connect();

    socket.on('disconnect', () => {
      get().resetStates();
      set({ loginState: 'RECONNECTING' });
    });

    socket.on('user/sameUserLoggedIn', () => {
      get().disconnect({
        message: {
          copy:
            'Sie wurden automatisch abgemeldet, da ihr Account auf einem anderen Gerät oder Browserfenster verwendet wird.',
        },
      });
    });

    socket.on('error/wrongProtocol', () => {
      get().disconnect({
        message: {
          copy: 'Protocol mismatch.',
        },
      });
    });

    socket.on('user/reload', callback => {
      callback();
      window.location.reload();
    });

    socket.on('reconnect', async () => {
      const token = userStore.getToken();
      if (token) {
        await get().login({ token });
      } else {
        set({ loginState: 'LOGGED_OUT' });
      }
    });

    socket.on('user/ping', time => {
      const { isIdle, hasFocus } = get();
      socket.emit('user/pong', {
        time,
        ms: Math.round(ms) % 65535,
        isIdle,
        hasFocus,
        width: Math.round(window.innerWidth) % 65535,
        height: Math.round(window.innerHeight) % 65535,
        devicePixelRatio: window.devicePixelRatio,
        webgl2: webgl2,
        post: false,
      });
    });

    return new Promise(resolve => {
      socket.once('reconnect_failed', () => {
        set({ loginState: 'CRITICAL_ERROR' });
        resolve();
      });

      const { application } = applicationApi.getState();
      const { defaultEvent: eventId } = application;

      socket.emit('user/login', { ...credentials, eventId }, async result => {
        const { user, token } = result;
        if (user != null) {
          profilingApi.getState().mark('Init User APIs');
          userStore.setToken(token);
          set({ user, eventId });
          await get().initStates(user);
          set({ loginState: 'LOGGED_IN' });
          profilingApi.getState().mark('Init User APIs Complete');
          resolve(user);
        } else {
          const { waitForCode } = result;
          if (waitForCode) {
            get().disconnect({ message: null, waitForCode: true });
          } else {
            let { message } = result;
            let retryCode = false;
            if (message === 'Unauthorized') {
              message = null; // in that case, the user came back but the current event changed. show the login silently
            }
            if (message === 'Precondition Required') {
              message = 'Ihre Zugangsberechtigung ist abgelaufen. Bitte melden Sie sich erneut an.';
            }
            if (message === 'Too crowded') {
              message = 'Aktuell ist das Event leider zu voll. Bitte versuchen Sie es später erneut.';
            }
            if (message === 'Identifier invalid') {
              message =
                'Diese Email-Adresse ist uns nicht bekannt. Bitte überprüfen Sie sie und nehmen Sie Kontakt zu uns auf, sollten Sie Hilfe benötigen. Tel.: +49 173 4373901';
            }
            if (message === 'Code invalid') {
              retryCode = true;
              message = 'Der von Ihnen verwendete Code wurde nicht erkannt. Bitte versuchen Sie es noch einmal.';
            }
            if (message === 'Code invalid, reset') {
              message =
                'Der von Ihnen verwendete Code wurde bereits verwendet, ist abgelaufen oder der Code wurde zu oft falsch eingegeben. Bitte fordern Sie einen neuen Link an, um fortzufahren.';
            }
            if (message === 'Link invalid') {
              message = 'Der Link ist abgelaufen. Bitte versuchen sie es erneut oder fordern Sie einen neuen an.';
            }
            if (message === 'Account blocked') {
              message = 'Ihr Account wurde gesperrt.';
            }
            get().disconnect({ message: { copy: message ? message.toString() : null }, waitForCode: retryCode });
            resolve(null);
            trackEvent('Login Error', 'Show', message);
          }
        }
      });
    });
  },

  initStates: async () => {
    await eventApi.getState().init();
    activityApi.getState().init(socket);
    peopleApi.getState().init(socket);
    districtApi.getState().init(socket);
    await wispApi.getState().init(socket);
    await chatApi.getState().init(socket);
    await emojiApi.getState().init(socket);
    await talkApi.getState().init(socket);
    await arenaApi.getState().init(socket);
    twilioApi.getState().init(socket);
    await pollApi.getState().init(socket);
  },

  resetStates: () => {
    activityApi.getState().reset();
    contentApi.getState().reset();
    chatApi.getState().reset();
    peopleApi.getState().reset();
    districtApi.getState().reset();
    talkApi.getState().reset();
    menuApi.getState().reset();
    soundApi.getState().reset();
    twilioApi.getState().reset();
    eventApi.getState().reset();
  },

  logout: () => {
    userStore.setToken(null);
    get().disconnect();
    set({
      eventId: null,
      user: null,
    });
  },

  disconnect: change => {
    get().resetStates();
    set({ loginState: 'LOGGED_OUT', waitForCode: false, ...change });
    socketApi.getState().disconnect();
  },

  updateUserData: user => {
    const users = get().users.filter(u => u.id !== user.id);
    set({ users: [...users, user] });
  },

  openProfileModal(user) {
    const { user: ownUser } = get();
    if (ownUser.id === user.id) {
      menuApi.getState().setActiveMenuComponent(menuApi.getState().profile);
    } else {
      modalApi.getState().setModal(<ModalProfile user={user} />);
    }
  },

  async updateProfile(data) {
    await new Promise(resolve => {
      socket.emit('user/edit', data, async result => {
        const { user } = result;
        if (user) {
          set({ user: result.user });
        } else {
          const { message } = result;
          // eslint-disable-next-line no-console
          console.error(message);
        }
        resolve();
      });
    });
  },
}));
