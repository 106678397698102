//import Storage from 'utilities/storage';
//
//const PERMISSION_GRANTED = 'permission_granted';
//
//const sessionStorage = new Storage({ type: 'session' });
//sessionStorage.init('live_twilio_1');
//sessionStorage.defineEntry(PERMISSION_GRANTED, false);

let permissionGranted = false;

export default {
  setPermissionGranted: value => {
    permissionGranted = value;
    //sessionStorage.setItem(PERMISSION_GRANTED, value);
  },
  getPermissionGranted: () => {
    return permissionGranted;
    //return sessionStorage.getItem(PERMISSION_GRANTED);
  },
};
