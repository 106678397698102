import create from 'utilities/zustand/create';

export const LOCAL = 'LOCAL';
export const DEVELOPMENT = 'DEVELOPMENT';
export const PRODUCTION = 'PRODUCTION';
export const MEDICAL = 'MEDICAL';

const INTEGRATIONS = {
  ['fortbildung.campusdialog.de']: MEDICAL,
  ['medical.dmdr.io']: MEDICAL,
  ['develop.ipsen-space.com']: DEVELOPMENT,
  ['ipsen-space.com']: PRODUCTION,
  ['develop.ipsen.dmdr.io']: DEVELOPMENT,
  ['ipsen.dmdr.io']: PRODUCTION,
  ['localhost']: DEVELOPMENT,
};

export const [useBuildStore, buildApi] = create(module, set => ({
  integration: '',
  init: () => {
    const hostname = window.location.hostname;

    // search for the first rule that matches
    let integration = hostname.indexOf('192.168') === 0 ? LOCAL : DEVELOPMENT;
    for (const key of Object.keys(INTEGRATIONS)) {
      if (hostname.endsWith(key)) {
        integration = INTEGRATIONS[key];
        break;
      }
    }
    set({ integration });
  },
}));
