import { css } from 'styled-components';
import { baseColors, colors } from 'styles';

export const iconFillAndStroke = (accentColor, baseColor) => css`
  path,
  rect,
  circle,
  ellipse,
  line,
  polyline,
  polygon {
    &[fill] {
      fill: ${baseColor};
      &.accent {
        fill: ${accentColor};
      }
    }
    &[stroke] {
      stroke: ${baseColor};
      &.accent {
        stroke: ${accentColor};
      }
    }
  }
`;

export const iconStyle = css`
  ${iconFillAndStroke(colors.accent, baseColors.white)}
`;

export const iconInactiveStyle = css`
  ${iconFillAndStroke(baseColors.white, baseColors.white)}
`;
