import create from 'utilities/zustand/create';
import { socketApi } from 'services/SocketService';
import { eventApi } from 'services/EventService';
import userStore from 'storage/user';
const axios = require('axios');

export const [useLibraryStore, libraryApi] = create(module, set => ({
  categories: [],
  pending: false,

  track: async headline => {
    const restUrl = socketApi.getState().getRestUrl('strapi');
    const token = userStore.getToken();
    const auth = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      headline,
    };
    await axios.post(`${restUrl}library-activities`, data, auth);
  },

  poll: async () => {
    const { library } = eventApi.getState().event;
    set({ pending: true });
    const restUrl = socketApi.getState().getRestUrl('strapi');
    const searchParam = library ? `&id=${library.id}` : '';
    const params = `_limit=1${searchParam}`;
    const token = userStore.getToken();
    const auth = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${restUrl}libraries?${params}`, auth);

    if (response.status === 200 && response.data && response.data.length > 0) {
      const { categories } = response.data[0];
      set({ pending: false, categories });
    }
  },
}));
