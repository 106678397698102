import { UniformsLib } from 'three';
import { UniformsUtils } from 'three';

export const uniforms = UniformsUtils.merge([
  UniformsLib.common,
  {
    tCube: { value: null },
    tNoise: { value: null },
  },
]);

export const vertexShader = /* glsl */ `
  #define USE_UV
  #include <common>
  #include <uv_pars_vertex>
  #include <uv2_pars_vertex>
  varying vec3 vWorldPosition;
  varying vec4 vGLPosition;
  varying vec3 vNormal;

  void main() {
  	#include <uv_vertex>
	  #include <uv2_vertex>
	  
    #include <begin_vertex>
  	#include <project_vertex>
  	
  	vGLPosition = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  	
    vNormal = normalize( normalMatrix * normal );
  
    vec4 worldPosition = vec4( transformed, 1.0 );
    worldPosition = modelMatrix * worldPosition;
    vWorldPosition = worldPosition.xyz;
  }
`;

export const fragmentShader = /* glsl */ `
  #define USE_UV
  #include <common>
  #include <uv_pars_fragment>
  #include <uv2_pars_fragment>

  varying vec3 vNormal;
  varying vec3 vWorldPosition;
  varying vec4 vGLPosition;

  uniform samplerCube tCube;
  uniform sampler2D tNoise;
  
  void main() {
  
    vec2 screenUv = vec2(vGLPosition.x / vGLPosition.w, vGLPosition.y / vGLPosition.w) * 0.5 + 0.5;
  
    vec3 result = vec3(1.0,0.0,0.0);
    vec3 normal = normalize(vNormal);
    
    result = textureCube(tCube, vWorldPosition).rgb;
    result -= ((texture2D(tNoise, vUv * 15.0).r / 255.0) * 4.0) - (2.0 / 255.0);
        
    float radius = 0.9;
    vec2 dist = screenUv - vec2(0.5);
    float vignette = 1.0 - smoothstep(0.0, radius+radius, dot(dist, dist) * 4.0);
    vignette = 0.5 + vignette * 0.5;
    result *= vec3(vignette);
    
    
    gl_FragColor = vec4(result, 1.0);
  }
`;
