import styled, { css } from 'styled-components';
import { baseColors, colors } from 'styles';
import { iconFillAndStroke } from 'common/icon';
import { styledScrollbar } from 'common/layout';

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const IconContainer = styled.div`
  width: 12px;
  height: 8px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  ${iconFillAndStroke(baseColors.white, baseColors.white)};

  ${props =>
    props.rotate &&
    css`
      transform-origin: center;
      transform: rotateZ(${props.rotate}deg);
    `}
`;

export const DropdownButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 24px;
  cursor: pointer;
  color: ${baseColors.white};

  background: ${props =>
    props.open
      ? colors.interactiveBlue
      : 'linear-gradient(90.84deg, rgba(255, 255, 255, 0.15) 0.07%, rgba(255, 255, 255, 0.075) 100%)'};
  border-radius: 8px;
  ${props =>
    props.open
      ? css`
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : ''}
`;

const scrollable = css`
  max-height: 264px;
  overflow-y: auto;
  ${styledScrollbar}
`;

export const DropdownOptionList = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: auto;
  ${props => (props.numItems > 5 ? scrollable : null)};

  background: ${colors.interactiveBlue};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const DropdownOption = styled.div`
  padding: 13px 24px;
  display: ${props => (props.disabled ? 'none' : ' block')};
  color: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;
