import React from 'react';
import create from 'utilities/zustand/create';
import { connect, createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video';
import { wispApi } from 'services/WispService';
import { modalApi } from 'services/ModalService';
import { trackEvent } from 'utilities/analytics';
import ModalError from 'components/Content/Networking/ModalError';

let socket = null;

export const [useTwilioStore, twilioApi] = create(module, (set, get) => ({
  twilioState: 'ask_for_permission',
  room: null, // twilio room
  audio: false,
  video: false,
  localParticipant: null,
  participants: [],

  participantConnected: participant => {
    const localParticipantId = get().localParticipant.identity.split('_')[0];
    const newParticipantId = participant.identity.split('_')[0];
    if (newParticipantId == localParticipantId) {
      // eslint-disable-next-line no-console
      return console.warn('skipped user with the similar identity', participant.identity);
    }
    // eslint-disable-next-line no-console
    console.log(`${participant} connected`);
    const prevParticipants = get().participants;
    set({ participants: [...prevParticipants, participant] });
  },

  participantDisconnected: participant => {
    // eslint-disable-next-line no-console
    console.log(`${participant} disconnected`);
    const prevParticipants = get().participants;
    const participants = prevParticipants.filter(p => p !== participant);
    set({ participants });
  },

  triggerErrorModal: () => {
    set({ twilioState: 'ok' });
    const modal = <ModalError clearContentOnClose={false} />;
    modalApi.getState().setModal(modal);
  },

  connect: async jwt => {
    let room;
    try {
      room = await connect(jwt, { audio: false, video: false });
    } catch (e) {
      if (e.name === 'TwilioError' && e.message === 'Room contains too many Participants') {
        set({ twilioState: 'too_many_participants' });
      } else {
        get().triggerErrorModal();
      }
      return null;
    }

    const localParticipant = room.localParticipant;

    set({ room, localParticipant, participants: [localParticipant] });
    room.participants.forEach(p => {
      get().participantConnected(p);
    });

    room.on('participantConnected', p => {
      get().participantConnected(p);
    });
    room.on('participantDisconnected', p => {
      get().participantDisconnected(p);
    });

    return room;
  },

  init: managedSocket => {
    socket = managedSocket;

    // documentation lies, doesn't work:
    //window.addEventListener('beforeunload', () => {
    //  const { room } = get();
    //  if (room) {
    //    room.disconnect();
    //  }
    //});

    socket.on('twilio/disconnect', identity => {
      const room = get().room;
      if (room) {
        const keys = room.participants.keys();
        const id = identity.split('_')[0];
        for (const key of keys) {
          const p = room.participants.get(key);
          if (p.identity.split('_')[0] == id) {
            // eslint-disable-next-line no-console
            console.warn('manual disconnect of identity', identity);
            get().participantDisconnected(p);
            room.participants.delete(key);
          }
        }
      }
    });
  },

  join: async wispRoom => {
    set({ twilioState: 'pending' });
    socket.emit('twilio/join', { roomName: wispRoom.id }, async jwt => {
      if (jwt !== null) {
        const room = await get().connect(jwt);
        if (!room) {
          return socket.emit('twilio/leave', () => {
            get().reset(false);
          });
        }
        wispApi.getState().changeRoom(wispRoom);
        set({ twilioState: 'connected', room });
        trackEvent('Network Corner', 'Join', wispRoom.name, room.participants.size);
      } else {
        get().triggerErrorModal();
      }
    });
  },

  leave: () => {
    wispApi.getState().changeRoom(null);
    socket.emit('twilio/leave', () => {
      get().reset();
    });
    trackEvent('Network Corner', 'Leave');
  },

  toggleAudio: async () => {
    const { audio, room } = get();

    const localParticipant = room.localParticipant;

    if (audio) {
      localParticipant.audioTracks.forEach(publication => {
        publication.track.disable();
      });
      set({ audio: false });
    } else {
      if (localParticipant.audioTracks.size === 0) {
        try {
          const localAudioTrack = await createLocalAudioTrack();
          localParticipant.publishTrack(localAudioTrack);
          localParticipant.emit('trackSubscribed', localAudioTrack);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } else {
        room.localParticipant.audioTracks.forEach(publication => {
          publication.track.enable();
        });
      }
      set({ audio: true });
    }
  },

  toggleVideo: async () => {
    const { video, room, resetLocalParticipantTracks } = get();

    if (video) {
      resetLocalParticipantTracks(room.localParticipant, room.localParticipant.videoTracks);
      set({ video: false });
    } else {
      const localParticipant = room.localParticipant;
      if (localParticipant.videoTracks.size === 0) {
        try {
          const localVideoTrack = await createLocalVideoTrack();
          localParticipant.publishTrack(localVideoTrack);
          localParticipant.emit('trackSubscribed', localVideoTrack);
          set({ video: true });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }
  },

  resetLocalParticipantTracks: (localParticipant, tracks) => {
    tracks.forEach(publication => {
      publication.track.stop();
      publication.unpublish();
      localParticipant.emit('trackUnsubscribed', publication.track, publication, localParticipant);
    });
  },

  reset: (resetState = true) => {
    const { room, resetLocalParticipantTracks, twilioState } = get();
    if (room !== null) {
      resetLocalParticipantTracks(room.localParticipant, room.localParticipant.audioTracks);
      resetLocalParticipantTracks(room.localParticipant, room.localParticipant.videoTracks);
      room.disconnect();
    }

    twilioApi.setState({ wispRoom: null });

    set({
      twilioState: resetState ? 'ok' : twilioState,
      audio: false,
      video: false,
      room: null,
      localParticipant: null,
      participants: [],
    });
  },
}));
