import styled from 'styled-components';
import { H2, Body2 } from 'common/text';

export const StyledName = styled(H2)`
  user-select: text;
  cursor: text;
  margin-bottom: 16px;
`;

export const StyledInfo = styled(Body2)`
  user-select: text;
  cursor: text;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
`;
