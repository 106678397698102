import React from 'react';
import { modalApi } from 'services/ModalService';
import { contentApi } from 'services/ContentService';
import CloseButton from 'common/components/CloseButton';
import { Button } from 'common/components/Button';
import { StyledColumn, StyledInfo, StyledTitle, StyledButtonContainer } from 'components/Modal/styles';
import { useDocumentListener } from 'utilities/hooks';

export default function ModalNoPermission() {
  const onCloseClick = () => {
    modalApi.getState().closeModal();
    contentApi.getState().clearActiveContent();
  };

  const onReload = () => {
    window.location.reload();
  };

  useDocumentListener(true, 'keyup', e => {
    e.code === 'Escape' && onCloseClick();
  });

  return (
    <>
      <CloseButton onClick={onCloseClick} />
      <StyledColumn>
        <StyledTitle>Wir können nicht auf Ihr Mikrofon zugreifen!</StyledTitle>
        <StyledInfo>
          Für die Audio Räume ist ein Mikrofonzugriff erforderlich. Ändern Sie die Berechtigungen in den
          Browsereinstellungen. Laden Sie die Website neu und besuchen Sie anschließend die BackStage.
        </StyledInfo>
        <StyledButtonContainer>
          <Button primaryInverted onClick={onReload}>
            Website neu laden
          </Button>
        </StyledButtonContainer>
      </StyledColumn>
    </>
  );
}
