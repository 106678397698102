import React from 'react';
import styled, { css } from 'styled-components';
import { styledScrollbar } from 'common/layout';
import { mediaMin } from 'common/layout';

export const StyledScrollContentSpacer = styled.div`
  height: 100%;
  overflow: hidden;

  padding-right: 4px;
`;

const maskGradientHeight = 16;

export const StyledScrollContent = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;

  ${styledScrollbar};

  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(#fff, #fff),
    linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%), linear-gradient(#fff, #fff);
  mask-size: 100% ${maskGradientHeight}px, 100% calc(100% - ${2 * maskGradientHeight}px), 100% ${maskGradientHeight}px,
    8px 100%;
  mask-position: top, center, bottom, right;
  mask-repeat: no-repeat;

  padding-left: 24px;
  padding-right: 20px;
  ${mediaMin(
    'medium',
    css`
      padding-left: 40px;
      padding-right: 36px;
    `
  )};

  > div {
    &:first-child {
      margin-top: 0;
    }
  }
`;

export function ScrollContent({ children, forwardRef }) {
  return (
    <StyledScrollContentSpacer>
      <StyledScrollContent ref={forwardRef}>{children}</StyledScrollContent>
    </StyledScrollContentSpacer>
  );
}
