import create from 'utilities/zustand/create';

export const [useProfilingStore, profilingApi] = create(module, (set, get) => ({
  startMs: 0,
  marks: [],
  init: () => {
    set({ startMs: Date.now() });
  },

  mark: message => {
    const mark = { message, time: Date.now() - get().startMs };
    const { marks } = get();
    marks.push(mark);
    set({ marks: [...marks] });
  },
}));
