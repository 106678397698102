import React, { useRef, useState } from 'react';
import { useChatStore } from 'services/ChatService';
import SendIcon from './assets/send.svg';
import { Form, Container, ButtonContainer } from './style';
import { StyledInput } from 'common/components/Input';
import { Button } from 'common/components/Button';
import { deviceApi } from 'services/DeviceService';

const ChatInput = ({ maxCharacters }) => {
  const [input, setInput] = useState('');
  const send = useChatStore(state => state.send);
  const { setKeyboardActive } = deviceApi.getState();

  const textArea = useRef();

  const handleChange = e => {
    if (e.nativeEvent.inputType === 'insertLineBreak') {
      return;
    }
    const input = e.nativeEvent.target.value.substring(0, maxCharacters);
    setInput(input);
  };

  const submitOnEnter = e => {
    if (e.which === 13) {
      e.target.form.dispatchEvent(new Event('submit', { cancelable: true }));
      e.preventDefault(); // Prevents the addition of a new line in the text field (not needed in a lot of cases)
    }
  };

  const submitInput = e => {
    setInput('');
    e.preventDefault();
    if (input === '') return false;
    send(input);
    return true;
  };

  const handleOnFocus = () => {
    setKeyboardActive(true);
  };

  const handleOnBlur = () => {
    setKeyboardActive(false);
  };

  return (
    <Container>
      <Form id="inputForm" onSubmit={submitInput}>
        <StyledInput
          ref={textArea}
          value={input}
          onChange={handleChange}
          onKeyUp={submitOnEnter}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          placeholder={'Schreiben Sie los...'}
          form={'inputForm'}
        />
        <ButtonContainer>
          <Button filled background="interactiveBlue" type="submit">
            <SendIcon />
          </Button>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default ChatInput;
