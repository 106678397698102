import styled, { css } from 'styled-components';
import { mediaMin } from 'common/layout';

export const StyledAccordion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90vw;
  margin: 0 auto;
  ${mediaMin(
    'medium',
    css`
      width: 100%;
      max-width: 100%;
    `
  )}
`;
export const StyledAccordionItem = styled.div`
  width: 100%;
  ${props =>
    props.isHeader &&
    props.useExpandedIcon &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    `};
  ${props =>
    !props.noPadding &&
    css`
      padding: 16px 20px;
      ${mediaMin(
        'medium',
        css`
          padding: 34px 48px;
        `
      )}
    `};
  ${props =>
    !props.disabled &&
    !props.autoExpanded &&
    css`
      cursor: pointer;
    `}
`;

export const StyledAccordionExpandedIcon = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 100%;
`;
