import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { Vector3, Euler } from 'three';

const r = 3.4666;

const i = -Math.PI * 0.5;
let j, k;
j = k = i;
j += (1 / 3) * Math.PI * 2;
k += (2 / 3) * Math.PI * 2;

const networkingPosition = new Vector3(Math.cos(j) * r, 0.0, Math.sin(j) * r);
networkingPosition.add(new Vector3(0.07, 0.0, 0.0).applyEuler(new Euler(0, (-Math.PI * 2) / 3, 0))); // adjust for small networking corner displacement

export const hotspots = [
  {
    contentType: CONTENT_TYPE.NETWORKING,
    position: networkingPosition,
    scale: 1,
  },
  {
    contentType: CONTENT_TYPE.ARENA,
    position: new Vector3(Math.cos(i) * r, 0.0, Math.sin(i) * r),
    scale: 1,
  },
  {
    contentType: CONTENT_TYPE.LIBRARY,
    position: new Vector3(Math.cos(k) * r, 0.0, Math.sin(k) * r),
  },
];
