import React, { useEffect, useState } from 'react';
import { chatApi, useChatStore } from 'services/ChatService';
import ChatMessageContainer from 'components/Chat/ChatMessageContainer';
import ChatOverview from 'components/Chat/ChatOverview';
import ChatInput from 'components/Chat/ChatInput';
import ChatCreator from 'components/Chat/ChatCreator';
import ChatUserList from 'components/Chat/ChatUserList';
import ChatMessageHint from 'components/Chat/ChatMessageHint';
import ChatPendingOverlay from 'components/Chat/ChatPendingOverlay';
import { StyledInputContainer, StyledButton, StyledStartNewChatContainer } from './styles';

const ChatContainer = ({ createNewChat, onToggleNewChat }) => {
  const activeChat = useChatStore(state => state.activeChat);
  const chatsAsc = useChatStore(state => state.chats);
  const chats = [...chatsAsc].reverse();
  const [chatList, setChatList] = useState([]);

  const updateChatList = users => {
    setChatList(users);
  };

  const onStartNewChat = () => {
    if (chatList.length === 0) return;
    chatApi.getState().start(chatList);
  };

  const content = () => {
    return createNewChat ? (
      <ChatCreator key={'creator'} updateChatList={updateChatList} />
    ) : activeChat ? (
      <ChatMessageContainer key={'messages'} chat={activeChat} />
    ) : (
      <ChatOverview key={'overview'} chats={chats} />
    );
  };

  useEffect(() => {
    if (activeChat && createNewChat) onToggleNewChat();
  }, [activeChat]);

  return (
    <>
      {content()}
      <ChatPendingOverlay />
      {!createNewChat && !activeChat && (
        <StyledStartNewChatContainer>
          <StyledButton filled background="interactiveBlue" onClick={onToggleNewChat}>
            Neuer Chat
          </StyledButton>
        </StyledStartNewChatContainer>
      )}
      <StyledInputContainer>
        {activeChat && (
          <>
            <ChatMessageHint />
            <ChatInput maxCharacters={1000} />
          </>
        )}
        {createNewChat && <ChatUserList chatList={chatList} onStartNewChat={onStartNewChat} />}
      </StyledInputContainer>
    </>
  );
};

export default ChatContainer;
