import React, { useState } from 'react';
import { useFrame } from 'react-three-fiber';
import { contentApi } from 'services/ContentService';
import Hotspot from 'components/Hotspot';
import { hotspots } from './hotspots';
import { cameraApi } from 'services/CameraService';

const lockIndexToHotspot = [hotspots[1], hotspots[2], hotspots[0]];

export default function HotSpotController() {
  const [potentialHotspot, setPotentialHotspot] = useState(hotspots[0]);

  useFrame(() => {
    const orbitLockIndexCandidate = cameraApi.getState().orbit.getCurrentLockCandidate(false);
    if (orbitLockIndexCandidate >= 0 && orbitLockIndexCandidate < lockIndexToHotspot.length) {
      const potentialCandidate = lockIndexToHotspot[orbitLockIndexCandidate];
      if (potentialHotspot !== potentialCandidate) {
        contentApi.getState().setPotentialContent(potentialCandidate.contentType);
        setPotentialHotspot(potentialCandidate);
      }
    }
  });

  const hotspotComponents = hotspots.map((hotspot, index) => {
    return (
      <object3D key={index}>
        <Hotspot hotspot={hotspot} potentialHotspot={potentialHotspot} />
      </object3D>
    );
  });

  return <>{hotspotComponents}</>;
}
