import styled from 'styled-components';
import { colors } from 'styles';
import { Overline2, Body2 } from 'common/text';

export const ChatBubbleContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.floatRight ? 'row-reverse' : 'row')};
  width: 100%;
  margin: 8px 0;
  justify-content: flex-start;
`;

export const Bubble = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  padding: 16px 24px;
  text-align: left;
  border-radius: 16px 16px ${props => (props.isSelf ? '0 16px' : '16px 0')};
  background: ${props =>
    props.isSelf
      ? 'linear-gradient(76.27deg, #2E47AC 0%, #4C7FDA 101.28%)'
      : 'linear-gradient(92.68deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.075) 100%)'};
  color: #fff;
  word-break: break-word;
  opacity: ${props => (props.hide ? 0.3 : 1)};
`;

export const StyledMessageText = styled(Body2)`
  user-select: text;
  cursor: text;
`;

export const Sender = styled(Overline2)`
  cursor: ${props => (props.isInteractive ? 'pointer' : 'auto')};
  color: ${props => (props.isSelf ? 'white' : colors.highlightCyan)};
  margin-bottom: 2px;
`;

export const ChatBubbleVisibilityToggle = styled.div`
  align-self: center;
  display: flex;
  justify-content: ${props => (props.isSelf ? 'flex-start' : 'flex-end')};
  margin: ${props => (props.isSelf ? '0 15px 0 0' : '0 0 0 15px')};
`;
