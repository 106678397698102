import create from 'utilities/zustand/create';

export const [useUiStore, uiApi] = create(module, set => ({
  showMenu: true,
  showEmojiBar: true,
  showNavigation: true,
  setShowMenu: showMenu => set({ showMenu }),
  setShowEmojiBar: showEmojiBar => set({ showEmojiBar }),
  setShowNavigation: showNavigation => set({ showNavigation }),
  hideAll: () => {
    set({ showMenu: false, showEmojiBar: false, showNavigation: false });
  },
}));
