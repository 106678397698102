import React from 'react';
import {
  Container,
  ContentContainer,
  CenteredContent,
  IpsenLogoDesktop,
  IpsenLogoMobile,
  BottomLinkContainer,
  BottomLinkContainerContainer,
  BottomLink,
  Transition,
  WelcomeHeadline,
} from './styles';
import IpsenLogoSrc from 'common/assets/ipsenLogo.png';
import { trackEvent } from 'utilities/analytics';
import { useUserStore } from 'services/UserService';
import { useApplicationStore } from 'services/ApplicationService';

export default function PreEventScreen({ children, headline = 'Herzlich Willkommen' }) {
  const appStore = useApplicationStore();
  const loginState = useUserStore(state => state.loginState);

  const isReconnecting = loginState === 'RECONNECTING';

  const subject = appStore.theme === 'branded' ? 'Ipsen Space Login' : 'Campusdialog-Fortbildung Login';

  return (
    <Container>
      <ContentContainer>
        {appStore.theme === 'branded' && <IpsenLogoMobile src={IpsenLogoSrc} />}
        <Transition animIndex={1}>
          <WelcomeHeadline>{isReconnecting ? 'Bitte warten...' : headline}</WelcomeHeadline>
        </Transition>
        <CenteredContent>{!isReconnecting && children}</CenteredContent>
        <BottomLinkContainerContainer>
          <BottomLinkContainer>
            <BottomLink
              onClick={() => {
                window.open('https://www.ipsen.com/germany/contact-us/');
                trackEvent('Login', 'Open', 'Kontakt');
              }}
            >
              Kontakt
            </BottomLink>
            <BottomLink
              onClick={() => {
                window.open('https://www.ipsen.com/germany/datenschutz/', '_blank');
                trackEvent('Login', 'Open', 'Datenschutz');
              }}
            >
              Datenschutz
            </BottomLink>
            <BottomLink
              onClick={() => {
                window.open('https://www.ipsen.com/germany/impressum/', '_blank');
                trackEvent('Login', 'Open', 'Impressum');
              }}
            >
              Impressum
            </BottomLink>
          </BottomLinkContainer>
        </BottomLinkContainerContainer>
      </ContentContainer>
      {appStore.theme === 'branded' && <IpsenLogoDesktop src={IpsenLogoSrc} />}
    </Container>
  );
}
