import React from 'react';
import { menuApi, useMenuStore } from 'services/MenuService';
import IconPeople from '../assets/profiles.svg';
import MenuButton from '../components/MenuButton';

export default function PeopleButton() {
  const activeMenuComponent = useMenuStore(state => state.activeMenuComponent);
  const people = useMenuStore(state => state.people);

  const onClick = () => {
    menuApi.getState().setActiveMenuComponent(activeMenuComponent === people ? null : people);
  };

  return (
    <MenuButton active={activeMenuComponent === people} onClick={onClick} hoverLabel="Teilnehmer">
      <IconPeople />
    </MenuButton>
  );
}
