import axios from 'axios';
import { socketApi } from 'services/SocketService';
import { userApi } from 'services/UserService';
import create from 'utilities/zustand/create';
import userStore from 'storage/user';

export const [useEventStore, eventApi] = create(module, (set /*, get*/) => ({
  event: null,
  init: async () => {
    const token = userStore.getToken();
    const restUrl = socketApi.getState().getRestUrl('strapi');
    let event;
    try {
      const { eventId } = userApi.getState();
      const response = await axios.get(`${restUrl}events/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      event = response.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (event) {
      set({ event });
    } else {
      set({ event: null });
    }
  },
  reset: () => {
    set({ event: null });
  },
}));
