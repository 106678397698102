import { baseColors, zIndex } from 'styles';
import styled, { keyframes, css } from 'styled-components';
import { Body2, H1 } from 'common/text';

const aspectRatio = '1/1';

const appear = index => keyframes`
  from { opacity: 0; transform: translateY(${index * 5 + 20}px); }
  to { opacity: 1; transform: translateY(0px); }
`;

export const StyledNavigation = styled.div`
  z-index: ${zIndex.navigation};
  position: absolute;
  left: 0%;
  bottom: 23%;
  pointer-events: none;
  opacity: ${props => (props.show ? 1 : 0)};
  animation: ${props => props.show && appear} 1s ease forwards;
  ${props =>
    props.isLibrary &&
    css`
      @media only screen and (min-width: 1024px) and (min-aspect-ratio: ${aspectRatio}) {
        margin-left: 4vw;
      }
    `}


  @media only screen and (max-width: 1023px), only screen and (max-aspect-ratio: ${aspectRatio}) { {
    bottom: initial;
    top: 10%;
    left: 50%;
  }
`;

export const StyledTransform = styled.div`
  @media only screen and (min-width: 1024px) and (min-aspect-ratio: ${aspectRatio}) {
    transform: translateX(${props => props.x}px);
  }
  @media only screen and (max-width: 1023px), only screen and (max-aspect-ratio: ${aspectRatio}) {
    transform: translateX(-50%);
  }
`;

const animation = css`
  opacity: 0;
  animation: ${props => props.show && appear(props.index)} 0.8s ${props => props.index * 0.05}s ease-out forwards;
`;

export const StyledHeadline = styled(H1)`
  ${animation};
  color: ${baseColors.white};
  font-weight: 300;
  font-size: 96px;
  line-height: 140%;
  margin: 0;
  @media only screen and (max-width: 1439px) {
    font-size: 48px;
    margin-top: 8px;
    margin-bottom: 16px;
  )};
  @media only screen and (max-width: 1023px), only screen and (max-aspect-ratio: ${aspectRatio}) {
    line-height: 100%;
    text-align: center;
    font-size: 40px;
    margin-top: 16px;
    margin-bottom: 24px;
  )};
`;

export const StyledSubline = styled(Body2)`
  ${animation};
  color: ${baseColors.white};
  margin: 0;
  line-height: 100%;
  @media only screen and (max-width: 1023px), only screen and (max-aspect-ratio: ${aspectRatio}) {
    line-height: 140%;
    text-align: center;
  )};
`;

export const StyledButtonContainer = styled.div`
  ${animation};
  position: absolute;
  @media only screen and (max-width: 1023px), only screen and (max-aspect-ratio: ${aspectRatio}) {
    width: 100%;
    button {
      margin: 0 auto;
    }
  }
`;
