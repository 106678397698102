import React from 'react';
import styled, { css } from 'styled-components';
import { chatApi } from 'services/ChatService';
import { baseColors, colors } from 'styles';
import IconDistrict from 'components/Menu/assets/eventchat.svg';
import { iconFillAndStroke } from 'common/icon';
import { StyledFullname, StyledSpeciality } from 'common/components/PersonComponent/PersonHeader/styles';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin: 12px 0;
`;

const Icon = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  background: ${props => props.color};
  margin-right: 12px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  font-family: PF Highway Sans Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 140%;

  color: #ffffff;
  svg {
    ${iconFillAndStroke('white', 'white')};
  }

  ${props =>
    props.groupMemberCount &&
    css`
      border-radius: 50%;
    `}
`;

const UnseenNotification = styled.div`
  position: absolute;
  right: ${props => (props.isDisctrictOrSupportChat ? -4 : -2)}px;
  top: ${props => (props.isDisctrictOrSupportChat ? -4 : -2)}px;
  width: ${props => (props.isDisctrictOrSupportChat ? 12 : 7)}px;
  height: ${props => (props.isDisctrictOrSupportChat ? 12 : 7)}px;
  border-radius: 50%;
  background-color: ${props => (props.isDisctrictOrSupportChat ? baseColors.red : colors.accent)};
  ${props => props.isDisctrictOrSupportChat && `border 2px solid ${baseColors.grey.base}`};
`;

const StyledChatRoom = styled.div`
  padding: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ChatSelector = props => {
  const { chat } = props;
  const chatName = chat.name;
  const onClick = () => {
    chatApi.getState().enter(chat);
  };

  const renderIcon = () => {
    if (chat.type === 'district') {
      return (
        <Icon color={'linear-gradient(76.27deg, #E78650 0%, #FFAA7A 101.28%)'}>
          <IconDistrict width={32} height={32} />
          {chat && !chat.seen && <UnseenNotification isDisctrictOrSupportChat />}
        </Icon>
      );
    }

    return (
      <Icon color={'linear-gradient(76.27deg, #049EA8 0%, #74DAE1 101.28%)'}>
        {chat.users.length}
        {chat && !chat.seen && <UnseenNotification isDisctrictOrSupportChat />}
      </Icon>
    );
  };

  return (
    <Container onClick={onClick}>
      {renderIcon()}
      <StyledChatRoom>
        <StyledFullname>{chatName}</StyledFullname>
        {chat.type === 'district' && <StyledSpeciality>Chatten Sie mit allen Teilnehmern.</StyledSpeciality>}
      </StyledChatRoom>
    </Container>
  );
};

export default ChatSelector;
