import create from 'utilities/zustand/create';
import userStore from 'storage/user';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { contentApi } from 'services/ContentService';
import { trackEvent } from 'utilities/analytics';
import { arenaApi } from 'services/ArenaService';

export const [useSoundStore, soundApi] = create(module, (set, get) => ({
  enabled: userStore.getSoundEnabled(),

  reset: () => {},

  toggle: () => {
    const enabled = !get().enabled;
    set({ enabled });
    userStore.setSoundEnabled(enabled);
    const activeContent = contentApi.getState().activeContent;
    const contentType = activeContent && activeContent.type.id;
    const useSound = contentType === CONTENT_TYPE.ARENA.id && enabled;
    const { videoTeaser } = arenaApi.getState();
    if (videoTeaser) {
      videoTeaser.volume = useSound ? 1 : 0;
      videoTeaser.muted = !useSound;
    }
    trackEvent('Sound', enabled ? 'Unmute' : 'Mute');
  },

  playHubAmbient: () => {
    const { videoTeaser } = arenaApi.getState();
    if (videoTeaser) {
      videoTeaser.volume = 0;
      videoTeaser.muted = true;
    }
  },

  playArenaAmbient: () => {
    const { enabled } = get();
    const { videoTeaser } = arenaApi.getState();
    if (videoTeaser) {
      videoTeaser.volume = enabled ? 1 : 0;
      videoTeaser.muted = !enabled;
    }
  },
}));
