import React, { useState, useEffect } from 'react';
import { H4 } from 'common/text';
import styled from 'styled-components';
import DatGuiPanel from './DatGuiPanel';
import { useDebugStore } from 'storage/debug';
import ToggleButton from 'common/components/ToggleButton';
import { useProfilingStore } from 'services/ProfilingService';
import { HIGHEST_ONLINE_WISP_COUNT, offlineOpacity, onlineColor, onlineOpacity, wispApi } from 'services/WispService';
import { userApi } from 'services/UserService';
import { offlineColor } from 'services/WispService';
import { useInterval } from 'utilities/hooks';
import calculateLimitRatio from 'utilities/canvas/limiter';

import { StyledContainer } from './styles';
import MaterialsPanel from './MaterialPanel';

const Container = styled.div`
  position: relative;
  padding: 0 20px 20px;
`;

function Developer() {
  const debugStore = useDebugStore();
  const marks = useProfilingStore(state => state.marks);
  const [display, setDisplay] = useState(null);

  const updateDisplay = () => {
    const canvasContainer = document.getElementById('mainCanvas');
    const canvas = canvasContainer.children[0];
    const canvasWidth = canvas.getAttribute('width');
    const canvasHeight = canvas.getAttribute('height');
    const limiterRatio = debugStore.getCanvasResolutionLimitEnabled() ? calculateLimitRatio() : 1;

    const display = {
      window: { x: window.innerWidth, y: window.innerHeight },
      pixels: { x: Math.ceil(canvasWidth / limiterRatio), y: Math.ceil(canvasHeight / limiterRatio) },
      canvas: { x: canvasWidth, y: canvasHeight },
      limiterRatio,
    };

    setDisplay(display);
  };

  useEffect(() => {
    updateDisplay();
  }, []);

  useInterval(() => {
    updateDisplay();
  }, 1000);

  const changeWispActivity = (activity, factor) => {
    const wisps = wispApi.getState().wisps;
    let count = HIGHEST_ONLINE_WISP_COUNT;
    count *= factor;
    for (let i = 0; i < count; ++i) {
      const wisp = wisps[i];
      if (wisp.id === userApi.getState().user.id) {
        continue;
      }
      wisp.activity = activity;
      wisp.state.targetColor = activity !== null ? onlineColor : offlineColor;
      wisp.state.targetOpacity = activity !== null ? onlineOpacity : offlineOpacity;
    }
  };

  const onArenaClick = () => {
    changeWispActivity('ARENA', 1);
  };

  const onLibraryClick = () => {
    changeWispActivity('LIBRARY', 1);
  };

  const onNetworkingClick = () => {
    changeWispActivity('NETWORKING', 1);
  };

  const onRoamClick = () => {
    changeWispActivity(null, 1);
  };

  const onArenaClick50 = () => {
    changeWispActivity('ARENA', 0.5);
  };

  const onLibraryClick50 = () => {
    changeWispActivity('LIBRARY', 0.5);
  };

  const onNetworkingClick50 = () => {
    changeWispActivity('NETWORKING', 0.5);
  };

  const onArenaClick25 = () => {
    changeWispActivity('ARENA', 0.25);
  };

  const onLibraryClick25 = () => {
    changeWispActivity('LIBRARY', 0.25);
  };

  const onNetworkingClick25 = () => {
    changeWispActivity('NETWORKING', 0.25);
  };

  const body = (
    <Container>
      &nbsp;
      <H4>Options</H4>
      <div>
        Show Developer Menu:
        <ToggleButton
          active={debugStore.getDeveloperContentEnabled()}
          onToggle={value => {
            debugStore.setDeveloperContentEnabled(value);
          }}
        />
      </div>
      <div>
        Limit Resolution to Full HD:
        <ToggleButton
          active={debugStore.getCanvasResolutionLimitEnabled()}
          onToggle={value => {
            debugStore.setCanvasResolutionLimitEnabled(value);
          }}
        />
      </div>
      <div>
        Show Socket Messages:
        <ToggleButton
          active={debugStore.getSocketDebugEnabled()}
          onToggle={value => {
            debugStore.setSocketDebugEnabled(value);
          }}
        />
      </div>
      <div>
        Disable 3D:
        <ToggleButton
          disabled={process.env.NO_3D}
          active={debugStore.get3dDisabled()}
          onToggle={value => {
            debugStore.set3dDisabled(value);
          }}
        />
      </div>
      <div>
        Enable Stats:
        <ToggleButton
          active={debugStore.getStatsEnabled()}
          onToggle={value => {
            debugStore.setStatsEnabled(value);
          }}
        />
      </div>
      {display && (
        <>
          <H4>Display</H4>
          <div>
            window: {display.window.x} x {display.window.y}
          </div>
          <div>
            pixels: {display.pixels.x} x {display.pixels.y}
          </div>
          <div>+</div>
          <div>limiter ratio: {display.limiterRatio}</div>
          <div>device pixel ratio: {window.devicePixelRatio}</div>
          <div>=</div>
          <div>
            canvas: {display.canvas.x} x {display.canvas.y}
          </div>
        </>
      )}
      <H4>Wisps</H4>
      <button onClick={onArenaClick}>arena</button>
      <button onClick={onLibraryClick}>library</button>
      <button onClick={onNetworkingClick}>networking</button>
      <button onClick={onRoamClick}>roam</button>
      <hr />
      <button onClick={onArenaClick50}>arena 50%</button>
      <button onClick={onLibraryClick50}>library 50%</button>
      <button onClick={onNetworkingClick50}>networking 50%</button>
      <hr />
      <button onClick={onArenaClick25}>arena 25%</button>
      <button onClick={onLibraryClick25}>library 25%</button>
      <button onClick={onNetworkingClick25}>networking 25%</button>
      <H4>Dat GUIs</H4>
      <DatGuiPanel />
      <H4>Materials</H4>
      <MaterialsPanel />
      <H4>Profiling</H4>
      {marks.map((m, i) => {
        return (
          <div key={i}>
            {m.message} {m.time}
          </div>
        );
      })}
    </Container>
  );

  return <StyledContainer>{body}</StyledContainer>;
}

export default Developer;
