import styled from 'styled-components';
import { StyledPersonImage } from 'common/components/PersonImage/styles';

export const PersonContainer = styled.div`
  display: flex;
  margin: 12px 0;

  ${StyledPersonImage} {
    margin-right: 12px;
  }
`;
