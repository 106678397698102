import React, { useMemo, useState } from 'react';
import { useLongPress } from 'use-long-press';
import { NewReactionOverlay, Reaction, ReactionBubble, ReactionImage, Reactions } from './styles';
import { UsersOverlay } from '../UsersOverlay/styles';
import { chatApi } from 'services/ChatService';
import { userApi } from 'services/UserService';
import { useDocumentListener, useDocumentClickListener } from 'utilities/hooks';

import Heart from 'common/assets/Emojis/emoji_heart.png';
import Laugh from 'common/assets/Emojis/emoji_laugh.png';
import Like from 'common/assets/Emojis/emoji_like.png';
import Clap from 'common/assets/Emojis/emoji_clap.png';
import IconAddReaction from './chat-reaction.svg';

const ALL_REACTIONS = [
  {
    name: 'like',
    image: Like,
  },
  {
    name: 'heart',
    image: Heart,
  },
  {
    name: 'laugh',
    image: Laugh,
  },
  {
    name: 'clap',
    image: Clap,
  },
];

function NewReaction({ message, floatRight }) {
  const [overlayOpen, setOverlayOpen] = useState(false);
  useDocumentClickListener(overlayOpen, () => {
    setOverlayOpen(false);
  });
  return (
    <Reaction
      onClick={() => {
        setOverlayOpen(!overlayOpen);
      }}
    >
      <IconAddReaction />
      {overlayOpen && (
        <NewReactionOverlay floatRight={floatRight}>
          {ALL_REACTIONS.map((r, i) => {
            return (
              <Reaction key={i}>
                <img
                  onClick={() => {
                    chatApi.getState().toggleReaction(r.name, message);
                    setOverlayOpen(false);
                  }}
                  src={r.image}
                  alt={''}
                />
              </Reaction>
            );
          })}
        </NewReactionOverlay>
      )}
    </Reaction>
  );
}

function SingleReaction({ message, reaction, nameReactions, floatRight }) {
  const [overlayOpen, setOverlayOpen] = useState(false);

  const names = useMemo(() => {
    return nameReactions
      .map(user => {
        if (user.userId === userApi.getState().user.id) {
          return 'Ich';
        } else {
          return user.name.replace(/ /g, '\u00a0');
        }
      })
      .join(', ');
  }, [nameReactions]);

  const selfInReactions = useMemo(() => {
    return nameReactions.find(user => user.userId === userApi.getState().user.id);
  }, [nameReactions]);

  const bind = useLongPress(() => {
    setOverlayOpen(true);
  });

  useDocumentListener(overlayOpen, 'touchstart', () => {
    setOverlayOpen(false);
  });

  useDocumentClickListener(overlayOpen, () => {
    setOverlayOpen(false);
  });

  return (
    <Reaction>
      <ReactionBubble
        {...bind}
        showSelfOutline={selfInReactions}
        onMouseEnter={() => {
          setOverlayOpen(true);
        }}
        onMouseLeave={() => {
          setOverlayOpen(false);
        }}
        onClick={() => {
          chatApi.getState().toggleReaction(reaction.name, message);
        }}
      >
        <ReactionImage src={reaction.image} alt={''} />
        <span>{nameReactions.length}</span>
        {overlayOpen && (
          <UsersOverlay floatLeft={!floatRight} floatRight={floatRight}>
            {names}
          </UsersOverlay>
        )}
      </ReactionBubble>
    </Reaction>
  );
}

const ChatReactions = ({ message, floatRight }) => {
  const messageReactions = message.reactions || {};
  return (
    <Reactions>
      {Object.keys(messageReactions).map(name => {
        const nameReactions = messageReactions[name];
        const reaction = ALL_REACTIONS.find(r => r.name === name);
        if (reaction) {
          return (
            <SingleReaction
              key={name}
              message={message}
              reaction={reaction}
              nameReactions={nameReactions}
              floatRight={floatRight}
            />
          );
        } else {
          return null;
        }
      })}
      <NewReaction message={message} floatRight={floatRight} />
    </Reactions>
  );
};

export default ChatReactions;
