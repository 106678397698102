const limitWidth = 1920;
const limitHeight = 1080;

export default function calculateLimitRatio() {
  const devicePixelRatio = window.devicePixelRatio;
  let ratio = 1;
  const realWidth = window.innerWidth * devicePixelRatio;
  if (realWidth > limitWidth) {
    const limitFactor = realWidth / limitWidth;
    ratio /= limitFactor;
  } else {
    const realHeight = window.innerHeight * devicePixelRatio;
    // less likely, but still we should handle it
    if (realHeight > limitHeight) {
      const limitFactor = realHeight / limitHeight;
      ratio /= limitFactor;
    }
  }
  return ratio;
}
