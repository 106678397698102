import React, { useEffect, useRef } from 'react';
import { useSceneStore } from 'services/SceneService';
import { Container, LoaderWrapper, DelayedMessage } from './styles';
import LoaderSVG from './assets/loader.svg';

export default function VisualLoader() {
  const isLoaded = useSceneStore(state => state.isLoaded);
  const bgIsLoaded = useSceneStore(state => state.bgIsLoaded);
  const delayedMessage = useRef();

  const finished = isLoaded && bgIsLoaded;

  useEffect(() => {
    if (!finished) {
      setTimeout(() => {
        if (delayedMessage.current) {
          delayedMessage.current.style.opacity = '1';
        }
      }, 5000);
    }
  }, [finished]);

  if (finished) {
    return null;
  }

  return (
    <Container>
      <LoaderWrapper>
        <LoaderSVG />
      </LoaderWrapper>
      <DelayedMessage ref={delayedMessage}>Bitte haben Sie noch etwas Geduld...</DelayedMessage>
    </Container>
  );
}
