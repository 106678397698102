import React, { useRef, useState } from 'react';
import { useEmojiStore } from 'services/EmojiService';
import { EmoteButton, emojis } from '../index';
import { colors, easings } from 'styles';
import ArrowIcon from 'common/assets/Icon_Arrow_Down.svg';

import styled, { css } from 'styled-components';
import { mediaMin } from 'common/layout';
import { useDocumentClickListener, useDocumentListener } from 'utilities/hooks';
import { zIndex } from 'styles';

const ArrowIconContainer = styled.div`
  width: 12px;
  transform: rotate(180deg);
  margin-left: 7px;
  path {
    fill: ${colors.interactiveBlue};
  }
`;

const Container = styled.div`
  z-index: ${zIndex.emoji};
  position: absolute;
  right: 24px;
  bottom: 90px;
  overflow: hidden;
  border-radius: 8px;
  pointer-events: none;
`;

const OpenButton = styled.div`
  position: absolute;
  bottom: 0;
  width: 64px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: ${props => (props.open ? 'translateY(50%)' : 'translateY(0)')};
  transition: transform 0.5s ease-out;
  pointer-events: all;
  * {
    pointer-events: none;
  }
`;

const EmoteRow = styled.div`
  pointer-events: all;
  padding: 8px 20px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  transform: ${props => (props.open ? 'translateY(0)' : 'translateY(100%)')};
  transition: transform 0.5s ${easings.easeOutSemiStrong};
  ${mediaMin(
    'medium',
    css`
      display: none;
    `
  )}
`;

const ChatEmotesMobile = () => {
  const [open, setOpen] = useState(false);
  const sendEmoji = useEmojiStore(state => state.sendEmoji);
  const containerRef = useRef();

  const onClickEmoji = emoji => {
    sendEmoji(emoji.type);
  };

  const close = () => {
    setOpen(false);
  };

  useDocumentClickListener(open, close, containerRef);
  useDocumentListener(open, 'touchstart', close, containerRef);

  return (
    <Container ref={containerRef}>
      <OpenButton
        open={open}
        onClick={() => {
          setOpen(true);
        }}
      >
        <EmoteButton show>
          <img src={emojis[2].img} alt={''} />
        </EmoteButton>
        <ArrowIconContainer>
          <ArrowIcon />
        </ArrowIconContainer>
      </OpenButton>
      <EmoteRow open={open}>
        {emojis.map((emoji, index) => (
          <EmoteButton
            index={index}
            show={open}
            key={index}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              onClickEmoji(emoji);
            }}
          >
            <img src={emoji.img} alt={''} />
          </EmoteButton>
        ))}
      </EmoteRow>
    </Container>
  );
};

export default ChatEmotesMobile;
