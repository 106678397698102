import React, { useEffect, useRef, useState } from 'react';
import ChatBubble from 'components/Chat/ChatBubble';
import { useChatStore, chatApi } from 'services/ChatService';
import styled, { css } from 'styled-components';
import { center } from 'styles';
import { H5 } from 'common/text';
import { ScrollContent } from 'components/Menu/SubMenu/styles';
import { userApi } from 'services/UserService';
import { mediaMax } from 'common/layout';

const StyledChatMessageContainer = styled.div`
  padding: 10px 0 0 0;
`;

const topNoticeStyle = css`
  position: relative;
  margin: 10px auto 20px auto;
  text-align: center;
  font-weight: bold;
`;

const bottomNoticeStyle = css`
  ${center.horizontal()};
  bottom: 10px;
`;

const StyledNotice = styled(H5)`
  width: 100%;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.5);
  ${props => (props.isTop ? topNoticeStyle : bottomNoticeStyle)};
  padding-left: 40px;
  ${mediaMax(
    'small',
    css`
      padding-left: 24px;
    `
  )}
  span {
    display: block;
  }
`;

const SCROLL_THRESHOLD = 1;

const ChatMessageContainer = ({ chat }) => {
  const pending = useChatStore(state => state.pending);
  const loadMessages = useChatStore(state => state.loadMessages);
  const [loaded, setLoaded] = useState(false);
  const [visibleMessages, setVisibleMessages] = useState([]);
  const messageCount = visibleMessages ? visibleMessages.length : 0;
  const scrollContent = useRef();
  const shouldScrollToBottom = useRef(true);
  const isLoadingMoreMessages = useRef(false);

  useEffect(() => {
    if (!pending && !loaded) {
      setLoaded(true);
    }
  }, [pending, loaded]);

  const shouldScroll = () => {
    const latestMessage = visibleMessages.length && visibleMessages[visibleMessages.length - 1];
    const isMyMessage = latestMessage && latestMessage.user.id === userApi.getState().user.id;
    return isMyMessage || shouldScrollToBottom.current;
  };

  const scrollToBottom = () => {
    const doSmoothScroll = messageCount < 20;
    if (scrollContent.current) {
      if (shouldScroll()) {
        shouldScrollToBottom.current = false;
        scrollContent.current.scroll({
          top: scrollContent.current.scrollHeight,
          behavior: doSmoothScroll ? 'smooth' : 'auto',
        });
      }
    }
  };

  const isAtTop = () => {
    return scrollContent.current.scrollTop < SCROLL_THRESHOLD;
  };
  const isAtBottom = () => {
    return (
      scrollContent.current.scrollHeight - scrollContent.current.scrollTop - scrollContent.current.clientHeight <
      SCROLL_THRESHOLD
    );
  };

  useEffect(() => {
    if (!isLoadingMoreMessages.current) {
      shouldScrollToBottom.current = shouldScrollToBottom.current || isAtBottom();
    }
    setVisibleMessages([...chat.messages]);
  }, [chat]);

  const updatedHeight = element =>
    new Promise(resolve => {
      const height = element.scrollHeight;
      const timer = setInterval(() => {
        if (height !== element.scrollHeight && element.scrollHeight !== 0) {
          clearInterval(timer);
          setTimeout(() => {
            resolve();
          }, 1);
        }
      }, 1);
    });
  const notAtTop = () =>
    new Promise(resolve => {
      const timer = setInterval(() => {
        if (!isAtTop()) {
          clearInterval(timer);
          resolve();
        }
      }, 1);
    });

  const handleScroll = async event => {
    if (!event.target || isLoadingMoreMessages.current) return;
    if (isAtTop()) {
      isLoadingMoreMessages.current = true;

      const prevHeight = scrollContent.current.scrollHeight;
      scrollContent.current.scroll({ top: scrollContent.current.scrollTop + SCROLL_THRESHOLD + 1 });

      await loadMessages(chat);
      await updatedHeight(scrollContent.current);

      const top = scrollContent.current.scrollHeight - prevHeight;
      scrollContent.current.scroll({ top });

      await notAtTop();
      isLoadingMoreMessages.current = false;
      shouldScrollToBottom.current = false;
    }
    if (!chat.seen && isAtBottom()) {
      chatApi.getState().setChatSeen();
    }
  };

  useEffect(() => {
    if (visibleMessages.length > 0) {
      if (shouldScroll()) {
        chatApi.getState().setChatSeen();
      }
      if (!isLoadingMoreMessages.current) {
        // @todo: get rid of setTimeout
        setTimeout(() => {
          if (!isLoadingMoreMessages.current && shouldScrollToBottom.current) {
            scrollToBottom();
          }
        }, 0);
      }
    }
  }, [visibleMessages]);

  useEffect(() => {
    if (chat.id !== null && !chat.hasInitialLoad) {
      chatApi.getState().loadMessages(chat);
    } else {
      chatApi.getState().setPending(false);
      // setLoaded(false);
    }
  }, [chat]);

  useEffect(() => {
    if (scrollContent.current) scrollContent.current.addEventListener('scroll', handleScroll);
    return () => {
      if (scrollContent.current) scrollContent.current.removeEventListener('scroll', handleScroll);
    };
  }, [chat]);

  return (
    <ScrollContent forwardRef={scrollContent}>
      <StyledChatMessageContainer>
        {!loaded ? (
          <StyledNotice />
        ) : (
          <>
            {visibleMessages.map((message, i) => (
              <ChatBubble key={i} message={message} />
            ))}
            {!messageCount && <StyledNotice>Es gibt noch keine Nachrichten in diesem Chat.</StyledNotice>}
          </>
        )}
      </StyledChatMessageContainer>
    </ScrollContent>
  );
};

export default ChatMessageContainer;
