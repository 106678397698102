import React from 'react';
import { StyledIndicatorContainer, StyledIndicator } from './styles';

export default function Indicator() {
  return (
    <StyledIndicatorContainer>
      <StyledIndicator />
    </StyledIndicatorContainer>
  );
}
