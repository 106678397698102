import styled, { css, keyframes } from 'styled-components';
import { backdropBlurOrGradient, BG_SOLID, SolidBackdropGradient, zIndex } from 'styles';
import { mediaMin, mediaMax, mobileLandscapeFullHeightBreakPoint } from 'common/layout';
import { H1 } from 'common/text';
import { StyledMenuButton } from './components/MenuButton/styles';
import { StyledIndicator } from './components/Indicator/styles';

export const menuWidth = '64px';
export const menuHeight = '64px';
export const subMenuWidth = '470px';
export const combinedMenuWidth = '534px';

export const marginTopAndButton = 15;
export const menuButtonSize = 22;

export const StyledMenuContainer = styled.div`
  z-index: ${zIndex.menu};
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: ${props => (props.menuActive ? combinedMenuWidth : menuWidth)}};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  ${SolidBackdropGradient};
  ${mediaMin('medium', backdropBlurOrGradient(40))};

  pointer-events: ${props => (props.show ? 'all' : 'none')};
  user-select: none;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity ${props => (props.show ? 2 : 0.15)}s ease-in-out;

  ${mediaMax(
    'small',
    css`
      height: ${props => (props.menuActive ? '100%' : menuHeight)};
      width: 100%;
    `
  )};

  ${props =>
    props.isFullHeight &&
    mediaMax(
      'small',
      css`
        @media only screen and (min-aspect-ratio: ${mobileLandscapeFullHeightBreakPoint}) {
          display: none;
        }
      `
    )}
`;

export const StyledMenu = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: ${menuWidth};
  height: 100%;

  ${mediaMax(
    'small',
    css`
      background: ${BG_SOLID};
      position: absolute;
      height: ${menuHeight};
      bottom: 0;
      width: 100%;
      flex-direction: row;
    `
  )};

  ${props =>
    props.solidBackground
      ? css`
          background: ${BG_SOLID};
        `
      : ''}
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  visibility: ${props => (props.hidden ? 'hidden' : 'initial')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  ${mediaMax(
    'small',
    css`
      display: none;
    `
  )};
`;

export const StyledMainMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mediaMax(
    'small',
    css`
      width: 100%;
      flex-direction: row;
    `
  )};
`;

export const StyledFooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  ${mediaMax(
    'small',
    css`
      display: none;
    `
  )};
`;

export const StyledSubMenu = styled.div`
  z-index: ${zIndex.submenu};
  display: ${props => (props.show ? 'flex' : 'none')};
  position: absolute;
  left: ${menuWidth};
  top: 0;
  width: ${subMenuWidth};
  height: 100%;
  flex-direction: column;
  color: #fff;

  ${mediaMax(
    'small',
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - ${menuHeight});
    `
  )};
`;

export const StyledSubMenuHeadline = styled(H1)`
  margin-top: 56px;
  margin-bottom: 32px;
  ${mediaMax(
    'small',
    css`
      margin-top: 18px;
      margin-bottom: 16px;
      padding-right: 32px;
      font-size: 24px;
    `
  )};
`;

export const StyledSubMenuHeader = styled.div`
  padding: 0 24px;
  ${mediaMin(
    'medium',
    css`
      padding: 0 40px;
    `
  )};
`;

export const StyledOnlyMobile = styled.div`
  ${mediaMin(
    'medium',
    css`
      display: none;
    `
  )};
`;

const offset = 70;
const SlideHorizontal = keyframes`
  from { opacity: 0; transform: translate(-${offset}px, 0); }
  to { opacity: 1; transform: translate(0, 0); }
`;
const SlideVertical = keyframes`
  from { opacity: 0; transform: translate(0, ${offset}px); }
  to { opacity: 1; transform: translate(0, 0); }
`;

export const SlideInAnimation = styled.div`
  ${StyledMenuButton} > svg, ${StyledIndicator} {
    transform: translate(0, ${offset}px);
    animation-name: ${props => props.show && SlideVertical};
    ${mediaMin(
      'medium',
      css`
        transform: translate(-${offset}px, 0);
        animation-name: ${props => props.show && SlideHorizontal};
      `
    )};
    animation-duration: 0.5s;
    animation-delay: ${props => props.index * 0.2}s;
    animation-easing: ease-out;
    animation-fill-mode: forwards;
  }
`;
