import styled from 'styled-components';
import { baseColors } from 'styles';

export const Content = styled.div`
  color: white;
  background: ${baseColors.blue.interactive};
  padding: 16px;
  padding-right: 32px;
  min-width: 220px;
  max-width: 210px;
  font-family: 'PF Highway Sans Pro';
  font-size: 12px;
  line-height: 140%;
  border-radius: 8px;
  button {
    border: none;
    background: none;
    padding: 0;
    color: white;
    margin-top: 10px;
    font-weight: 700;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 8px;
  cursor: pointer;
`;
