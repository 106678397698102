import React, { useEffect } from 'react';
import { sRGBEncoding } from 'three';
import { useResource, useThree } from 'react-three-fiber';
import { useEnvironmentStore } from './store';
import Skybox from './Skybox';

export default function Environment() {
  const toneMappings = useEnvironmentStore(state => state.toneMappings);
  const env = useEnvironmentStore(state => state.environmentConfiguration);

  //configure encoding
  const { scene, gl, camera } = useThree();
  gl.outputEncoding = sRGBEncoding;

  //configure toneMapping
  const toneMapping = toneMappings.find(i => i.name === env.toneMapping.name);
  gl.toneMapping = toneMapping.value;
  gl.toneMappingExposure = env.toneMapping.exposure;
  gl.toneMappingWhitePoint = env.toneMapping.whitePoint;
  useEffect(() => {
    scene.traverse(o => {
      if (o.material) {
        o.material.needsUpdate = true;
      }
    });
  }, [toneMapping.name]);

  //configure camera
  gl.logarithmicDepthBuffer = true;
  camera.far = 12000;
  camera.near = 0.1;
  camera.updateProjectionMatrix();

  const [ref, light] = useResource();
  const helper = false;
  const useLight = env.directionalLight.on;

  return (
    <>
      {useLight && (
        <directionalLight
          ref={ref}
          intensity={env.directionalLight.intensity}
          color={env.directionalLight.color}
          position={[-Math.cos(-Math.PI * 0.25) * 2, 1.0, Math.sin(-Math.PI * 0.25) * 2]}
        />
      )}
      {helper && light && <directionalLightHelper args={[light, 56]} />}
      <Skybox />
    </>
  );
}
