import React from 'react';
import { contentApi, createArenaContent, useContentStore } from 'services/ContentService';
import { CONTENT_TYPE, CONTENT_UI_TYPE } from 'services/ContentService/contentTypes';
import LiveStream from './LiveStream';
import LibraryCategory from './LibraryCategory';
import { StyledContent, StyledFullscreenContentContainer, StyledTopBar } from './styles';
import CloseButton from 'common/components/CloseButton';

export default function FullscreenContent() {
  const content = useContentStore(state => state.activeContent);
  const contentTypeId = content && content.type.id;
  const fullscreenActive = content != null && content.type.uiType === CONTENT_UI_TYPE.FULLSCREEN;

  const currentContent = () => {
    if (!fullscreenActive) return null;
    const { id } = content.type;
    switch (id) {
      case CONTENT_TYPE.LIVESTREAM.id:
        return <LiveStream />;
      case CONTENT_TYPE.LIBRARY_CATEGORY.id:
        return <LibraryCategory headline={content.headline} />;
    }
    return null;
  };

  const onBackClick = () => {
    if (content.type.id === CONTENT_TYPE.LIBRARY_CATEGORY.id) {
      contentApi.getState().setActiveContent({
        type: CONTENT_TYPE.LIBRARY,
      });
    } else if (content.type.id === CONTENT_TYPE.LIVESTREAM.id) {
      const content = createArenaContent();
      contentApi.getState().setActiveContent(content);
    } else {
      contentApi.getState().clearActiveContent();
    }
  };

  const isLivestream = contentTypeId === 'LIVESTREAM';

  return (
    <StyledFullscreenContentContainer fullscreenActive={fullscreenActive} isFullHeight={isLivestream}>
      {fullscreenActive && <StyledContent>{currentContent()}</StyledContent>}
      <StyledTopBar>
        <CloseButton onClick={onBackClick} />
      </StyledTopBar>
    </StyledFullscreenContentContainer>
  );
}
