import React from 'react';
import { useModalService } from 'services/ModalService';
import { ModalContainer, ContentContainer } from './styles';

const Modal = () => {
  const modal = useModalService(state => state.modal);
  return (
    <ModalContainer isVisible={modal !== null}>
      <ContentContainer>{modal}</ContentContainer>
    </ModalContainer>
  );
};

export default Modal;
