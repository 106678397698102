import styled from 'styled-components';
import { baseColors } from 'styles';
import { H2, Caption1 } from 'common/text';
import { Input } from 'common/components/Input';
import { Button } from 'common/components/Button';

export const StyledInput = styled(Input)`
  margin-bottom: 16px;
`;

export const StyledButton = styled(Button)`
  margin-top: 16px;
`;

export const CodeCaption = styled(Caption1)`
  margin: 25px 0 10px;
  color: rgba(255, 255, 255, 0.5);
`;

export const StyledPanelHeadline = styled(H2)`
  color: ${baseColors.white};
  margin: 0 0 20px 0;
  display: flex;
`;
