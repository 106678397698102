import React, { useState, useMemo } from 'react';
import { chatApi, useChatStore } from 'services/ChatService';
import { menuApi } from 'services/MenuService';
import CloseButton from 'common/components/CloseButton';
import ChatFilter from '../ChatFilter';
import { BackButton, SmallTitle, SmallTitleContainer, StyledTitleContainer, OverlayContainer } from './styles';
import IconBack from 'components/Menu/assets/back.svg';
import { UsersOverlay } from '../UsersOverlay/styles';
import { useUserStore, assembleNameWithTitle } from 'services/UserService';
import { useDocumentClickListener } from 'utilities/hooks';
import { StyledSubMenuHeadline } from 'components/Menu/styles';

const ChatTitle = ({ createNewChat, onToggleNewChat }) => {
  const activeChat = useChatStore(state => state.activeChat);
  const selfId = useUserStore(state => state.user.id);
  const [overlayOpen, setOverlayOpen] = useState(false);

  useDocumentClickListener(overlayOpen, () => {
    setOverlayOpen(false);
  });

  const onBackClick = () => {
    if (createNewChat) {
      onToggleNewChat();
    } else if (activeChat) {
      chatApi.getState().leave();
    }
  };

  const onCloseClick = () => {
    menuApi.getState().setActiveMenuComponent(null);
  };

  const names = useMemo(() => {
    if (!activeChat) return '';

    return activeChat.users
      .map(user => {
        if (user.id === selfId) {
          return 'Ich';
        } else {
          const fullname = assembleNameWithTitle(user);
          return fullname.replace(/ /g, '\u00a0');
        }
      })
      .join(', ');
  }, [activeChat]);

  const getChatTitle = () => {
    if (activeChat) {
      const isEventChat = activeChat.type === 'district';
      return isEventChat ? (
        <>
          <>
            <BackButton onClick={onBackClick}>
              <IconBack />
            </BackButton>
            {activeChat.name}
            {overlayOpen && (
              <OverlayContainer>
                <UsersOverlay under>{names}</UsersOverlay>
              </OverlayContainer>
            )}
          </>
        </>
      ) : (
        <>
          <SmallTitleContainer>
            <BackButton onClick={onBackClick}>
              <IconBack />
            </BackButton>
            <SmallTitle
              onMouseEnter={() => setOverlayOpen(true)}
              onClick={event => {
                setOverlayOpen(true);
                event.stopPropagation();
              }}
              onMouseLeave={() => setOverlayOpen(false)}
            >
              {activeChat.name}
            </SmallTitle>
          </SmallTitleContainer>
          {overlayOpen && (
            <OverlayContainer>
              <UsersOverlay under>{names}</UsersOverlay>
            </OverlayContainer>
          )}
        </>
      );
    }
    if (createNewChat) {
      return (
        <>
          <BackButton onClick={onBackClick}>
            <IconBack />
          </BackButton>
          Chat starten
        </>
      );
    }
    return <>Chat</>;
  };

  return (
    <StyledSubMenuHeadline style={createNewChat ? { marginBottom: '8px' } : {}}>
      <CloseButton onClick={onCloseClick} />
      <StyledTitleContainer>{getChatTitle()}</StyledTitleContainer>
      {createNewChat && <ChatFilter />}
    </StyledSubMenuHeadline>
  );
};

export default ChatTitle;
