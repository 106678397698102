import React from 'react';
import { contentApi } from 'services/ContentService';
import { StyledCalendarButton } from '../styles';
import IconAttend from 'components/Menu/assets/attend.svg';
import { createLiveStreamContent } from 'services/ContentService';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { trackEvent } from 'utilities/analytics';
import { menuApi } from 'services/MenuService';

export default function TalkCTA({ talk }) {
  const { link, type } = talk;
  const isLiveStream = type === 'live_talk';
  const isExchange = type === 'exchange';
  const hasJoinButton = isExchange || isLiveStream || link;

  const closeMenu = () => {
    menuApi.getState().setActiveMenuComponent(null);
  };

  if (!hasJoinButton) return null;

  const onClick = () => {
    if (isExchange) {
      contentApi.getState().setActiveContent({
        type: CONTENT_TYPE.NETWORKING,
      });
      closeMenu();
    } else if (isLiveStream) {
      const content = createLiveStreamContent();
      contentApi.getState().setActiveContent(content);
      closeMenu();
    } else if (link) {
      window.open(link);
    }
    trackEvent('Schedule', 'Join', talk.title);
  };

  return (
    <StyledCalendarButton onClick={onClick}>
      <span>Teilnehmen</span> <IconAttend />
    </StyledCalendarButton>
  );
}
