import create from 'utilities/zustand/create';
import { applicationApi } from 'services/ApplicationService';
import { socketApi } from 'services/SocketService';
import userStore from 'storage/user';
import axios from 'axios';

let socket = null;

export const [useArenaStore, arenaApi] = create(module, (set, get) => ({
  stream: null,
  videoTeaser: null, // video played on texture in 3d space
  video: null, // 2d video viewed in fullscreen content
  isPlayAllowed: false,

  init: async managedSocket => {
    socket = managedSocket;

    // initialize with the value from the event
    const application = await applicationApi.getState().poll();
    const streamId = application?.arenaStream;

    if (streamId) {
      const token = userStore.getToken();
      const restUrl = socketApi.getState().getRestUrl('strapi');
      const response = await axios.get(`${restUrl}streams/${streamId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const stream = response.data;
      if (stream) {
        set({ stream });
      }
    }

    socket.on('arena/setLiveStream', stream => {
      // console.log('arena/setLiveStream', url);
      set({ stream });
    });
  },

  allowPlay: () => {
    set({ isPlayAllowed: true });
    if (get().videoTeaser) {
      try {
        get().videoTeaser.play();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  },

  playVideoTeaser: videoTeaser => {
    set({ videoTeaser });
    get().videoTeaser.play();
  },

  setVideo: video => {
    set({ video });
  },
}));
