import styled, { css } from 'styled-components';
import { Button } from 'common/components/Button';
import { mediaMin } from 'common/layout';

export const StyledInputContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
`;

export const StyledStartNewChatContainer = styled.div`
  margin: 24px;
  margin-top: 8px;
  margin-bottom: 0;
  ${mediaMin(
    'medium',
    css`
      margin: 0 40px;
      margin-bottom: 56px;
      margin-top: 8px;
    `
  )}
`;

export const StyledButton = styled(Button)``;
