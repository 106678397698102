import React from 'react';
import {
  IndicatorContainer,
  StyledPersonImage,
  ImageContainer,
  sizes,
  ChatUnseenNotification,
  StackedContainer,
  NormalContainer,
} from './styles';
import InitialLettersFallback from './InitialLettersFallback';
import OnlineIndicator from 'common/components/OnlineIndicator';
import { safeSelectUrlFromMedia } from 'utilities/media';

export default function PersonImage({ user, chatNotice, showOnlineIndicator, size, stacked, square, first }) {
  let src;
  if (user) {
    src = safeSelectUrlFromMedia(user.thumbnail, 'thumbnail');
  }
  const hasIndicator = chatNotice || (showOnlineIndicator && user && user.isOnline);
  const children = (
    <>
      {chatNotice ? (
        <IndicatorContainer>
          <ChatUnseenNotification />
        </IndicatorContainer>
      ) : null}
      {showOnlineIndicator && user && (
        <IndicatorContainer>
          <OnlineIndicator active={user.isOnline} />
        </IndicatorContainer>
      )}
    </>
  );
  const Container = stacked ? StackedContainer : NormalContainer;

  if (src) {
    return (
      <StyledPersonImage size={size} hasIndicator={hasIndicator}>
        <Container size={size} first={first}>
          <ImageContainer size={size} src={src} stacked={stacked} square={square}>
            {children}
          </ImageContainer>
        </Container>
      </StyledPersonImage>
    );
  }
  return (
    <StyledPersonImage size={size} hasIndicator={hasIndicator}>
      <Container size={size} first={first}>
        <InitialLettersFallback user={user || {}} size={sizes[size]} stacked={stacked} square={square}>
          {children}
        </InitialLettersFallback>
      </Container>
    </StyledPersonImage>
  );
}
