import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { districtApi, useDistrictStore } from 'services/DistrictService';
import { windowApi } from 'services/WindowService';
import { useOnboardingStore } from 'services/OnboardingService';
import { useDebugStore } from 'storage/debug';
import { useContentStore } from 'services/ContentService';
import { useHistory, useParams } from 'react-router-dom';
import PlayMap from 'components/Play/PlayMap';
import ChatEmotesDesktop from 'components/Chat/ChatEmotes/DesktopBar';
import ChatEmotesMobile from 'components/Chat/ChatEmotes/MobileBar';
import ScreenFade from 'components/ScreenFade';
import Navigation from 'components/Navigation';
import Menu from 'components/Menu';
import VisualLoader from './VisualLoader';
import Intro from './Intro';
import InstructionBox from './InstructionBox';
import Modal from 'components/Modal';
import SoundController from './SoundController';
import Content from 'components/Content';
import FullscreenContent from 'components/FullscreenContent';
import Stats from './Stats';
import Notifications from 'components/Notifications';
import WispHoverDisplay from 'components/Play/WispHoverDisplay';
import EmojiFX from './EmojiFX';
import { mediaMax, mobileLandscapeFullHeightBreakPoint } from 'common/layout';
import { menuWidth, menuHeight, combinedMenuWidth } from 'components/Menu/styles';
import { useMenuStore } from 'services/MenuService';

export const ContainerStage = styled.div`
  height: 100%;
  touch-action: none;
`;

export const CanvasOverlayContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: ${props => (props.menuActive ? combinedMenuWidth : menuWidth)};
  width: calc(100% - ${props => (props.menuActive ? combinedMenuWidth : menuWidth)});
  pointer-events: none;

  ${mediaMax(
    'small',
    css`
      left: 0;
      height: calc(100% - ${menuHeight});
      width: 100%;
    `
  )};
`;

export const ChatEmotesMobileContainer = styled.div`
  ${props =>
    props.isLivestream &&
    mediaMax(
      'small',
      css`
        @media only screen and (min-aspect-ratio: ${mobileLandscapeFullHeightBreakPoint}) {
          display: none;
        }
      `
    )}
`;

function useDistricts(mapId) {
  const district = useDistrictStore(state => state.districts.find(d => d.room === mapId));
  const loadedDistrict = useDistrictStore(state => state.districts.find(d => d.room === mapId && d.isLoaded));
  useEffect(() => {
    const { initDistrict, leaveDistrict, district: oldDistrict } = districtApi.getState();
    if (district && oldDistrict !== district) {
      if (oldDistrict) {
        leaveDistrict(oldDistrict);
      }
      initDistrict(district);
    }
  }, [mapId]);

  useEffect(() => {
    const { enterDistrict } = districtApi.getState();
    if (loadedDistrict && !loadedDistrict.entered) {
      enterDistrict(loadedDistrict);
    }
  }, [loadedDistrict]);
}

export default function Play() {
  const { mapId } = useParams();
  const district = useDistrictStore(state => state.districts.find(d => d.room === mapId));
  const districtIsLoaded = useDistrictStore(state => state.districts.find(d => d.isLoaded));
  const hasDistrict = districtApi.getState().district;
  const debugStore = useDebugStore();
  const introCompleted = useOnboardingStore(state => state.introCompleted);
  const activeContent = useContentStore(state => state.activeContent);
  const activeContentTypeId = activeContent && activeContent.type.id;
  const history = useHistory();
  const menuActive = useMenuStore(state => state.activeMenuComponent !== null);

  useDistricts(mapId);

  //update cursor pointer from windowApi hover without rerender
  const containerRef = useRef();
  useEffect(() => {
    let hover = windowApi.getState().hover;
    return windowApi.subscribe(() => {
      const state = windowApi.getState();
      if (state.hover !== hover) {
        hover = state.hover;
        if (containerRef.current) {
          containerRef.current.style['cursor'] = hover ? 'pointer' : 'default';
        }
      }
    });
  }, []);

  if (!district) {
    history.push('/play/hub');
    return null;
  }

  const showEmotes =
    activeContentTypeId === 'NETWORKING' || activeContentTypeId === 'ARENA' || activeContentTypeId === 'LIVESTREAM';

  const isLivestream = activeContentTypeId === 'LIVESTREAM';

  return (
    <ContainerStage ref={containerRef}>
      <SoundController />
      <PlayMap key={mapId} district={district} />
      {hasDistrict && (
        <>
          <Menu />
          <CanvasOverlayContainer menuActive={menuActive}>
            <Content />
            <WispHoverDisplay />
            <InstructionBox />
          </CanvasOverlayContainer>
          <Navigation />
          <FullscreenContent />
          {showEmotes && <ChatEmotesDesktop />}
          {showEmotes && (
            <ChatEmotesMobileContainer isLivestream={isLivestream}>
              <ChatEmotesMobile />
            </ChatEmotesMobileContainer>
          )}
          <EmojiFX />
        </>
      )}
      {!introCompleted && districtIsLoaded && <Intro />}
      <ScreenFade />
      <VisualLoader />
      <Modal />
      <Notifications />
      {debugStore.getStatsEnabled() && <Stats />}
    </ContainerStage>
  );
}
