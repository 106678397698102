import React from 'react';
import { materialApi, useMaterialStore } from 'services/MaterialService';
import { useDebugStore } from 'storage/debug';
import { Item, DevButton } from '../styles';

export default function MaterialsPanel() {
  const debugStore = useDebugStore();
  const materials = useMaterialStore(state => state.materials);
  const options = useMaterialStore(state => state.materialsOptions);
  const option = options.find(o => o.name === materials.name) || { name: 'null' };
  return (
    <>
      <div>Current: {option.name}</div>
      Override:
      {['OFF', 'ON'].map(target => {
        const value = target === 'ON' ? 0 : -1;
        const selected = target === 'ON' ? debugStore.getMaterialsIndex() > -1 : debugStore.getMaterialsIndex() === -1;
        return (
          <DevButton
            small
            accent={selected}
            key={target}
            onClick={() => {
              materialApi.getState().setMaterialsIndex(value);
            }}
          >
            {target}
          </DevButton>
        );
      })}
      {debugStore.getMaterialsIndex() !== -1 &&
        options.map((o, i) => {
          return (
            <Item
              key={i}
              selected={materials.name === o.name}
              onClick={() => {
                materialApi.getState().setMaterialsIndex(i);
              }}
            >
              {o.name}
            </Item>
          );
        })}
    </>
  );
}
