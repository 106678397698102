import React, { useEffect, useRef } from 'react';

const Participant = ({ participant }) => {
  const audioRef = useRef();

  useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === 'audio') {
        audioRef.current.appendChild(track.attach());
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'audio') {
        track.detach();
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    for (const publication of participant.audioTracks.values()) {
      if (publication.track) {
        audioRef.current.appendChild(publication.track.attach());
      }
    }

    return () => {
      participant.removeListener('trackSubscribed', trackSubscribed);
      participant.removeListener('trackUnsubscribed', trackUnsubscribed);
    };
  }, [participant]);

  return <div ref={audioRef} />;
};

export default Participant;
