import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { iconFillAndStroke } from 'common/icon';
import { Button } from 'common/components/Button';

export const StyledAddOrRemoveToChat = styled(Button)`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;

  opacity: ${props => (props.disabled ? 0.5 : 1)};
  background: ${props => (props.isAdded ? '#38CE8F' : colors.interactiveBlue)};
  border-color: ${props => (props.isAdded ? '#38CE8F' : colors.interactiveBlue)};

  &:hover {
    ${props =>
      props.isAdded &&
      css`
        background: linear-gradient(76.24deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #38ce8f;
        border-color: #38ce8f;
      `};
  }

  border-radius: 50%;
  svg {
    display: block;
    width: 16px;
    height: 16px;
    ${iconFillAndStroke('white', 'white')};
  }
`;

export const StyledChatCreator = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  form {
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 5px;
      flex-grow: 1;
    }
  }
`;

export const Persons = styled.div`
  padding-top: 24px;
  padding-bottom: 8px;
`;
