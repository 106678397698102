import create from 'utilities/zustand/create';

export const [useSceneStore, sceneApi] = create(module, set => ({
  scene: null,
  isLoaded: false,
  bgIsLoaded: false,
  setScene: scene => {
    set({ scene });
  },
}));
