import create from 'utilities/zustand/create';
import React from 'react';
import Schedule from 'components/Menu/SubMenu/Schedule';
import People from 'components/Menu/SubMenu/People';
import Profile from 'components/Menu/SubMenu/Profile';
import Poll from 'components/Menu/SubMenu/Poll';
import Developer from 'components/Menu/SubMenu/Developer';
import Chat from 'components/Menu/SubMenu/Chat';
import Info from 'components/Menu/SubMenu/Info';
import { trackEvent } from 'utilities/analytics';
import { chatApi } from 'services/ChatService';

export const [useMenuStore, menuApi] = create(module, (set, get) => ({
  activeMenuComponent: null,

  schedule: <Schedule />,
  people: <People />,
  profile: <Profile />,
  poll: <Poll />,
  dev: <Developer />,
  chat: <Chat />,
  info: <Info />,

  setActiveMenuComponent: menuComponent => {
    const { activeMenuComponent, chat } = get();

    if (activeMenuComponent === chat) {
      const { activeChat, leave } = chatApi.getState();
      if (activeChat) leave();
    }

    if (menuComponent) {
      trackEvent(menuComponent.type.name, 'Open');
    } else {
      if (activeMenuComponent) {
        trackEvent(activeMenuComponent.type.name, 'Close');
      }
    }

    set({ activeMenuComponent: menuComponent });
  },

  reset: () => {
    set({ activeMenuComponent: null });
  },
}));
