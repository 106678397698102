import { useCameraStore } from 'services/CameraService';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

export const transitionDuration = 500;

const AnimatedScreenFade = styled(animated.div)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.color};
  pointer-events: none;
  transition: background 1s;
  z-index: 20000;
`;

export default function ScreenFade() {
  const alpha = useCameraStore(state => state.screenFade.alpha);
  const color = useCameraStore(state => state.screenFade.color);
  //const isLoaded = useSceneStore(state => state.isLoaded);
  const [{ opacity }, setScreen] = useSpring(() => ({
    opacity: alpha,
    config: { duration: transitionDuration },
    /*onRest: value => {
      if (value.opacity >= 1) {
        const { resetScreenFade, screenFade } = cameraApi.getState();
        screenFade.callback();
        resetScreenFade();
      }
    },*/
  }));
  useEffect(() => {
    setScreen({
      opacity: alpha,
    });
  }, [alpha]);

  return <AnimatedScreenFade style={{ opacity }} color={color} />;
}
