import styled, { css } from 'styled-components';
import { animations, baseColors, colors } from 'styles';
import { Caption2, H5, Overline2 } from 'common/text';
import { Button } from 'common/components/Button';

export const StyledTalkComponent = styled.div`
  background: ${props => (props.noBackground ? 'none' : 'rgba(255, 255, 255, 0.1)')};
  margin: 16px 0;
  text-align: left;
  border-radius: 8px;
  overflow: hidden;

  ${props =>
    props.withAnimation &&
    css`
      opacity: 0;
      ${animations.showFromBottom};
      animation-delay: ${props => props.index * 0.05 + 0.15 * (props.itemAnimOffset + 1)}s;
      animation-duration: 0.45s;
      animation-timing-function: cubic-bezier(0.17, 0.67, 0, 0.98);
    `}
`;

export const StyledTalkBody = styled.div`
  padding: 16px;
`;

export const StyledTalkGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-basis: 100%;
`;

export const StyledTalkFooter = styled.div`
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const StyledFooterRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

export const StyledCalendarButton = styled(Button)`
  background: ${colors.interactiveBlue};
  color: ${baseColors.white};
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  box-sizing: border-box;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 9px;
  padding-left: 12px;
  border-radius: 0;

  span {
    padding-left: 12px;
    font-size: 12px;
    line-height: 32px;
    font-family: PF Highway Sans Pro;
    font-weight: 300;
  }
`;

export const StyledSpeakers = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
`;

export const StyledTalkTitle = styled(H5)`
  color: white;
  margin-top: 0;
  margin-bottom: 16px;
  user-select: text;
  cursor: text;
`;

export const StyledTalkTime = styled(Caption2)`
  color: rgba(255, 255, 255, 0.5);
  margin: auto 0;
  margin-left: 16px;
  user-select: text;
  cursor: text;
`;

export const StyledTalkType = styled(Overline2)`
  color: ${colors.highlightCyan};
  user-select: text;
  cursor: text;
  margin-top: 0px;
  margin-bottom: 4px;
`;

export const StyledSpeakerNames = styled(Caption2)`
  display: block;
  margin: auto 0;
  margin-left: 20px;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
`;
