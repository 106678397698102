import styled, { css } from 'styled-components';
import { Caption2 } from 'common/text';
import { mediaMax, mediaMin } from 'common/layout';

export const TooltipContainerDektop = styled.div`
  cursor: pointer;
  ${mediaMax(
    'small',
    css`
      display: none;
    `
  )}
`;

export const TooltipContainerMobile = styled.div`
  cursor: pointer;
  ${mediaMin(
    'medium',
    css`
      display: none;
    `
  )}
`;

export const StyledTooltipText = styled(Caption2)`
  margin-bottom: 8px;
`;
