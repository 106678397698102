import styled, { css } from 'styled-components';
import { animations } from 'styles';

export const StyledPersonComponent = styled.div`
  cursor: ${props => (props.isInteractable ? 'pointer' : 'inherit')};

  margin: 12px 0;

  ${props =>
    props.withAnimation &&
    css`
      opacity: 0;
      ${animations.showFromBottom};
      animation-delay: ${props => props.index * 0.05 + 0.15}s;
      animation-duration: 0.45s;
      animation-timing-function: cubic-bezier(0.17, 0.67, 0, 0.98);
    `}
`;
