import React, { useRef, useState, useMemo } from 'react';
import { useFrame } from 'react-three-fiber';
import { useContentStore } from 'services/ContentService';
import { twilioApi, useTwilioStore } from 'services/TwilioService';
import { windowApi } from 'services/WindowService';
import { cameraApi } from 'services/CameraService';
import { Text } from '@react-three/drei';
import LookAtCamera from '../LookAtCamera';
import roomLineVert from './shader/roomLine.vert';
import roomLineFrag from './shader/roomLine.frag';
import * as THREE from 'three';
import HighwayBoldTtf from 'fonts/PFHighwaySansPro-Bold.ttf';
import { betterLerp } from 'utilities/lerp';
import { useWispStore } from 'services/WispService';

const HEIGHTSEGMENTS = 2;
const WIDTHSEGMENTS = 4;
const LINECOUNT = HEIGHTSEGMENTS * WIDTHSEGMENTS + (HEIGHTSEGMENTS - 1) * WIDTHSEGMENTS;

export default function WispRoom({ wispRoom, index }) {
  const [isHover, setIsHover] = useState(false);
  const lastTime = useRef(0.0);

  const lineMaterialRef = useRef();
  const textMaterialRef = useRef();

  const twilioState = useTwilioStore(state => state.twilioState);
  const activeWispRoom = useWispStore(state => state.wispRoom);
  const activeContent = useContentStore(state => state.activeContent);
  const activeContentTypeId = activeContent && activeContent.type.id;
  const isNetworkingContentActive = activeContentTypeId === 'NETWORKING';
  const isActive = activeWispRoom ? activeWispRoom.id === wispRoom.id : false;
  const usePointerEvents = isNetworkingContentActive && activeWispRoom === null;

  const [params] = useState({ scale: 0.05 + Math.random() * 0.05, rotation: Math.random() * Math.PI });

  const rotation = useMemo(
    () => new THREE.Euler(Math.random() * Math.PI * 2, Math.random() * Math.PI * 2, Math.random() * Math.PI * 2),
    []
  );

  const [pos1Array, pos2Array] = useMemo(() => {
    const array1 = new Array(LINECOUNT * 3).fill(0);
    const array2 = new Array(LINECOUNT * 3).fill(0);

    const getSpherePos = (i, j) => {
      const py = Math.cos((i / HEIGHTSEGMENTS) * Math.PI) * params.scale;
      const layerwidth = Math.sin((i / HEIGHTSEGMENTS) * Math.PI) * params.scale;
      const px = Math.sin((j / WIDTHSEGMENTS) * Math.PI * 2.0) * layerwidth;
      const pz = Math.cos((j / WIDTHSEGMENTS) * Math.PI * 2.0) * layerwidth;
      return [px, py, pz];
    };
    let ptr = 0;
    for (let i = 0; i < HEIGHTSEGMENTS; i++) {
      for (let j = 0; j < WIDTHSEGMENTS; j++) {
        {
          const pos1 = getSpherePos(i, j);
          const pos2 = getSpherePos(i + 1, j);
          for (let k = 0; k < 3; k++) {
            array1[ptr + k] = Math.round(pos1[k] * 100) / 100;
            array2[ptr + k] = Math.round(pos2[k] * 100) / 100;
          }
          ptr += 3;
        }
        if (i > 0) {
          const pos1 = getSpherePos(i, j);
          const pos2 = getSpherePos(i, j + 1);
          for (let k = 0; k < 3; k++) {
            array1[ptr + k] = Math.round(pos1[k] * 100) / 100;
            array2[ptr + k] = Math.round(pos2[k] * 100) / 100;
          }
          ptr += 3;
        }
      }
    }

    return [Float32Array.from(array1), Float32Array.from(array2)];
  }, []);

  useFrame(context => {
    const { orbit } = cameraApi.getState();
    const orbitLockIndex = orbit.orbitLockIndex;
    const orbitLockIndexCandidate = orbit.getCurrentLockCandidate();
    const focusedIndex = orbitLockIndexCandidate !== null ? orbitLockIndexCandidate : orbitLockIndex;
    const isFocused = focusedIndex === index;

    const elapsedTime = context.clock.getElapsedTime();
    const delta = elapsedTime - lastTime.current;
    const isHighlighted = !activeWispRoom && isNetworkingContentActive && (isHover || isFocused);

    const lineTargetAlpha = isActive || isHighlighted ? 1.0 : 0.5;
    const lineAlpha = lineMaterialRef.current.uniforms.uAlpha.value;
    lineMaterialRef.current.uniforms.uAlpha.value = betterLerp(
      lineAlpha,
      lineTargetAlpha,
      isHighlighted ? 0.2 : 0.1,
      delta
    );

    let targetScale = 0.8;
    if (isActive) {
      targetScale = 1.2;
    } else if (isHighlighted) {
      targetScale = isFocused && isHover ? 1.2 : 1.0;
    }
    const scale = lineMaterialRef.current.uniforms.uScale.value;
    lineMaterialRef.current.uniforms.uScale.value = betterLerp(scale, targetScale, isHighlighted ? 0.2 : 0.1, delta);

    const textTargetAlpha = !activeWispRoom && isHighlighted ? 1.0 : 0.0;
    const textAlpha = textMaterialRef.current.opacity;
    textMaterialRef.current.opacity = betterLerp(textAlpha, textTargetAlpha, isHighlighted ? 0.2 : 0.1, delta);
    textMaterialRef.current.visible = textMaterialRef.current.opacity > 0.001;
    textMaterialRef.current.needsUpdate = true;

    lineMaterialRef.current.uniforms.uTime.value = (elapsedTime / 10000.0) % 1.0;
    lineMaterialRef.current.needsUpdate = true;

    lastTime.current = elapsedTime;
  });

  const uniforms = useMemo(
    () => ({
      uWidth: { value: 0.005 },
      uGlowWidth: { value: 0.1 },
      uTime: { value: 0.0 },
      uRandom: { value: Math.random() },
      uAlpha: { value: 0.5 },
      uScale: { value: 1.0 },
    }),
    []
  );

  const canJoin =
    (twilioState === 'ok' || twilioState === 'too_many_participants') && activeContentTypeId === 'NETWORKING';
  const onClick = e => {
    e.stopPropagation();
    const isFocused = cameraApi.getState().orbit.orbitLockIndex === index;
    if (isFocused) {
      if (canJoin) {
        twilioApi.getState().join(wispRoom);
      }
    } else {
      cameraApi.getState().setOrbitLockIndex(index);
    }
  };

  const handlePointerOver = e => {
    //e.stopPropagation();
    const timeStamp = e.timeStamp; // Little hack to allow only one room to be hovered, but don't stop event propagation
    if (e.ray.wispRoomTimeStamp === timeStamp) {
      return;
    }
    e.ray.wispRoomTimeStamp = timeStamp;

    setIsHover(true);
    windowApi.getState().setHover(true);
  };

  const handlePointerMove = e => {
    const timeStamp = e.timeStamp;
    if (e.ray.wispRoomTimeStamp === timeStamp) {
      setIsHover(false);
    } else {
      e.ray.wispRoomTimeStamp = timeStamp;
      setIsHover(true);
      windowApi.getState().setHover(true);
    }
  };

  const handlePointerOut = () => {
    setIsHover(false);
    windowApi.getState().setHover(false);
  };

  return (
    <group position={wispRoom.position}>
      <instancedMesh renderOrder={1000} args={[null, null, LINECOUNT]} rotation={rotation}>
        <planeBufferGeometry attach="geometry" args={[1.0, 1.0]}>
          <instancedBufferAttribute attachObject={['attributes', 'worldPos1']} args={[pos1Array, 3, false]} />
          <instancedBufferAttribute attachObject={['attributes', 'worldPos2']} args={[pos2Array, 3, false]} />
        </planeBufferGeometry>
        <shaderMaterial
          ref={lineMaterialRef}
          uniforms={uniforms}
          blending={THREE.AdditiveBlending}
          vertexShader={roomLineVert}
          fragmentShader={roomLineFrag}
          depthTest={true}
          depthWrite={false}
          transparent={true}
          needsUpdate={true}
          attach="material"
        />
      </instancedMesh>
      <mesh
        rotation={[Math.PI * 0.25, params.rotation, 0]}
        visible={false}
        onClick={usePointerEvents ? onClick : null}
        onPointerOver={usePointerEvents ? handlePointerOver : null}
        onPointerMove={usePointerEvents ? handlePointerMove : null}
        onPointerOut={usePointerEvents ? handlePointerOut : null}
      >
        <sphereGeometry attach="geometry" args={[params.scale * 1.2, WIDTHSEGMENTS, HEIGHTSEGMENTS]} />
      </mesh>
      <LookAtCamera position={[0, params.scale + 0.02, 0]}>
        <Text
          text={wispRoom.name}
          font={HighwayBoldTtf}
          fontSize={0.02}
          anchorX="center"
          anchorY="middle"
          renderOrder={999}
        >
          <meshBasicMaterial ref={textMaterialRef} attach="material" opacity={1.0} depthWrite={false} />
        </Text>
      </LookAtCamera>
    </group>
  );
}
