import React from 'react';
import { chatApi } from 'services/ChatService';
import { modalApi } from 'services/ModalService';
import { menuApi } from 'services/MenuService';
import Notification from 'components/Notification';
import { assembleNameWithTitle } from 'services/UserService';
import { Body2 } from 'common/text';
import { trackEvent } from 'utilities/analytics';

const ChatNotification = ({ message }) => {
  const { user, text, chat } = message;

  const headline = assembleNameWithTitle(user);

  const onClick = async () => {
    const chatToEnter = chatApi.getState().findById(chat);
    const { activeMenuComponent, chat: chatMenuComponent, setActiveMenuComponent } = menuApi.getState();
    if (activeMenuComponent !== chatMenuComponent) {
      setActiveMenuComponent(chatMenuComponent);
    }
    modalApi.getState().closeModal();
    chatApi.getState().enter(chatToEnter);
    trackEvent('Notification', 'Join', 'Chat');
  };

  const notificationProps = {
    onClick,
    headline: headline,
    body: <Body2>{text}</Body2>,
  };
  return <Notification {...notificationProps} />;
};

export default ChatNotification;
