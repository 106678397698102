import { useFrame, useThree } from 'react-three-fiber';
import { useWindowStore } from 'services/WindowService';
import { CONTENT_UI_TYPE } from 'services/ContentService/contentTypes';
import { useContentStore } from 'services/ContentService';
import { useMenuStore } from 'services/MenuService';

let frameIndex = 0;

export default function Renderer() {
  const { gl, scene, camera } = useThree();
  const windowFocus = useWindowStore(state => state.focus);

  const content = useContentStore(state => state.activeContent);
  const activeMenuComponent = useMenuStore(state => state.activeMenuComponent);
  const isMobileBreakpoint = useWindowStore(state => state.isMobileBreakpoint);
  const canvasHiddenByMenu = isMobileBreakpoint && activeMenuComponent !== null;
  const isFullscreenContent =
    canvasHiddenByMenu || (content ? content.type.uiType === CONTENT_UI_TYPE.FULLSCREEN : false);

  useFrame(() => {
    const windowOutOfFocus = !windowFocus && frameIndex++ % 4 !== 0;
    if (windowOutOfFocus || isFullscreenContent) return;

    gl.setClearColor(0x1f2944);
    gl.clear();

    gl.render(scene, camera);
  }, 100);
  return null;
}
