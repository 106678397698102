import React from 'react';
import { menuApi, useMenuStore } from 'services/MenuService';
import IconProfile from '../assets/profile.svg';
import MenuButton from '../components/MenuButton';

export default function ProfileButton() {
  const activeMenuComponent = useMenuStore(state => state.activeMenuComponent);
  const profile = useMenuStore(state => state.profile);

  const onClick = () => {
    menuApi.getState().setActiveMenuComponent(activeMenuComponent === profile ? null : profile);
  };

  return (
    <MenuButton active={activeMenuComponent === profile} onClick={onClick} hoverLabel="Profil">
      <IconProfile />
    </MenuButton>
  );
}
