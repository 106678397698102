import React, { useMemo, useState } from 'react';
import { WEBGL } from 'three/examples/jsm/WebGL';
import { Canvas } from 'react-three-fiber';
import { useInterval } from 'utilities/hooks';
import calculateLimitRatio from 'utilities/canvas/limiter';
import { useDebugStore } from 'storage/debug';

export default function ThreeCanvas({ children }) {
  const debugStore = useDebugStore();
  const gl2 = useMemo(() => {
    return WEBGL.isWebGL2Available();
  }, []);

  const [combinedPixelRatio, setCombinedPixelRatio] = useState(1.0);
  useInterval(() => {
    const devicePixelRatio = window.devicePixelRatio;
    const limiterRatio = debugStore.getCanvasResolutionLimitEnabled() ? calculateLimitRatio() : 1;
    const ratio = devicePixelRatio * limiterRatio;
    setCombinedPixelRatio(ratio);
  }, 500);

  return (
    <Canvas
      id="mainCanvas"
      pixelRatio={combinedPixelRatio}
      onContextMenu={e => {
        e.nativeEvent.preventDefault();
      }}
      colorManagement={true}
      gl2={gl2}
      gl={{ alpha: false, antialias: true, powerPreference: 'low-power' }}
    >
      {children}
    </Canvas>
  );
}
