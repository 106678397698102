import React from 'react';
import { menuApi, useMenuStore } from 'services/MenuService';
import MenuButton from '../components/MenuButton/index';

export default function DeveloperButton() {
  const activeMenuComponent = useMenuStore(state => state.activeMenuComponent);
  const dev = useMenuStore(state => state.dev);

  const onClick = () => {
    menuApi.getState().setActiveMenuComponent(activeMenuComponent === dev ? null : dev);
  };

  return (
    <MenuButton active={activeMenuComponent === dev} onClick={onClick} hoverLabel="Entwickler">
      <span style={{ fontSize: '24px', width: '32px', height: '32px', textAlign: 'center' }}>🐞</span>
    </MenuButton>
  );
}
