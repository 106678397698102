import React from 'react';
import IconOpenLink from 'components/Menu/assets/open-link.svg';
import { StyledSubMenuHeader, StyledSubMenuHeadline } from '../../styles';
import { menuApi } from 'services/MenuService';
import CloseButton from 'common/components/CloseButton';
import { PlatformCode, Infos, InfoMenu, InfoItemContainer, InfoItemTitle, InfoItemLink, InfoItemText } from './styles';
import { ScrollContent } from 'components/Menu/SubMenu/styles';
import { trackEvent } from 'utilities/analytics';
import { TransitionItem } from 'styles';

const InfoItem = ({ title, url, text = null, index = 0 }) => {
  const handleClick = () => {
    window.open(url, '_blank');
    trackEvent('Info', 'Select', title);
  };
  return (
    <TransitionItem index={index}>
      <InfoItemContainer>
        <InfoItemTitle hasText={!!text}>{title}</InfoItemTitle>
        <InfoItemLink onClick={handleClick}>
          <IconOpenLink />
        </InfoItemLink>
        {!!text && <InfoItemText>{text}</InfoItemText>}
      </InfoItemContainer>
    </TransitionItem>
  );
};

export default function Info() {
  return (
    <>
      <StyledSubMenuHeader>
        <CloseButton
          onClick={() => {
            menuApi.getState().setActiveMenuComponent(null);
          }}
        />
        <StyledSubMenuHeadline>Info</StyledSubMenuHeadline>
      </StyledSubMenuHeader>
      <ScrollContent>
        <Infos>
          <InfoMenu>
            <InfoItem
              title="Kontakt"
              url="https://www.ipsen.com/germany/contact-us/"
              text="Sie haben Fragen oder Anregungen? Schreiben Sie uns und wir melden uns schnellstmöglich zurück."
              index={0}
            />
            <InfoItem
              title="Medinfo"
              url="https://www.ipsenmedicalinformation.com/introduction/europe/germany/"
              index={1}
            />
            <InfoItem title="Datenschutz" url="https://www.ipsen.com/germany/datenschutz/" index={2} />
            <InfoItem title="Impressum" url="https://www.ipsen.com/germany/impressum/" index={3} />
          </InfoMenu>
          <PlatformCode>CBZ-AT-001690 05/21</PlatformCode>
        </Infos>
      </ScrollContent>
    </>
  );
}
