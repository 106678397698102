import { mediaMax } from 'common/layout';
import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { iconFillAndStroke } from '../../icon';

export const disabledStyle = css`
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
`;

const enabledStyle = css`
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
`;

// IMPORTANT: do not add new properties without consultation! this button related to the style guide
export const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: auto;
  height: 48px;
  ${props =>
    props.mobileSlim
      ? css`
          ${mediaMax(
            'small',
            css`
              height: 40px;
            `
          )}
        `
      : ''};
  padding: 0 16px;
  cursor: pointer;
  font-family: 'PF Highway Sans Pro';
  font-weight: 700;
  font-size: 14px;
  outline: none;
  border-radius: 8px;
  background-color: ${props => {
    if (props.secondary || props.secondaryWhite) return 'rgba(0, 0, 0, 0)';
    if (props.primaryInverted) return 'white';
    return colors.interactiveBlue;
  }};
  color: ${props => {
    if (props.primaryInverted || props.secondary) return colors.interactiveBlue;
    return 'white';
  }};
  border: 1px solid
    ${props => {
      if (props.secondary) return colors.interactiveBlue;
      if (props.primaryInverted || props.secondaryWhite) return 'white';
      return colors.interactiveBlue;
    }};
  svg {
    ${props => () => {
      const color = props.primaryInverted || props.secondary ? colors.interactiveBlue : 'white';
      return iconFillAndStroke(color, color);
    }}
    vertical-align: middle;
  }

  ${props => (props.disabled ? disabledStyle : enabledStyle)};

  &:hover {
    background: ${props => {
      if (props.secondary) return 'linear-gradient(76.24deg, rgba(40, 112, 230, 0.5) 0%, rgba(40, 112, 230, 0) 100%)';
      if (props.secondaryWhite)
        return 'linear-gradient(76.24deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%)';
      if (props.primaryInverted) return 'white';
      return `linear-gradient(76.24deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), ${colors.interactiveBlue}`;
    }};
    border: 1px solid
      ${props => {
        if (props.secondary) return colors.interactiveBlue;
        if (props.secondaryWhite || props.primaryInverted) return 'white';
        return `transparent`;
      }};
    color: ${props => {
      if (props.primaryInverted) return '#458CFF';
      return `white`;
    }};
    svg {
      ${props => {
        if (props.primaryInverted) return iconFillAndStroke(colors.interactiveBlue, colors.interactiveBlue);
        return iconFillAndStroke('white', 'white');
      }};
    }
  }
`;
