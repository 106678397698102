import React, { useEffect, useState } from 'react';
import { useTwilioStore } from 'services/TwilioService';
import Participant from 'components/Content/Networking/Participant';
import { contentApi, useContentStore } from 'services/ContentService';
import { twilioApi } from 'services/TwilioService';
import { modalApi } from 'services/ModalService';
import { useDocumentListener, usePrevious } from 'utilities/hooks';
import { Button } from 'common/components/Button';
import { ActionButton } from 'common/components/ActionButton';
import {
  StyledNetworking,
  StyledContentType,
  StyledContentTypeBackground,
  StyledRoomName,
  StyledTooltipContainer,
} from './styles';
import { CTA } from 'components/Content/CTA/styles';
import { StyledBackButtonContainer } from '../styles';
import twilioStore from 'storage/twilio/index';
import IconLeave from './leave.svg';
import IconMicOff from './mic-off.svg';
import IconMicOn from './mic-on.svg';
import IconCameraOff from './camera-off.svg';
import IconCameraOn from './camera-on.svg';
import TooltipTop from 'components/Menu/components/Tooltips/TooltipTop';
import { Caption2 } from 'common/text';
import ModalAskPermission from './ModalAskPermission';
import ModalNoPermission from './ModalNoPermission';
import ModalError from './ModalError';
import { useWindowStore } from 'services/WindowService';
import { useWispStore } from 'services/WispService';
import { wispApi } from 'services/WispService';

export default function Networking({ show }) {
  const twilioState = useTwilioStore(state => state.twilioState);
  const room = useTwilioStore(state => state.room);
  const wispRoom = useWispStore(state => state.wispRoom);
  const [mediaStream, setMediaStream] = useState(null);
  const [audioButtonDisabled, setAudioButtonDisabled] = useState(false);
  const [videoButtonDisabled, setVideoButtonDisabled] = useState(false);
  const [mutedTooltipVisible, setMutedTooltipVisible] = useState(true);
  const audioEnabled = useTwilioStore(state => state.audio);
  const videoEnabled = useTwilioStore(state => state.video);
  const participants = useTwilioStore(state => state.participants);
  const localParticipant = useTwilioStore(state => state.localParticipant);
  const activeContent = useContentStore(state => state.activeContent);
  const contentTypeId = activeContent && activeContent.type.id;
  const prevContentTypeId = usePrevious(contentTypeId);
  const isMobileBreakpoint = useWindowStore(state => state.isMobileBreakpoint);

  useEffect(() => {
    const askForPermission = async () => {
      let granted;
      try {
        const result = await navigator.permissions.query({ name: 'microphone' });
        granted = result.state === 'granted';
      } catch (e) {
        granted = twilioStore.getPermissionGranted();
        if (granted) {
          const result = await navigator.mediaDevices.getUserMedia({ audio: true });
          if (result) {
            setMediaStream(result);
          } else {
            granted = false;
          }
        }
      }

      if (granted) {
        twilioApi.setState({ twilioState: 'ok' });
      } else {
        const onAskForPermission = async () => {
          const gotoError = () => {
            twilioApi.setState({ twilioState: 'error' });
            const modal = <ModalError />;
            modalApi.getState().setModal(modal);
          };
          try {
            const result = await navigator.mediaDevices.getUserMedia({ audio: true });
            if (result) {
              twilioStore.setPermissionGranted(true);
              setMediaStream(result);
            }
            modalApi.getState().closeModal();
            if (result) {
              twilioApi.setState({ twilioState: 'ok' });
            } else {
              gotoError();
            }
          } catch (e) {
            if (e.name === 'NotAllowedError') {
              twilioApi.setState({ twilioState: 'permission_denied' });
              const modal = <ModalNoPermission />;
              modalApi.getState().setModal(modal);
            } else {
              // eslint-disable-next-line no-console
              console.log(e);
              gotoError();
            }
          }
        };
        const modal = <ModalAskPermission onAskForPermission={onAskForPermission} />;
        modalApi.getState().setModal(modal);
        twilioApi.setState({ twilioState: 'permission_ask' });
      }
    };

    if (contentTypeId === 'NETWORKING' && prevContentTypeId !== 'NETWORKING') {
      askForPermission();
    }
    if (contentTypeId !== 'NETWORKING' && prevContentTypeId === 'NETWORKING') {
      mediaStream && mediaStream.getTracks().forEach(t => t.stop());
      setMediaStream(null);
      if (room) {
        twilioApi.getState().leave();
      }
    }
  }, [activeContent]);

  const participantComponents = participants.map(participant => {
    if (participant !== localParticipant) {
      return <Participant key={participant.identity} participant={participant} />;
    }
  });

  useEffect(() => {
    if (room === null && !audioEnabled) setMutedTooltipVisible(true);
  }, [room]);

  const onToggleAudioClick = async () => {
    if (audioButtonDisabled) return;
    setAudioButtonDisabled(true);
    await twilioApi.getState().toggleAudio();
    setAudioButtonDisabled(false);
    setMutedTooltipVisible(false);
  };
  const onToggleVideoClick = async () => {
    if (videoButtonDisabled) return;
    setVideoButtonDisabled(true);
    await twilioApi.getState().toggleVideo();
    setVideoButtonDisabled(false);
  };

  const onBackClick = () => {
    contentApi.getState().clearActiveContent();
  };

  const onLeaveRoomClick = () => {
    twilioApi.getState().leave();
  };

  const onMutedTooltipClick = e => {
    e.stopPropagation();
    setMutedTooltipVisible(false);
  };

  const mainComponents = (
    <>
      {room && (
        <>
          {participantComponents}
          <StyledContentType>
            <StyledContentTypeBackground>
              <div>
                <ActionButton onClick={onToggleAudioClick} disabled={audioButtonDisabled} primary>
                  {audioEnabled ? <IconMicOn /> : <IconMicOff />}
                </ActionButton>
                <StyledTooltipContainer onClick={onMutedTooltipClick}>
                  <TooltipTop
                    visible={mutedTooltipVisible}
                    onClose={() => {
                      setMutedTooltipVisible(false);
                    }}
                    anchorLeft={isMobileBreakpoint}
                  >
                    <Caption2>Du bist momentan stumm geschaltet. Klicke hier, um zu sprechen.</Caption2>
                  </TooltipTop>
                </StyledTooltipContainer>
              </div>
              <ActionButton onClick={onToggleVideoClick} disabled={videoButtonDisabled} primary>
                {videoEnabled ? <IconCameraOn /> : <IconCameraOff />}
              </ActionButton>
              <ActionButton onClick={onLeaveRoomClick} alert>
                <span>Verlassen</span> <IconLeave />
              </ActionButton>
            </StyledContentTypeBackground>
          </StyledContentType>
        </>
      )}
    </>
  );

  let statusComponents;

  switch (twilioState) {
    case 'ok':
      statusComponents = 'Betreten Sie einen der Audioräume, um an einer Unterhaltung teilzunehmen.';
      break;
    case 'pending':
      statusComponents = 'Bitte warten...';
      break;
    case 'permission_ask':
      statusComponents = null;
      break;
    case 'permission_pending':
      statusComponents = null;
      break;
    case 'permission_denied':
      statusComponents = null;
      break;
    case 'too_many_participants':
      statusComponents =
        'Dieser Audioraum ist gerade voll belegt. Kommen Sie später wieder oder probieren Sie es in einem der anderen Audioräume.';
      break;
    case 'error':
      statusComponents = null;
      break;
  }

  const isMainState = twilioState === 'connected';

  useDocumentListener(show, 'keyup', e => {
    const wispRoom = wispApi.getState().wispRoom;
    if (e.code === 'Escape') {
      if (wispRoom) {
        twilioApi.getState().leave();
      } else {
        onBackClick();
      }
    }
  });

  return (
    <StyledNetworking show={show}>
      {twilioState !== 'pending' && !room && (
        <StyledBackButtonContainer>
          <Button primaryInverted onClick={onBackClick} mobileSlim>
            Verlassen
          </Button>
        </StyledBackButtonContainer>
      )}
      <StyledRoomName show={!!wispRoom}>{wispRoom?.name}</StyledRoomName>
      <CTA show={!!statusComponents}>{statusComponents}</CTA>
      {isMainState && mainComponents}
    </StyledNetworking>
  );
}
