import Storage from 'utilities/storage';

const TOKEN = 'TOKEN'; // JSON Web Token
const SOUND_ENABLED = 'SOUND_ENABLED';
const INTRO_COMPLETED = 'INTRO_COMPLETED';
const storage = new Storage();
storage.init('USER');
storage.defineEntry(TOKEN, null);
storage.defineEntry(SOUND_ENABLED, true);
storage.defineEntry(INTRO_COMPLETED, window.location.hostname === 'localhost');

const sessionStorage = new Storage({ type: 'session' });
sessionStorage.init('USER');
sessionStorage.defineEntry(TOKEN, null);

export default {
  setToken: value => {
    storage.setItem(TOKEN, value);
    sessionStorage.setItem(TOKEN, value);
  },
  getToken: () => {
    return sessionStorage.getItem(TOKEN) || storage.getItem(TOKEN);
  },
  setSoundEnabled: value => {
    storage.setItem(SOUND_ENABLED, value);
  },
  getSoundEnabled: () => {
    return storage.getItem(SOUND_ENABLED);
  },
  setIntroCompleted: value => {
    storage.setItem(INTRO_COMPLETED, value);
  },
  getIntroCompleted: () => {
    return storage.getItem(INTRO_COMPLETED);
  },
};
