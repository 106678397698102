import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useFrame } from 'react-three-fiber';

function Loading({ load, complete }) {
  useEffect(() => {
    if (load) load();
    return () => {
      if (complete) complete();
    };
  }, []);
  return null;
}

function Blocker(props) {
  throw props.x.p;
}
function Delay(props) {
  const [blockerActive, setBlockerActive] = useState(true);

  useFrame(() => {
    const state = props.x.state;
    if (state === 'idle' || state === 'resolved') {
      props.x.out();
      setBlockerActive(false);
    }
    if (state === 'out') {
      props.x.state = 'end';
      props.x.inn();
    }
  });
  return <Suspense>{blockerActive && <Blocker {...props} />}</Suspense>;
}

export default function ThreeLoader(props) {
  const [visible, setVisible] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [finish, setFinish] = useState(false);
  const x = useMemo(() => {
    const store = {
      state: 'wait',
      out: function() {
        store.state = 'out';
      },
      inn: function() {
        store.state = 'inn';
        setFadeIn(true);
      },
      load: function() {
        store.state = 'blocked';
      },
      complete: function() {
        store.state = 'resolved';
      },
      p: new Promise(() => {}),
    };
    return store;
  }, []);

  useEffect(() => {
    const t = setTimeout(() => {
      x.state = 'idle';
      setVisible(true);
    }, 100);
    return () => {
      clearTimeout(t);
    };
  }, []);

  useEffect(() => {
    if (fadeIn) {
      if (props.onInit) props.onInit();
      const t = setTimeout(() => {
        if (props.onFadeIn) props.onFadeIn();
        setFinish(true);
      }, 100);
      return () => {
        clearTimeout(t);
      };
    }
  }, [fadeIn]);

  useEffect(() => {
    if (props.onLoad) props.onLoad();
  }, []);

  return (
    <Suspense fallback={null}>
      {!finish && <Delay x={x} />}
      <Suspense fallback={<Loading load={x.load} complete={x.complete} />}>{visible && props.children}</Suspense>
    </Suspense>
  );
}
