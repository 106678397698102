import React from 'react';
import DatGuiStack from './DatGuiStack';

export default function DevContent() {
  return (
    <>
      <DatGuiStack />
    </>
  );
}
