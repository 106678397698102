import React, { useRef } from 'react';
import { RepeatWrapping, TextureLoader } from 'three';
import { useFrame, useLoader } from 'react-three-fiber';
import { useSpring } from 'react-spring';
import { useContentStore } from 'services/ContentService';
import { useWindowStore, windowApi } from 'services/WindowService';
import hoverFrag from './shader/hover.frag';
import hoverVert from './shader/hover.vert';
import reflectionPng from './Reflection.png';

export default function Hover({ geometry, onClick, disabled }) {
  const [reflection] = useLoader(TextureLoader, [reflectionPng]);
  const activeContent = useContentStore(state => state.activeContent);
  const activeContentTypeId = activeContent && activeContent.type.id;
  reflection.wrapS = RepeatWrapping;

  const materialRef = useRef();
  const meshRef = useRef();

  const [{ hover }, setHover] = useSpring(() => ({
    hover: 0.0,
    config: { tension: 200, friction: 30 },
  }));

  const [{ active }, setActive] = useSpring(() => ({
    active: 0.0,
  }));

  const args = {
    uniforms: {
      offset: { value: 0 },
      reflTex: { type: 't', value: reflection },
    },
  };

  useFrame(() => {
    const hoverValue = hover.value;
    setActive({ active: !disabled ? 1 : 0 });
    materialRef.current.uniforms.offset.value = windowApi.getState().supportsTouch
      ? active.value
      : active.value * hoverValue;
  });

  const setWindowStoreHover = useWindowStore(state => state.setHover);

  const onScreenClick = activeContentTypeId
    ? e => {
        e.stopPropagation();
        if (active.value === 1) {
          onClick && onClick();
        }
      }
    : null;

  return (
    <>
      <mesh
        name={'ScreenOver'}
        ref={meshRef}
        position={[0, 0, -0.001]}
        geometry={geometry}
        onPointerOver={() => {
          if (!onClick) return;
          if (active.value > 0) setWindowStoreHover(true);
          setHover({ hover: 1 });
        }}
        onPointerMove={() => {
          if (!onClick) return;
          if (active.value > 0) setWindowStoreHover(true);
          setHover({ hover: 1 });
        }}
        onPointerOut={() => {
          setWindowStoreHover(false);
          setHover({ hover: 0 });
        }}
        onClick={onScreenClick}
      >
        <shaderMaterial
          ref={materialRef}
          args={[args]}
          vertexShader={hoverVert}
          fragmentShader={hoverFrag}
          transparent={true}
          visible={true}
          needsUpdate={true}
          attach="material"
        />
      </mesh>
    </>
  );
}
