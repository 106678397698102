export const instancedBillboardVertex = `
precision highp float;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;

uniform float tileNum;

attribute vec3 translation;
attribute float emojiId;
attribute vec3 position;
attribute vec3 params; // scale, rotation, alpha
attribute vec2 uv;

varying vec2 vUv;
varying float vAlpha;

mat2 rotation(float a) {
 float s = sin(a);
 float c = cos(a);
 return mat2(c, -s, s, c);
}

void main() {
  vec4 mvPosition = modelViewMatrix * vec4(translation.xyz,1.0);

  vec3 billboardOffset = position * params.x;
  billboardOffset.xy *= rotation(params.y);    
  mvPosition.xyz += billboardOffset;
  
  vec2 transformedUv = uv;
  transformedUv.x /= tileNum;
  transformedUv.x += emojiId / tileNum;
  
  vUv = transformedUv;
  vAlpha = params.z;
  gl_Position = projectionMatrix * mvPosition;
}`;

export const instancedBillboardFragment = `
precision highp float;

uniform sampler2D map;

varying vec2 vUv;
varying float vAlpha;

void main() {
  vec4 c = texture2D(map, vUv);
  c.a *= vAlpha;
  gl_FragColor = c;
}`;
