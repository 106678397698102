import React from 'react';
import { useWispStore } from 'services/WispService';
import WispRoom from '../WispRoom';

export default function WispRooms() {
  const wispRooms = useWispStore(state => state.rooms);

  const wispRoomComponents = wispRooms.map((wispRoom, index) => {
    return <WispRoom key={wispRoom.id} wispRoom={wispRoom} index={index} />;
  });

  return <>{wispRoomComponents}</>;
}
