import styled, { css } from 'styled-components';
import { iconFillAndStroke } from '../../icon';
import { Button } from 'common/components/Button';
import { mediaMax } from 'common/layout';

export const ActionButton = styled(Button)`
  font-size: 16px;
  height: 40px;
  ${props => (props.primary ? 'padding: 0 3px;' : 'padding: 0 5px 0 16px;')};
  ${props => props.primary && 'background-color: #2870E6; border: 1px solid #2870E6;'};
  ${props => props.alert && 'background-color: #FA5F5F; border: 1px solid #FA5F5F;'};
  outline: none;
  cursor: pointer;
  color: white;
  overflow: visible;

  svg {
    ${iconFillAndStroke('white', 'white')}
    vertical-align: middle;
  }

  span {
    margin-right: 5px;
  }
  ${mediaMax(
    'small',
    css`
      width: 40px;
      padding-left: 0;
      padding-right: 0;
      span {
        display: none;
      }
    `
  )}

  &:hover {
    ${props =>
      props.alert &&
      css`
        background: linear-gradient(76.24deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #fa5f5f;
        border-color: FA5F5F;
      `};
  }
`;
