import styled from 'styled-components';
import { baseColors } from 'styles';

export const Container = styled.div`
  position: absolute;
  left: ${props => (props.anchorLeft ? 0 : 50)}%;
  bottom: 75px;
  transition: all 0.2s ease-out;

  transform: ${props =>
    props.visible ? `translate(${props.anchorLeft ? 0 : -50}%, 0)` : `translate(${props.anchorLeft ? 0 : -50}%, -8px)`};

  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
`;

export const TriangleDown = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: ${baseColors.blue.interactive};
  transform: translateX(-50%);
  left: ${props => (props.anchorLeft ? '31px' : '50%')};

  bottom: -12px;
`;
