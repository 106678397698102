import React, { useRef, useState } from 'react';
import { userApi, useUserStore } from 'services/UserService';
import { socketApi } from 'services/SocketService';
import {
  StyledEditProfileLabel,
  StyledEditProfileInput,
  StyledEditProfileFileInput,
  StyledPreviewImage,
} from './styles';
import { Big, SmallRow, Flex, StyledProfileMenu } from '../styles';
import { TransitionItem } from 'styles';
import { ScrollContent } from 'components/Menu/SubMenu/styles';
import { Button } from 'common/components/Button';
import { trackEvent } from 'utilities/analytics';
import { safeSelectUrlFromMedia } from 'utilities/media';
import userStore from 'storage/user';

export default function EditProfile({ setIsEditing }) {
  const [isLoading, setIsLoading] = useState(false);
  const user = useUserStore(state => state.user);
  const [src, setSrc] = useState(safeSelectUrlFromMedia(user.thumbnail, 'thumbnail'));
  const formRef = useRef();
  const imageRef = useRef();

  const [userData, setUserData] = useState({
    speciality: user.speciality || '',
    company: user.company || '',
    title: user.title || '',
    forename: user.forename || '',
    surname: user.surname || '',
    thumbnail: user.thumbnail ? user.thumbnail.id : null,
  });

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    if (formRef.current.elements.files.value) {
      const request = new XMLHttpRequest();
      const restUrl = socketApi.getState().getRestUrl('strapi');
      const token = userStore.getToken();
      request.open('POST', restUrl + 'upload');
      request.setRequestHeader('Authorization', `Bearer ${token}`);
      request.send(new FormData(formRef.current));

      await new Promise(resolve => {
        request.onload = async () => {
          const response = JSON.parse(request.response);
          userData.thumbnail = response[0].id;
          resolve();
        };
      });
    }

    await userApi.getState().updateProfile(userData);
    setIsLoading(false);
    setIsEditing(false);
    trackEvent('Profile', 'Save');
  };

  const handleInput = e => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
  };

  const onFileChange = e => {
    const input = e.target;
    const reader = new FileReader();

    reader.onload = function(e) {
      setSrc(e.target.result);
    };

    reader.readAsDataURL(input.files[0]);
  };
  return (
    <ScrollContent>
      <StyledProfileMenu>
        <form ref={formRef} onSubmit={handleSubmit} style={{ height: '100%' }}>
          <Flex>
            <Big>
              <TransitionItem index={0}>
                <StyledEditProfileLabel>Profilbild</StyledEditProfileLabel>
                {src && <StyledPreviewImage ref={imageRef} src={src} />}
                <StyledEditProfileFileInput type="file" name="files" onChange={onFileChange} />
              </TransitionItem>
              <TransitionItem index={1}>
                <StyledEditProfileLabel>Titel</StyledEditProfileLabel>
                <StyledEditProfileInput value={userData.title} id="title" onChange={handleInput} />
              </TransitionItem>
              <TransitionItem index={2}>
                <StyledEditProfileLabel>Vorname</StyledEditProfileLabel>
                <StyledEditProfileInput value={userData.forename} id="forename" onChange={handleInput} />
              </TransitionItem>
              <TransitionItem index={3}>
                <StyledEditProfileLabel>Nachname</StyledEditProfileLabel>
                <StyledEditProfileInput value={userData.surname} id="surname" onChange={handleInput} />
              </TransitionItem>
              <TransitionItem index={4}>
                <StyledEditProfileLabel>Fachbereich</StyledEditProfileLabel>
                <StyledEditProfileInput value={userData.speciality} id="speciality" onChange={handleInput} />
              </TransitionItem>
              <TransitionItem index={5}>
                <StyledEditProfileLabel>Institution</StyledEditProfileLabel>
                <StyledEditProfileInput value={userData.company} id="company" onChange={handleInput} />
              </TransitionItem>
            </Big>
            <SmallRow>
              <TransitionItem index={5}>
                <Button primary type="submit" disabled={isLoading} background="interactiveBlue">
                  Speichern
                </Button>
              </TransitionItem>
            </SmallRow>
          </Flex>
        </form>
      </StyledProfileMenu>
    </ScrollContent>
  );
}
