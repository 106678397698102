import create from 'utilities/zustand/create';
import Storage from 'utilities/storage';

const DEVELOPER_CONTENT_ENABLED = 'DEVELOPER_CONTENT_ENABLED';
const CANVAS_RESOLUTION_LIMIT_ENABLED = 'CANVAS_RESOLUTION_LIMIT_ENABLED';
const SOCKET_DEBUG_ENABLED = 'SOCKET_DEBUG_ENABLED';
const SOCKET_TARGET = 'SOCKET_TARGET';
const SELECTED_DAT_GUI_INDEX = 'SELECTED_DAT_GUI_INDEX';
const MATERIALS_INDEX = 'MATERIALS_INDEX_2';
const DISABLED_3D = 'DISABLED_3D';
const STATS_ENABLED = 'STATS_ENABLED';
const SKIP_WELCOME = 'SKIP_WELCOME';

const storage = new Storage();
storage.init('DEBUG');
storage.defineEntry(DEVELOPER_CONTENT_ENABLED, false);
storage.defineEntry(CANVAS_RESOLUTION_LIMIT_ENABLED, true);
storage.defineEntry(SOCKET_DEBUG_ENABLED, false);
storage.defineEntry(SOCKET_TARGET, 'AUTO');
storage.defineEntry(SELECTED_DAT_GUI_INDEX, 0);
storage.defineEntry(MATERIALS_INDEX, -1);
storage.defineEntry(DISABLED_3D, false);
storage.defineEntry(STATS_ENABLED, false);
storage.onSetItem = () => {
  debugStoreApi.setState({});
};

const sessionStorage = new Storage({ type: 'session' });
sessionStorage.init('DEBUG');
sessionStorage.defineEntry(SKIP_WELCOME, false);

const storageApi = {
  setDeveloperContentEnabled: enabled => {
    storage.setItem(DEVELOPER_CONTENT_ENABLED, enabled);
  },
  getDeveloperContentEnabled: () => {
    return storage.getItem(DEVELOPER_CONTENT_ENABLED);
  },
  setCanvasResolutionLimitEnabled: enabled => {
    storage.setItem(CANVAS_RESOLUTION_LIMIT_ENABLED, enabled);
  },
  getCanvasResolutionLimitEnabled: () => {
    return storage.getItem(CANVAS_RESOLUTION_LIMIT_ENABLED);
  },
  setSocketDebugEnabled: enabled => {
    storage.setItem(SOCKET_DEBUG_ENABLED, enabled);
  },
  getSocketDebugEnabled: () => {
    return storage.getItem(SOCKET_DEBUG_ENABLED);
  },
  set3dDisabled: enabled => {
    storage.setItem(DISABLED_3D, enabled);
  },
  get3dDisabled: () => {
    return storage.getItem(DISABLED_3D) || process.env.NO_3D;
  },
  setSocketTarget: target => {
    storage.setItem(SOCKET_TARGET, target);
  },
  getSocketTarget: () => {
    return storage.getItem(SOCKET_TARGET);
  },
  setSelectedDatGuiIndex: selectedIndex => {
    storage.setItem(SELECTED_DAT_GUI_INDEX, selectedIndex);
  },
  getSelectedDatGuiIndex: () => {
    return storage.getItem(SELECTED_DAT_GUI_INDEX);
  },
  setMaterialsIndex: selectedIndex => {
    storage.setItem(MATERIALS_INDEX, selectedIndex);
  },
  getMaterialsIndex: () => {
    return storage.getItem(MATERIALS_INDEX);
  },
  setStatsEnabled: enabled => {
    storage.setItem(STATS_ENABLED, enabled);
  },
  getStatsEnabled: () => {
    return storage.getItem(STATS_ENABLED);
  },
  setSkipWelcome: skip => {
    sessionStorage.setItem(SKIP_WELCOME, skip);
  },
  getSkipWelcome: () => {
    return sessionStorage.getItem(SKIP_WELCOME);
  },
};
export const [useDebugStore, debugStoreApi] = create(module, () => storageApi);
export default storageApi;
