import { useEffect, useMemo, useRef } from 'react';
import { useFrame, useThree } from 'react-three-fiber';
import { Clock, Euler, Vector3 } from 'three';
import { usePrevious } from 'utilities/hooks';
import { cameraApi } from 'services/CameraService';
import { controlsApi } from 'services/ControlsService';
import { contentApi, useContentStore } from 'services/ContentService';
import { CONTENT_TYPE, CONTENT_UI_TYPE } from 'services/ContentService/contentTypes';
import { hotspots } from 'components/Play/Hub/HotSpotController/hotspots';
import { useLibraryStore } from 'services/LibraryService';
import { wispApi } from 'services/WispService';
import { useWispStore } from 'services/WispService';

export default function CameraController() {
  const { camera } = useThree();

  const content = useContentStore(state => state.activeContent);
  const wispRoom = useWispStore(state => state.wispRoom);
  const libraryCategories = useLibraryStore(state => state.categories);
  const contentType = content && content.type.id;
  const prevContentType = usePrevious(contentType);
  const lastLockId = useRef(0);

  useMemo(() => {
    cameraApi.getState().init(camera);
  });

  useEffect(() => {
    const state = cameraApi.getState();
    const { setFieldOfView, setOrbitEulerLimits, setOrbitLock, transition, orbit } = state;

    const activeContent = contentApi.getState().activeContent;
    const closeupMode = activeContent != null && activeContent.type.uiType !== CONTENT_UI_TYPE.HIDDEN;

    const r = 3.466;
    const k = -Math.PI * 0.5 + (2 / 3) * Math.PI * 2;

    let orbitLock = null;

    switch (contentType) {
      case CONTENT_TYPE.LIBRARY.id: {
        const orbitLockIndex = prevContentType === null ? 0 : orbit.orbitLockIndex;
        const orbitLockCount = Math.max(1, libraryCategories.length);
        orbitLock = {
          enabled: true,
          orbitLockOffset: Math.PI * (2.0 / 3.0),
          orbitLockCount,
          orbitLockIndex,
        };

        if (lastLockId.current === 1) {
          break;
        }
        transition.start(1.5);
        lastLockId.current = 1;
        const euler = (1 / 3 + orbitLockIndex / orbitLockCount) * Math.PI * 2;
        orbit.updateLock();
        orbit.orbitEuler = new Euler(0.2, euler, 0, 'YXZ');
        orbit.origin = new Vector3(Math.cos(k) * r, 0.0, Math.sin(k) * r);
        orbit.distance = 0.37;
        orbit.offset = 0;
        setFieldOfView(55);
        setOrbitEulerLimits({
          xMin: 0.2,
          xMax: 0.2,
          yMin: null,
          yMax: null,
        });
        break;
      }

      case CONTENT_TYPE.NETWORKING.id:
        {
          lastLockId.current = 2;
          const orbitOrigin = hotspots[0].position.clone();
          orbitOrigin.y += 0.25;

          const rooms = wispApi.getState().rooms;
          const orbitLockIndex = prevContentType === null ? 0 : orbit.orbitLockIndex;
          const orbitLockCount = rooms.length;

          //const isSame = prevContentType === 'NETWORKING';
          transition.start(1.5);
          orbit.updateLock();
          //const orbitEulerY = isSame || wispRoom ? orbit.orbitEuler.y : (-1 / 3 + orbitLockIndex / orbitLockCount) * Math.PI * 2;
          const orbitEulerY = (-1 / 3 + orbitLockIndex / orbitLockCount) * Math.PI * 2;
          orbit.orbitEuler = new Euler(0.1 * Math.PI, orbitEulerY, 0, 'YXZ');
          orbit.origin = wispRoom ? wispRoom.position : orbitOrigin;
          orbit.distance = wispRoom ? 1 : 3.5;
          orbit.offset = 0;
          setFieldOfView(25);
          setOrbitEulerLimits({
            xMin: 0.1 * Math.PI,
            xMax: 0.1 * Math.PI,
            yMin: null,
            yMax: null,
          });
          orbitLock = { enabled: !wispRoom, orbitLockOffset: Math.PI * (-2.0 / 3.0), orbitLockCount, orbitLockIndex };
        }
        break;
      case CONTENT_TYPE.LIVESTREAM.id:
      case CONTENT_TYPE.ARENA.id:
        {
          const isSame = prevContentType === 'LIVESTREAM' || prevContentType === 'ARENA';
          if (!isSame) {
            transition.start(1.5);
            lastLockId.current = 0;
            const orbitOrigin = hotspots[1].position.clone();
            orbitOrigin.z -= 0.5;
            orbit.orbitEuler = new Euler(0.05 * Math.PI, 0, 0, 'YXZ');
            orbit.origin = orbitOrigin;
            orbit.distance = 1.3;
            orbit.offset = 0;
            setFieldOfView(30);
            setOrbitEulerLimits({
              xMin: 0,
              xMax: 0.15 * Math.PI,
              yMin: -0.4,
              yMax: 0.4,
              yMargin: 0.1,
              xMargin: 0.1,
            });
            orbitLock = { enabled: false };
          }
        }
        break;
      case null:
        {
          if (prevContentType !== undefined) {
            transition.start(1.5);
          }
          const orbitLockIndex = lastLockId.current;
          lastLockId.current = null;
          const orbitLockCount = 3;
          orbit.updateLock();
          const lockEuler = (orbitLockIndex / orbitLockCount) * Math.PI * 2;
          orbit.orbitEuler = new Euler(0.04 * Math.PI, lockEuler, 0.0, 'YXZ');
          orbit.origin = new Vector3(0, -0.2, 0);
          orbit.distance = 9;
          orbit.offset = closeupMode ? 1 : 0;
          setFieldOfView(30);
          setOrbitEulerLimits({
            xMin: 0.04 * Math.PI,
            xMax: 0.04 * Math.PI,
            yMin: null,
            yMax: null,
          });
          orbitLock = { enabled: true, orbitLockOffset: 0, orbitLockIndex, orbitLockCount };
        }
        break;
    }

    if (orbitLock) {
      setOrbitLock(orbitLock);
    }
  }, [contentType, wispRoom, libraryCategories]);

  const clock = useMemo(() => {
    return new Clock();
  });

  useFrame(() => {
    // run mode-specific update
    const { updateCamera } = cameraApi.getState();
    const { cursorPosition, cursorDown, cursorUp } = controlsApi.getState();
    const dt = Math.min(clock.getDelta(), 1);

    const params = {
      camera: camera,
      delta: dt,
      cursorPosition,
      cursorDown,
      cursorUp,
    };
    updateCamera(params);
    if (cursorUp) {
      controlsApi.setState({ cursorUp: false });
    }
  }, -50);

  useEffect(() => {
    cameraApi.setState({ mode: 'free' });
    cameraApi.getState().orbit.enabled = true;
  });

  return null;
}
