import React, { useState, useRef, useEffect } from 'react';
import { Plane, Text } from '@react-three/drei';
import LookAtCamera from '../../LookAtCamera';
import { useFrame } from 'react-three-fiber';
import * as THREE from 'three';
import libraryTeaserVert from './shader/libraryTeaser.vert';
import libraryTeaserFrag from './shader/libraryTeaser.frag';
import { contentApi, createContentFromLibraryCategory, useContentStore } from 'services/ContentService';
import SimplexNoise from 'simplex-noise';
import { cameraApi } from 'services/CameraService';
import { windowApi } from 'services/WindowService';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { safeSelectUrlFromMedia } from 'utilities/media';
import { betterLerp, betterLerpVec } from 'utilities/lerp';
import HighwayBoldTtf from 'fonts/PFHighwaySansPro-Bold.ttf';

const simplex = new SimplexNoise();

export default function LibraryCategory({ category, position, index }) {
  const materialRef = useRef();
  const groupRef = useRef();
  const textRef = useRef();
  const lastTime = useRef(0);
  const teaserUrl = safeSelectUrlFromMedia(category.teaser, 'thumbnail');
  const [isHover, setIsHover] = useState(false);

  const content = useContentStore(state => state.activeContent);
  const isLibraryContentActive = content && content.type.id === CONTENT_TYPE.LIBRARY.id;

  const [args] = useState({
    uniforms: {
      uExpandFactor: { value: 0 },
      uExpandSize: { value: 2.0 },
      uPhase: { value: 0.0 },
      uHover: { value: 0.0 },
      mainTex: { type: 't', value: null },
      uAspectX: { value: 1 },
      uAspectY: { value: 1 },
    },
  });

  useEffect(() => {
    const updateTexture = texture => {
      const width = texture ? texture.image.width : 1;
      const height = texture ? texture.image.height : 1;
      materialRef.current.uniforms.mainTex.value = texture;
      materialRef.current.uniforms.uAspectX.value = 1 / Math.max(1, width / height);
      materialRef.current.uniforms.uAspectY.value = 1 / Math.max(1, height / width);
      materialRef.current.needsUpdate = true;
    };

    if (!teaserUrl) {
      updateTexture(null);
      return;
    }
    new THREE.TextureLoader().load(
      `${teaserUrl}${teaserUrl && `${teaserUrl.includes('?') ? '&' : '?'}js`}`,
      updateTexture
    );
    return () => {
      if (materialRef.current.uniforms.mainTex.value) {
        materialRef.current.uniforms.mainTex.value.dispose();
      }
    };
  }, [teaserUrl]);

  const planeSize = 0.035;

  const handleClick = () => {
    const isFocused = cameraApi.getState().orbit.orbitLockIndex === index;
    if (isFocused) {
      const content = createContentFromLibraryCategory(category);
      contentApi.getState().setActiveContent(content);
      setIsHover(false);
      windowApi.getState().setHover(false);
    } else {
      cameraApi.getState().setOrbitLockIndex(index);
    }
  };

  const onPointerOver = () => {
    setIsHover(true);
    windowApi.getState().setHover(true);
  };

  const onPointerOut = () => {
    setIsHover(false);
    windowApi.getState().setHover(false);
  };

  const onPointerMove = () => {
    setIsHover(true);
    windowApi.getState().setHover(true);
  };

  useFrame(context => {
    //groupRef.current.position.y += 0.01;
    const uniforms = materialRef.current.uniforms;
    const elapsedTime = context.clock.getElapsedTime();
    const delta = elapsedTime - lastTime.current;
    const targetPos = position.clone();

    const { orbit, transition } = cameraApi.getState();
    const orbitLockIndex = orbit.orbitLockIndex;
    const orbitLockIndexCandidate = orbit.getCurrentLockCandidate();
    const focusedIndex = orbitLockIndexCandidate !== null ? orbitLockIndexCandidate : orbitLockIndex;
    const isFocused = isLibraryContentActive && focusedIndex === index;

    const highlighted = isFocused && !transition.enabled;
    if (highlighted) {
      targetPos.y = 0.0;
    }

    const timeFactor = 0.1;
    const noiseFactor = 0.01;
    targetPos.x += simplex.noise2D(elapsedTime * timeFactor, 0.2 + index * 64.0) * noiseFactor;
    targetPos.y += simplex.noise2D(elapsedTime * timeFactor, 0.5 + index * 64.0) * noiseFactor;
    targetPos.z += simplex.noise2D(elapsedTime * timeFactor, 0.8 + index * 64.0) * noiseFactor;

    const lerpFactor = highlighted ? 0.1 : 0.02;
    betterLerpVec(groupRef.current.position, targetPos, lerpFactor, delta);

    const targetExpandFactor = highlighted ? 1.0 : 0.0;
    const expandFactor = uniforms.uExpandFactor.value;
    const efDelta = Math.abs(expandFactor - targetExpandFactor);
    if (efDelta > 0.001) {
      uniforms.uExpandFactor.value = betterLerp(expandFactor, targetExpandFactor, 0.1, delta);
      materialRef.current.needsUpdate = true;
    }
    if (isFocused) {
      uniforms.uPhase.value = (elapsedTime * 0.2) % 1.0;
      materialRef.current.needsUpdate = true;
    }

    const targetHover = isHover ? 1.0 : 0.0;
    const hover = uniforms.uHover.value;
    const hoverDelta = Math.abs(hover - targetHover);
    if (hoverDelta > 0.001) {
      uniforms.uHover.value = betterLerp(hover, targetHover, 0.1, delta);
      materialRef.current.needsUpdate = true;
    }

    const showText = highlighted || isHover;
    const textTargetAlpha = showText ? 1.0 : 0.0;
    const textMaterial = textRef.current.material;
    const textAlpha = textMaterial.opacity;

    textMaterial.opacity = betterLerp(textAlpha, textTargetAlpha, showText ? 0.3 : 0.2, delta);
    textMaterial.visible = textMaterial.opacity > 0.001;
    textMaterial.needsUpdate = true;

    lastTime.current = elapsedTime;
  });
  const positionRef = useRef(position.clone());

  const usePointerEvents = isLibraryContentActive;
  return (
    <group position={positionRef.current} ref={groupRef}>
      <LookAtCamera
        position={[0, 0.15, 0]}
        onClick={usePointerEvents ? handleClick : null}
        onPointerOver={usePointerEvents ? onPointerOver : null}
        onPointerMove={usePointerEvents ? onPointerMove : null}
        onPointerOut={usePointerEvents ? onPointerOut : null}
      >
        <Plane args={[planeSize, planeSize]}>
          <shaderMaterial
            ref={materialRef}
            args={[args]}
            vertexShader={libraryTeaserVert}
            fragmentShader={libraryTeaserFrag}
            transparent={true}
            depthTest={true}
            depthWrite={false}
            visible={true}
            needsUpdate={true}
            attach="material"
          />
        </Plane>
        <Text
          ref={textRef}
          position={[0, 0.02, 0.001]}
          text={category.headline}
          font={HighwayBoldTtf}
          fontSize={0.007}
          anchorX="center"
          anchorY="bottom"
          renderOrder={999}
        />
      </LookAtCamera>
    </group>
  );
}
