import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useChatStore } from 'services/ChatService';
import { useEmojiStore } from 'services/EmojiService';
import { CONTENT_TYPE, CONTENT_UI_TYPE } from 'services/ContentService/contentTypes';
import { useContentStore } from 'services/ContentService';
import { zIndex } from 'styles';

import Like from 'common/assets/Emojis/emoji_like.png';
import Laugh from 'common/assets/Emojis/emoji_laugh.png';
import Heart from 'common/assets/Emojis/emoji_heart.png';
import Oh from 'common/assets/Emojis/emoji_oh.png';
import Clap from 'common/assets/Emojis/emoji_clap.png';

import { gsap, Power2, Sine, TweenMax } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

gsap.registerPlugin(MotionPathPlugin);

const offsetCollapsed = 85;
const offsetExpanded = offsetCollapsed + 400;

const FXContainer = styled.div`
  position: absolute;
  z-index: ${zIndex.fx};
  width: 10px;
  height: 10px;
  bottom: 0;
  right: ${props => (props.chatExpanded ? offsetExpanded : offsetCollapsed)}px;
  pointer-events: none;
`;

const EmojiSprite = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: -30px;
  right: 30px;
  pointer-events: none;
  background: url(${props => props.background}) no-repeat;
  background-size: contain;
`;

const spriteImages = {
  like: Like,
  laugh: Laugh,
  heart: Heart,
  oh: Oh,
  clap: Clap,
};

const getSign = () => {
  return Math.random() > 0.5 ? -1 : 1;
};

const getRandomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

function Emote(props) {
  const { type } = props;
  const sprite = useRef(null);

  useEffect(() => {
    const duration = 1.5 + Math.random();
    const path = [
      { x: 0, y: -30 },
      { x: getSign() * getRandomBetween(10, 25), y: -getRandomBetween(65, 85) },
      { x: 0, y: -getRandomBetween(140, 160) },
      { x: getSign() * getRandomBetween(10, 50), y: -getRandomBetween(215, 235) },
      { x: 0, y: -getRandomBetween(280, 350) },
    ];
    TweenMax.set(sprite.current, { right: 30, x: 0, y: -30 });
    TweenMax.to(sprite.current, duration, {
      motionPath: { curviness: 0.45, path: path, autoRotate: 90 },
      ease: Sine.easeOut,
    });
    TweenMax.to(sprite.current, 0.5, {
      opacity: 0,
      scale: 1.5,
      delay: duration - 0.5,
      ease: Power2.easeIn,
    });
  }, []);

  return <EmojiSprite ref={sprite} background={spriteImages[type]} />;
}

let canSpawn = true;
let forceIndex = 0;

export default function EmojiFX() {
  const emoji = useEmojiStore(state => state.emoji);
  const chatExpanded = useChatStore(state => state.isActive);
  const activeContent = useContentStore(state => state.activeContent);
  const contentType = activeContent && activeContent.type.id;
  const [, setForceRender] = useState(0);

  const isFullscreenContent = activeContent && activeContent.type.uiType === CONTENT_UI_TYPE.FULLSCREEN;

  const timeout = useRef();
  const emotesRef = useRef([]);

  useEffect(() => {
    if (canSpawn && emoji != null && isFullscreenContent && contentType === CONTENT_TYPE.LIVESTREAM.id) {
      canSpawn = false;
      emotesRef.current.push(emoji);
      setForceRender(forceIndex++);
      const localEmojiId = emoji.id;
      timeout.current = setTimeout(() => {
        emotesRef.current = emotesRef.current.filter(e => e.id !== localEmojiId);
        setForceRender(forceIndex++);
      }, 2500);
      setTimeout(() => {
        canSpawn = true;
      }, 50);
    }
  }, [emoji]);

  useEffect(() => {
    if (isFullscreenContent) {
      emotesRef.current = [];
    }
  }, [isFullscreenContent]);

  const hasEmojis = activeContent ? activeContent.type.show2DEmojis : false;

  return hasEmojis ? (
    <FXContainer chatExpanded={chatExpanded}>
      {emotesRef.current.map(item => {
        return <Emote key={item.id} type={item.emoji} />;
      })}
    </FXContainer>
  ) : null;
}
