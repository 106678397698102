import styled, { css } from 'styled-components';
import { baseColors } from 'styles';
import { mediaMax } from 'common/layout';

export const StyledScheduleHeaderItem = styled.div`
  width: 100%;
  color: ${baseColors.white};
  font-family: 'PF Highway Sans Pro';
  font-style: normal;
  font-size: 16px;
  line-height: 120%;
`;

export const StyledTalkComponents = styled.div`
  padding: 40px 0;
  padding-top: 32px;
  ${mediaMax(
    'small',
    css`
      padding: 16px 0;
    `
  )}
`;
