import React from 'react';
import PersonImage from 'common/components/PersonImage';
import PersonInfos from './PersonInfos';
import PersonActions from './PersonActions';
import { PersonContainer } from './styles';

export default function Person({
  user,
  infoComponents,
  actionComponents,
  chatNotice = false,
  showOnlineIndicator = false,
  verticalCenter = false,
}) {
  return (
    <PersonContainer>
      <PersonImage square size="m" user={user} chatNotice={chatNotice} showOnlineIndicator={showOnlineIndicator} />
      {infoComponents && <PersonInfos verticalCenter={verticalCenter} infoComponents={infoComponents} />}
      {actionComponents && <PersonActions verticalCenter={verticalCenter} actionComponents={actionComponents} />}
    </PersonContainer>
  );
}
