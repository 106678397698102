import React from 'react';
import { Bubble, ChatBubbleContainer, ChatBubbleVisibilityToggle, Sender, StyledMessageText } from './styles';
import { userApi, useUserStore, assembleNameWithTitle } from 'services/UserService';
import { chatApi } from 'services/ChatService';
import textLink from 'utilities/textLink';
import ChatReactions from '../ChatReactions';
import { Button } from 'common/components/Button';

const ChatBubble = ({ message }) => {
  const { user, text, isHidden, id } = message;
  const isSelf = user.id === userApi.getState().user.id;
  const fullname = user ? assembleNameWithTitle(user) : '';
  const roleType = useUserStore(state => state.user.role.type);
  const showHideOrShowButton = message.id && (roleType === 'orga' || roleType === 'staff' || roleType === 'invisible');
  const isInteractive = !isSelf && user;

  const onShowOrHideMessage = () => {
    if (message.isHidden) chatApi.getState().showMessage(id);
    else chatApi.getState().hideMessage(id);
  };

  const onClick = isInteractive
    ? () => {
        if (user) {
          userApi.getState().openProfileModal(user);
        }
      }
    : null;

  return (
    <>
      <ChatBubbleContainer floatRight={isSelf}>
        <Bubble isSelf={isSelf} hide={showHideOrShowButton && isHidden}>
          <Sender onClick={onClick} isInteractive={isInteractive} isSelf={isSelf}>
            {fullname}
          </Sender>
          <StyledMessageText
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {textLink(text)}
          </StyledMessageText>
        </Bubble>
        {showHideOrShowButton ? (
          <ChatBubbleVisibilityToggle isSelf={isSelf}>
            <Button secondary onClick={onShowOrHideMessage}>
              {message.isHidden ? 'show' : 'hide'}
            </Button>
          </ChatBubbleVisibilityToggle>
        ) : null}
      </ChatBubbleContainer>
      <ChatBubbleContainer floatRight={isSelf}>
        <ChatReactions message={message} floatRight={isSelf} />
      </ChatBubbleContainer>
    </>
  );
};

export default ChatBubble;
