import React from 'react';
import { modalApi } from 'services/ModalService';
import { contentApi } from 'services/ContentService';
import CloseButton from 'common/components/CloseButton';
import { Button } from 'common/components/Button';
import { StyledColumn, StyledInfo, StyledTitle, StyledButtonContainer } from 'components/Modal/styles';
import { useDocumentListener } from 'utilities/hooks';

export default function ModalAskPermission({ onAskForPermission }) {
  const onCloseClick = () => {
    modalApi.getState().closeModal();
    contentApi.getState().clearActiveContent();
  };

  useDocumentListener(true, 'keyup', e => {
    e.code === 'Escape' && onCloseClick();
  });

  return (
    <>
      <CloseButton onClick={onCloseClick} />
      <StyledColumn>
        <StyledTitle>Zugriff erlauben</StyledTitle>
        <StyledInfo>
          Um in Audioräume partizipieren zu können, bitten wir Sie um Zugriff auf Ihr Mikrofon. Sie können die Anfrage
          im nächsten Schritt erlauben.
        </StyledInfo>
        <StyledButtonContainer>
          <Button primaryInverted onClick={onAskForPermission}>
            Browser-Berechtigung anfordern
          </Button>
        </StyledButtonContainer>
      </StyledColumn>
    </>
  );
}
