import React from 'react';
import DatGui, { DatBoolean, DatColor, DatFolder, DatNumber, DatSelect } from 'react-dat-gui';
import { useEnvironmentStore } from './store';

const EnvironmentDatGui = () => {
  const { environmentConfiguration, toneMappings, set } = useEnvironmentStore();
  return (
    <DatGui data={environmentConfiguration} onUpdate={set}>
      <DatFolder title="Directional Light" closed={false}>
        <DatBoolean path="directionalLight.on" label="Toggle On/Off" />
        <DatNumber path="directionalLight.intensity" label="Intensity" min={0} max={1} step={0.001} />
        <DatColor path="directionalLight.color" label="Color" />
      </DatFolder>
      <DatFolder title="ToneMapping" closed={false}>
        <DatSelect path="toneMapping.name" label="Type" options={toneMappings.map(i => i.name)} />
        <DatNumber path="toneMapping.exposure" label="Exposure" min={0} max={2} step={0.001} />
        <DatNumber path="toneMapping.whitePoint" label="WP (uncharted2)" min={0} max={2} step={0.001} />
      </DatFolder>
      <DatFolder title="Sky" closed={false}>
        <DatNumber path="sky.inclination" label="Sun Inclination" min={-2} max={2} step={0.001} />
        <DatNumber path="sky.azimuth" label="Sun Azimuth" min={-Math.PI} max={Math.PI} step={0.001} />
      </DatFolder>
    </DatGui>
  );
};

export default EnvironmentDatGui;
