import styled, { css } from 'styled-components';
import { iconFillAndStroke } from 'common/icon';
import { H3 } from 'common/text';
import { mediaMax } from 'common/layout';

export const BackButton = styled.button`
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 0;
  outline: none;
  border: none;
  display: block;
  background: transparent;
  margin-right: 8px;
  flex-shrink: 0;

  svg {
    ${iconFillAndStroke('#fff', '#fff')}
  }

  ${mediaMax(
    'small',
    css`
      margin-left: -12px;
    `
  )}
`;
export const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;
export const SmallTitle = styled(H3)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const SmallTitleContainer = styled.div`
  margin-top: -2px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const OverlayContainer = styled.div`
  position: absolute;
  left: 32px;
  top: 8px;
  right: 0;
`;
