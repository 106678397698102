import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';
import Routes from '../Routes';
import { deviceApi } from 'services/DeviceService';
import { windowApi } from 'services/WindowService';
import { buildApi } from 'services/BuildService';
import { applicationApi, useApplicationStore } from 'services/ApplicationService';
import { userApi, useUserStore } from 'services/UserService';
import { profilingApi } from 'services/ProfilingService';
import { useDebugStore } from 'storage/debug';
import DevContent from 'components/DevContent';
import StartMessage from 'components/Start/StartMessage';
import Config from 'components/DevContent/Config';

import { SolidBackdropGradient } from 'styles';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';

const Connecting = styled.div`
  width: 100%;
  height: 100%;
  ${SolidBackdropGradient}
`;

function SkipWelcomeRedirect() {
  const debugStore = useDebugStore();
  const history = useHistory();
  useEffect(() => {
    debugStore.setSkipWelcome(true);
    history.push('/');
  }, []);
  return null;
}

function Start() {
  const [state, setState] = useState('INIT');
  const applicationStore = useApplicationStore(state => state.state);
  const loginState = useUserStore(state => state.loginState);

  useEffect(() => {
    profilingApi.getState().mark('Init');

    const init = async () => {
      deviceApi.getState().init();
      windowApi.getState().init();
      buildApi.getState().init();
      let skipLogin = window.location.pathname === '/login';
      try {
        profilingApi.getState().mark('Event Start Load');
        await applicationApi.getState().init();
        profilingApi.getState().mark('Event Loaded');
      } catch (e) {
        // do not attempt to login in case that socket connection failed
        skipLogin = true;
        // eslint-disable-next-line no-console
        e && console.error(e);
      }
      userApi.getState().init(skipLogin);
    };
    init();
  }, []);

  useEffect(() => {
    if (
      applicationStore === 'NONE_AVAILABLE' ||
      applicationStore === 'FAILED' ||
      applicationStore === 'WELCOME' ||
      loginState === 'CRITICAL_ERROR'
    ) {
      setState('MESSAGE');
    } else if (loginState === 'LOGGED_OUT' || loginState === 'LOGGED_IN') {
      setState('SOLVED');
    }
  }, [loginState, applicationStore]);

  const showMessage = state === 'MESSAGE';
  const connected = state === 'SOLVED';

  const mainComponents = connected ? (
    <>
      <Routes />
      <DevContent />
    </>
  ) : (
    <>
      <Router>
        <Switch>
          <Route key="skip-welcome" exact path="/skip-welcome" component={SkipWelcomeRedirect} />,
        </Switch>
      </Router>
      <Connecting />
    </>
  );

  const startMessage = (
    <Router>
      <Switch>
        <Route key="config" path="/config" component={Config} />,
        <Route path="/" component={StartMessage} />,
      </Switch>
    </Router>
  );

  const render = showMessage ? startMessage : mainComponents;
  return <>{render}</>;
}

export default hot(module)(Start);
