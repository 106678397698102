import React, { useRef } from 'react';
import { useFrame } from 'react-three-fiber';

import { Vector3, Quaternion, Matrix4 } from 'three';

const _q1 = new Quaternion();
const _m1 = new Matrix4();
const _m2 = new Matrix4();

const _target = new Vector3();
const _position = new Vector3();
const _right = new Vector3();
const _up = new Vector3();
const _forward = new Vector3();

function lookAt(_this, camera) {
  const parent = _this.parent;

  camera.getWorldPosition(_target);

  _this.updateWorldMatrix(true, false);
  _this.getWorldPosition(_position);

  _m2.extractRotation(camera.matrixWorld);

  _right
    .set(1, 0, 0)
    .applyMatrix4(_m2)
    .normalize();
  _forward
    .copy(_target)
    .sub(_position)
    .normalize();
  _up.crossVectors(_forward, _right).normalize();
  _forward.crossVectors(_right, _up).normalize();

  _m1.elements[0] = _right.x;
  _m1.elements[1] = _right.y;
  _m1.elements[2] = _right.z;
  _m1.elements[3] = 0;

  _m1.elements[4] = _up.x;
  _m1.elements[5] = _up.y;
  _m1.elements[6] = _up.z;
  _m1.elements[7] = 0;

  _m1.elements[8] = _forward.x;
  _m1.elements[9] = _forward.y;
  _m1.elements[10] = _forward.z;
  _m1.elements[11] = 0;

  _m1.elements[12] = _m1.elements[13] = _m1.elements[14] = 0;
  _m1.elements[15] = 1;

  _this.quaternion.setFromRotationMatrix(_m1);

  if (parent) {
    _m1.extractRotation(parent.matrixWorld);
    _q1.setFromRotationMatrix(_m1);
    _this.quaternion.premultiply(_q1.inverse());
  }
}

export default function LookAtCamera(props) {
  const localRef = useRef();
  useFrame(context => {
    const { camera } = context;
    lookAt(localRef.current, camera);

    // this one tends to tilt a bit... not optimal for text
    //localRef.current.lookAt(camera.position);
  });
  return (
    <object3D ref={localRef} {...props}>
      {props.children}
    </object3D>
  );
}
