import React from 'react';
import { StyledBadge } from './styles';

export function Badge({ user }) {
  let description;
  switch (user.role.type) {
    case 'moderator':
      description = 'Moderator';
      break;
    case 'speaker':
      description = 'Referent';
      break;
    case 'staff':
      description = 'Ipsen';
      break;
    case 'orga':
      description = 'Orga-Team';
      break;
    case 'invisible':
      description = 'Unsichtbar';
      break;
    //case "authenticated":
    default:
      description = 'Teilnehmer';
  }
  return <StyledBadge>{description}</StyledBadge>;
}
