import React from 'react';
import styled from 'styled-components';

export const StyleTextLink = styled.a`
  color: inherit;
  text-decoration: underline !important;
`;
const TextLink = ({ to, children }) => {
  return (
    <StyleTextLink href={to} target="_blank" rel="noopener noreferrer">
      {children}
    </StyleTextLink>
  );
};

export default TextLink;
