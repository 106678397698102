import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Overline2 } from 'common/text';
import { colors } from 'styles';

const StyledTalkLiveBadge = styled(Overline2)`
  display: ${props => (props.live ? 'block' : 'none')};
  color: ${colors.alertRed};
  margin-right: 4px;
`;

export default function TalkLiveBatch({ talk }) {
  const { startTime, endTime } = talk;
  const isLive = moment().isBetween(moment(startTime), endTime);
  return <StyledTalkLiveBadge live={isLive}>Live</StyledTalkLiveBadge>;
}
