import styled from 'styled-components';
import { baseColors } from 'styles';

export default styled.div`
  display: ${props => (props.active ? 'block' : 'none')};
  margin-left: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${baseColors.aquamarine};
`;
