import styled from 'styled-components';
import { TransitionComponent } from '../styles';

export const StyledArena = styled(TransitionComponent)`
  display: ${props => (props.show ? 'inherit' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 50px;
`;
