import { BackSide, CubeTextureLoader, RepeatWrapping, sRGBEncoding, TextureLoader } from 'three';
import React, { useEffect, useRef } from 'react';
import { sceneApi, useSceneStore } from 'services/SceneService';
import { environmentApi } from '../store';
import { fragmentShader, uniforms, vertexShader } from './glsl/environment.glsl';
import { useLoader } from 'react-three-fiber';

const sides = ['Left.jpg', 'Right.jpg', 'Top.jpg', 'Bottom.jpg', 'Front.jpg', 'Back.jpg'];

export default function Skybox() {
  // const { scene } = useThree();
  const init = useSceneStore(state => state.init);
  const isLoaded = useSceneStore(state => state.isLoaded);
  const matRef = useRef();

  const noise = useLoader(
    TextureLoader,
    'https://d38oh1si3euxck.cloudfront.net/assets/hub/textures/color_noise_256.jpg'
  );
  noise.wrapT = noise.wrapS = RepeatWrapping;

  useEffect(() => {
    if (!init || !isLoaded) return;

    //Background
    const cubeMap = new CubeTextureLoader()
      .setPath('https://d38oh1si3euxck.cloudfront.net/assets/hub/cubemap/12_1k/')
      .load(sides, () => {
        sceneApi.setState({ bgIsLoaded: true });
      });
    cubeMap.encoding = sRGBEncoding;

    //Reflection Probe Stage
    const cubeMapArena = new CubeTextureLoader()
      .setPath('https://d38oh1si3euxck.cloudfront.net/assets/hub/cubemap/arena/2_512/')
      .load(sides);
    cubeMapArena.encoding = sRGBEncoding;

    //Reflection Probe Library
    const cubeMapLibrary = new CubeTextureLoader()
      .setPath('https://d38oh1si3euxck.cloudfront.net/assets/hub/cubemap/library/2_512/')
      .load(sides);
    cubeMapLibrary.encoding = sRGBEncoding;

    environmentApi.setState({ envMaps: { library: cubeMapLibrary, scene: cubeMap, arena: cubeMapArena } });

    if (isLoaded) {
      uniforms.tCube.value = cubeMap;
      uniforms.tNoise.value = noise;
    } else {
      uniforms.tCube.value = null;
      uniforms.tNoise.value = null;
    }
  }, [init, isLoaded]);

  return (
    isLoaded && (
      <mesh name={'Background'} onClick={() => {}} position={[0, 0, 0]} scale={[500, 500, 500]}>
        <boxBufferGeometry attach="geometry" />
        <shaderMaterial
          ref={matRef}
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          uniforms={uniforms}
          attach="material"
          side={BackSide}
        />
      </mesh>
    )
  );
}
