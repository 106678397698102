import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFrame } from 'react-three-fiber';
import * as THREE from 'three';
import { Plane, Text } from '@react-three/drei';
import { useTwilioStore } from 'services/TwilioService';
import { useWispStore } from 'services/WispService';
import { MathUtils, sRGBEncoding, VideoTexture } from 'three';
import LookAtCamera from 'components/Play/LookAtCamera';
import avatarVert from './shader/avatar.vert';
import avatarFrag from './shader/avatar.frag';
import { safeSelectFormatFromMedia } from 'utilities/media';
import HighwayBoldTtf from 'fonts/PFHighwaySansPro-Bold.ttf';

function WispParticipant({ index, wisp, participant }) {
  const objectRef = useRef();
  const materialRef = useRef();
  const textRef = useRef();
  const [videoTrack, setVideoTrack] = useState(null);
  const [audioTrack, setAudioTrack] = useState(null);
  const whiteRingBaseSize = useRef(0);
  const smoothFlash = useRef(0);
  const targetFlash = useRef(0);
  const staticAvatar = useRef({
    texture: null,
    width: 1,
    height: 1,
  });

  const teaser = safeSelectFormatFromMedia(wisp.thumbnail, 'thumbnail');

  const [args] = useState({
    uniforms: {
      uAspectX: { value: 1 },
      uAspectY: { value: 1 },
      uMainTex: { type: 't', value: null },
      uScale: { value: 1.0 },
      uFlash: { value: 0.0 },
      uAvatarRadius: { value: 0.5 },
      uHasAvatar: { value: 0.0 },
    },
  });

  const setMainTex = (texture, width, height) => {
    if (materialRef.current) {
      materialRef.current.uniforms.uMainTex.value = texture;
      materialRef.current.uniforms.uHasAvatar.value = !!texture;
      materialRef.current.uniforms.uAspectX.value = 1 / Math.max(1, width / height);
      materialRef.current.uniforms.uAspectY.value = 1 / Math.max(1, height / width);
      materialRef.current.needsUpdate = true;
    }
    if (textRef.current) {
      textRef.current.visible = !texture;
    }
  };
  const resetMainTex = () =>
    setMainTex(staticAvatar.current.texture, staticAvatar.current.width, staticAvatar.current.height);

  useEffect(() => {
    resetMainTex();
    if (!teaser) {
      return;
    }
    const { width, height, url } = teaser;
    new THREE.TextureLoader().load(`${url}${url && `${url.includes('?') ? '&' : '?'}js`}`, texture => {
      staticAvatar.current = { texture, width, height };
      if (materialRef.current && !materialRef.current.uniforms.uMainTex.value) {
        resetMainTex();
      }
    });
    return () => {
      if (staticAvatar.current.texture) {
        staticAvatar.current.texture.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.material.depthWrite = true;
    }
    return () => {
      if (textRef.current) {
        textRef.current.geometry.dispose();
      }
    };
  }, []);

  const name = useMemo(() => {
    const { forename, surname, state } = wisp;
    if (state.isSelf) {
      return 'Ich';
    }
    const text = (forename && forename.length > 0 ? forename[0] : '') + (surname && surname.length > 0 ? surname[0] : '');
    return text.toUpperCase();
  }, []);

  useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTrack(track);
      } else if (track.kind === 'audio') {
        setAudioTrack(track);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTrack(null);
      } else if (track.kind === 'audio') {
        setAudioTrack(null);
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    if (participant.audioTracks.size > 0) {
      setAudioTrack(participant.audioTracks.values().next().value.track);
    }

    return () => {
      setVideoTrack(null);
      setAudioTrack(null);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    if (videoTrack) {
      const video = document.createElement('video');
      videoTrack.attach(video);
      video.play();
      const texture = new VideoTexture(video);
      texture.anisotropy = 16;
      texture.encoding = sRGBEncoding;
      texture.flipY = true;

      const updateTex = () => {
        setMainTex(texture, videoTrack.dimensions.width, videoTrack.dimensions.height);
      };
      videoTrack.on('dimensionsChanged', updateTex);

      if (videoTrack.isStarted) {
        updateTex();
      } else {
        videoTrack.once('started', updateTex);
      }

      return () => {
        resetMainTex();
        videoTrack.detach();
        texture.dispose();
      };
    }
  }, [videoTrack]);

  useEffect(() => {
    const analyse = mediaStream => {
      var AudioContext = window.AudioContext || window.webkitAudioContext;
      var context = new AudioContext();
      var processor = context.createScriptProcessor(256);
      var source = context.createMediaStreamSource(mediaStream);

      processor.connect(context.destination);
      source.connect(processor);

      let lastDetectTime = Number.MIN_VALUE;

      processor.onaudioprocess = evt => {
        if (!materialRef.current) {
          return; // in rare cases, onaudioprocess is called after disposal
        }

        const input = evt.inputBuffer.getChannelData(0);
        const len = input.length;
        let total = 0;
        let i = 0;
        while (i < len) total += Math.abs(input[i++]);
        const rms = Math.sqrt(total / len);

        if (evt.playbackTime - 0.1 < lastDetectTime) {
          return;
        }

        targetFlash.current = rms * 8;
        lastDetectTime = evt.playbackTime;
      };
      return { context, source, processor };
    };

    if (audioTrack) {
      const mediaStream = new MediaStream([audioTrack.mediaStreamTrack]);
      const { context, source, processor } = analyse(mediaStream);
      return () => {
        targetFlash.current = 0;
        processor.disconnect(context.destination);
        source.disconnect(processor);
      };
    }
  }, [audioTrack]);

  useFrame(() => {
    const whiteRingBaseSizeTarget = audioTrack && audioTrack.isEnabled ? 0.1 : 0.0;
    whiteRingBaseSize.current = MathUtils.lerp(whiteRingBaseSize.current, whiteRingBaseSizeTarget, 0.1);
    smoothFlash.current = MathUtils.lerp(smoothFlash.current, targetFlash.current, 0.1);
    const flashValue = Math.max(wisp.state.mouseDistance, smoothFlash.current) * 0.125;
    objectRef.current.position.copy(wisp.state.position);
    objectRef.current.position.y += (index % 4) * 0.02;
    objectRef.current.position.z += Math.floor(index / 4) * 0.02;
    //objectRef.current.scale.set(1 + scale, 1 + scale, 1 + scale);
    materialRef.current.uniforms.uFlash.value = flashValue;
    materialRef.current.uniforms.uScale.value = 0.5 + whiteRingBaseSize.current + flashValue;
    materialRef.current.needsUpdate = true;
  }, 100);

  const planeSize = 0.1;

  return (
    <object3D ref={objectRef}>
      <LookAtCamera>
        <Plane args={[planeSize, planeSize]} scale={[1, 1, 1]} renderOrder={999}>
          <shaderMaterial
            ref={materialRef}
            args={[args]}
            vertexShader={avatarVert}
            fragmentShader={avatarFrag}
            transparent={true}
            depthTest={true}
            depthWrite={false}
            visible={true}
            needsUpdate={true}
            attach="material"
          />
        </Plane>
        <Text
          ref={textRef}
          position={[0, 0.0022, 0.001]}
          text={name}
          font={HighwayBoldTtf}
          fontSize={0.008}
          anchorX="center"
          anchorY="middle"
          renderOrder={999}
        />
      </LookAtCamera>
    </object3D>
  );
}

export default function WispParticipants() {
  const wisps = useWispStore(state => state.wisps);
  const participants = useTwilioStore(state => state.participants);

  const participantComponents = useMemo(() => {
    const indices = {};
    return participants.map(participant => {
      const id = participant.identity.split('_')[0];
      if (!Object.prototype.hasOwnProperty.call(indices, id)) {
        indices[id] = 0;
      } else {
        indices[id]++;
      }
      const wisp = wisps.find(w => w.id == id);
      if (wisp) {
        const index = indices[id];
        return <WispParticipant index={index} key={participant.identity} participant={participant} wisp={wisp} />;
      }
    });
  }, [wisps, participants]);
  return participantComponents;
}
