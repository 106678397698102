import create from 'utilities/zustand/create';
import React from 'react';
import { assembleNameWithTitle, userApi } from 'services/UserService';
import { districtApi } from 'services/DistrictService';
import { eventApi } from 'services/EventService';
import { notificationApi } from 'services/NotificationService';
import ChatNotification from 'components/Chat/ChatNotification';
import { trackEvent } from 'utilities/analytics';

let requestSequencer = 0;

const getChatName = chat => {
  switch (chat.type) {
    case 'district':
      return 'Event Chat';
    default:
      return chat.district || chat.identifier;
  }
};

const prepareChat = chat => {
  chat.messages = [];
  if (chat.type === 'private') {
    const selfId = userApi.getState().user.id;
    chat.name = chat.users
      .filter(user => user.id !== selfId)
      .map(user => assembleNameWithTitle(user))
      .join(', ');
  } else {
    // @TODO refactor chat titles here and in Chat component
    chat.name = getChatName(chat);
  }
  chat.modified_at_date = new Date(chat.modified_at);
  chat.seen = true;
  chat.last = false; // server tells us if that was the last page, the client cannot extrapolate that information
};

const chatHasSameTypeAndUserIds = (chat, type, userIds) => {
  const selfId = userApi.getState().user.id;
  if (chat.type !== type) return false;
  const chatUserIds = chat.users
    .map(u => u.id)
    .filter(id => id !== selfId)
    .sort();
  if (chatUserIds.length !== userIds.length) return false;
  const sortedUserIds = userIds.sort();
  for (let i = 0; i < sortedUserIds.length; ++i) {
    if (sortedUserIds[i] !== chatUserIds[i]) {
      return false;
    }
  }
  return true;
};

const sortChats = chats => {
  chats.sort((a, b) => {
    return a.modified_at_date - b.modified_at_date;
  });
};

let socket = null;

const initialState = () => {
  return {
    chats: [],
    activeChat: null,
    pending: false,
    isFullHeight: true,
    isLargeInput: false,
    userDidScrollUp: false,
    scrollIsTop: false,
    chatType: 'default',
  };
};

export const [useChatStore, chatApi] = create(module, (set, get) => ({
  ...initialState(),

  reset: () => {
    set(initialState());
  },

  setActiveChat: chat => {
    const { chats } = get();
    const index = chats.findIndex(c => c.id === chat.id);
    const activeChat = { ...chat };
    if (index !== -1) {
      chats[index] = activeChat;
    }
    set({ chats: [...chats], activeChat });
  },

  init: managedSocket => {
    socket = managedSocket;

    socket.on('chat/message', async message => {
      await get().pushMessage(message);
    });
    socket.on('message/hidden', message => {
      const chats = get().chats;
      const activeChat = get().activeChat;
      const chat = chats.find(c => c.id === message.chat);
      const user = userApi.getState().user;
      const isAdmin = user.role.type === 'orga' || user.role.type === 'staff' || user.role.type === 'invisible';
      if (!isAdmin) {
        chat.messages = chat.messages.filter(m => m.id !== message.id);
      } else {
        const messageToHide = chat.messages.find(m => m.id === message.id);
        if (!messageToHide) return;
        messageToHide.isHidden = true;
      }

      set({ ...chats });
      if (activeChat && chat.id === activeChat.id) {
        get().setActiveChat(chat);
      }
    });

    socket.on('message/shown', message => {
      const chats = get().chats;
      const activeChat = get().activeChat;
      const chat = chats.find(c => c.id === message.chat);
      if (!chat) return;
      const user = userApi.getState().user;
      const isAdmin = user.role.type === 'orga' || user.role.type === 'staff' || user.role.type === 'invisible';
      if (isAdmin) {
        const messageToShow = chat.messages.find(m => m.id === message.id);
        if (!messageToShow) return;
        messageToShow.isHidden = false;
      } else {
        chat.messages.push(message);
        chat.messages = chat.messages.sort((a, b) => a.id - b.id);
      }

      set({ chats });
      if (activeChat && chat.id === activeChat.id) {
        get().setActiveChat(chat);
      }
    });

    socket.on('chat/reactions', newReactions => {
      const chats = get().chats;
      const chat = chats.find(c => c.id === newReactions.chatId);
      if (!chat) return;

      const messageWithNewReactions = chat.messages.find(m => m.id === newReactions.messageId);
      if (!messageWithNewReactions) return;
      messageWithNewReactions.reactions = newReactions.reactions;

      set({ chats });
      const activeChat = get().activeChat;
      if (activeChat && chat.id === activeChat.id) {
        get().setActiveChat(chat);
      }
    });

    return new Promise(resolve => {
      const { pushChat } = get();
      socket.emit('chat/list', chats => {
        chats.forEach(chat => pushChat({ ...chat }));
        resolve();
      });
    });
  },

  enter: chat => {
    if (!chat) return;
    chat.seen = true;
    requestSequencer++;

    // in case that there a notification related to the chat, we close it automatically
    const { notification } = notificationApi.getState();
    if (notification !== null) {
      if (notification.type.name === 'ChatNotification' && notification.props.message.chat === chat.id) {
        notificationApi.getState().closeNotification();
      }
    }

    trackEvent('Chat', 'Enter Chat', chat.type);

    set({
      activeChat: chat,
      pending: true,
      scrollIsTop: false,
    });
  },

  leave: () => {
    const { activeChat } = get();
    requestSequencer++;
    activeChat.messages = [];
    activeChat.hasInitialLoad = false;
    set({ activeChat: null });
  },

  setPending: isPending => {
    set({ pending: isPending });
  },

  hideMessage: messageId => {
    socket.emit('message/hide', messageId);
  },

  showMessage: messageId => {
    socket.emit('message/show', messageId);
  },

  setChatSeen: () => {
    const { activeChat: chat, chats } = get();
    if (!chat) return;
    chat.seen = true;
    const activeInAll = chats.find(c => c.id === chat.id);
    if (activeInAll) {
      activeInAll.seen = true;
    }
    sortChats(chats);
    set({ chats: [...chats] });
  },

  setLargeInput: isLargeInput => {
    set({ isLargeInput });
  },

  send: async text => {
    const { activeChat } = get();
    if (activeChat == null) {
      // TODO: do not call send() if there is no active chat instead of bail out in service
      return;
    }
    let chatId;
    if (activeChat.id === null) {
      await new Promise(resolve => {
        const userIds = activeChat.users.map(u => u.id);
        socket.emit('chat/start', { userIds, type: activeChat.type }, chat => {
          const { pushChat } = get();
          pushChat(chat);
          get().enter(chat);
          set({ activeChat: chat });
          chatId = chat.id;
          resolve();
        });
      });
    } else {
      chatId = activeChat.id;
    }
    await new Promise(resolve => {
      socket.emit('message/send', { text, chatId }, () => {
        resolve();
        trackEvent('Chat', 'Send Message', activeChat.type, text.length);
      });
    });
  },

  toggleReaction: async (name, message) => {
    socket.emit('reaction/toggle', { name, chatId: message.chat, messageId: message.id });
  },

  start: (users, type = 'private') => {
    const chats = get().chats;
    const userIds = users.map(u => u.id);
    const localChat = chats.find(c => {
      return chatHasSameTypeAndUserIds(c, type, userIds);
    });
    if (localChat) {
      get().enter(localChat);
    } else {
      const chat = { id: null, messages: [], type, users };
      prepareChat(chat);
      get().enter(chat);
    }
  },

  getChat: chatId => {
    return new Promise(resolve => {
      socket.emit('chat/get', chatId, chat => {
        const c = { ...chat };
        get().pushChat(c);
        resolve(c);
      });
    });
  },

  findById: chatId => {
    return get().chats.find(c => c.id === chatId);
  },

  loadMessages: chat =>
    new Promise(resolve => {
      set({ pending: true });
      const payload = { chatId: chat.id };
      if (chat.messages.length > 0) {
        payload.messageId = chat.messages[0].id;
      }

      // TODO: add timeout so prevent that the user loads millions of messages :) or at least try with a threshold / debounce mechanism
      const waitAtLeastForMilliseconds = 500;
      const startRequestTime = new Date();

      const storedRequestId = requestSequencer;
      set({ pendingRequestId: requestSequencer++ });

      socket.emit('chat/getMessages', payload, result => {
        let waitTimeInMilliseconds;
        if (chat.messages.length === 0) {
          waitTimeInMilliseconds = 0;
        } else {
          const ms = new Date() - startRequestTime;
          waitTimeInMilliseconds = Math.max(waitAtLeastForMilliseconds - ms, 0);
        }

        setTimeout(() => {
          if (storedRequestId !== requestSequencer - 1) {
            return;
          }
          const { messages, last } = result;
          chat.messages.unshift(...messages);
          chat.messages = chat.messages.sort((a, b) => a.id - b.id);
          chat.last = last;
          chat.hasInitialLoad = true;
          get().setActiveChat(chat);
          resolve();
        }, waitTimeInMilliseconds);
      });
    }),
  pushChat: chat => {
    prepareChat(chat);
    let { chats } = get();
    // TODO: remove that filter, everything should work without "sanitization"
    chats = chats.filter(c => c.id !== chat.id);
    chats.push(chat);
    sortChats(chats);
    set({ chats });
  },

  sendNotification: message => {
    const chatToMessage = get().chats.find(c => c.id === message.chat);
    if (chatToMessage) chatToMessage.seen = false;
    const chatById = get().findById(message.chat);
    const isDistrictChat = chatById && chatById.type === 'district';
    const event = eventApi.getState().event;
    const senderIsAnnoucementUser = event.announcementUser ? event.announcementUser.id === message.user.id : false;
    if (senderIsAnnoucementUser) {
      // empty block intended, skip the isDistrictChat below in that case
    } else if (isDistrictChat) return;
    notificationApi.getState().setNotification(<ChatNotification key={message.id} message={message} />);
  },

  setUserDidScrollUp: userDidScrollUp => {
    set({ userDidScrollUp });
  },

  setScrollIsTop: scrollIsTop => {
    set({ scrollIsTop });
  },

  pushMessage: async message => {
    const { chats, activeChat } = get();
    const chat = chats.find(c => c.id === message.chat);

    if (!chat) {
      const chat = await get().getChat(message.chat);
      if (activeChat !== null) {
        if (
          chatHasSameTypeAndUserIds(
            chat,
            activeChat.type,
            activeChat.users.map(u => u.id)
          )
        ) {
          set({ activeChat: chat });
        }
      }
      get().sendNotification(message);
      return;
    }

    if (get().activeChat !== null && chat.id === get().activeChat.id) {
      chat.messages = chat.messages.filter(m => m.id !== message.id);
      chat.messages.push(message);
    }

    const selfId = userApi.getState().user.id;
    const selfUserHasYetWrittenInChat = message.user.id === selfId;
    if (selfUserHasYetWrittenInChat) {
      chat.seen = selfUserHasYetWrittenInChat;
    } else {
      chat.seen = false;
    }

    const sendNotificationToUsers = activeChat === null || activeChat.id !== message.chat;

    if (sendNotificationToUsers) {
      get().sendNotification(message);
    }

    chat.modified_at_date = new Date();

    sortChats(chats);

    if (activeChat && chat.id === activeChat.id) {
      get().setActiveChat(chat);
    } else {
      set({ chats: [...chats] });
    }
  },

  switchDistrict: () => {
    const { activeChat, chats } = get();
    let update = { chats: chats.filter(c => c.type !== 'district') };
    const isDistrictChat = activeChat && activeChat.type === 'district';
    if (isDistrictChat) {
      requestSequencer++;
      update = { ...update, activeChat: null };
    }
    set(update);

    const { district } = districtApi.getState();
    socket.emit('chat/district', district.room, chat => {
      const { pushChat } = get();
      pushChat(chat);
      if (isDistrictChat) {
        requestSequencer++;
        set({ activeChat: chat });
      }
    });
  },
}));
