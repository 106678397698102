import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Input } from 'common/components/Input';
import { useEventStore } from 'services/EventService';
import { choosePeopleSearchText, peopleApi, usePeopleStore } from 'services/PeopleService';

const StyledChatFilter = styled.div`
  position: relative;
  z-index: 2;
  margin-top: 32px;

  form {
    position: relative;
  }
`;

const ChatFilter = () => {
  const event = useEventStore(state => state.event);
  const search = usePeopleStore(state => state.search);
  const input = useRef();

  useEffect(() => {
    peopleApi.getState().setSearchValue('');
  }, []);

  const closeKeyboard = e => {
    e.preventDefault();
    if (input.current) {
      input.current.blur();
    }
  };
  const placeholder = choosePeopleSearchText(event);

  return (
    <StyledChatFilter>
      <form onSubmit={closeKeyboard}>
        <Input
          forwardRef={input}
          onChange={e => peopleApi.getState().setSearchValue(e.target.value)}
          value={search}
          placeholder={placeholder}
        />
      </form>
    </StyledChatFilter>
  );
};

export default ChatFilter;
