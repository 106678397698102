import React from 'react';
import styled from 'styled-components';
import { Button } from 'common/components/Button';
import { Overline1 } from 'common/text';
import { StyledStartNewChatContainer } from '../ChatContainer/styles';

const StartChatButton = styled.div`
  position: relative;
  display: inline-block;
`;

const ChatListCounter = styled(Overline1)`
  position: absolute;
  margin: 0;
  right: -17px;
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;
  color: #fff;
  display: ${props => (props.visible ? 'flex' : 'none')};
  background: #38ce8f;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

const ChatUserList = ({ chatList, onStartNewChat }) => {
  const emptyList = chatList.length === 0;
  return (
    <StyledStartNewChatContainer>
      <StartChatButton>
        <Button primary disabled={emptyList} onClick={onStartNewChat}>
          Start
        </Button>
        <ChatListCounter visible={!emptyList}>{chatList.length}</ChatListCounter>
      </StartChatButton>
    </StyledStartNewChatContainer>
  );
};

export default ChatUserList;
