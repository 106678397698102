import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';

export const Container = styled.div`
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => (props.square ? '8px' : '50%')};
  background: ${props => (props.square ? 'linear-gradient(76.27deg, #2e47ac 0%, #4c7fda 101.28%)' : '#ffffff')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PF Highway Sans Pro';
  font-weight: ${props => (props.square ? '300' : 'bold')};
  font-size: ${props => props.size * 0.38}px;
  color: ${props => (props.square ? '#ffffff' : colors.interactiveBlue)};
`;

export default function InitialLettersFallback({ user, children, size, stacked, square }) {
  return (
    <Container size={size} stacked={stacked} square={square}>
      <span>{user.forename && user.forename.substr(0, 1).toUpperCase()}</span>
      <span>{user.surname && user.surname.substr(0, 1).toUpperCase()}</span>
      {children}
    </Container>
  );
}
