import React, { useEffect, useState } from 'react';
import { Persons, StyledAddOrRemoveToChat } from './styles';
import { StyledUser, StyledFullname, StyledSpeciality } from 'common/components/PersonComponent/PersonHeader/styles';
import { usePeopleStore } from 'services/PeopleService';
import { assembleNameWithTitle } from 'services/UserService';
import Person from 'common/components/Person';
import IconCheck from './check.svg';
import IconPlus from './plus.svg';
import { ScrollContent } from 'components/Menu/SubMenu/styles';

const ChatCreator = ({ updateChatList = null }) => {
  const [chatList, setChatList] = useState([]);
  const [maxUsers, setMaxUsers] = useState(false);
  const people = usePeopleStore(state => state.people);

  useEffect(() => {
    if (updateChatList) updateChatList(chatList);
  }, [chatList]);

  const isInList = id => !!chatList.find(c => c.id === id);

  const updateList = (person, disabled) => {
    if (disabled) return;
    const { id } = person;
    const didReachMaxUsers = chatList.length === 8;

    let newList;
    if (!isInList(id) && !didReachMaxUsers) {
      newList = [...chatList, person];
    } else {
      newList = chatList.filter(c => c.id !== id);
    }
    setMaxUsers(newList.length === 8);
    setChatList(newList);
  };

  return (
    <ScrollContent>
      <Persons>
        {people.map((user, index) => {
          const fullname = assembleNameWithTitle(user);
          const isAdded = isInList(user.id);
          const disabled = !isAdded && maxUsers;
          return (
            <Person
              verticalCenter
              key={index}
              user={user}
              showOnlineIndicator
              infoComponents={[
                <StyledUser key={index}>
                  <StyledFullname>{fullname}</StyledFullname>
                  {user.speciality && <StyledSpeciality>{user.speciality}</StyledSpeciality>}
                </StyledUser>,
              ]}
              actionComponents={[
                <StyledAddOrRemoveToChat
                  isAdded={isAdded}
                  key="addRemove"
                  disabled={disabled}
                  onClick={() => updateList(user, disabled)}
                >
                  <span>{isAdded ? <IconCheck /> : <IconPlus />}</span>
                </StyledAddOrRemoveToChat>,
              ]}
            />
          );
        })}
      </Persons>
    </ScrollContent>
  );
};

export default ChatCreator;
