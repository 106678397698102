import React, { useEffect, useMemo } from 'react';
import { usePollStore } from 'services/PollService';
import CloseButton from 'common/components/CloseButton';
import { menuApi } from 'services/MenuService';
import { useUserStore } from 'services/UserService';
import { StyledSubMenuHeader, StyledSubMenuHeadline } from '../../styles';
import {
  CanVoteNotice,
  Letter,
  Answers,
  Question,
  Headline,
  Answer,
  Progress,
  Percentage,
  PersonImageContainer,
} from './styles';
import PersonImage from 'common/components/PersonImage';
import { ScrollContent } from '../styles';
import { TransitionItem } from 'styles';
import { trackEvent } from 'utilities/analytics';

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const Poll = () => {
  const { poll, open, didVote, update, answer, viewCurrentPoll, observePoll, unObservePoll } = usePollStore();
  const user = useUserStore(state => state.user);

  const answerPoll = answerId => {
    answer(poll.id, answerId);
  };

  useEffect(() => {
    update();
    observePoll();

    return () => {
      unObservePoll();
    };
  }, []);

  useEffect(() => {
    viewCurrentPoll();
  }, [poll, open]);

  const hasPoll = poll && poll.answers;
  const allVotes = useMemo(() => (poll ? poll.answers.reduce((acc, curr) => acc + curr.votes, 0) : 0), [poll]);

  const canVote = !didVote;

  const onAnswerClick = answer => {
    canVote && answerPoll(answer.id);
    trackEvent('Polling', 'Vote', poll.question, answer.text);
  };

  return (
    <>
      <StyledSubMenuHeader>
        <CloseButton
          onClick={() => {
            menuApi.getState().setActiveMenuComponent(null);
          }}
        />
        <StyledSubMenuHeadline>Publikumsfrage</StyledSubMenuHeadline>
      </StyledSubMenuHeader>
      <ScrollContent>
        {hasPoll ? (
          <>
            <TransitionItem index={0}>
              <Headline>Was meinen Sie:</Headline>
            </TransitionItem>
            <TransitionItem index={1}>
              <Question>{poll.question}</Question>
            </TransitionItem>
            <Answers>
              {poll.answers.map((answer, index) => {
                const percentage = allVotes ? (answer.votes / allVotes) * 100 : 0;
                const letter = letters[index % letters.length];
                return (
                  <TransitionItem index={2 + index} key={`${poll.id}${answer.id}`}>
                    <Answer
                      onClick={() => {
                        canVote && onAnswerClick(answer);
                      }}
                      interactive={canVote}
                    >
                      <Progress myVote={answer.myVote} percentage={percentage} />
                      <Percentage>{Math.round(percentage)}%</Percentage>
                      <div className="textContainer">
                        <Letter>{letter}</Letter>
                        <div className="text">{answer.text}</div>
                      </div>
                      {answer.myVote && (
                        <PersonImageContainer>
                          <PersonImage user={user} size="s" />
                        </PersonImageContainer>
                      )}
                    </Answer>
                  </TransitionItem>
                );
              })}
            </Answers>
            <CanVoteNotice visible={!open}>Diese Umfrage ist beendet.</CanVoteNotice>
          </>
        ) : (
          <CanVoteNotice visible={true}>Momentan gibt es keine Umfrage.</CanVoteNotice>
        )}
      </ScrollContent>
    </>
  );
};

export default Poll;
