import React from 'react';
import styled, { css } from 'styled-components';
import { useLibraryStore } from 'services/LibraryService';
import LibraryResource from '../LibraryResource';
import { H1 } from 'common/text';
import { ScrollContent } from 'components/Menu/SubMenu/styles';
import { mediaMax } from 'common/layout';

export const Container = styled.div`
  width: calc(78% + 24px + 32px);
  padding: 100px 0 0;
  ${mediaMax(
    'small',
    css`
      padding-top: 88px;
      width: calc(86% + 24px + 24px);
    `
  )}
  max-width: 1856px;
  margin: 0 auto;
`;

export const Headline = styled(H1)`
  color: white;
  margin: 0 0 42px;
  padding: 0 12px;

  ${mediaMax(
    'small',
    css`
      padding: 0;
      font-size: 24px;
      margin-bottom: 24px;
    `
  )}
`;

export const Resources = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;

export default function LibraryCategory({ headline }) {
  const categories = useLibraryStore(state => state.categories);
  const category = categories.find(c => c.headline === headline);
  if (!category) {
    return null;
  }

  const { resources = [] } = category;

  return (
    <ScrollContent>
      <Container>
        <Headline>{category.headline}</Headline>
        <Resources>
          {resources.map((r, i) => (
            <LibraryResource resource={r} key={i} />
          ))}
        </Resources>
      </Container>
    </ScrollContent>
  );
}
