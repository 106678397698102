import React from 'react';
import { menuApi, useMenuStore } from 'services/MenuService';
import IconCalender from '../assets/schedule.svg';
import MenuButton from '../components/MenuButton/index';

export default function AgendaButton() {
  const activeMenuComponent = useMenuStore(state => state.activeMenuComponent);
  const schedule = useMenuStore(state => state.schedule);

  const onClick = () => {
    menuApi.getState().setActiveMenuComponent(activeMenuComponent === schedule ? null : schedule);
  };

  return (
    <MenuButton active={activeMenuComponent === schedule} onClick={onClick} hoverLabel="Agenda">
      <IconCalender />
    </MenuButton>
  );
}
