import create from 'utilities/zustand/create';
import { emojiApi } from 'services/EmojiService';
import { userApi } from 'services/UserService';
import { eventApi } from 'services/EventService';
import { hotspots } from 'components/Play/Hub/HotSpotController/hotspots';
import { emojis } from 'components/Chat/ChatEmotes';
import { randomIndex } from 'utilities/math';
import { MathUtils, Color, Vector3 } from 'three';

let socket = null;

export const HIGHEST_ONLINE_WISP_COUNT = 500; // the platform supports up to 500 users
export const MAX_WISP_COUNT = 2000;

export const offlineColor = new Color(0.5, 0.5, 0.5);
export const onlineColor = new Color(0.05, 1.0, 1.0);
const selfColor = new Color(1.0, 0.7, 0.05);
export const offlineOpacity = 0.5;
export const onlineOpacity = 1.0;

export function prepareWisp(wisp) {
  const selfId = userApi.getState().user.id;
  let targetColor, targetOpacity, isSelf;
  if (wisp.id !== null) {
    isSelf = wisp.id === selfId;
    targetColor = isSelf ? selfColor : onlineColor;
    targetOpacity = onlineOpacity;
  } else {
    isSelf = false;
    targetColor = offlineColor;
    targetOpacity = offlineOpacity;
  }
  const state = {
    isSelf,
    seed: Math.random() * 10000,
    position: new Vector3(),
    smoothPosition: null,
    roomPosition: null,
    scale: 1.0,
    spring: 1.0,
    mouseDistance: 0.0,
    roomFactor: 0.0,
    targetColor,
    targetOpacity,
    maxHeightDisplacement: MathUtils.lerp(0.15, 0.3, Math.random()),
  };
  state.color = new Color().copy(state.targetColor);
  state.opacity = state.targetOpacity;
  wisp.state = state;
}

export const [useWispStore, wispApi] = create(module, (set, get) => ({
  //updated only on result
  wisps: [],
  rooms: [],
  roomIdToRoom: null,
  roomIdToWisps: {},
  activeWisp: null,
  wispRoom: null, // our 3d room

  async init(managedSocket) {
    socket = managedSocket;
    const { event } = eventApi.getState();
    let { networkingRooms: rooms } = event;
    if (!rooms) {
      rooms = [];
    }
    const roomIdToRoom = {};
    rooms.forEach((room, i) => {
      const t = i / rooms.length - 1.0 / 3.0;
      const radius = 0.4;
      let offsetY = (i % 3) * 0.1;
      offsetY += 0.35;
      const position = (room.position = hotspots[0].position.clone());
      position.x -= Math.sin(t * Math.PI * 2) * radius;
      position.y += offsetY;
      position.z -= Math.cos(t * Math.PI * 2) * radius;
      roomIdToRoom[room.id] = room;
    });
    set({ rooms, roomIdToRoom });

    await get().poll();

    socket.on('wisps/add', newWisp => {
      const { wisps } = get();
      const wisp = wisps.find(w => w.id === null);
      if (wisp) {
        wisp.id = newWisp.id;
        wisp.role = newWisp.role;
        wisp.thumbnail = newWisp.thumbnail;
        wisp.activity = newWisp.activity;
        wisp.title = newWisp.title;
        wisp.forename = newWisp.forename;
        wisp.surname = newWisp.surname;
        wisp.speciality = newWisp.speciality;
        wisp.company = newWisp.company;
        wisp.state.targetColor = onlineColor;
        wisp.state.targetOpacity = onlineOpacity;
        set({ wisps: [...wisps] });
      } else {
        if (wisps.length < MAX_WISP_COUNT) {
          prepareWisp(newWisp);
          wisps.push(newWisp);
        }
      }
    });
    socket.on('wisps/remove', id => {
      const { wisps } = get();
      const wisp = wisps.find(w => w.id === id);
      if (wisp) {
        wisp.isSelf = null;
        wisp.activity = null;
        wisp.id = null;
        wisp.roomId = null;
        wisp.state.targetColor = offlineColor;
        wisp.state.targetOpacity = offlineOpacity;
        set({ wisps: [...wisps] });
      }
    });
    socket.on('wisps/edit', ({ id, newWisp }) => {
      const { wisps } = get();
      const wisp = wisps.find(w => w.id === id);
      if (wisp) {
        wisp.title = newWisp.title;
        wisp.forename = newWisp.forename;
        wisp.surname = newWisp.surname;
        wisp.speciality = newWisp.speciality;
        wisp.company = newWisp.company;
        wisp.thumbnail = newWisp.thumbnail;
        set({ wisps: [...wisps] });
      }
    });
    socket.on('wisps/activity', ({ id, activity }) => {
      const { wisps } = get();
      const wisp = wisps.find(w => w.id === id);
      if (wisp) {
        wisp.activity = activity;
        wisp.state.spring = 0;
      }
      set({ wisps: [...wisps] });
    });
    socket.on('wisps/room', ({ id, roomId }) => {
      const { wisps, roomIdToWisps } = get();
      const wisp = wisps.find(w => w.id === id);
      if (wisp) {
        wisp.roomId = roomId;
        if (roomId) {
          if (Object.prototype.hasOwnProperty.call(roomIdToWisps, roomId)) {
            roomIdToWisps[roomId].push(wisp);
          } else {
            roomIdToWisps[roomId] = [wisp];
          }
        } else {
          for (const name of Object.keys(roomIdToWisps)) {
            const room = roomIdToWisps[name];
            roomIdToWisps[name] = room.filter(w => w !== wisp);
            if (roomIdToWisps.length === 0) {
              delete roomIdToWisps[name];
            }
          }
        }
      }
      set({ wisps: [...wisps], roomIdToWisps: { ...roomIdToWisps } });
    });
  },

  changeRoom: room => {
    return new Promise(resolve => {
      set({ wispRoom: room });
      socket.emit('wisps/changeRoom', room ? room.id : null, () => {
        resolve();
      });
    });
  },

  poll: () => {
    return new Promise(resolve => {
      socket.emit('wisps/list', ({ wisps, rooms }) => {
        const offlineWispCount = 2000;
        const addCount = Math.max(offlineWispCount - wisps.length, 0);
        for (let i = 0; i < addCount; ++i) {
          wisps.push({ id: null, activity: null, roomId: null });
        }
        for (const wisp of wisps) {
          prepareWisp(wisp);
        }

        const roomIdToWisps = {};
        for (const name of Object.keys(rooms)) {
          const room = rooms[name];
          roomIdToWisps[name] = room.map(id => wisps.find(w => w.id === id));
        }
        set({ wisps: wisps, roomIdToWisps });
        resolve();
      });
    });
  },

  setOwnWispActivity: activity => {
    const { wisps } = get();
    const wisp = wisps.find(w => w.state.isSelf);
    if (wisp) {
      wisp.state.spring = 0;
      wisp.activity = activity;
    }
  },

  spawnRandomEmojiFromRandomWisp: () => {
    const { wisps } = get();
    if (wisps.length > 0) {
      const userId = wisps[randomIndex(wisps.length)].id;
      const emojiType = emojis[randomIndex(emojis.length)].type;
      emojiApi.getState().spawnEmoji(userId, emojiType);
    }
  },

  setActiveWisp(activeWisp) {
    set({ activeWisp });
  },
}));
