import create from 'utilities/zustand/create';
import userStorage from 'storage/user';

export const [useOnboardingStore, onboardingApi] = create(module, set => ({
  introPlaying: false,
  introContinued: false,
  introCompleted: userStorage.getIntroCompleted(), // true when intro is fully over
  resetIntroCompleted: () => {
    userStorage.setIntroCompleted(false);
    set({ introCompleted: false });
  },
  startIntro: () => {
    set({ introContinued: false, introPlaying: true });
  },
  continueIntro: () => {
    set({ introContinued: true });
  },
  completeIntro: () => {
    userStorage.setIntroCompleted(true);
    set({ introCompleted: true, introContinued: false, introPlaying: false });
  },
}));
