import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { Body2, H4 } from 'common/text';
import { Button } from 'common/components/Button';
import { mediaMax, mediaMin } from 'common/layout';

export const InfoMenu = styled.div`
  padding-top: 24px;
  color: white;
  flex-grow: 1;
`;

export const InfoItemContainer = styled.div`
  font-family: 'PF Highway Sans Pro';
  position: relative;
  width: 100%;
  margin-bottom: 12px;
`;

export const InfoItemTitle = styled(H4)`
  width: 100%;
  padding: 21px 24px;
  ${mediaMax(
    'small',
    css`
      padding: 15px 16px;
    `
  )}
  padding-right: 17px;
  background: linear-gradient(96.93deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.075) 100%);
  border-radius: ${props => (props.hasText ? '8px 8px 0 0' : '8px')};
`;

export const InfoItemLink = styled(Button)`
  position: absolute;
  top: 16px;
  right: 24px;
  ${mediaMax(
    'small',
    css`
      top: 8px;
      right: 8px;
    `
  )}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: ${colors.interactiveBlue};
  border: 0;
  outline: 0;
`;

export const InfoItemText = styled.div`
  padding: 16px 24px;
  background: rgba(255, 255, 255, 0.05);
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 8px 8px;
  ${mediaMax(
    'small',
    css`
      padding: 12px 16px;
    `
  )}
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PlatformCode = styled(Body2)`
  flex-grow: 0;
  padding-bottom: 24px;
  ${mediaMin(
    'medium',
    css`
      padding-bottom: 38px;
    `
  )}
  text-align: right;
  color: #fff;
  opacity: 0.5;
`;
