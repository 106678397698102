import React from 'react';
import LibraryCategory from './LibraryCategory';
import { Vector3 } from 'three';
import { useLibraryStore } from 'services/LibraryService';

const fastHash = x => (Math.sin(x) * 43758.5453123) % 1; // fast hacky hash

export default function LibraryCategories() {
  const categories = useLibraryStore(state => state.categories);

  const libraryCategoryComponents = categories.map((category, index) => {
    const t = index / categories.length + 1.0 / 3.0;
    const radius = 0.1;
    const offsetY = fastHash(1 + index) * 0.06;
    const localPosition = new Vector3();
    localPosition.x = -Math.sin(t * Math.PI * 2) * radius;
    localPosition.y = offsetY;
    localPosition.z = -Math.cos(t * Math.PI * 2) * radius;

    return <LibraryCategory key={category.id} category={category} position={localPosition} index={index} />;
  });

  const r = 3.466;
  const k = -Math.PI * 0.5 + (2 / 3) * Math.PI * 2;
  const position = new Vector3(Math.cos(k) * r, -0.15, Math.sin(k) * r);
  //const position = hotspots[2].position.clone();

  return <group position={position}>{libraryCategoryComponents}</group>;
}
