import React from 'react';
import { modalApi } from 'services/ModalService';
import { contentApi } from 'services/ContentService';
import CloseButton from 'common/components/CloseButton';
import { StyledColumn, StyledInfo, StyledTitle } from 'components/Modal/styles';

export default function ModalError({ clearContentOnClose = true }) {
  const onCloseClick = () => {
    modalApi.getState().closeModal();
    clearContentOnClose && contentApi.getState().clearActiveContent();
  };
  return (
    <>
      <CloseButton onClick={onCloseClick} />
      <StyledColumn>
        <StyledTitle>Hoppla!</StyledTitle>
        <StyledInfo>
          Entschuldigung, hier ist leider ein Fehler aufgetreten! Versuchen Sie es später nochmal.
        </StyledInfo>
      </StyledColumn>
    </>
  );
}
