import React from 'react';
import { Container, TriangleDown } from './styles';
import { Content, CloseButtonContainer } from '../styles';
import Close from '../close-small.svg';

export default function TooltipTop({ children, visible, onClose, anchorLeft }) {
  return (
    <Container visible={visible} anchorLeft={anchorLeft}>
      <CloseButtonContainer onClick={onClose}>
        <Close />
      </CloseButtonContainer>
      <Content>{children}</Content>
      <TriangleDown anchorLeft={anchorLeft} />
    </Container>
  );
}
