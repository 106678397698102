import styled, { css } from 'styled-components';
import { baseColors } from 'styles';
import { mediaMin } from 'common/layout';
import { Body2 } from 'common/text';
import { iconFillAndStroke } from 'common/icon';
import { Button } from 'common/components/Button';

const fontSizes = { s: 14, m: 16, l: 20 };
export const StyledPerson = styled.div`
  background: inherit;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'PF Highway Sans Pro';
  ${mediaMin(
    'medium',
    css`
      font-size: ${props => (props.size ? fontSizes[props.size] : fontSizes[props.size])};
    `
  )};
`;

export const StyledTopLabel = styled.span`
  font-size: 14px;
  line-height: 100%;
  color: ${baseColors.grey.light};
`;

export const StyledPersonRow = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledPersonColumn = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledStartChatButton = styled(Button)`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  padding: 0;
  outline: none;
  cursor: pointer;
  color: white;
  svg {
    ${iconFillAndStroke('white', 'white')}
    margin-left: 1px;
  }
`;

export const StyledUser = styled.div`
  width: 100%;
`;

export const StyledSpeciality = styled(Body2)`
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledFullname = styled(Body2)`
  width: 100%;
  line-height: 120%;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
