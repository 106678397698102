import React from 'react';
import styled from 'styled-components';
import { useStatsStore } from './store';
export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 64px;
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  z-index: 10050;
`;

export default function Stats() {
  const stats = useStatsStore(state => state);
  return <Container>{JSON.stringify(stats)}</Container>;
}
