import styled, { css } from 'styled-components';
import { H5 } from 'common/text';
import { mediaMax } from 'common/layout';

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  padding: 32px 0;
  padding-bottom: 16px;
  pointer-events: none;
  width: 100%;
  text-align: center;
`;

export const Centered = styled.div`
  display: inline-block;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity ${props => (props.show ? 1 : 0.25)}s;
`;
export const Gradient = styled.div`
  position: absolute;
  ${mediaMax(
    'small',
    css`
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(4, 20, 49, 0) 0%, #041431 100%);
      transform: ${props => (props.show ? 'scaleY(1)' : 'scaleY(0)')};
      transform-origin: left bottom;
      transition: transform 2s;
    `
  )}
`;

export const Text = styled(H5)`
  color: white;
  padding-bottom: 10px;
  margin-top: 16px;
`;

export const CircleContainer = styled.div`
  width: 31px;
  height: 31px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Circle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
`;

export const Point = styled.div`
  position: absolute;
  left: calc(50% - 2px);
  top: calc(50% - 2px);
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
`;
