import create from 'utilities/zustand/create';
import {
  NoToneMapping,
  ACESFilmicToneMapping,
  CineonToneMapping,
  LinearToneMapping,
  ReinhardToneMapping,
  Uncharted2ToneMapping,
} from 'three';

const presets = {
  booth: {
    directionalLight: {
      on: false,
      color: '#ffffff',
      intensity: 0.2,
    },
    toneMapping: {
      name: 'NoToneMapping',
      exposure: 1,
      whitePoint: 1,
    },
    sky: {
      azimuth: 0.009,
      inclination: 0.044,
    },
  },
};

export const [useEnvironmentStore, environmentApi] = create(module, set => ({
  presets: presets,
  envMaps: {},
  toneMappings: [
    { name: 'NoToneMapping', value: NoToneMapping },
    { name: 'LinearToneMapping', value: LinearToneMapping },
    { name: 'ReinhardToneMapping', value: ReinhardToneMapping },
    { name: 'Uncharted2ToneMapping', value: Uncharted2ToneMapping },
    { name: 'CineonToneMapping', value: CineonToneMapping },
    { name: 'ACESFilmicToneMapping', value: ACESFilmicToneMapping },
  ],
  environmentConfiguration: JSON.parse(JSON.stringify(presets.booth)),

  set: environmentConfiguration => {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(environmentConfiguration, null, 2));
    set({ environmentConfiguration: JSON.parse(JSON.stringify(environmentConfiguration)) });
  },

  setPresetByName: name => {
    set(state => {
      if (state.presets[name]) {
        return { environmentConfiguration: JSON.parse(JSON.stringify(state.presets[name])) };
      }
    });
  },
}));
