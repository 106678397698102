import React, { useEffect } from 'react';
import moment from 'moment';
import { talkApi, useTalkStore } from 'services/TalkService';
import { StyledScheduleHeaderItem } from './styles';
import Dropdown from 'common/components/Dropdown';
import format from 'utilities/date/format';

const getClosestDateInArray = (array, dateToCompare) => {
  if (array.length === 0) {
    return null;
  }

  let minDiff = null;
  let mostAccurateDate = array[0];

  array.map(item => {
    const diff = Math.abs(moment(dateToCompare).diff(item.value, 'minutes', true));
    if (!minDiff || diff < minDiff) {
      minDiff = diff;
      mostAccurateDate = item;
    }
  });

  const today = format(dateToCompare, 'date');
  array.map(item => {
    if (item.value === today) mostAccurateDate = item;
  });

  return mostAccurateDate;
};

export default function ScheduleDatePicker() {
  const filters = useTalkStore(state => state.filters);
  const filter = filters ? filters.date : null;
  const talksByDate = useTalkStore(state => state.groupedTalks);
  const dates = Object.keys(talksByDate).map(d => {
    const value = talksByDate[d][0].startOfDay;
    return { name: d, value };
  }, []);
  const initial = [
    /*{ name: 'Alle Veranstaltungstage', value: null }*/
  ];
  const options = [...initial, ...dates];

  const setFilter = date => {
    talkApi.getState().setFilters({ date });
  };

  useEffect(() => {
    const closestDate = getClosestDateInArray(dates, new Date());
    setFilter(closestDate?.value);
  }, [talksByDate]);

  return (
    <StyledScheduleHeaderItem>
      <Dropdown options={options} handleSelect={setFilter} value={filter} fallbackText={'Bitte warten...'} />
    </StyledScheduleHeaderItem>
  );
}
