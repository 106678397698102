import styled from 'styled-components';
import { iconFillAndStroke } from 'common/icon';
import { colors, zIndex } from 'styles';
import { mediaMax } from 'common/layout';

export const HoverEffect = styled.div`
  position: absolute;
  background: ${colors.interactiveBlue};
  left: 100%;
  border-radius: 4px;
  line-height: 26px;
  padding: 4px 12px;
  pointer-events: none;
  z-index: ${zIndex.tooltip};
  font-family: 'PF Highway Sans Pro';
  font-weight: 700;
  font-size: 12px;
  color: white;

  ${mediaMax('small', 'display: none;')}
`;

export const StyledMenuButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  align-items: center;
  svg {
    ${props =>
      props.active
        ? iconFillAndStroke(colors.interactiveBlue, colors.interactiveBlue)
        : iconFillAndStroke('white', 'white')};
  }
  color: ${props => (props.active ? 'white' : colors.interactiveBlue)};
  cursor: pointer;

  ${HoverEffect} {
    transform-origin: 0 50%;
    transform: translateX(-8px);
    transition: all 0.2s ease-out;
    opacity: 0;
  }
  &:hover {
    ${HoverEffect} {
      transition: transform 0.2s ease-out, opacity 0.2s ease-out;
      transform: none;
      opacity: 1;
    }
  }
`;
export const MenuButtonContainer = styled.div`
  position: relative;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  left: -6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  border-right: 6px solid ${colors.interactiveBlue};
`;
