import React from 'react';
import styled, { css } from 'styled-components';
import { useSoundStore, soundApi } from 'services/SoundService';
import { useContentStore } from 'services/ContentService';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import SoundIcon from './icon/sound.png';
import { mediaMax } from 'common/layout';
import { useArenaStore } from 'services/ArenaService';

export const Container = styled.div`
  position: absolute;
  right: 40px;
  bottom: 30px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: inherit;
  box-sizing: content-box;
  opacity: 1;
  transition: all 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  svg {
    stroke-width: 1.5;
  }

  ${mediaMax(
    'small',
    css`
      left: 24px;
      top: 24px;
    `
  )};
`;

const Icon = styled.img`
  opacity: ${props => (props.enabled ? 1 : 0.4)};
`;

export default function SoundButton() {
  const enabledByUser = useSoundStore(state => state.enabled);
  const enabledBySystem = useArenaStore(state => state.isPlayAllowed);
  const content = useContentStore(state => state.activeContent);
  const contentType = content && content.type.id;

  const enabled = enabledByUser && enabledBySystem;
  const isVisible = contentType === CONTENT_TYPE.ARENA.id;

  if (!isVisible || !enabledBySystem) {
    return null;
  }

  return (
    <Container
      onClick={() => {
        if (enabledBySystem) {
          soundApi.getState().toggle();
        }
      }}
    >
      <Icon src={SoundIcon} enabled={enabled} />
    </Container>
  );
}
