import styled, { keyframes } from 'styled-components';
import { SolidBackdropGradient, zIndex } from 'styles';
import { Body2 } from 'common/text';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.loader};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${SolidBackdropGradient}
`;

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderWrapper = styled.div`
  animation: ${rotate} 1s linear infinite;
  margin-bottom: 16px;
  height: 74px;
  width: 74px;
`;

export const DelayedMessage = styled(Body2)`
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s;
`;
