import { Caption1, H1 } from 'common/text';
import styled, { css } from 'styled-components';
import { zIndex } from 'styles';
import { Button } from 'common/components/Button';
import { mediaMax } from 'common/layout';

export const Container = styled.div`
  color: white;
  font-size: 22px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.intro};
`;

export const SkipButton = styled(Caption1)`
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
`;

const logoScale = 0.666;
export const Image = styled.img`
  width: ${364 * logoScale}px;
  height: ${160 * logoScale}px;

  ${mediaMax(
    'small',
    css`
      width: ${364 * logoScale * 0.75}px;
      height: ${160 * logoScale * 0.75}px;
    `
  )}
`;

export const Centered = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  right: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CenteredTop = styled(Centered)`
  height: 38%;
`;

export const StyledLine = styled(H1)`
  ${mediaMax(
    'small',
    css`
      font-size: 18px;
    `
  )}
  text-align: center;
  opacity: 0;
`;

export const StartButton = styled(Button)`
  margin-top: 32px;
  min-width: 100px;
`;

export const IntroContainer = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const Bar = styled.div`
  position: absolute;
  background: #01070a;
  width: 100%;
  height: 10vh;
  left: 0;
`;

export const TopBar = styled(Bar)`
  top: 0;
`;

export const BottomBar = styled(Bar)`
  bottom: 0;
`;
