import create from 'utilities/zustand/create';
import { isMobile, isTablet, isMobileOnly } from 'mobile-device-detect';

let keyboardTimeout = null;

export const [useDeviceStore, deviceApi] = create(module, set => ({
  device: {},
  keyboard: { isActive: false },
  init: () => {
    const device = {};
    device.isMobileOnly = isMobileOnly;
    device.isMobile = isMobile;
    device.isTablet = isTablet;
    set({ device: device });
  },

  setKeyboardActive: active => {
    if (active) {
      clearTimeout(keyboardTimeout);
      set({ keyboard: { isActive: true } });
    } else {
      keyboardTimeout = setTimeout(() => {
        set({ keyboard: { isActive: false } });
      }, 350);
    }
  },
}));
