import React, { useEffect, useRef } from 'react';
import { arenaApi, useArenaStore } from 'services/ArenaService';
import Hls from 'hls.js';
import { Container, Video, Image } from './styles';

export const createVideo = (video, videoSrc) => {
  video.setAttribute('playsinline', '');
  video.setAttribute('autoplay', '');
  video.setAttribute('loop', '');
  video.setAttribute('muted', '');
  video.loop = true;
  video.playsinline = true;
  video.muted = false;
  video.controls = true;
  video.crossOrigin = 'anonymous';
  video.width = 1920;
  video.height = 1080;

  if (!videoSrc) return video;

  if (videoSrc.endsWith('m3u8')) {
    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSrc;
      video.addEventListener('loadedmetadata', () => {
        video.play();
      });
    }
  } else {
    const source = document.createElement('source');
    source.src = videoSrc;
    video.append(source);
  }

  return video;
};

export default function LiveStream() {
  const stream = useArenaStore(state => state.stream);
  const ref = useRef();
  const component = stream?.source[0];
  useEffect(() => {
    if (component?.__component === 'stream.hls-stream') {
      createVideo(ref.current, component.url);
      arenaApi.getState().setVideo(ref.current);
    }
  }, [component]);
  return (
    <Container>
      {component?.__component === 'stream.hls-stream' && <Video ref={ref} />}
      {component?.__component === 'stream.image' && <Image url={component.posterImage.url} />}
    </Container>
  );
}
