import React from 'react';
import {
  StyledMenuContainer,
  StyledMenu,
  StyledLogoContainer,
  StyledMainMenu,
  StyledFooterMenu,
  StyledSubMenu,
  StyledOnlyMobile,
  SlideInAnimation,
} from './styles';
import { useMenuStore } from 'services/MenuService';
import { useSceneStore } from 'services/SceneService';
import { useUiStore } from 'services/UiService';
import { useDebugStore } from 'storage/debug';
import AgendaButton from './AgendaButton';
import PeopleButton from './PeopleButton';
import PollButton from './PollButton';
import SoundButton from './SoundButton';
import DeveloperButton from './DeveloperButton';
import ProfileButton from './ProfileButton';
import ChatButton from './ChatButton';
import InfoButton from './InfoButton';
import LogoIcon from './assets/logo-ipsen.svg';
import { useContentStore } from 'services/ContentService';
import { CONTENT_UI_TYPE } from 'services/ContentService/contentTypes';
import { useApplicationStore } from 'services/ApplicationService';

export default function Menu() {
  const debugStore = useDebugStore();
  const appStore = useApplicationStore();
  const activeMenuComponent = useMenuStore(state => state.activeMenuComponent);
  const menuActive = useMenuStore(state => state.activeMenuComponent !== null);
  const isLoaded = useSceneStore(state => state.isLoaded);
  const showMenu = useUiStore(state => state.showMenu);
  const showSoundButton = isLoaded;
  const activeContent = useContentStore(state => state.activeContent);
  const activeContentTypeId = activeContent && activeContent.type.id;
  const solidBackground = !menuActive && activeContentTypeId === CONTENT_UI_TYPE.FULLSCREEN;

  return (
    <>
      <StyledMenuContainer menuActive={menuActive} show={showMenu} isFullHeight={activeContentTypeId === 'LIVESTREAM'}>
        <StyledMenu solidBackground={solidBackground}>
          <StyledLogoContainer hidden={appStore.theme !== 'branded'}>
            <SlideInAnimation show={showMenu} index={0}>
              <LogoIcon />
            </SlideInAnimation>
          </StyledLogoContainer>
          <StyledMainMenu>
            <SlideInAnimation show={showMenu} index={1}>
              <AgendaButton />
            </SlideInAnimation>
            <SlideInAnimation show={showMenu} index={2}>
              <PeopleButton />
            </SlideInAnimation>
            <SlideInAnimation show={showMenu} index={3}>
              <PollButton />
            </SlideInAnimation>
            <SlideInAnimation show={showMenu} index={4}>
              <ChatButton />
            </SlideInAnimation>
            {debugStore.getDeveloperContentEnabled() && <DeveloperButton />}
            <StyledOnlyMobile>
              <SlideInAnimation show={showMenu} index={5}>
                <ProfileButton />
              </SlideInAnimation>
            </StyledOnlyMobile>
            <StyledOnlyMobile>
              <SlideInAnimation show={showMenu} index={6}>
                <InfoButton />
              </SlideInAnimation>
            </StyledOnlyMobile>
          </StyledMainMenu>
          <StyledFooterMenu>
            <SlideInAnimation show={showMenu} index={5}>
              <ProfileButton />
            </SlideInAnimation>
            <SlideInAnimation show={showMenu} index={6}>
              <InfoButton />
            </SlideInAnimation>
          </StyledFooterMenu>
        </StyledMenu>
        <StyledSubMenu show={menuActive}>{activeMenuComponent}</StyledSubMenu>
      </StyledMenuContainer>
      {showSoundButton && <SoundButton />}
    </>
  );
}
