import React, { useEffect, useState } from 'react';
import { useContentStore } from 'services/ContentService';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { activityApi } from 'services/ActivityService';
import Arena from './Arena';
import Networking from './Networking';
import Library from './Library';
import { libraryApi } from 'services/LibraryService';

export default function Content() {
  const content = useContentStore(state => state.activeContent);
  const [hasActivity, setHasActivity] = useState(false);

  useEffect(() => {
    if (content) {
      const activity = content.activity ? content.activity : content.type.id;
      activityApi.getState().setActivity(activity);
      setHasActivity(true);
    } else if (hasActivity) {
      activityApi.getState().setActivity(null);
    }

    if (content && content.type.id === CONTENT_TYPE.LIBRARY.id) {
      libraryApi.getState().poll();
    }
  }, [content]);

  const types = [
    {
      id: CONTENT_TYPE.ARENA.id,
      component: <Arena />,
    },
    {
      id: CONTENT_TYPE.NETWORKING.id,
      component: <Networking />,
    },
    {
      id: CONTENT_TYPE.LIBRARY.id,
      component: <Library />,
    },
  ];

  return (
    <>
      {types.map(type => {
        const show = !!(content && content.type.id === type.id);
        return <type.component.type key={type.id} show={show} />;
      })}
    </>
  );
}
