import React, { useRef, useMemo } from 'react';
import { useWindowStore } from 'services/WindowService';
import { useControlsStore } from 'services/ControlsService';
import { contentApi, useContentStore } from 'services/ContentService';
import { materialApi } from 'services/MaterialService';
import { cameraApi, useCameraStore } from 'services/CameraService';
import { Sphere, Vector3 } from 'three';
import { meshBounds } from '@react-three/drei';

export default function Hotspot({ hotspot, potentialHotspot }) {
  const { contentType, position } = hotspot;
  const { id: contentTypeId } = contentType;
  const setWindowStoreHover = useWindowStore(state => state.setHover);
  const setMaterialStoreHoverID = materialApi.getState().setHoverID;
  const lockInput = useCameraStore(state => state.lockInput);
  const controlsActive = useControlsStore(state => state.active);
  const activeContent = useContentStore(state => state.activeContent);
  const activeContentTypeId = activeContent && activeContent.type.id;
  const meshRef = useRef();

  const canClick =
    !lockInput && controlsActive && activeContentTypeId !== contentTypeId && activeContentTypeId === null;
  const onClick = e => {
    if (cameraApi.getState().transition.lerp < 0.6) return;
    if (cameraApi.getState().cursor.cursorDownDistanceTravelled > 0.02) return;
    e.stopPropagation();
    const nativeEvent = e.nativeEvent;
    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();

    if (hotspot !== potentialHotspot) {
      if (contentTypeId === 'ARENA') cameraApi.getState().setOrbitLockIndex(0);
      else if (contentTypeId === 'NETWORKING') cameraApi.getState().setOrbitLockIndex(2);
      else if (contentTypeId === 'LIBRARY') cameraApi.getState().setOrbitLockIndex(1);
      return;
    }

    const isAlreadyInSameContentType = activeContentTypeId === contentTypeId;
    if (isAlreadyInSameContentType) {
      return;
    }
    const content = {
      type: contentType,
    };
    contentApi.getState().setActiveContent(content);
    setWindowStoreHover(false);
  };

  const boundingSphere = useMemo(() => new Sphere(new Vector3(), 1.5), []);

  return (
    <>
      <mesh
        ref={meshRef}
        position={position}
        scale={[0.5, 0.5, 0.5]}
        onPointerOver={
          canClick
            ? () => {
                setWindowStoreHover(true);
                setMaterialStoreHoverID(contentTypeId);
              }
            : null
        }
        onPointerMove={
          canClick
            ? () => {
                setWindowStoreHover(true);
              }
            : null
        }
        onPointerOut={
          canClick
            ? () => {
                activeContent === null && setWindowStoreHover(false);
                setMaterialStoreHoverID(null);
              }
            : null
        }
        visible={false}
        onClick={canClick ? onClick : null}
        raycast={meshBounds}
      >
        <planeGeometry attach="geometry" raycast={meshBounds} boundingSphere={boundingSphere} />
      </mesh>
    </>
  );
}
