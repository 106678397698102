import styled, { css } from 'styled-components';
import { H3 } from 'common/text';
import { easings } from 'styles';
import { mediaMax } from 'common/layout';

export const CTA = styled(H3)`
  transform: ${props => (props.show ? 'translate(-50%, 0%)' : 'translate(-50%, -100%)')};
  transition: transform 1.5s ${easings.easeOutStrong};
  color: white;
  position: absolute;
  top: 40px;
  left: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: initial;
  text-shadow: 0px 0px 3px #000532;

  ${mediaMax(
    'small',
    css`
      top: 88px;
      width: 85%;
    `
  )};
`;
