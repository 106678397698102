export const renderTextureVert = `
      uniform mat3 uvTransform;
    	varying vec2 vUv;

      void main() {
      	vUv = ( uvTransform * vec3( uv, 1 ) ).xy;

        vec3 transformed = vec3( position );

        vec4 mvPosition = vec4( transformed, 1.0 );

        mvPosition = modelViewMatrix * mvPosition;
        gl_Position = projectionMatrix * mvPosition;
      }
`;

export const renderTextureFrag = `
    	varying vec2 vUv;
    	uniform vec2 resolution;
      uniform sampler2D video;
      uniform vec3 color;

      void main() {
        vec2 uv = vec2( 0.8 * (1.0 - vUv.x) + 0.1, 0.8 * (1.0 - vUv.y) + 0.1);
        vec3 result = vec3(uv.x,uv.y,0.0);
        result = vec3(vUv,0.0);

        vec4 color = vec4(0.0);
        vec2 off1 = vec2(1.3846153846) * vec2(0.0,0.5);
        vec2 off2 = vec2(3.2307692308) * vec2(0.0,0.5);
        color += texture2D(video, uv) * 0.2270270270;
        color += texture2D(video, uv + (off1 / resolution)) * 0.3162162162;
        color += texture2D(video, uv - (off1 / resolution)) * 0.3162162162;
        color += texture2D(video, uv + (off2 / resolution)) * 0.0702702703;
        color += texture2D(video, uv - (off2 / resolution)) * 0.0702702703;
        vec2 off3 = vec2(1.3846153846) * vec2(0.5,0.0);
        vec2 off4 = vec2(3.2307692308) * vec2(0.5,0.0);
        color += texture2D(video, uv) * 0.2270270270;
        color += texture2D(video, uv + (off3 / resolution)) * 0.3162162162;
        color += texture2D(video, uv - (off3 / resolution)) * 0.3162162162;
        color += texture2D(video, uv + (off4 / resolution)) * 0.0702702703;
        color += texture2D(video, uv - (off4 / resolution)) * 0.0702702703;

        result = color.rgb;
        gl_FragColor = vec4(result, 1.0);
       }
`;
