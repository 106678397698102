import { useLoader } from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useEffect, useMemo } from 'react';
import { sceneApi } from 'services/SceneService';
import dracoLoader from 'utilities/dracoLoader';

export default function useScene(url) {
  const gltf = useLoader(GLTFLoader, url, dracoLoader);

  const renderable = useMemo(() => {
    const clone = gltf.scene.clone();
    clone.traverse(o => {
      if (o.material) {
        const mat = (o.orgMaterial = o.material);
        //initial configure materials
        if (mat.map) mat.map.anisotropy = 16;
      }
    });
    return clone;
  }, []);

  //extract structure
  const { contentGroup, arena, library, networking } = useMemo(() => {
    let contentGroup = null;
    let arena = null;
    let library = null;
    let networking = null;
    renderable.traverse(o => {
      if (o.name === 'content_mesh') {
        contentGroup = o;
      }
      if (o.name === 'Arena') {
        arena = o;
      }
      if (o.name === 'Networking') {
        networking = o;
      }
      if (o.name === 'Library') {
        library = o;
      }
    });
    return { contentGroup, arena, library, networking };
  }, []);

  //configure visibility
  useEffect(() => {
    renderable.traverse(o => {
      if (o.name.endsWith('_content_screen') || o.name.endsWith('_ContentScreen')) {
        o.visible = false;
      }
    });
  }, []);

  const scene = useMemo(() => {
    const scene = {
      contentGroup,
      renderable,
      arena,
      library,
      networking,
    };
    sceneApi.getState().setScene(scene);
    return scene;
  }, []);
  return scene;
}
