import axios from 'axios';
import { socketApi } from 'services/SocketService';
import { debugStoreApi } from 'storage/debug';
import create from 'utilities/zustand/create';

export const [useApplicationStore, applicationApi] = create(module, (set, get) => ({
  application: null,
  theme: 'unbranded', // TODO: replace with analysis of window.location?
  state: 'PENDING',
  init: async () => {
    const application = await get().poll();
    if (application) {
      const event = application.defaultEvent;
      if (event) {
        const showWelcome = application.showWelcome;
        const skipWelcome = debugStoreApi.getState().getSkipWelcome();
        set({ state: showWelcome && !skipWelcome ? 'WELCOME' : 'SUCCESSFUL', application, theme: application.theme });
      } else {
        set({ state: 'NONE_AVAILABLE', application, theme: application.theme });
      }
    } else {
      set({ state: 'FAILED', application });
    }
    return application;
  },

  poll: async () => {
    let application = null;
    try {
      const restUrl = socketApi.getState().getRestUrl('strapi');
      const response = await axios.get(restUrl + 'application');
      application = response.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return application;
  },
}));
