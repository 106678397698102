import React from 'react';
import styled from 'styled-components';
import { useDebugStore } from 'storage/debug';
import { DEVELOPMENT, LOCAL, PRODUCTION } from 'services/BuildService';
import { Button } from 'common/components/Button';
import ToggleButton from 'common/components/ToggleButton';
import { colors } from 'styles';

export const Container = styled.div`
  height: 100%;
  padding: 20px;
  color: ${colors.font.light};
  button {
    margin: 5px 0;
  }
`;

export default function Config() {
  const debugStore = useDebugStore();
  return (
    <Container>
      <h1>CONFIG</h1>
      <h3>Socket Target</h3>
      {['AUTO', LOCAL, DEVELOPMENT, PRODUCTION].map(target => {
        const selected = debugStore.getSocketTarget() === target;
        return (
          <Button
            key={target}
            onClick={() => {
              debugStore.setSocketTarget(target);
            }}
          >
            {selected ? '* ' : ''}
            {target}
          </Button>
        );
      })}
      <h3>Show Developer Content</h3>
      <ToggleButton
        active={debugStore.getDeveloperContentEnabled()}
        onToggle={value => {
          debugStore.setDeveloperContentEnabled(value);
        }}
      />
    </Container>
  );
}
