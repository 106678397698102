import styled from 'styled-components';
import { BG_SOLID } from 'styles';
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Video = styled.video`
  width: 100%;
  height: auto;
  max-height: 100%;
  outline: none;
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  ${props => props.url && `background: url(${props.url});`}
  background-color: ${BG_SOLID};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
