import React, { useEffect } from 'react';
import { useMaterialStore } from 'services/MaterialService';
import Material from './Material';
import { useSpring } from 'react-spring';
import { useFrame } from 'react-three-fiber';

export default function HubObject({ object, contentType }) {
  const { activeHoverID } = useMaterialStore(state => state);

  const [{ hover }, setHover] = useSpring(() => ({
    hover: 0.0,
    config: { tension: 200, friction: 30 },
  }));

  useEffect(() => {
    setHover({ hover: activeHoverID !== null && activeHoverID === contentType.id ? 1 : 0 });
  }, [activeHoverID]);

  useFrame(() => {
    object.traverse(o => {
      if (o.material) {
        if (o.material.userData.hover) o.material.userData.hover.value = hover.value;
      }
    });
  });

  return (
    <group>
      <primitive object={object} />
      <Material object={object} />
    </group>
  );
}
