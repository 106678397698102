import React from 'react';
import format from 'utilities/date/format';
import TalkCTA from './TalkCTA';
import {
  StyledTalkComponent,
  StyledTalkGroup,
  StyledTalkBody,
  StyledTalkFooter,
  StyledFooterRightContainer,
  StyledTalkType,
  StyledTalkTime,
  StyledTalkTitle,
  StyledSpeakers,
  StyledSpeakerNames,
} from './styles';
import TalkLiveBatch from './TalkLiveBatch';
import PersonImage from 'common/components/PersonImage';
import { assembleNameWithTitle } from 'services/UserService';

export default function TalkComponent({
  talk,
  index,
  withAnimation = false,
  itemAnimOffset = 0,
  noBackground = false,
  showCTA = true,
}) {
  const { id, title, startTime, endTime, duration, type, speakers } = talk;
  const _startTime = startTime ? format(startTime, 'time') : null;

  let time = null;

  if (_startTime) {
    if (duration === 0) {
      time = _startTime;
    } else {
      const _endTime = format(endTime, 'time');
      time = _startTime + ' - ' + _endTime;
    }
  }

  const clampedAnimIndex = index > 5 ? 5 : index;

  const hasSpeakers = speakers && speakers.length > 0;

  const speakerComponents = (
    <StyledSpeakers>
      {hasSpeakers &&
        speakers.map((speaker, i) => {
          return <PersonImage key={id + '_' + speaker.id} size={'s'} user={speaker} stacked first={i === 0} />;
        })}
      {hasSpeakers && (
        <StyledSpeakerNames>
          {speakers.map((speaker, i) => {
            const fullname = assembleNameWithTitle(speaker);
            return (
              <span key={i}>
                {i !== 0 && ', '}
                {fullname}
              </span>
            );
          })}
        </StyledSpeakerNames>
      )}
    </StyledSpeakers>
  );

  let typeText;

  switch (type) {
    case 'exchange':
      typeText = 'Austausch';
      break;
    case 'group_session':
      typeText = 'Diskussion';
      break;
    case 'feedback':
      typeText = 'Feedback zum Event';
      break;
    default:
      // 'live_talk':
      typeText = 'Präsentation';
  }

  return (
    <StyledTalkComponent
      withAnimation={withAnimation}
      index={clampedAnimIndex}
      itemAnimOffset={itemAnimOffset}
      noBackground={noBackground}
    >
      <StyledTalkBody>
        <StyledTalkGroup>
          <TalkLiveBatch talk={talk} />
          {type && <StyledTalkType highlight>{typeText}</StyledTalkType>}
        </StyledTalkGroup>
        <StyledTalkTitle>{title}</StyledTalkTitle>
        {speakerComponents}
      </StyledTalkBody>
      <StyledTalkFooter>
        {time && <StyledTalkTime>{time}</StyledTalkTime>}
        <StyledFooterRightContainer>{showCTA && <TalkCTA talk={talk} />}</StyledFooterRightContainer>
      </StyledTalkFooter>
    </StyledTalkComponent>
  );
}
