import React, { useMemo } from 'react';
import { contentApi, useContentStore } from 'services/ContentService';
import { useWindowStore } from 'services/WindowService';
import { useUiStore } from 'services/UiService';
import { StyledNavigation, StyledTransform, StyledHeadline, StyledSubline, StyledButtonContainer } from './styles';
import { Button } from 'common/components/Button';
import { useOnboardingStore } from 'services/OnboardingService';

export default function Navigation() {
  const activeContent = useContentStore(state => state.activeContent);
  const potentialContent = useContentStore(state => state.potentialContent);
  const showNavigation = useUiStore(state => state.showNavigation);
  const windowWidth = useWindowStore(state => state.width);
  const windowHeight = useWindowStore(state => state.height);
  const introCompleted = useOnboardingStore(state => state.introCompleted);

  const key = useMemo(() => {
    return Math.random();
  }, [potentialContent]);

  if (!potentialContent || activeContent) {
    return null;
  }

  const onClick = () => {
    contentApi.getState().setActiveContent({
      type: potentialContent,
    });
  };

  const aspectRatio = windowHeight / windowWidth;
  const x = windowWidth * 0.5 + windowWidth * 0.28 * aspectRatio;
  const show = introCompleted && showNavigation;
  const isLibrary = potentialContent.id === 'LIBRARY';

  return (
    <StyledNavigation key={key} show={show} isLibrary={isLibrary}>
      <StyledTransform x={x}>
        <StyledSubline show={show} index={1}>
          {potentialContent.subline}
        </StyledSubline>
        <StyledHeadline show={show} index={2}>
          {potentialContent.headline}
        </StyledHeadline>
        <StyledButtonContainer show={show} index={3}>
          <Button secondaryWhite onClick={onClick}>
            Betreten
          </Button>
        </StyledButtonContainer>
      </StyledTransform>
    </StyledNavigation>
  );
}
