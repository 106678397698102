import styled from 'styled-components';
import { Caption2 } from 'common/text';

const w2 = 8;
export const UsersOverlay = styled(Caption2)`
  text-align: center;
  min-width: 60px;
  font-size: 10px;
  line-height: 16px;
  font-family: sans-serif;
  font-weight: bold;
  
  ${props => (props.floatRight || props.floatLeft) && 'max-width: 250px;'}
  ${props => props.floatRight && 'right: -20px;'}
  ${props => props.floatLeft && 'left: -20px;'}
  background-color: #ffffff;
  color: #0d2467;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  ${props => (props.under ? 'top: 30px;' : 'bottom: 30px;')}
  ${props => props.floatRight && 'right: -20px'};
  ${props => props.floatLeft && 'left: -20px'};
  padding: 12px 16px;

  &:after {
    content: '';
    position: absolute;
    ${props => (props.under ? 'top: 0;' : 'bottom: 0;')}
    
    ${props => props.floatRight && 'right: 30px;'}
    ${props => props.floatLeft && 'left: 36px;'}
    ${props => !(props.floatRight || props.floatLeft) && 'left: 50%;'};
    width: 0;
    height: 0;
    border: ${w2}px solid transparent;
    
    ${props => (props.under ? 'border-bottom-color: #ffffff;' : '    border-top-color: #ffffff;')}
    ${props => (props.under ? 'border-top: 0;' : '    border-bottom: 0;')}

    margin-left: -${w2}px;
    
    ${props => (props.under ? `margin-top: -${w2}px;` : `margin-bottom: -${w2}px;`)}
  }
`;
