import create from 'utilities/zustand/create';

export const [useNotificationStore, notificationApi] = create(module, (set /*, get*/) => ({
  notification: null,
  timer: null,

  setNotification: notification => {
    // TODO: either differentiate between toasts and notifications or add a feature similiar to e.g. the windows notification center / facebook notifications - so users (and devs) can look up the notification that they have missed.

    //    let { timer } = get();
    //    if (timer) {
    //      clearTimeout(timer);
    //    }
    //
    //    timer = setTimeout(() => {
    //      const { notification: n, closeNotification } = get();
    //      if (n && n === notification) {
    //        closeNotification();
    //      }
    //    }, 10000);

    set({ notification /*, timer*/ });
  },
  closeNotification: () => {
    //    const { timer } = get();
    //    if (timer) {
    //      clearTimeout(timer);
    //    }
    set({ notification: null /*, timer: null */ });
  },
}));
