import React, { useEffect, useRef, useMemo } from 'react';
import { useSceneStore } from 'services/SceneService';
import { uiApi } from 'services/UiService';
import { cameraApi } from 'services/CameraService';
import { controlsApi } from 'services/ControlsService';
import { onboardingApi, useOnboardingStore } from 'services/OnboardingService';
import { useUserStore, assembleNameWithTitle } from 'services/UserService';
import { wispApi } from 'services/WispService';
import { useApplicationStore } from 'services/ApplicationService';
import {
  Container,
  SkipButton,
  IntroContainer,
  Centered,
  Image,
  StyledLine,
  StartButton,
  CenteredTop,
  TopBar,
  BottomBar,
} from './styles';
import { Power2, TimelineMax } from 'gsap';
import IpsenLogo from 'common/assets/ipsenLogo.png';

export default function Intro() {
  const user = useUserStore(state => state.user);
  const isLoaded = useSceneStore(state => state.isLoaded);
  const introPlaying = useOnboardingStore(state => state.introPlaying);
  const introContinued = useOnboardingStore(state => state.introContinued);
  const appStore = useApplicationStore();

  const fullname = assembleNameWithTitle(user);

  const timeline = useMemo(() => new TimelineMax(), []);

  const filterBlur = 'blur(5px)';

  const fadeInOut = (ref, position, duration, { blur = true } = {}) => {
    timeline.fromTo(ref.current, duration, { autoAlpha: 0, scale: 1.1 }, { scale: 0.9 }, position);
    timeline.fromTo(
      ref.current,
      duration * 0.25,
      { autoAlpha: 0, filter: blur ? filterBlur : null },
      { autoAlpha: 1, filter: blur ? 'blur(0px)' : null },
      position
    );
    timeline.to(
      ref.current,
      duration * 0.25,
      { autoAlpha: 0, filter: blur ? filterBlur : null },
      position + duration * 0.75
    );
  };

  const fadeIn = (ref, position, duration, { blur = true } = {}) => {
    timeline.call(() => (ref.current.style.display = ''), [], position);
    timeline.fromTo(
      ref.current,
      duration,
      { autoAlpha: 0, filter: blur ? filterBlur : null, scale: 1.1 },
      { autoAlpha: 1, filter: blur ? 'blur(0px)' : null, scale: 1 },
      position
    );
  };

  const fadeOut = (ref, position, duration) => {
    timeline.fromTo(ref.current, duration, { autoAlpha: 1 }, { autoAlpha: 0 }, position);
  };

  const swipeUp = (ref, position, duration) => {
    timeline.fromTo(
      ref.current,
      duration,
      { transform: 'translateY(0)', autoAlpha: 1 },
      { transform: 'translateY(-20vh)', autoAlpha: 0, ease: Power2.easeIn },
      position
    );
  };

  const moveBar = (ref, position, duration, direction) => {
    timeline.fromTo(
      ref.current,
      duration,
      { transform: `translateY(0vh)` },
      { transform: `translateY(${direction * 10}vh)`, ease: Power2.easeIn },
      position
    );
  };

  useEffect(() => {
    uiApi.getState().hideAll();
  }, []);

  const startIntro = () => {
    controlsApi.getState().setActive(false);
    cameraApi.getState().setScreenFade(0.0);
    onboardingApi.getState().startIntro();
  };
  const continueIntro = () => {
    if (!introContinued) {
      onboardingApi.getState().continueIntro();
    }
  };
  const completeIntro = () => {
    uiApi.getState().setShowMenu(true);
    uiApi.getState().setShowEmojiBar(true);
    uiApi.getState().setShowNavigation(true);
    controlsApi.getState().setActive(true);
    onboardingApi.getState().completeIntro();
  };

  useEffect(() => {
    if (!introPlaying && isLoaded) {
      startIntro();
    }
  }, [introPlaying, isLoaded]);

  const logo = useRef();
  const welcomeContainer = useRef();
  const welcome = useRef();
  const fullnameRef = useRef();
  const start = useRef();
  const learn = useRef();
  const dive = useRef();
  const topBar = useRef();
  const bottomBar = useRef();
  const skipButton = useRef();

  useEffect(() => {
    if (!introPlaying || introContinued) {
      return;
    }

    timeline.clear();

    timeline.set(logo.current, { autoAlpha: 0 });
    timeline.set(welcome.current, { autoAlpha: 0, filter: filterBlur, scale: 1.1 });
    timeline.set(fullnameRef.current, { autoAlpha: 0, filter: filterBlur, scale: 1.1 });
    timeline.set(start.current, { autoAlpha: 0, filter: filterBlur, scale: 1.1 });
    timeline.set(learn.current, { autoAlpha: 0, scale: 1.1 });
    timeline.set(dive.current, { autoAlpha: 0, scale: 1.1 });
    topBar.current.style.transform = bottomBar.current.style.transform = 'translateY(0)';
    skipButton.current.style.opacity = 0;
    skipButton.current.style.display = 'none';

    let offset = 0;
    if (appStore.theme === 'branded') {
      fadeInOut(logo, 1, 5);
    } else {
      offset = 5;
    }

    timeline.call(() => wispApi.getState().setOwnWispActivity('INTRO'), [], 6 - offset);

    fadeIn(welcome, 6 - offset, 3);
    fadeIn(fullnameRef, 7 - offset, 2);
    fadeIn(start, 8 - offset, 1);
  }, [introPlaying, introContinued]);

  useEffect(() => {
    if (!introContinued) {
      return;
    }

    timeline.clear();

    fadeOut(start, 0, 0.5);
    swipeUp(welcomeContainer, 0, 1);
    moveBar(topBar, 0, 2, -1);
    moveBar(bottomBar, 0, 2, 1);

    fadeIn(skipButton, 0, 1, { blur: false });

    timeline.call(() => wispApi.getState().setOwnWispActivity(null), [], 3);
    fadeInOut(learn, 3, 5, { blur: false });
    fadeInOut(dive, 9, 5, { blur: false });
    fadeOut(skipButton, 13, 1);

    timeline.call(() => completeIntro(), [], 14);
  }, [introContinued]);

  const welcomeText =
    appStore.theme === 'branded'
      ? 'Herzlich Willkommen im Ipsen Space'
      : 'Herzlich Willkommen zur virtuellen Campusdialog-Fortbildung';

  return (
    <Container>
      <IntroContainer visible={introPlaying}>
        <Centered ref={logo}>
          <Image src={IpsenLogo} />
        </Centered>
        <Centered ref={welcomeContainer}>
          <>
            <StyledLine ref={welcome}>{welcomeText}</StyledLine>
            <StyledLine ref={fullnameRef}>{fullname}</StyledLine>
            <StyledLine ref={start} onClick={continueIntro}>
              <StartButton secondaryWhite>Start</StartButton>
            </StyledLine>
          </>
        </Centered>
        <CenteredTop>
          <StyledLine ref={learn}>Lernen Sie Kollegen und Experten kennen.</StyledLine>
        </CenteredTop>
        <CenteredTop>
          <StyledLine ref={dive}>
            Erfahren Sie mehr über Innovationen oder erweitern Sie das Wissen auf Ihrem Gebiet.
          </StyledLine>
        </CenteredTop>
      </IntroContainer>
      <SkipButton onClick={completeIntro} ref={skipButton}>
        Überspringen
      </SkipButton>
      <TopBar ref={topBar} />
      <BottomBar ref={bottomBar} />
    </Container>
  );
}
