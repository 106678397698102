import React from 'react';
import { assembleNameWithTitle } from 'services/UserService';
import PersonImage from 'common/components/PersonImage';
import { chatApi } from 'services/ChatService';
import { modalApi } from 'services/ModalService';
import { menuApi } from 'services/MenuService';
import {
  StyledPerson,
  StyledSpeciality,
  StyledPersonRow,
  StyledTopLabel,
  StyledPersonColumn,
  StyledFullname,
  StyledStartChatButton,
} from './styles';
import SendIcon from './assets/send.svg';
import { trackEvent } from 'utilities/analytics';

export default function PersonHeader({ user, size = null, topLabel = null }) {
  const { speciality } = user;
  const fullname = assembleNameWithTitle(user);
  const onStartChat = e => {
    e.stopPropagation();
    chatApi.getState().start([user]);
    modalApi.getState().closeModal();
    menuApi.getState().setActiveMenuComponent(menuApi.getState().chat);
    trackEvent('People', 'Start Chat');
  };

  return (
    <StyledPerson size={size}>
      <div style={{ position: 'relative' }}>
        <div style={{ marginRight: '15px' }}>
          <PersonImage size="m" user={user} square showOnlineIndicator />
        </div>
      </div>
      <StyledPersonRow>
        <StyledPersonColumn>
          {topLabel && <StyledTopLabel>{topLabel}</StyledTopLabel>}
          <StyledFullname>{fullname}</StyledFullname>
          <StyledSpeciality>{speciality}</StyledSpeciality>
        </StyledPersonColumn>
        <StyledStartChatButton onClick={onStartChat}>
          <SendIcon />
        </StyledStartChatButton>
      </StyledPersonRow>
    </StyledPerson>
  );
}
