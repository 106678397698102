import styled from 'styled-components';
import { baseColors } from 'styles';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 75px;
  transition: all 0.2s ease-out;
  transform: ${props => (props.visible ? 'translate(0, -50%)' : 'translate(8px, -50%)')};
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
`;

export const TriangleLeft = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-right-color: ${baseColors.blue.interactive};
  transform: translateY(-50%);
  top: 50%;
  left: -12px;
`;
