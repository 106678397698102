import create from 'utilities/zustand/create';
import { chatApi } from 'services/ChatService';
import config from 'services/DistrictService/config';
import { profilingApi } from '../ProfilingService';

let socket = null;
export const [useDistrictStore, districtApi] = create(module, (set, get) => ({
  districts: config,
  district: null,

  init: managedSocket => {
    socket = managedSocket;
  },

  initDistrict: district => {
    get().setDistrict(district);
  },

  setDistrict: async district => {
    set({ district: district });
  },

  enterDistrict: async district => {
    const { districts } = get();
    district.entered = true;
    set({ districts: [...districts] });
    profilingApi.getState().mark('Enter District');
    chatApi.getState().switchDistrict();

    await new Promise(resolve => {
      socket.emit('district/switch', district.room, resolve);
    });
  },

  leaveDistrict: district => {
    const { districts } = get();
    district.entered = false;
    district.isLoaded = false;
    set({ district: null, districts: [...districts] });
  },

  setDistrictLoaded: () => {
    const { districts, district } = get();
    if (!district) return;
    district.isLoaded = true;
    set({ districts: [...districts], district: district });
  },

  reset: () => {
    const { districts, district } = get();
    if (district) {
      district.entered = false;
      district.isLoaded = false;
    }
    set({ districts: [...districts], district: null });
  },

  getSimpleDistrictList: () => {
    const ds = get().districts.filter(d => !d.isHidden);
    return ds.map(d => ({ id: d.room, name: d.title }));
  },
}));
