import React from 'react';
import { contentApi } from 'services/ContentService';
import { Button } from 'common/components/Button';
import { StyledLibrary } from './styles';
import { StyledBackButtonContainer } from '../styles';
import { useDocumentListener } from 'utilities/hooks';

export default function Library({ show }) {
  const onBackClick = () => {
    contentApi.getState().clearActiveContent();
  };

  useDocumentListener(show, 'keyup', e => {
    e.code === 'Escape' && onBackClick();
  });

  return (
    <StyledLibrary show={show}>
      <StyledBackButtonContainer>
        <Button primaryInverted onClick={onBackClick} mobileSlim>
          Verlassen
        </Button>
      </StyledBackButtonContainer>
    </StyledLibrary>
  );
}
