import React from 'react';
import { modalApi } from 'services/ModalService';
import { assembleNameWithTitle } from 'services/UserService';
import { chatApi } from 'services/ChatService';
import { menuApi } from 'services/MenuService';
import { Badge } from 'common/components/Badge';
import PersonImage from 'common/components/PersonImage';
import CloseButton from 'common/components/CloseButton';
import { Button } from 'common/components/Button';
import { StyledName, StyledRow, StyledPersonImageContainer } from './styles';
import { StyledColumn, StyledInfo, StyledButtonContainer } from 'components/Modal/styles';
import { useDocumentListener } from 'utilities/hooks';

export default function ModalProfile({ user }) {
  const { speciality, company } = user;
  const fullname = assembleNameWithTitle(user);

  const onStartChat = () => {
    chatApi.getState().start([user]);
    modalApi.getState().closeModal();
    menuApi.getState().setActiveMenuComponent(menuApi.getState().chat);
  };

  const onCloseClick = () => {
    modalApi.getState().closeModal();
  };

  useDocumentListener(true, 'keyup', e => {
    e.code === 'Escape' && onCloseClick();
  });

  return (
    <>
      <CloseButton onClick={onCloseClick} />
      <StyledRow>
        <StyledPersonImageContainer>
          <PersonImage size="l" user={user} square />
        </StyledPersonImageContainer>
        <StyledColumn>
          <Badge user={user} />
          <StyledName>{fullname}</StyledName>
          <StyledInfo>{speciality}</StyledInfo>
          <StyledInfo>{company}</StyledInfo>
          <StyledButtonContainer>
            <Button primaryInverted onClick={onStartChat}>
              Chat starten
            </Button>
          </StyledButtonContainer>
        </StyledColumn>
      </StyledRow>
    </>
  );
}
