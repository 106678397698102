import React from 'react';
import { menuApi, useMenuStore } from 'services/MenuService';
import MenuButton from '../components/MenuButton';
import InfoIcon from '../assets/info.svg';

export default function InfoButton() {
  const activeMenuComponent = useMenuStore(state => state.activeMenuComponent);
  const infoComponent = useMenuStore(state => state.info);
  const onClick = () => {
    menuApi.getState().setActiveMenuComponent(activeMenuComponent === infoComponent ? null : infoComponent);
  };

  return (
    <MenuButton active={activeMenuComponent === infoComponent} onClick={onClick} hoverLabel="Info">
      <InfoIcon />
    </MenuButton>
  );
}
