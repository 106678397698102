import { MeshStandardMaterial, sRGBEncoding, TextureLoader, VideoTexture, Color } from 'three';
import Hls from 'hls.js';
import { soundApi } from 'services/SoundService';
import { contentApi } from 'services/ContentService';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { arenaApi } from 'services/ArenaService';

export const createVideoTeaser = videoSrc => {
  const activeContent = contentApi.getState().activeContent;
  const activeContentType = activeContent && activeContent.type.id;
  const videoTeaser = document.createElement('video');
  videoTeaser.setAttribute('playsinline', '');
  videoTeaser.setAttribute('autoplay', '');
  videoTeaser.setAttribute('loop', '');
  videoTeaser.setAttribute('muted', '');
  videoTeaser.loop = true;
  videoTeaser.playsinline = true;

  const useSound = activeContentType === CONTENT_TYPE.ARENA.id && soundApi.getState().enabled;

  videoTeaser.volume = useSound ? 1 : 0;
  videoTeaser.muted = !useSound;
  videoTeaser.crossOrigin = 'anonymous';

  if (!videoSrc) return videoTeaser;

  let hls = null;
  if (videoSrc.endsWith('m3u8')) {
    if (Hls.isSupported()) {
      const config = {
        //reduce initial loading cpu load
        maxMaxBufferLength: 15,
      };
      hls = new Hls(config);
      //show lowest resolution on screen
      hls.autoLevelCapping = 0;
      hls.subtitleDisplay = false;
      hls.loadSource(videoSrc);
      hls.attachMedia(videoTeaser);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        arenaApi.getState().playVideoTeaser(videoTeaser);
      });
    } else if (videoTeaser.canPlayType('application/vnd.apple.mpegurl')) {
      videoTeaser.src = videoSrc;
      videoTeaser.addEventListener('loadedmetadata', () => {
        arenaApi.getState().playVideoTeaser(videoTeaser);
      });
    }
  } else {
    const source = document.createElement('source');
    source.src = videoSrc;
    videoTeaser.append(source);
    arenaApi.getState().playVideoTeaser(videoTeaser);
  }

  return { videoTeaser, hls };
};

function createDisplayMaterial(texture) {
  const material = new MeshStandardMaterial({ emissiveMap: texture });
  material.emissiveIntensity = 0.9;
  material.emissive = new Color(0xffffff);
  material.color = new Color(0x000000);
  material.roughness = 0.1;
  material.metalness = 0.9;
  return material;
}

export const createVideoMaterial = url => {
  const { videoTeaser, hls } = createVideoTeaser(url);
  const texture = new VideoTexture(videoTeaser);
  texture.anisotropy = 16;
  texture.encoding = sRGBEncoding;
  texture.flipY = false;
  const material = createDisplayMaterial(texture);
  material.addEventListener('dispose', () => {
    videoTeaser.pause();
    texture.dispose();
    if (hls) {
      hls.destroy();
    }
  });
  return { material, videoTeaser };
};

export const createImageMaterial = url => {
  const texture = new TextureLoader().load(url + '?arena');
  texture.anisotropy = 16;
  texture.encoding = sRGBEncoding;
  texture.flipY = false;
  const material = createDisplayMaterial(texture);
  return { material };
};
