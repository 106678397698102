export const safeSelectFormatFromMedia = (media, size) => {
  if (!media) {
    return null;
  }
  if (media.formats === null) {
    return media;
  }
  if (size === 'large' && !media.formats.large) {
    size = 'medium';
  }
  if (size === 'medium' && !media.formats.medium) {
    size = 'small';
  }
  if (size === 'small' && !media.formats.small) {
    size = 'thumbnail';
  }
  return media.formats[size];
};

export const safeSelectUrlFromMedia = (media, size) => {
  const result = safeSelectFormatFromMedia(media, size);
  return result?.url;
};
