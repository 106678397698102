import { useEffect } from 'react';
import { useContentStore } from 'services/ContentService';
import { soundApi } from 'services/SoundService';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { arenaApi } from 'services/ArenaService';

export default function SoundController() {
  const content = useContentStore(state => state.activeContent);
  const contentType = content && content.type.id;

  useEffect(() => {
    // Allow sound playing after first interaction
    const autoPlay = () => {
      window.removeEventListener('touchstart', autoPlay);
      window.removeEventListener('mousedown', autoPlay);
      window.removeEventListener('keydown', autoPlay);
      arenaApi.getState().allowPlay();
    };
    window.addEventListener('touchstart', autoPlay);
    window.addEventListener('mousedown', autoPlay);
    window.addEventListener('keydown', autoPlay);
  }, []);

  useEffect(() => {
    // Play fitting ambient sound or mute for certain content types
    if (contentType === CONTENT_TYPE.ARENA.id) {
      soundApi.getState().playArenaAmbient();
    } else {
      soundApi.getState().playHubAmbient();
    }
  }, [contentType]);

  return null;
}
