import { useUserStore } from 'services/UserService';

const useLoginStatus = () => {
  const loginState = useUserStore(state => state.loginState);
  const message = useUserStore(state => state.message);

  const uiMessage = loginState === 'LOGGED_OUT' && message ? message.copy : null;
  return { isPending: loginState === 'PENDING', message: { copy: uiMessage } };
};

export { useLoginStatus };
