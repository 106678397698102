import React from 'react';
import styled from 'styled-components';
import { deviceApi } from 'services/DeviceService';

export const StyledInput = styled.input`
  font-family: 'PF Highway Sans Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  text-indent: 16px;
  display: block;
  width: 100%;
  padding: 12px 0;
  color: rgba(255, 255, 255, 0.5);
  outline: none;
  border-radius: 8px;
  background: linear-gradient(90.87deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.075) 100%);
  border: 1px solid transparent;
  border-right-color: #081842;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    border: 1px solid #2870e6;
    color: white;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
`;

export function Input(props) {
  const { setKeyboardActive } = deviceApi.getState();

  const handleOnFocus = () => {
    setKeyboardActive(true);
  };
  const handleOnBlur = () => {
    setKeyboardActive(false);
  };

  return <StyledInput ref={props.forwardRef} {...props} onFocus={handleOnFocus} onBlur={handleOnBlur} />;
}
