import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFrame, useLoader, useThree } from 'react-three-fiber';
import * as THREE from 'three';
import { DoubleSide, MathUtils, Matrix3, NormalBlending, Vector3 } from 'three';
import flareImage from './assets/wisp-06.png';
import {
  HIGHEST_ONLINE_WISP_COUNT,
  MAX_WISP_COUNT,
  offlineColor,
  offlineOpacity,
  useWispStore,
  wispApi,
} from 'services/WispService';
import { useControlsStore } from 'services/ControlsService';
import { useActivityStore } from 'services/ActivityService';
import { userApi } from 'services/UserService';
import { useWindowStore } from 'services/WindowService';
import { hotspots } from 'components/Play/Hub/HotSpotController/hotspots';
import SimplexNoise from 'simplex-noise';
import Emojis from './emojis';
import { trackEvent } from 'utilities/analytics';
import { useContentStore } from 'services/ContentService';
import instancedWispsVert from './glsl/instancedWisps.vert';
import instancedWispsFrag from './glsl/instancedWisps.frag';
import staticWispsVert from './glsl/staticWisps.vert';
import staticWispsFrag from './glsl/staticWisps.frag';
import { betterLerp, betterLerpVec } from 'utilities/lerp';

const STATIC_PARTICLE_COUNT = MAX_WISP_COUNT - HIGHEST_ONLINE_WISP_COUNT;

const simplex = new SimplexNoise();

const revolutionPeriod = 100.0; // how many seconds is needed for one rotation around the world

const _position = new Vector3();
//const _projectedPosition = new Vector3();
const _cameraForward = new Vector3();
const _tempObject = new THREE.Object3D();

const calculateWispPosition = (wisp, context, out) => {
  const { time, roomIdToRoom, cameraForward, camera } = context;
  const fastHash = x => (Math.sin(x) * 43758.5453123) % 1; // fast hacky hash
  const offset = fastHash(wisp.state.seed);

  let octave0Factor;
  let octave1Factor;

  let xFactor = 1,
    yFactor = 1,
    zFactor = 1;
  let s0Factor = 1,
    s1Factor = 1;

  switch (wisp.activity) {
    case 'INTRO': {
      const { position: camPosition, quaternion: camQuaternion } = camera;
      out
        .set(0, 0, -3)
        .applyQuaternion(camQuaternion)
        .add(camPosition);
      out.y -= 0.4;
      octave0Factor = 0.2;
      octave1Factor = 0.05;
      break;
    }
    case 'ARENA':
    case 'LIVESTREAM':
      {
        if (wisp.state.isSelf) {
          const { position: camPosition, quaternion: camQuaternion } = camera;
          out
            .set(0, -0.15, -1.5)
            .applyQuaternion(camQuaternion)
            .add(camPosition);
        } else {
          const offset2 = fastHash(wisp.state.seed + 37.3892);
          out.x = 0.5 * Math.sqrt(Math.abs(offset)) * Math.sin(offset2 * Math.PI);
          out.z = 0.3 * Math.sqrt(Math.abs(offset)) * Math.cos(offset2 * Math.PI);
          out.y = 0.2 * Math.cos(offset2 * Math.PI * 4) * 0.25;

          const y = simplex.noise2D(0, offset * 64.0) * 0.075 + offset * Math.sin(offset + Math.PI * 2) * 0.15;
          const baseY = 2.0 * hotspots[0].position.y - 0.3;

          out.x += hotspots[1].position.x;
          out.y = baseY + y;
          out.z += hotspots[1].position.z - 0.35;
        }
        octave0Factor = 0.025 * wisp.state.scale;
        octave1Factor = 0.01 * wisp.state.scale;
      }
      break;
    case 'NETWORKING': {
      const isSelf = wisp.state.isSelf;
      const roomFactor = wisp.state.roomFactor;
      const offset2 = fastHash(wisp.state.seed + 37.3892);
      // const x = 0.5 * Math.sqrt(Math.abs(offset)) * Math.sin(offset2 * Math.PI);
      const y = 0.5 * Math.cos(offset2 * Math.PI * 4) * 0.25;
      // const z = 0.5 * Math.sqrt(Math.abs(offset)) * Math.cos(offset2 * Math.PI);
      const baseX = hotspots[0].position.x;
      const baseY = hotspots[0].position.y + 0.35;
      const baseZ = hotspots[0].position.z;
      const rotationPhase = time * 0.2 * ((2.0 * Math.PI) / revolutionPeriod) + offset * 2.0 * Math.PI;
      const r = 0.7 + offset2 * 0.1;
      const freeX = baseX + Math.sin(rotationPhase) * r;
      const freeY = baseY + y;
      const freeZ = baseZ + Math.cos(rotationPhase) * r;
      const room =
        wisp.roomId != null && Object.prototype.hasOwnProperty.call(roomIdToRoom, wisp.roomId)
          ? roomIdToRoom[wisp.roomId]
          : null;
      let busyX = room ? room.position.x : freeX;
      let busyY = room ? room.position.y : freeY;
      let busyZ = room ? room.position.z : freeZ;
      if (room && wisp.state.roomPosition) {
        busyX += wisp.state.roomPosition.x * 0.25;
        busyY += wisp.state.roomPosition.y * 0.25;
        busyZ += wisp.state.roomPosition.z * 0.25;
      }
      const ownX = baseX - cameraForward.x * 1.4;
      const ownY = baseY - cameraForward.y * 1.4 - 0.4;
      const ownZ = baseZ - cameraForward.z * 1.4;
      out.x = MathUtils.lerp(MathUtils.lerp(freeX, ownX, isSelf ? 1 : 0), busyX, roomFactor);
      out.y = MathUtils.lerp(MathUtils.lerp(freeY, ownY, isSelf ? 1 : 0), busyY, roomFactor);
      out.z = MathUtils.lerp(MathUtils.lerp(freeZ, ownZ, isSelf ? 1 : 0), busyZ, roomFactor);
      octave0Factor = 0.01 * wisp.state.scale;
      octave1Factor = 0.01 * wisp.state.scale;
      break;
    }
    case 'LIBRARY_CATEGORY':
    case 'LIBRARY': {
      const offset2 = fastHash(wisp.state.seed + 37.3892);
      out.x = 0.3 * Math.sqrt(Math.abs(offset)) * Math.sin(offset2 * Math.PI);
      out.z = 0.3 * Math.sqrt(Math.abs(offset)) * Math.cos(offset2 * Math.PI);
      out.y = 0;
      out.x += hotspots[2].position.x;
      out.y += hotspots[2].position.y - 0.05;
      out.z += hotspots[2].position.z;
      octave0Factor = 0.025 * wisp.state.scale;
      octave1Factor = 0.005 * wisp.state.scale;
      break;
    }
    default: {
      const rotationPhase = time * 0.2 * ((2.0 * Math.PI) / revolutionPeriod) + offset * 2.0 * Math.PI;
      const r = 10.0;
      out.x = Math.sin(rotationPhase) * r;
      out.y = 0;
      out.z = Math.cos(rotationPhase) * r;
      const hash = fastHash(wisp.state.seed + 37.3892);
      xFactor = 6.0;
      yFactor = 6.0;
      zFactor = 6.0;
      octave0Factor = 0.33 * hash;
      octave1Factor = 0.33 * hash;
      s0Factor = 0.0;
      s1Factor = 0.0;
    }
  }
  // two octaves of noise
  const s0 = 0.075 * s0Factor;
  const s1 = 0.4 * s1Factor;
  const octave0x = simplex.noise2D(time * s0, 0.2 + offset * 64.0);
  const octave0y = simplex.noise2D(time * s0, 0.5 + offset * 64.0);
  const octave0z = simplex.noise2D(time * s0, 0.8 + offset * 64.0);
  const octave1x = simplex.noise2D(time * s1, 0.2 + offset * 64.0);
  const octave1y = simplex.noise2D(time * s1, 0.5 + offset * 64.0);
  const octave1z = simplex.noise2D(time * s1, 0.8 + offset * 64.0);
  const noiseX = octave0x * octave0Factor + octave1x * octave1Factor;
  const noiseY = octave0y * octave0Factor + octave1y * octave1Factor;
  const noiseZ = octave0z * octave0Factor + octave1z * octave1Factor;
  out.x += xFactor * noiseX;
  out.y += yFactor * noiseY;
  out.z += zFactor * noiseZ;
};

const getRoomPosition = (roomId, wisps) => {
  // generate random room positions and find the one with the highest minimum distance to all others
  const MAX_TRIES_FOR_ROOMPOSITION = 8;
  const wispsInRoom = wisps.filter(wisp => wisp.roomId === roomId && wisp.state.roomPosition);
  let maxMinDist = 0;
  const bestCandidate = new Vector3();
  for (let i = 0; i < MAX_TRIES_FOR_ROOMPOSITION; i++) {
    const maxRadius = 0.45;
    const radius = maxRadius * Math.sqrt(Math.random());
    const theta = 2 * Math.PI * Math.random();
    const phi = Math.acos(-1.0 + 2.0 * Math.random());
    const x = radius * Math.sin(phi) * Math.sin(theta);
    const y = radius * Math.cos(phi);
    const z = radius * Math.sin(phi) * Math.cos(theta);

    const position = new Vector3(x, y, z);

    let minDist = 1e9;
    wispsInRoom.forEach(wisp => {
      minDist = Math.min(minDist, position.distanceToSquared(wisp.state.roomPosition));
    });
    if (minDist > maxMinDist) {
      maxMinDist = minDist;
      bestCandidate.copy(position);
    }
  }
  return bestCandidate;
};

const mouse = new THREE.Vector3();

export default function Wisps() {
  const { camera } = useThree();
  const flare = useLoader(THREE.TextureLoader, flareImage);

  const activeWispRoom = useWispStore(state => state.wispRoom);
  const activeContent = useContentStore(state => state.activeContent);
  const activeContentTypeId = activeContent && activeContent.type.id;
  const ownActivity = useActivityStore(state => state.activity);
  const wisps = useWispStore(state => state.wisps);
  const onlineWisps = useMemo(() => {
    return wisps.filter(wisp => wisp.id);
  }, [wisps]);
  const wispsInRooms = wisps.filter(wisp => !!wisp.roomId);
  const roomIdToRoom = useWispStore(state => state.roomIdToRoom);
  const setWindowStoreHover = useWindowStore(state => state.setHover);
  const controlsActive = useControlsStore(state => state.active);
  const usePointerEvents =
    (controlsActive && activeContentTypeId === 'ARENA') ||
    activeContentTypeId === 'LIBRARY' ||
    activeContentTypeId === 'NETWORKING';

  const wispMaterialRef = useRef();
  const wispColorArray = useMemo(() => Uint8Array.from(new Array(MAX_WISP_COUNT * 3).fill(0)), []);
  const wispOpacityArray = useMemo(() => Uint8Array.from(new Array(MAX_WISP_COUNT).fill(0)), []);

  const instancedMeshRef = useRef();
  const instancedColliderMeshRef = useRef();
  const staticMeshRef = useRef();

  useEffect(() => {
    document.onmousemove = e => {
      mouse.x = (e.pageX / window.innerWidth) * 2 - 1;
      mouse.y = -(e.pageY / window.innerHeight) * 2 + 1;
      mouse.z = 0.0;
    };
  }, []);

  const [state] = useState({ lastElapsedTime: null });

  const wispIsClickable = otherWisp => {
    if (activeContentTypeId === 'ARENA') {
      if (otherWisp.activity === 'ARENA' || otherWisp.activity === 'LIVESTREAM') {
        return true;
      }
    } else if (activeContentTypeId === 'NETWORKING') {
      if (activeWispRoom && activeWispRoom.id !== otherWisp.roomId) {
        return false;
      }
      if (otherWisp.activity === 'NETWORKING') {
        return true;
      }
    } else if (activeContentTypeId === otherWisp.activity) {
      return true;
    }

    return false;
  };

  useFrame(context => {
    const elapsedTime = context.clock.getElapsedTime();
    const lastElapsedTime = state.lastElapsedTime;
    const deltaTime = lastElapsedTime != null ? elapsedTime - lastElapsedTime : 0; // getDeltaTime() is no good
    state.lastElapsedTime = elapsedTime;

    const arenaCount = wisps.filter(wisp => wisp.activity === 'ARENA' || wisp.activity === 'LIVESTREAM').length;
    const arenaScale = THREE.MathUtils.lerp(1.0, 0.5, arenaCount / HIGHEST_ONLINE_WISP_COUNT);

    const ref = instancedMeshRef;
    const refCollider = instancedColliderMeshRef;
    const colorBuffer = ref.current.geometry.attributes.color;
    const opacityBuffer = ref.current.geometry.attributes.opacity;

    camera.getWorldDirection(_cameraForward);

    const wispCount = Math.min(HIGHEST_ONLINE_WISP_COUNT, wisps.length);

    let j = 0; // used by collider mesh
    for (let i = 0; i < wispCount; i++) {
      const wisp = wisps[i];

      if (wisp.roomId && !wisp.state.roomPosition) {
        wisp.state.roomPosition = getRoomPosition(wisp.roomId, wispsInRooms);
      } else if (!wisp.roomId && wisp.state.roomPosition) {
        wisp.state.roomPosition = null;
      }

      let targetScale = 1.0;
      const isArenaActivity =
        wisp.activity === 'ARENA' || wisp.activity === 'LIVESTREAM' || wisp.activity === 'NETWORKING';
      if (isArenaActivity) {
        targetScale *= arenaScale;
      }
      const isLibraryActivity = wisp.activity === 'LIBRARY' || wisp.activity === 'LIBRARY_CATEGORY';
      if (isLibraryActivity) {
        targetScale *= 0.3;
      }
      if (ownActivity !== null && wisp.activity !== null) {
        targetScale *= 0.5;
      }
      wisp.state.scale = betterLerp(wisp.state.scale, targetScale, 0.01, deltaTime);
      _tempObject.scale.set(wisp.state.scale, wisp.state.scale, wisp.state.scale);

      const context = {
        time: elapsedTime,
        roomIdToRoom,
        cameraForward: _cameraForward,
        camera,
      };

      calculateWispPosition(wisp, context, _position);

      //let target;
      //if (wisp.activity === null || wisp.roomId !== null) {
      //  _projectedPosition.copy(_position).project(camera);
      //  _projectedPosition.z = 0;
      //  const distance = mouse.distanceTo(_projectedPosition);
      //  const maxDistance = 0.3;
      //  target = Math.max(maxDistance - distance, 0) / maxDistance;
      //} else {
      //  target = 0;
      //}
      //wisp.state.mouseDistance = MathUtils.lerp(wisp.state.mouseDistance, target, 0.03);
      wisp.state.roomFactor = betterLerp(wisp.state.roomFactor, wisp.roomId ? 1 : 0, 0.03, deltaTime);

      //if (wisp.roomId === null) {
      //  _position.y += wisp.state.mouseDistance * wisp.state.maxHeightDisplacement;
      //}

      wisp.state.position.copy(_position);

      const springDamping = wisp.activity === null ? 0.2 : 1;
      wisp.state.spring = betterLerp(wisp.state.spring, 1, springDamping / 60, deltaTime);
      if (wisp.state.smoothPosition === null) {
        wisp.state.smoothPosition = wisp.state.position.clone();
      } else {
        const damping = wisp.state.isSelf && wisp.activity === 'NETWORKING' ? 16 : 4;
        const spring = wisp.state.spring * wisp.state.spring;
        betterLerpVec(wisp.state.smoothPosition, wisp.state.position, damping * spring * (1 / 60), deltaTime);
      }

      _tempObject.position.copy(wisp.state.smoothPosition);
      _tempObject.lookAt(camera.position);
      _tempObject.updateMatrix();

      betterLerpVec(wisp.state.color, wisp.state.targetColor, 0.02, deltaTime);
      wisp.state.opacity = betterLerp(wisp.state.opacity, wisp.state.targetOpacity, 0.02, deltaTime);

      ref.current.setMatrixAt(i, _tempObject.matrix);
      if (wisp.id) {
        if (wispIsClickable(wisp)) {
          // not necessary since we doubled the size of the network corner wisps
          //_tempObject.scale.multiplyScalar(0.55); // smaller hitbox for wisp participants
          _tempObject.updateMatrix();
        }

        refCollider.current.setMatrixAt(j++, _tempObject.matrix);
      }
      const visible = wisp.roomId && activeWispRoom ? 0 : 1;
      colorBuffer.array[i * 3 + 0] = wisp.state.color.r * 255 * visible;
      colorBuffer.array[i * 3 + 1] = wisp.state.color.g * 255 * visible;
      colorBuffer.array[i * 3 + 2] = wisp.state.color.b * 255 * visible;
      opacityBuffer.array[i] = wisp.state.opacity * 255;
    }

    ref.current.count = wispCount;
    ref.current.instanceMatrix.needsUpdate = true;
    ref.current.instanceMatrix.updateRange = { offset: 0, count: wispCount * 16 };

    refCollider.current.count = j;
    refCollider.current.instanceMatrix.needsUpdate = true;
    refCollider.current.instanceMatrix.updateRange = { offset: 0, count: j * 16 };

    colorBuffer.needsUpdate = true;
    colorBuffer.updateRange = { offset: 0, count: wispCount * 3 };

    opacityBuffer.needsUpdate = true;
    opacityBuffer.updateRange = { offset: 0, count: wispCount };

    staticMeshRef.current.material.uniforms.uTime.value = (elapsedTime / 20.0) % 1.0;
    staticMeshRef.current.rotation.y = elapsedTime / revolutionPeriod;
  }, 0);

  const onPointerMove = event => {
    const wisp = onlineWisps[event.instanceId];
    if (wisp && wisp.id !== null) {
      if (wispIsClickable(wisp)) {
        wispApi.getState().setActiveWisp(wisp);
        if (ownActivity === 'NETWORKING' && !activeWispRoom ? wisp.roomId === null : true) {
          setWindowStoreHover(true);
        }
      } else {
        wispApi.getState().setActiveWisp(null);
      }
    } else {
      wispApi.getState().setActiveWisp(null);
      setWindowStoreHover(false);
    }
  };

  const onPointerOut = () => {
    wispApi.getState().setActiveWisp(null);
    setWindowStoreHover(false);
  };

  const onClick = e => {
    const wisp = onlineWisps[e.instanceId];
    const isNoRoomOrSameRoom = !wisp.roomId || (activeWispRoom && wisp.roomId === activeWispRoom.id);
    if (wisp && wisp.id !== null && isNoRoomOrSameRoom) {
      e.stopPropagation();
      userApi.getState().openProfileModal(wisp);
      trackEvent('Wisp', 'Open');
    }
  };

  const idArray = useMemo(
    () => Float32Array.from(new Array(STATIC_PARTICLE_COUNT).fill(0).map((v, index) => index / STATIC_PARTICLE_COUNT)),
    []
  );

  const wispSize = 0.22;

  const instancedWispUniforms = useMemo(() => ({
    map: { type: 't', value: flare },
    uvTransform: { value: new Matrix3() },
  }));

  const staticWispUniforms = useMemo(
    () => ({
      map: { type: 't', value: flare },
      uColor: { value: offlineColor },
      uOpacity: { value: offlineOpacity },
      uTime: { value: 0.0 },
    }),
    []
  );

  return (
    <>
      <instancedMesh ref={instancedMeshRef} args={[null, null, MAX_WISP_COUNT]}>
        <planeBufferGeometry attach="geometry" args={[wispSize, wispSize]}>
          <instancedBufferAttribute attachObject={['attributes', 'color']} args={[wispColorArray, 3, true]} />
          <instancedBufferAttribute attachObject={['attributes', 'opacity']} args={[wispOpacityArray, 1, true]} />
        </planeBufferGeometry>
        <shaderMaterial
          attach="material"
          vertexShader={instancedWispsVert}
          fragmentShader={instancedWispsFrag}
          uniforms={instancedWispUniforms}
          blending={NormalBlending}
          transparent={true}
          depthTest={true}
          depthWrite={false}
          side={DoubleSide}
          ref={wispMaterialRef}
        />
      </instancedMesh>
      <instancedMesh
        ref={instancedColliderMeshRef}
        args={[null, null, MAX_WISP_COUNT]}
        onPointerMove={usePointerEvents ? onPointerMove : null}
        onPointerOut={usePointerEvents ? onPointerOut : null}
        onClick={usePointerEvents ? onClick : null}
        visible={false}
      >
        <circleBufferGeometry attach="geometry" args={[0.05, 8]} />
        <meshBasicMaterial attach="material" />
      </instancedMesh>
      <instancedMesh ref={staticMeshRef} args={[null, null, STATIC_PARTICLE_COUNT]}>
        <planeBufferGeometry attach="geometry" args={[wispSize, wispSize]}>
          <instancedBufferAttribute attachObject={['attributes', 'id']} args={[idArray, 1, false]} />
        </planeBufferGeometry>
        <shaderMaterial
          blending={NormalBlending}
          uniforms={staticWispUniforms}
          vertexShader={staticWispsVert}
          fragmentShader={staticWispsFrag}
          depthTest={true}
          depthWrite={false}
          transparent={true}
          attach="material"
        />
      </instancedMesh>
      <Emojis wisps={wisps} />
    </>
  );
}
