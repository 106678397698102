import React from 'react';
import styled from 'styled-components';
import { Overline2 } from 'common/text';
import IconClose from './close-small.svg';
import { notificationApi } from 'services/NotificationService';
import { colors } from 'styles';
import { trackEvent } from 'utilities/analytics';

const StyledNotification = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background: linear-gradient(76.27deg, #2e47ac 0%, #4c7fda 101.28%);
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
  border-radius: 8px;
  width: 390px;
  max-width: calc(100vw - 32px);
`;

const CloseButton = styled.button`
  border: 0;
  padding: 0;
  display: block;
  outline: 0;
  background: transparent;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  svg {
    display: block;
  }
`;

const StyledContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

const StyledHeadline = styled(Overline2)`
  color: ${colors.highlightCyan};
`;

const StyledBodyContainer = styled.div`
  margin: 4px 0;
  margin-bottom: 0;
  color: white;
`;

const Notification = ({ headline, body, onClick = null }) => {
  const onCloseClick = event => {
    event.stopPropagation();
    notificationApi.getState().closeNotification();
    trackEvent('Notification', 'Close');
  };

  return (
    <StyledNotification onClick={onClick}>
      <CloseButton onClick={onCloseClick}>
        <IconClose />
      </CloseButton>
      <StyledContent>
        <StyledHeadline>{headline}</StyledHeadline>
        <StyledBodyContainer>{body}</StyledBodyContainer>
      </StyledContent>
    </StyledNotification>
  );
};
export default Notification;
