import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import { useWispStore } from 'services/WispService';
import { assembleNameWithTitle } from 'services/UserService';
import PersonImage from 'common/components/PersonImage';
import { mediaMax } from 'common/layout';
import { Body2 } from 'common/text';
import { BG_TRANSPARENT } from 'styles';

const Container = animated(styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: 128px;
  color: white;
  background-color: ${BG_TRANSPARENT};
  border-radius: 8px;
  padding: 8px;
  pointer-events: none;
  ${mediaMax(
    'small',
    css`
      display: none;
    `
  )}
`);

const TextContainer = styled.div`
  font-family: 'PF Highway Sans Pro';
  margin: 0 16px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
`;
const Name = styled(Body2)`
  font-family: 'PF Highway Sans Pro';
`;

const CTA = styled(Body2)`
  opacity: 0.5;
`;

export default function WispHoverDisplay() {
  const activeWisp = useWispStore(state => state.activeWisp);
  const activeWispRoom = useWispStore(state => state.wispRoom);
  const visible = activeWisp !== null;

  const [currentDisplayValues, setCurrentDisplayValues] = useState(null);

  const { progress } = useSpring({
    from: { progress: 0 },
    progress: visible ? 1 : 0,
    config: { tension: 275, friction: 25 },
  });

  useEffect(() => {
    if (activeWisp && visible) {
      const name = activeWisp ? assembleNameWithTitle(activeWisp) : null;
      setCurrentDisplayValues({ ...activeWisp, name });
    }
  }, [activeWisp, visible]);

  const showProfileCtaVisible =
    activeWisp && (activeWispRoom ? activeWisp.roomId === activeWispRoom.id : !activeWisp.roomId);

  return (
    <Container
      visible={visible}
      style={{
        opacity: progress.interpolate({ range: [0, 1], output: [0, 1] }),
        transform: progress
          .interpolate({ range: [0, 1], output: [0.95, 1] })
          .interpolate(progress => `translate(-50%) scale(${progress})`),
      }}
    >
      <PersonImage size={'m'} square user={currentDisplayValues} />
      <TextContainer>
        <Name>{currentDisplayValues && currentDisplayValues.name}</Name>
        {showProfileCtaVisible && <CTA>Profil ansehen</CTA>}
      </TextContainer>
    </Container>
  );
}
