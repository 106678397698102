import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useUserStore } from 'services/UserService';
import { onboardingApi } from 'services/OnboardingService';
import Play from 'components/Play';
import Config from 'components/DevContent/Config';
import Join from 'components/Login/Join';
import { userApi } from 'services/UserService';

const qs = require('query-string');

function LogoutRedirect() {
  const history = useHistory();
  useEffect(() => {
    userApi.getState().logout();
    history.push('/');
  }, []);
  return null;
}

function IntroTestRedirect() {
  const history = useHistory();
  useEffect(() => {
    onboardingApi.getState().resetIntroCompleted();
    history.push('/play/hub');
  }, []);
  return null;
}

function IntroSkipRedirect() {
  const history = useHistory();
  useEffect(() => {
    onboardingApi.getState().completeIntro();
    history.push('/');
  }, []);
  return null;
}

function LoginRedirect() {
  const loginState = useUserStore(state => state.loginState);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [to, setTo] = useState(null);

  useEffect(() => {
    if (loginState === 'LOGGED_IN') {
      userApi.getState().logout();
    }
    const queryParams = qs.parse(window.location.search);
    const { token } = queryParams;
    if (token) {
      const credentials = { token };
      userApi.getState().login({ credentials });
      setIsLoggingIn(true);
    } else {
      userApi.setState({ message: { copy: 'Something went wrong. Please retry.' } });
      setTo('/join');
    }
  }, []);

  useEffect(() => {
    if (isLoggingIn) {
      if (loginState === 'LOGGED_OUT' || loginState === 'LOGGED_IN') {
        setTo('/join');
      }
    }
  }, [loginState]);

  return <>{to ? <Redirect to={to} /> : null}</>;
}

export default function Routes() {
  const isLoggedIn = useUserStore(state => state.loginState === 'LOGGED_IN');

  const sharedRoutes = [
    <Route key="config" path="/config" component={Config} />,
    <Route exact key="login" path="/login" render={props => <LoginRedirect {...props} />} />,
  ];

  const notLoggedInRoutes = [
    ...sharedRoutes,
    <Route key="join" exact path="/join" component={Join} />,
    <Redirect key="redirect" to="/join" />,
  ];

  const playRoutes = [
    ...sharedRoutes,
    <Route key="play" path="/play/:mapId/" component={Play} />,
    <Route key="intro-test" exact path="/intro-test" component={IntroTestRedirect} />,
    <Redirect key="redirect" to="/play/hub" />,
  ];

  return (
    <Router>
      <Switch>
        <Route key="logout" exact path="/logout" component={LogoutRedirect} />,
        <Route key="intro-skip" exact path="/intro-skip" component={IntroSkipRedirect} />,
        {isLoggedIn ? playRoutes : notLoggedInRoutes}
      </Switch>
    </Router>
  );
}
