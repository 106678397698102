import React from 'react';
import { assembleNameWithTitle, userApi, useUserStore } from 'services/UserService';
import { StyledInfo, StyledName } from './styles';
import { Big, SmallRow, Small, Flex, HFlex, StyledProfileMenu, Picture } from '../styles';
import { TransitionItem } from 'styles';
import { ScrollContent } from 'components/Menu/SubMenu/styles';
import { Button } from 'common/components/Button';
import { Badge } from 'common/components/Badge';
import { trackEvent } from 'utilities/analytics';
import PersonImage from 'common/components/PersonImage';

export default function ShowProfile({ setIsEditing }) {
  const user = useUserStore(state => state.user);
  const { speciality, company } = user;

  const fullname = assembleNameWithTitle(user);

  return (
    <ScrollContent>
      <StyledProfileMenu>
        <Flex>
          <Big>
            <HFlex>
              <Small>
                <Picture>
                  <PersonImage size="l" user={user} square />
                </Picture>
              </Small>
              <Big>
                <TransitionItem index={0}>
                  <Badge user={user} />
                </TransitionItem>
                <TransitionItem index={1}>
                  <StyledName>{fullname}</StyledName>
                </TransitionItem>
                <TransitionItem index={2}>
                  <StyledInfo>{speciality}</StyledInfo>
                  <StyledInfo>{company}</StyledInfo>
                </TransitionItem>
              </Big>
            </HFlex>
          </Big>
          <SmallRow>
            <TransitionItem index={3}>
              <Button
                primary
                onClick={() => {
                  setIsEditing(true);
                  trackEvent('Profile', 'Edit');
                }}
              >
                Bearbeiten
              </Button>
            </TransitionItem>
            <TransitionItem index={3}>
              <Button secondary onClick={() => userApi.getState().logout()}>
                Logout
              </Button>
            </TransitionItem>
          </SmallRow>
        </Flex>
      </StyledProfileMenu>
    </ScrollContent>
  );
}
