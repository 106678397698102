import React, { useEffect, useState } from 'react';
import { menuApi, useMenuStore } from 'services/MenuService';
import { usePollStore } from 'services/PollService';
import MenuButton from '../components/MenuButton';
import { MenuButtonContainer } from '../components/MenuButton/styles';
import Indicator from '../components/Indicator';
import TooltipTop from '../components/Tooltips/TooltipTop';
import { Overline2 } from 'common/text';
import IconPoll from '../assets/poll.svg';
import { TooltipContainerDektop, StyledTooltipText, TooltipContainerMobile } from './styles';
import TooltipRight from '../components/Tooltips/TooltipRight';
import { trackEvent } from 'utilities/analytics';

export default function PollButton() {
  const activeMenuComponent = useMenuStore(state => state.activeMenuComponent);
  const pollComponent = useMenuStore(state => state.poll);
  const poll = usePollStore(state => state.poll);
  const didVote = usePollStore(state => state.didVote);
  const { lastPollUpdateTime, lastPollViewTime, open, pollIdDuringInit } = usePollStore();
  const [showTooltip, setShowTooltip] = useState(false);
  const [hovered, setHovered] = useState(false);

  const indicatorVisible = open && poll && lastPollUpdateTime >= lastPollViewTime && !didVote;
  const tooltipVisible =
    open && !activeMenuComponent && !hovered && !didVote && showTooltip && poll && poll.id !== pollIdDuringInit;

  useEffect(() => {
    if (indicatorVisible && activeMenuComponent !== pollComponent) {
      setShowTooltip(true);
    }
  }, [indicatorVisible]);

  const onClick = () => {
    setShowTooltip(false);
    menuApi.getState().setActiveMenuComponent(activeMenuComponent === pollComponent ? null : pollComponent);
    trackEvent('Polling', 'Join');
  };

  const closeTooltip = event => {
    event.stopPropagation();
    setShowTooltip(false);
  };

  return (
    <MenuButtonContainer>
      <MenuButton
        active={activeMenuComponent === pollComponent}
        onClick={onClick}
        handleMouseOver={() => setHovered(true)}
        handleMouseOut={() => setHovered(false)}
        hoverLabel="Umfrage"
      >
        {indicatorVisible && <Indicator />}
        <IconPoll />
      </MenuButton>
      <TooltipContainerDektop onClick={onClick}>
        <TooltipRight visible={tooltipVisible} onClose={closeTooltip}>
          <StyledTooltipText>Eine Umfrage steht bereit.</StyledTooltipText>
          <Overline2>Jetzt abstimmen</Overline2>
        </TooltipRight>
      </TooltipContainerDektop>
      <TooltipContainerMobile onClick={onClick}>
        <TooltipTop visible={tooltipVisible} onClose={closeTooltip}>
          <StyledTooltipText>Eine Umfrage steht bereit.</StyledTooltipText>
          <Overline2>Jetzt abstimmen</Overline2>
        </TooltipTop>
      </TooltipContainerMobile>
    </MenuButtonContainer>
  );
}
