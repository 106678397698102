import React from 'react';
import create from 'utilities/zustand/create';
import debugStore from 'storage/debug';
import EnvironmentDatGui from 'components/Play/Environment/EnvironmentDatGui';

const stack = [
  { label: 'Off', gui: null },
  { label: 'Environment', gui: <EnvironmentDatGui /> },
];
export const [useDatGuiStackStore, useDatGuiStackApi] = create(module, (set, get) => ({
  stack,
  selectedIndex: debugStore.getSelectedDatGuiIndex() % stack.length,
  setSelectedIndex: index => {
    const selectedIndex = index % get().stack.length;
    debugStore.setSelectedDatGuiIndex(selectedIndex);
    set({ selectedIndex });
  },
}));
