import React from 'react';
import { useEmojiStore } from 'services/EmojiService';
import { useUiStore } from 'services/UiService';
import { EmoteButton, emojis } from '../index';

import styled, { css } from 'styled-components';
import { mediaMax } from 'common/layout';
import { zIndex } from 'styles';

const EmoteRow = styled.div`
  z-index: ${zIndex.emoji};
  padding: 8px 16px;
  background-color: white;
  border-radius: 20px 0 0 20px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => (props.show ? 'all' : 'none')};
  ${mediaMax(
    'small',
    css`
      display: none;
    `
  )}
`;

const ChatEmotesDesktop = () => {
  const sendEmoji = useEmojiStore(state => state.sendEmoji);
  const showEmojiBar = useUiStore(state => state.showEmojiBar);

  const onClickEmoji = emoji => {
    sendEmoji(emoji.type);
  };

  return (
    <EmoteRow show={showEmojiBar}>
      {emojis.map((emoji, index) => (
        <EmoteButton
          index={index}
          show={showEmojiBar}
          key={index}
          onClick={() => {
            onClickEmoji(emoji);
          }}
        >
          <img src={emoji.img} alt={''} />
        </EmoteButton>
      ))}
    </EmoteRow>
  );
};

export default ChatEmotesDesktop;
