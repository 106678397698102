import styled from 'styled-components';
import { easings } from 'styles';
import Heart from 'common/assets/Emojis/emoji_heart.png';
import Laugh from 'common/assets/Emojis/emoji_laugh.png';
import Like from 'common/assets/Emojis/emoji_like.png';
import Oh from 'common/assets/Emojis/emoji_oh.png';
import Clap from 'common/assets/Emojis/emoji_clap.png';

export const emojis = [
  { img: Oh, text: '🎉', type: 'oh' },
  { img: Heart, text: '❤', type: 'heart' },
  { img: Like, text: '👍', type: 'like' },
  { img: Clap, text: '👏', type: 'clap' },
  { img: Laugh, text: '😆', type: 'laugh' },
];

export const EmoteButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin: 8px 0;
  cursor: pointer;
  opacity: ${props => (props.show ? 1 : 0)};
  transform: translateY(${props => (props.show ? 0 : '10px')});
  transition: all 0.5s ease-in-out;
  user-select: none;
  transition-delay: ${props => (props.show ? 0.35 - props.index / 12 : 0)}s;

  img {
    transition: transform 0.15s ${easings.easeOutStrong};
    width: 100%;
    height: 100%;
  }

  &:hover {
    img {
      transition-delay: 0s;
      transform: scale(1.2);
    }
  }
`;
