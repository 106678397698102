import { useEffect, useMemo, useRef } from 'react';
import { useThree } from 'react-three-fiber';
import { cameraApi } from 'services/CameraService';
import { Power2, TimelineMax } from 'gsap';
import { useOnboardingStore } from 'services/OnboardingService';

export default function IntroCameraController() {
  const { camera } = useThree();
  const introPlaying = useOnboardingStore(state => state.introPlaying);
  const introContinued = useOnboardingStore(state => state.introContinued);
  const initialPos = useRef();

  useMemo(() => {
    cameraApi.getState().init(camera);
  });

  const timeline = useMemo(() => new TimelineMax(), []);

  useEffect(() => {
    if (!introPlaying) {
      timeline.clear();
      return;
    }

    if (introContinued) {
      return;
    }

    timeline.clear();

    initialPos.current = camera.position.clone();

    camera.position.y = initialPos.current.y + 4.5;
    camera.position.z = initialPos.current.z - 2;
  }, [introPlaying, introContinued]);

  useEffect(() => {
    if (!introContinued) {
      return;
    }

    timeline.clear();

    timeline.to(camera.position, 3, { y: initialPos.current.y, ease: Power2.easeInOut }, 0); // move down

    timeline.to(camera.position, 12, { z: initialPos.current.z }, 1); // move close
  }, [introContinued]);

  return null;
}
