import pinPlay from './assets/pin/pin_play.svg';
import pinLive from './assets/pin/pin_live.svg';
import pinResource from './assets/pin/pin_ressource.svg';

export const CONTENT_UI_TYPE = { FULLSCREEN: 'FULLSCREEN', SPLITSCREEN: 'SPLITSCREEN', HIDDEN: 'HIDDEN' };

function createContentType(params) {
  const defaultParams = {
    uiType: CONTENT_UI_TYPE.HIDDEN,
    pinIcon: pinPlay,
    hideMenu: true,
    centerUIonLargeVP: false,
  };
  return { ...defaultParams, ...params };
}

export const CONTENT_TYPE = {
  LIBRARY_CATEGORY: createContentType({ id: 'LIBRARY_CATEGORY', uiType: CONTENT_UI_TYPE.FULLSCREEN }),
  LIVESTREAM: createContentType({
    id: 'LIVESTREAM',
    pinIcon: pinLive,
    uiType: CONTENT_UI_TYPE.FULLSCREEN,
    show2DEmojis: true,
  }),
  LIBRARY: createContentType({
    id: 'LIBRARY',
    headline: 'Library',
    subline: 'On-Demand Content entdecken',
    pinIcon: pinResource,
    uiType: CONTENT_UI_TYPE.SPLITSCREEN,
  }),
  ARENA: createContentType({
    id: 'ARENA',
    headline: 'MainStage',
    subline: 'Live Präsentationen verfolgen',
    pinIcon: pinPlay,
    uiType: CONTENT_UI_TYPE.HIDDEN,
    hideMenu: false,
  }),
  NETWORKING: createContentType({
    id: 'NETWORKING',
    headline: 'BackStage',
    subline: 'Mit anderen austauschen',
    pinIcon: pinPlay,
    uiType: CONTENT_UI_TYPE.HIDDEN,
    centerUIonLargeVP: true,
  }),
};
