import styled, { css, keyframes } from 'styled-components';
import { colors } from 'styles';
import { H3, Body2 } from 'common/text';

export const StyledChatOverViewContainer = styled.div``;
export const StyledChatOverView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent};
  height: 100%;
  padding-top: 4px;
`;

export const rowInAnimation = keyframes`
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const lastItemInRowStyle = css`
  // border-bottom: 1px solid rgba(221, 221, 221, 0.5);
  padding: 5px 0 10px 0;
  margin-bottom: 5px;
`;

export const firsItemOfTypeStyle = css``;

export const StyledRow = styled.div`
  ${props => props.lastItem && lastItemInRowStyle};
  ${props => props.firstItem && firsItemOfTypeStyle};
  transform: translateY(50px);
  opacity: 0;
  animation: ${rowInAnimation} 0.75s ease-out forwards;
  animation-delay: ${props => (props.animationIndex ? (props.animationIndex - 1) * 50 + 'ms' : 0)};
  animation-timing-function: cubic-bezier(0.17, 0.67, 0, 0.98);
  transition: color 0.15s ease;

  ${props =>
    props.interactive &&
    css`
      cursor: pointer;
      &:hover {
        color: ${colors.accent};
      }
    `};
`;

export const StyledHeadline = styled(H3)`
  margin-top: 28px;
  margin-bottom: 12px;
`;

export const StyledText = styled(Body2)`
  margin-top: 8px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.5);
`;

export const StyledChatContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const YourPrivateChats = styled.div`
  margin-bottom: 8px;
`;
