import { useEffect, useRef, useState } from 'react';

export function useDocumentListener(active, type, fn, ignoreRef = null) {
  const callback = e => {
    const clickTargetInIgnoreRef = ignoreRef !== null && ignoreRef.current.contains(e.target);
    if (!clickTargetInIgnoreRef) fn(e);
  };
  return useEffect(() => {
    if (active) document.addEventListener(type, callback);
    else document.removeEventListener(type, callback);
    return () => {
      document.removeEventListener(type, callback);
    };
  }, [active]);
}

export function useDocumentClickListener(active, fn, ignoreRef = null) {
  useDocumentListener(active, 'click', fn, ignoreRef);
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const useTimer = (every = 1) => {
  const getTime = () => Math.floor(Date.now() / (1000 * every));
  const [now, setNow] = useState(getTime());
  useEffect(() => {
    let x = now;
    const intervalId = setInterval(async () => {
      const newNow = getTime();
      if (x !== newNow) {
        x = newNow;
        setNow(x);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, []);
  return now;
};
