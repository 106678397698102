import React, { useMemo, useState } from 'react';
import { DropdownButton, DropdownContainer, DropdownOption, DropdownOptionList, IconContainer } from './styles';
import ArrowIcon from 'common/assets/Icon_Arrow_Down.svg';
import { useDocumentClickListener } from 'utilities/hooks';
import DeleteIcon from 'common/assets/Icon_Delete.svg';

export default function Dropdown({ options, handleSelect = () => {}, value, clearable, fallbackText }) {
  const [open, setOpen] = useState(false);

  const handleOptionClick = option => {
    setOpen(false);
    handleSelect(option.value);
  };

  const handleClear = event => {
    event.stopPropagation();
    handleSelect(null);
  };

  useDocumentClickListener(open, () => {
    setOpen(false);
  });

  const selectedOption = useMemo(() => {
    return options.find(o => o.value === value);
  }, [options, value]);

  return (
    <DropdownContainer>
      <DropdownButton
        open={open}
        greyedOut={value === null}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {selectedOption ? selectedOption.name : fallbackText || 'none'}
        {clearable && (
          <IconContainer onClick={handleClear}>
            <DeleteIcon />
          </IconContainer>
        )}
        <IconContainer rotate={open ? 180 : 0}>
          <ArrowIcon />
        </IconContainer>
      </DropdownButton>
      <DropdownOptionList open={open} numItems={options.length}>
        {options.map((option, index) => {
          if (option.name.length === 0) return null;
          return (
            <DropdownOption
              key={index}
              onClick={() => {
                handleOptionClick(option);
              }}
              selected={option.value === value}
            >
              {option.name}
            </DropdownOption>
          );
        })}
      </DropdownOptionList>
    </DropdownContainer>
  );
}
