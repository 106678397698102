import React, { useMemo, useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import { AdditiveBlending, Vector3 } from 'three';
import dnaVert from './shader/dna.vert';
import dnaFrag from './shader/dna.frag';

const PARTICLE_COUNT = 32768; // 65536

const camDir = new Vector3();

export default function DNAHelix() {
  const materialRef = useRef();

  const idArray = useMemo(
    () => Float32Array.from(new Array(PARTICLE_COUNT).fill(0).map((v, index) => index / PARTICLE_COUNT)),
    []
  );

  const uniforms = useMemo(
    () => ({
      uCameraAngle: { value: 0.0 },
      uParticleSize: { value: 0.03 },
      uTime: { value: 0.0 },
    }),
    []
  );

  useFrame(context => {
    const elapsedTime = context.clock.getElapsedTime();
    context.camera.getWorldDirection(camDir);
    materialRef.current.uniforms.uCameraAngle.value = Math.atan2(camDir.z, camDir.x);
    materialRef.current.uniforms.uTime.value = (elapsedTime / 240.0) % 1.0;
    materialRef.current.needsUpdate = true;
  });

  return (
    <group rotation={[0.0, -1.3, 0.0]}>
      <instancedMesh args={[null, null, PARTICLE_COUNT]} position={[20.0, -2.5, 0.0]} rotation={[-0.4, 0.0, 0.0]}>
        <planeBufferGeometry attach="geometry" args={[1.0, 1.0]}>
          <instancedBufferAttribute attachObject={['attributes', 'id']} args={[idArray, 1, false]} />
        </planeBufferGeometry>
        <shaderMaterial
          ref={materialRef}
          blending={AdditiveBlending}
          uniforms={uniforms}
          vertexShader={dnaVert}
          fragmentShader={dnaFrag}
          depthTest={true}
          depthWrite={false}
          transparent={true}
          attach="material"
        />
      </instancedMesh>
    </group>
  );
}
