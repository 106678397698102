import React from 'react';
import create from 'utilities/zustand/create';
import moment from 'moment';
import axios from 'axios';
import format from 'utilities/date/format';
import { eventApi } from 'services/EventService';
import { socketApi } from 'services/SocketService';
import { notificationApi } from 'services/NotificationService';
import { contentApi } from 'services/ContentService';
import { userApi } from 'services/UserService';
import AgendaNotification from 'components/AgendaNotification';
import userStore from 'storage/user';

const NOTIFICATION_INTERVAL = 10000;

const startsInLessThanOneMinute = startTime =>
  moment(startTime)
    .subtract(1, 'minutes')
    .isBefore(new Date());

const isAlreadyLiveSinceOneMinute = startTime =>
  moment(startTime)
    .add(1, 'minutes')
    .isBefore(new Date());

let notifications = {};

export const [useTalkStore, talkApi] = create(module, (set, get) => ({
  talks: [], // whole agenda
  groupedTalks: null,
  filters: {
    date: null,
  },
  intervalId: null,
  sentNotifications: {},
  async init() {
    const event = eventApi.getState().event;
    get().setTalks(event.agenda);
    get().updateInterval();
  },

  reset() {
    const { intervalId } = get();
    if (intervalId) {
      clearInterval(intervalId);
    }
    set({ talks: [], groupedTalks: null, filter: { date: null }, intervalId: null });
    notifications = {};
  },

  poll: () => {
    return new Promise((resolve, reject) => {
      const doPoll = async () => {
        const token = userStore.getToken();
        const restUrl = socketApi.getState().getRestUrl('strapi');
        try {
          const { eventId } = userApi.getState();
          const response = await axios.get(`${restUrl}events/${eventId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const event = response.data;
          if (event) {
            get().setTalks(event.agenda);
            get().updateInterval();
            set({ state: 'ok' });
            resolve();
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject();
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          // eslint-disable-next-line prefer-promise-reject-errors
          reject();
        }
      };
      doPoll();
    });
  },

  setTalks: talks => {
    const enrich = t => {
      if (t.startTime) {
        const m = moment(t.startTime);
        t.date = format(m.clone().startOf('day'), 'date');
        t.endTime = m.clone().add(t.duration, 'minutes');
        t.startOfDay = m.clone().startOf('day');
      } else {
        t.date = t.endTime = t.startOfDay = null;
      }
    };
    talks = talks.filter(t => t.startTime != null);
    talks.forEach(t => {
      enrich(t);
    });
    talks = talks.sort((a, b) => {
      return moment(a.startTime).diff(moment(b.startTime));
    });
    const groupedTalks = get().groupTalksByDate(talks);
    set({ groupedTalks, talks });
  },

  updateInterval: () => {
    const { talks } = get();
    let { intervalId } = get();
    if (intervalId) {
      clearInterval(intervalId);
    }
    intervalId = setInterval(() => {
      for (const talk of talks) {
        const { startTime, id } = talk;
        if (notifications[id]) {
          continue;
        }
        if (talk.type === 'exchange' || talk.type === 'feedback') {
          continue;
        }
        if (!isAlreadyLiveSinceOneMinute(startTime) && startsInLessThanOneMinute(startTime)) {
          const showNotification = () => {
            notificationApi.getState().setNotification(<AgendaNotification key={talk.id} talk={talk} />);
          };
          if (talk.type === 'live_talk') {
            const activeContent = contentApi.getState().activeContent;
            const activeContentTypeId = activeContent && activeContent.type.id;
            if (activeContentTypeId !== 'ARENA' && activeContentTypeId !== 'LIVESTREAM') {
              showNotification();
            }
          } else {
            showNotification();
          }
          notifications[id] = talk;
        }
      }
    }, NOTIFICATION_INTERVAL);
    set({ intervalId });
  },

  groupTalksByDate: talks => {
    const talksByDate = {};
    talks.forEach(talk => {
      const { date } = talk;
      if (!date) {
        return;
      }
      if (!talksByDate[date]) {
        talksByDate[date] = [];
      }
      talksByDate[date].push(talk);
    }, []);
    return talksByDate;
  },

  setFilters: filters => {
    set({ filters: { ...get().filters, ...filters } });
  },

  filterTalks: talks => {
    const { filters } = get();
    return talks.filter(talk => {
      if (filters.date === null) {
        return true;
      }
      return talk.startOfDay !== null && talk.startOfDay.isSame(filters.date);
    });
  },
}));
