import React from 'react';
import { StyledPersonComponent } from './styles';
import Accordion from 'common/components/Accordion';
import PersonHeader from './PersonHeader';
import { userApi } from 'services/UserService';
import { trackEvent } from 'utilities/analytics';

export default function PersonComponent({
  user,
  index = 0,
  withAnimation = false,
  isOwnProfile = false,
  isInteractable = true,
  size = null,
  topLabel = null,
  noBackground = false,
}) {
  const clampedAnimIndex = index > 5 ? 5 : index;

  const onClick = isInteractable
    ? () => {
        userApi.getState().openProfileModal(user);
        trackEvent('People', 'View User Profile');
      }
    : null;

  return (
    <StyledPersonComponent
      withAnimation={withAnimation}
      index={clampedAnimIndex}
      noBackround={noBackground || isOwnProfile}
      isInteractable={isInteractable}
      onClick={onClick}
    >
      <Accordion
        noPadding
        childComponents={null}
        headlineComponent={<PersonHeader user={user} size={size} topLabel={topLabel} />}
        autoExpanded={isOwnProfile}
        disabled={true}
      />
    </StyledPersonComponent>
  );
}
