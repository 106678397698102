import React from 'react';
import { contentApi } from 'services/ContentService';
import { Button } from 'common/components/Button';
import { StyledArena } from './styles';
import { StyledBackButtonContainer } from '../styles';
import { CTA } from 'components/Content/CTA/styles';
import { useDocumentListener } from 'utilities/hooks';

export default function Arena({ show }) {
  const onBackClick = () => {
    contentApi.getState().clearActiveContent();
  };

  useDocumentListener(show, 'keyup', e => {
    e.code === 'Escape' && onBackClick();
  });

  return (
    <StyledArena show={show}>
      <CTA show={show}>Klicke auf den Livestream, um ihn im Vollbild zu öffnen.</CTA>
      <StyledBackButtonContainer>
        <Button primaryInverted onClick={onBackClick} mobileSlim>
          Verlassen
        </Button>
      </StyledBackButtonContainer>
    </StyledArena>
  );
}
