import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { Body1, Body2, Overline1 } from 'common/text';
import { mediaMax } from 'common/layout';

export const Headline = styled(Overline1)`
  color: ${colors.highlightCyan};
  margin: 0;
  margin-top: 24px;
  margin-bottom: 2px;
`;

export const Question = styled(Body1)``;

export const Answers = styled.div`
  margin-top: 56px;
  ${mediaMax(
    'small',
    css`
      margin-top: 40px;
    `
  )}
  display: block;
`;

export const Answer = styled.button`
  font-family: 'PF Highway Sans Pro';
  display: block;
  width: 100%;
  margin: 14px 0;
  background: linear-gradient(90.87deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.075) 100%);
  border: 1px solid transparent;
  ${props =>
    props.interactive
      ? css`
          cursor: pointer;
          &:hover {
            border: 1px solid #2870e6;
          }
        `
      : ''}
  border-radius: 8px;
  position: relative;
  padding: 14px;
  padding-left: 16px;
  padding-right: 64px;
  text-align: left;
  outline: 0;
  color: #ffffff;

  font-size: 16px;
  line-height: 22px;

  ${mediaMax(
    'small',
    css`
      font-size: 14px;
      line-height: 140%;
    `
  )}

  .textContainer {
    display: flex;
    flex-direction: row;
    .text {
      position: relative;
      flex-shrink: 1;
      width: 100%;
    }
  }
`;
export const Progress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  background: linear-gradient(76.27deg, #2e47ac 0%, #4c7fda 101.28%);
  width: ${props => props.percentage}%;
  height: 100%;
  border-radius: 8px;
  transition: width 1s;
`;
export const Percentage = styled(Overline1)`
  position: absolute;
  top: 0;
  right: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.highlightCyan};
  ${mediaMax(
    'small',
    css`
      font-size: 12px;
    `
  )}
`;
export const PersonImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: -24px;
  ${mediaMax(
    'small',
    css`
      left: -20px;
    `
  )}
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Letter = styled.div`
  z-index: 1;
  width: 24px;
  height: 24px;
  background: #2870e6;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 16px;
`;

export const CanVoteNotice = styled(Body2)`
  display: ${props => (props.visible ? 'block' : 'none')};
  margin-top: 56px;
`;
