import styled from 'styled-components';
import { colors } from 'styles';

export const Reaction = styled.div`
  position: relative;
  cursor: pointer;
`;
/* eslint-disable */
export const Reactions = styled.div`
  display: flex;
  flex-direction: row;

  img {
    vertical-align: middle;
    height: 18px;
    margin-top: 0px;
  }

  span {
    vertical-align: middle;
    font-family: PF Highway Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    padding-left: 2px;
    padding-right: 6px;
    color: #ffffff;
  }

  ${Reaction}:not(:first-child) {
    margin-left: 4px;
  }
`;
/* eslint-enable */
export const ReactionBubble = styled.div`
  position: relative;
  cursor: pointer;
  color: #000;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  border: 1px solid ${props => (props.showSelfOutline ? colors.interactiveBlue : 'transparent')};
  padding: 2px;

  & > svg {
    stroke: #fff;
    opacity: 0.5;
  }
`;

export const ReactionImage = styled.img`
  pointer-events: none;
`;

// speech nose based on https://codepen.io/RajRajeshDn/pen/NpewXv
export const NewReactionOverlay = styled.div`
  background-color: #fff;
  border: 1px solid ${colors.background.base};
  border-radius: 60px;
  position: absolute;
  z-index: 1;
  top: -18px;
  ${props => (props.floatRight ? 'right:' : 'left:')} -17px;
  padding: 12px;
  display: flex;
  flex-direction: row;

  ${Reaction}:not(:first-child) {
    margin-left: 12px;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;
