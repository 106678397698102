import React, { useState } from 'react';
import { menuApi } from 'services/MenuService';
import EditProfile from './EditProfile';
import ShowProfile from './ShowProfile';
import { StyledSubMenuHeader, StyledSubMenuHeadline } from '../../styles';
import CloseButton from 'common/components/CloseButton';

export default function Profile() {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      <StyledSubMenuHeader>
        <CloseButton
          onClick={() => {
            menuApi.getState().setActiveMenuComponent(null);
          }}
        />
        <StyledSubMenuHeadline>Profil</StyledSubMenuHeadline>
      </StyledSubMenuHeader>
      {isEditing ? <EditProfile setIsEditing={setIsEditing} /> : <ShowProfile setIsEditing={setIsEditing} />}
    </>
  );
}
