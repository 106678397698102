import React from 'react';
import { Container, TriangleLeft } from './styles';
import { Content } from '../styles';
import { CloseButtonContainer } from '../styles';
import Close from '../close-small.svg';

export default function TooltipRight({ children, visible, onClose }) {
  return (
    <Container visible={visible}>
      <CloseButtonContainer onClick={onClose}>
        <Close />
      </CloseButtonContainer>
      <Content>{children}</Content>
      <TriangleLeft />
    </Container>
  );
}
