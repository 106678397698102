import React from 'react';
import ChatSelector from 'components/Chat/ChatSelector';
import Person from 'common/components/Person';
import { useUserStore } from 'services/UserService';
import { chatApi } from 'services/ChatService';
import {
  StyledChatOverView,
  StyledRow,
  StyledHeadline,
  StyledText,
  StyledChatContainer,
  YourPrivateChats,
} from './styles';
import { StyledUser, StyledFullname, StyledSpeciality } from 'common/components/PersonComponent/PersonHeader/styles';
import { ScrollContent } from 'components/Menu/SubMenu/styles';

const visibleChatCount = 14;

export default function ChatOverview({ chats }) {
  const user = useUserStore(state => state.user);
  const selfId = user.id;
  const districtChats = chats.filter(c => c.type === 'district');
  const privateChats = chats.filter(c => c.type === 'private');
  const limitedPrivateChats = privateChats.slice(0, visibleChatCount);

  for (let i = visibleChatCount; i < privateChats.length; ++i) {
    if (!privateChats[i].seen) {
      limitedPrivateChats.push(privateChats[i]);
    }
  }

  const openChat = c => {
    chatApi.getState().enter(c);
  };

  let animationIndex = 0;

  return (
    <ScrollContent>
      <StyledChatContainer>
        <StyledChatOverView justifyContent={limitedPrivateChats.length === 0 ? 'space-between' : 'flex-start'}>
          {districtChats.map((c, i) => {
            animationIndex++;
            return (
              <StyledRow key={i} animationIndex={animationIndex} interactive>
                <ChatSelector key={i} chat={c} />
              </StyledRow>
            );
          })}
          <YourPrivateChats>
            <StyledRow animationIndex={++animationIndex}>
              <StyledHeadline>Private Chats</StyledHeadline>
              {limitedPrivateChats.length === 0 && (
                <StyledText>Starte einen neuen Chat, um dich mit anderen Teilnehmern auszutauschen.</StyledText>
              )}
            </StyledRow>
          </YourPrivateChats>
          {limitedPrivateChats.length !== 0 && (
            <div>
              {limitedPrivateChats.map((c, i) => {
                animationIndex++;
                const firstItem = i === 0;
                if (c.users.length === 2) {
                  const otherUser = c.users.find(u => u.id !== selfId);
                  return (
                    <StyledRow
                      firstItem={firstItem}
                      key={i}
                      onClick={() => openChat(c)}
                      animationIndex={animationIndex}
                      interactive
                    >
                      <Person
                        verticalCenter
                        chatNotice={!c.seen}
                        user={otherUser}
                        infoComponents={[
                          <StyledUser key={i}>
                            <StyledFullname>{c.name}</StyledFullname>
                            {otherUser.speciality && <StyledSpeciality>{otherUser.speciality}</StyledSpeciality>}
                          </StyledUser>,
                        ]}
                      />
                    </StyledRow>
                  );
                } else {
                  return (
                    <StyledRow firstItem={firstItem} key={i} animationIndex={animationIndex} interactive>
                      <ChatSelector key={i} chat={c} isGroupChat />
                    </StyledRow>
                  );
                }
              })}
            </div>
          )}
        </StyledChatOverView>
      </StyledChatContainer>
    </ScrollContent>
  );
}
