import styled from 'styled-components';
import { colors } from 'styles';

export const StyledIndicatorContainer = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
`;

export const StyledIndicator = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 2px;
  background: ${colors.alertRed};
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;
