import styled from 'styled-components';
import { TransitionComponent } from 'components/Content/styles';

export const StyledLibrary = styled(TransitionComponent)`
  display: ${props => (props.show ? 'inherit' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 50px;
  pointer-events: none;
  * {
    pointer-events: all;
  }
`;
