import styled, { css } from 'styled-components';
import { BG_TRANSPARENT, zIndex } from 'styles';
import { mediaMax } from 'common/layout';
import { Body2, H2 } from 'common/text';

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.modalAndNotifications};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${BG_TRANSPARENT};
  pointer-events: all;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.5s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 25px 40px 48px 40px;
  ${mediaMax(
    'small',
    css`
      padding: 25px 16px 40px;
      margin: 24px;
    `
  )}
  background: linear-gradient(76.27deg, #2e47ac 0%, #4c7fda 101.28%);
  border-radius: 8px;
  width: 480px;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  color: white;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledInfo = styled(Body2)`
  user-select: text;
  cursor: text;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
`;

export const StyledTitle = styled(H2)`
  user-select: text;
  cursor: text;
  margin: 16px 0;
`;

export const StyledButtonContainer = styled.div`
  margin-top: 24px;
`;
