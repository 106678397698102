import styled, { css } from 'styled-components';
import { mediaMax } from 'common/layout';

export const Body1 = styled.p`
  font-family: 'PF Highway Sans Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;

  ${mediaMax(
    'small',
    css`
      font-size: 16px;
    `
  )}
`;

export const Body2 = styled.p`
  font-family: 'PF Highway Sans Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;

  ${mediaMax(
    'small',
    css`
      font-size: 14px;
    `
  )}
`;

export const Overline1 = styled.p`
  font-family: 'PF Highway Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  margin: 0;
  ${mediaMax(
    'small',
    css`
      font-size: 12px;
    `
  )}
`;

export const Overline2 = styled.p`
  font-family: 'PF Highway Sans Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
`;

export const Caption1 = styled.p`
  font-family: 'PF Highway Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
  ${mediaMax(
    'small',
    css`
      font-size: 12px;
    `
  )}
`;

export const Caption2 = styled.p`
  font-family: 'PF Highway Sans Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;

export const H1 = styled.h1`
  font-family: 'PF Highway Sans Pro';
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;

  ${mediaMax(
    'small',
    css`
      font-size: 28px;
    `
  )}
`;

export const H2 = styled.h2`
  font-family: 'PF Highway Sans Pro';
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
  ${mediaMax(
    'small',
    css`
      font-size: 20px;
    `
  )}
`;

export const H3 = styled.h2`
  font-family: 'PF Highway Sans Pro';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
  ${mediaMax(
    'small',
    css`
      font-size: 18px;
    `
  )}
`;

export const H4 = styled.h4`
  font-family: 'PF Highway Sans Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  ${mediaMax(
    'small',
    css`
      font-size: 16px;
    `
  )}
`;

export const H5 = styled.h5`
  font-family: 'PF Highway Sans Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
`;
