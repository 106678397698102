import styled, { css } from 'styled-components';
import { BG_SOLID, zIndex } from 'styles';
import { mediaMax } from 'common/layout';
import { menuWidth, menuHeight } from 'components/Menu/styles';

export const StyledFullscreenContentContainer = styled.div`
  z-index: ${zIndex.fullscreenContent};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${props => (props.fullscreenActive ? 'all' : 'none')};
  display: ${props => (props.fullscreenActive ? 'block' : 'none')};
  user-select: none;
  will-change: width;
  padding-left: ${menuWidth};
  background: ${BG_SOLID};

  ${props =>
    mediaMax(
      'small',
      css`
        padding-left: 0;
        height: calc(100% - ${props.isFullHeight ? 0 : menuHeight});
      `
    )};
`;

export const StyledContent = styled.div`
  margin-top: 64px;
  height: calc(100% - 64px);
  user-select: none;
  pointer-events: all;
`;

export const StyledTopBar = styled.div`
  position: absolute;
  top: 0;
  height: 64px;
  width: calc(100% - 64px);
  background-color: ${BG_SOLID};

  ${mediaMax(
    'small',
    css`
      width: 100%;
    `
  )}
`;
