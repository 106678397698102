import React, { useEffect, useRef } from 'react';
import { useEventStore } from 'services/EventService';
import { choosePeopleSearchText } from 'services/PeopleService';
import { peopleApi, usePeopleStore } from 'services/PeopleService';
import { ScrollContent } from 'components/Menu/SubMenu/styles';
import { StyledPeople } from './styles';
import { menuApi } from 'services/MenuService';
import PersonComponent from 'common/components/PersonComponent';
import { Input } from 'common/components/Input';
import CloseButton from 'common/components/CloseButton';
import { StyledSubMenuHeader, StyledSubMenuHeadline } from '../../styles';
import { Body2 } from 'common/text';

export default function People() {
  const event = useEventStore(state => state.event);
  const people = usePeopleStore(state => state.people);
  const search = usePeopleStore(state => state.search);
  const pending = usePeopleStore(state => state.pending);
  const input = useRef();

  useEffect(() => {
    peopleApi.getState().setSearchValue('');
  }, []);

  const placeholder = choosePeopleSearchText(event);

  const closeKeyboard = e => {
    e.preventDefault();
    if (input.current) {
      input.current.blur();
    }
  };

  return (
    <>
      <StyledSubMenuHeader style={{ marginBottom: '8px' }}>
        <CloseButton
          onClick={() => {
            menuApi.getState().setActiveMenuComponent(null);
          }}
        />
        <StyledSubMenuHeadline>Teilnehmer</StyledSubMenuHeadline>
        <form onSubmit={closeKeyboard}>
          <Input
            forwardRef={input}
            onChange={e => peopleApi.getState().setSearchValue(e.target.value)}
            value={search}
            placeholderColor={'white'}
            placeholder={placeholder}
          />
        </form>
      </StyledSubMenuHeader>
      <ScrollContent>
        <StyledPeople>
          {people.map((user, i) => (
            <PersonComponent withAnimation key={i} index={i} user={user} />
          ))}
          {!pending && people.length === 0 && <Body2>Unter diesem Begriff können wir leider nichts finden.</Body2>}
        </StyledPeople>
      </ScrollContent>
    </>
  );
}
