import React, { useEffect, useRef, useMemo } from 'react';
import { useFrame } from 'react-three-fiber';
import { MathUtils, NormalBlending } from 'three';
import * as THREE from 'three';
import { useEmojiStore } from 'services/EmojiService';
import { useOnboardingStore } from 'services/OnboardingService';
import { TweenMax, Power2, Sine, Elastic } from 'gsap';
import heart from 'common/assets/Emojis/emoji_heart.png';
import like from 'common/assets/Emojis/emoji_like.png';
import laugh from 'common/assets/Emojis/emoji_laugh.png';
import oh from 'common/assets/Emojis/emoji_oh.png';
import clap from 'common/assets/Emojis/emoji_clap.png';
import { instancedBillboardVertex, instancedBillboardFragment } from './glsl/instancedBillboard.glsl';
import { useActivityStore } from 'services/ActivityService';
import { useWispStore } from 'services/WispService';

const MAX_EMOJI_COUNT = 1000;

const EMOJI_CONFIG = {
  emojis: [
    {
      name: 'heart',
      asset: heart,
    },
    {
      name: 'like',
      asset: like,
    },
    {
      name: 'laugh',
      asset: laugh,
    },
    {
      name: 'oh',
      asset: oh,
    },
    {
      name: 'clap',
      asset: clap,
    },
  ],
};

const renderSpriteSheet = () => {
  const tileNum = 2 ** Math.ceil(Math.log2(EMOJI_CONFIG.emojis.length)); // ensure power of 2 for mipmapping
  EMOJI_CONFIG.tileNum = tileNum;

  const canvas = document.createElement('canvas');
  // document.body.appendChild(canvas);
  canvas.width = tileNum * 64;
  canvas.height = 64;
  const context = canvas.getContext('2d');
  context.fillStyle = 'rgba(0,0,0,0)';
  context.clearRect(0, 0, canvas.width, canvas.height);

  const texture = new THREE.CanvasTexture(canvas);
  texture.magFilter = THREE.NearestFilter;
  texture.minFilter = THREE.NearestFilter;
  texture.anisotropy = 2;

  const loader = new THREE.ImageLoader();
  EMOJI_CONFIG.emojis.forEach((emoji, emojiIndex) => {
    emoji.spriteIndex = emojiIndex;
    loader.load(emoji.asset, image => {
      const { width, height } = image;
      if (width > 64 || height > 64) {
        // eslint-disable-next-line no-console
        console.error('Emoji textures must not be larger than 64x64 pixels!');
        return;
      }
      const offsetx = Math.floor((64 - width) / 2);
      const offsety = Math.floor((64 - height) / 2);
      context.drawImage(image, emojiIndex * 64 + offsetx, offsety);
      texture.needsUpdate = true;
    });
  });
  return texture;
};

export default function Emojis({ wisps }) {
  const spriteTexture = useMemo(renderSpriteSheet, []);
  const instancedMeshRef = useRef();
  const uniforms = useMemo(() => ({ tileNum: { value: EMOJI_CONFIG.tileNum }, map: { value: spriteTexture } }), []);
  const positionArray = useMemo(() => Float32Array.from(new Array(MAX_EMOJI_COUNT * 3).fill(0.0)), []);
  const emojiIdArray = useMemo(() => Uint8Array.from(new Array(MAX_EMOJI_COUNT * 1).fill(0)), []);
  const paramArray = useMemo(() => Float32Array.from(new Array(MAX_EMOJI_COUNT * 3).fill(0)), []);

  const introCompleted = useOnboardingStore(state => state.introCompleted);
  const wispRoom = useWispStore(state => state.wispRoom);

  const emojis = useRef([]);
  const lastEmoji = useEmojiStore(state => state.emoji);
  const ownActivity = useActivityStore(state => state.activity);

  let scale = 1;
  if (ownActivity === 'NETWORKING' || ownActivity === 'ARENA' || ownActivity === 'LIVESTREAM') {
    if (ownActivity === 'NETWORKING' && wispRoom) {
      scale = 0.25;
    } else {
      scale = 0.5;
    }
  }

  const scaleRef = useRef(1.0);

  useEffect(() => {
    if (document.visibilityState === 'hidden' || !lastEmoji || !introCompleted) {
      return;
    }
    const wisp = wisps.find(w => w.id === lastEmoji.userId);
    const emojiConfig = EMOJI_CONFIG.emojis.find(e => e.name === lastEmoji.emoji);

    if (wisp && wisp.state && emojiConfig) {
      const targetScale = wisp.activity === 'ARENA' || wisp.activity === 'LIVESTREAM' ? 0.5 : 1.0;

      const emoji = {
        birthtime: Date.now(),
        spriteIndex: emojiConfig.spriteIndex,
        spawnPosition: wisp.state.position.clone(),
        position: wisp.state.position.clone(),
        scale: 0,
        alpha: 0,
        rotation: 0,
        progress: 0,
        active: true,
      };

      const duration = 2 + Math.random();
      const direction = Math.random() > 0.5 ? -1 : 1;
      const yDestination = 1 + Math.random();
      TweenMax.fromTo(
        emoji,
        1,
        { scale: 0 },
        {
          scale: 1.0, // targetScale
          ease: Elastic.easeOut.config(1.5, 0.5),
        }
      );
      TweenMax.fromTo(
        emoji,
        0.25,
        { alpha: 0 },
        {
          alpha: 1,
          ease: Power2.easeIn,
        }
      );
      TweenMax.to(emoji, 0.25, {
        alpha: 0,
        delay: duration - 0.25,
        ease: Power2.easeIn,
      });

      TweenMax.to(emoji, duration, {
        progress: 1,
        onUpdate: () => {
          const wave = Math.sin(6 * emoji.progress);
          const wave2 = Math.cos(6 * emoji.progress);
          emoji.rotation = -wave * 0.25;
          emoji.position.copy(emoji.spawnPosition);
          emoji.position.x += direction * wave * 0.025 * targetScale;
          emoji.position.z += direction * wave2 * 0.025 * targetScale;
          emoji.position.y += emoji.progress * yDestination * 0.1 * targetScale;
        },
        onComplete: () => {
          emoji.active = false;
        },
        ease: Sine.easeOut,
      });

      emojis.current.push(emoji);
    }
  }, [lastEmoji]);

  useFrame(() => {
    emojis.current = emojis.current.filter(e => e.active);

    const emojiCount = Math.min(MAX_EMOJI_COUNT, emojis.current.length);

    const translationBuffer = instancedMeshRef.current.geometry.attributes.translation;
    const spriteIdBuffer = instancedMeshRef.current.geometry.attributes.emojiId;
    const paramBuffer = instancedMeshRef.current.geometry.attributes.params;

    scaleRef.current = MathUtils.lerp(scaleRef.current, scale, 0.02);

    for (let i = 0; i < emojiCount; i++) {
      const emoji = emojis.current[i];
      const { position, spriteIndex, alpha, scale, rotation } = emoji;
      translationBuffer.array[i * 3 + 0] = position.x;
      translationBuffer.array[i * 3 + 1] = position.y;
      translationBuffer.array[i * 3 + 2] = position.z;
      paramBuffer.array[i * 3 + 0] = scale * scaleRef.current;
      paramBuffer.array[i * 3 + 1] = rotation;
      paramBuffer.array[i * 3 + 2] = alpha;
      spriteIdBuffer.array[i] = spriteIndex;
    }
    translationBuffer.needsUpdate = true;
    translationBuffer.updateRange = { offset: 0, count: emojiCount * 3 };
    paramBuffer.needsUpdate = true;
    paramBuffer.updateRange = { offset: 0, count: emojiCount * 3 };
    spriteIdBuffer.needsUpdate = true;
    spriteIdBuffer.updateRange = { offset: 0, count: emojiCount };

    instancedMeshRef.current.count = emojiCount;
  });

  return (
    <instancedMesh ref={instancedMeshRef} args={[null, null, MAX_EMOJI_COUNT]} renderOrder={1000}>
      <planeBufferGeometry attach="geometry" args={[0.075, 0.075]}>
        <instancedBufferAttribute attachObject={['attributes', 'translation']} args={[positionArray, 3]} />
        <instancedBufferAttribute attachObject={['attributes', 'emojiId']} args={[emojiIdArray, 1]} />
        <instancedBufferAttribute attachObject={['attributes', 'params']} args={[paramArray, 3]} />
      </planeBufferGeometry>
      <rawShaderMaterial
        attach="material"
        uniforms={uniforms}
        transparent={true}
        vertexShader={instancedBillboardVertex}
        fragmentShader={instancedBillboardFragment}
        depthTest={true}
        depthWrite={false}
        blending={NormalBlending}
      />
    </instancedMesh>
  );
}
