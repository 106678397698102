import React from 'react';
import { Container, Img, Headline, Subline, Footer, LinkButton } from './styles';
import { safeSelectUrlFromMedia } from 'utilities/media';
import OpenLinkIcon from 'common/assets/Icon_OpenLink.svg';
import { trackEvent } from 'utilities/analytics';
import { libraryApi } from 'services/LibraryService';

export default function LibraryResource({ resource }) {
  const { teaser, headline, subline } = resource;
  const link = resource.link ? resource.link : resource.media && resource.media.url;
  const onClick = () => {
    libraryApi.getState().track(resource.headline);
    window.open(link, '_blank');
    trackEvent('Library', 'Open Resource', resource.headline);
  };

  const teaserUrl = safeSelectUrlFromMedia(teaser, 'small');

  return (
    <Container>
      {teaserUrl && <Img src={teaserUrl} alt="" />}
      <Headline>{headline}</Headline>
      <Subline>{subline}</Subline>
      <Footer>
        {link && (
          <LinkButton onClick={onClick}>
            <span>Öffnen</span>
            <OpenLinkIcon />
          </LinkButton>
        )}
      </Footer>
    </Container>
  );
}
