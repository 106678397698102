import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled.form`
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

export const MessageIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  float: left;
  align-self: flex-start;
`;
