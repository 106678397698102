import create from 'utilities/zustand/create';
import debounce from 'utilities/debounce';
import { prefixEventListener } from 'utilities/prefixed-events';
import { breakPoints } from 'common/layout';

export const [useWindowStore, windowApi] = create(module, set => ({
  width: 0,
  height: 0,
  hover: false,
  focus: true,
  supportsTouch: 'ontouchstart' in window || navigator.msMaxTouchPoints,
  isMobileBreakpoint: null,

  setHover: hover => {
    set({ hover });
  },

  init: () => {
    const onResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      let breakpoint = '';
      for (const bpKey in breakPoints) {
        const bp = breakPoints[bpKey];
        if (bp.min <= width && bp.max >= width) {
          breakpoint = bpKey;
          break;
        }
      }
      const isMobileBreakpoint = breakpoint === 'small' || breakpoint === 'extraSmall';

      set({ width, height, isMobileBreakpoint });
    };

    const onFocus = () => {
      set({ focus: true });
    };

    const onBlur = () => {
      set({ focus: false });
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    prefixEventListener(window, 'resize', debounce(onResize, 50));
    onResize();
  },
}));
