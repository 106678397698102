import { useEffect } from 'react';
import { useSceneStore } from 'services/SceneService';
import { useLoader } from 'react-three-fiber';
import { Color, LinearFilter, LinearMipMapLinearFilter, RepeatWrapping, sRGBEncoding, TextureLoader } from 'three';
import { useEnvironmentStore } from 'components/Play/Environment/store';

export default function Material({ object }) {
  const init = useSceneStore(state => state.init);
  const renderable = useSceneStore(state => state.scene && state.scene.renderable);
  const isLoaded = useSceneStore(state => state.isLoaded);
  const envMaps = useEnvironmentStore(state => state.envMaps);

  const [triangles, colorGloss] = useLoader(TextureLoader, [
    'https://d38oh1si3euxck.cloudfront.net/assets/hub/textures/triangle_texture_512.jpg',
    'https://d38oh1si3euxck.cloudfront.net/assets/hub/textures/colorGloss_kl.png',
  ]);

  const lmPath = 'https://d38oh1si3euxck.cloudfront.net/assets/hub/lightmaps/9/';
  const [lm01, lm02, lm03, lm04] = useLoader(TextureLoader, [
    lmPath + 'LM01_LibraryInner_final_1k.jpg',
    lmPath + 'LM02_LibraryOuter_final_512.jpg',
    lmPath + 'LM03_Arena_final_1k.jpg',
    lmPath + 'LM04_Networking_final_1k.jpg',
  ]);

  useEffect(() => {
    if (!init || !renderable) return;

    function configureTexture(lightMap, flip = false) {
      lightMap.flipY = flip;
      lightMap.generateMipmaps = true;
      lightMap.anisotropy = 16;
      lightMap.wrapT = lightMap.wrapS = RepeatWrapping;
      lightMap.minFilter = LinearMipMapLinearFilter;
      lightMap.magFilter = LinearFilter;
      lightMap.encoding = sRGBEncoding;
    }

    configureTexture(lm01);
    configureTexture(lm02);
    configureTexture(lm03);
    configureTexture(lm04);
    configureTexture(triangles);
    configureTexture(colorGloss);

    const getLightMap = name => {
      if (name.includes('LM01')) return lm01;
      else if (name.includes('LM02')) return lm02;
      else if (name.includes('LM03')) return lm03;
      else if (name.includes('LM04')) return lm04;
      else return null;
    };

    object.traverse(o => {
      const remove = o.name.includes('content_mesh');
      if (o.orgMaterial) {
        const mat = o.orgMaterial.clone();
        // console.log(mat.name);

        const transparent = ['Arena_ReflectOuterBlue01', 'Arena_ReflectOuterBlue02', 'Arena_ReflectOuterBlue03'];
        const reflective = [
          'Arena_ReflectInnerBlue01',
          'Arena_ReflectInnerBlue02',
          'Arena_ReflectInnerBlue03',
          'Arena_ScreenBG',
        ];

        const nonLightMap = [];
        nonLightMap.push(...transparent);
        nonLightMap.push(...reflective);

        const useLightMap =
          o.geometry.attributes.uv2 && !nonLightMap.includes(o.orgMaterial.name) && !nonLightMap.includes(o.name);
        if (useLightMap) {
          if (getLightMap(mat.name)) mat.lightMap = getLightMap(mat.name);
        }

        if (
          (mat.name.includes('Arena') && reflective.includes(mat.name)) ||
          (mat.name.includes('Arena') && transparent.includes(mat.name))
        ) {
          mat.color = new Color(0xffffff);
          mat.envMap = envMaps.arena;
          mat.envMapIntensity = 1.0;
          mat.metalness = 0.25;
          mat.roughness = 0.05;
          mat.opacity = 0.25;
          mat.transparent = true;
          if (transparent.includes(mat.name)) {
            mat.depthTest = true;
            o.renderOrder = 100;
          }
          mat.onBeforeCompile = shader => {
            shader.fragmentShader = shader.fragmentShader.replace(
              'gl_FragColor = vec4( outgoingLight, diffuseColor.a );',
              `
              float fresnel = 1.0 - dot(normal, normalize(vViewPosition));
              fresnel = 0.02 + 0.98 * pow(max(0.0, fresnel), 2.0);
              float alpha = diffuseColor.a;
              alpha += fresnel;
              alpha = min(1.0, alpha);
              vec3 resultColor = outgoingLight;
              resultColor += vec3(fresnel) * vec3(0.35, 0.55, 1.0);              
              gl_FragColor = vec4(resultColor , alpha );
              `
            );
          };
        } else {
          if (mat.name.includes('Library')) {
            mat.envMap = envMaps.library;
            mat.envMapIntensity = 1;
            if (mat.name === 'LM01_Library_Inner_ReflectiveMat') {
              mat.roughness = 0.8;
              mat.roughness = 0.25;
            }
            if (['LM01_Library_Inner_DarkBlueMat', 'LM02_Library_Outer_DarkBlueMat'].includes(mat.name)) {
              mat.roughness = 0.8;
              mat.roughness = 0.25;
            }
          }

          if (['Networking_lightstripsWhite'].includes(mat.name)) {
            mat.emissive = new Color(0x454545);
          }

          if (['LibraryEmissiveYellow_mat'].includes(mat.name)) {
            mat.emissive = new Color('#cdb905');
          }

          if (!reflective.includes(mat.name) || !transparent.includes(mat.name))
            mat.onBeforeCompile = shader => {
              shader.fragmentShader = shader.fragmentShader.replace(
                '#include <common>',
                `
              uniform float hover;
            	#include <common>
            `
              );
              if (mat.name.toLowerCase().includes('emissive') || mat.name.toLowerCase().includes('lightstrips')) {
                shader.fragmentShader = shader.fragmentShader.replace(
                  'vec3 totalEmissiveRadiance = emissive;',
                  'vec3 totalEmissiveRadiance = emissive * (1.0 - hover) + vec3(2.0) * (hover);'
                );
              }
              shader.fragmentShader = shader.fragmentShader.replace(
                'gl_FragColor = vec4( outgoingLight, diffuseColor.a );',
                `
            float fog = vViewPosition.z * 0.075;
            fog = pow(fog, 3.0);
            fog = min(max(fog, 0.0), 0.5);
            fog = 1.0 - fog;
            outgoingLight = outgoingLight * fog * vec3(0.95, 1.0, 1.15) * (1.0 - hover) + outgoingLight * hover;
            outgoingLight += totalEmissiveRadiance;
            gl_FragColor = vec4( outgoingLight, diffuseColor.a );
            `
              );
              shader.uniforms['hover'] = mat.userData.hover;
              shader.uniformsNeedUpdate = true;
            };

          mat.userData = { hover: { value: 0 } };

          if (mat.name.includes('Networking_floor')) {
            mat.envMap = envMaps.arena;
            mat.envMapIntensity = 1.0;
            mat.transparent = true;
            mat.opacity = 0.4;
            mat.alphaMap = triangles;
            mat.metalness = 0.01;
            mat.metalnessMap = triangles;
            mat.roughness = 0.1;
            mat.roughnessMap = triangles;
            mat.onBeforeCompile = shader => {
              shader.fragmentShader = shader.fragmentShader.replace(
                'gl_FragColor = vec4( outgoingLight, diffuseColor.a );',
                `
              float fresnel = 1.0 - dot(normal, normalize(vViewPosition));
              fresnel = 0.02 + 0.98 * pow(max(0.0, fresnel), 2.0);
              float alpha = diffuseColor.a;
              alpha += fresnel;
              alpha = min(1.0, alpha);
              vec3 resultColor = outgoingLight;
              resultColor += vec3(fresnel) * vec3(0.35, 0.55, 1.0);
              gl_FragColor = vec4(resultColor , alpha );
              `
              );
              shader.fragmentShader = shader.fragmentShader.replace(
                '#include <alphamap_fragment>',
                `
                #ifdef USE_ALPHAMAP
                
                  diffuseColor.a *= (1.0 - texture2D( alphaMap, vUv ).g);
                
                #endif
              `
              );
            };
          }
        }

        o.material = mat;

        if (remove) o.material.visible = false;
      }
    });
  }, [init, renderable, isLoaded, envMaps]);
  return null;
}
