import create from 'utilities/zustand/create';

export const [useControlsStore, controlsApi] = create(module, (set, get) => ({
  cursorPosition: { x: 0, y: 0 },
  cursorDown: false,
  domSize: { w: 1024, h: 1024 },
  domElement: null,
  active: true,
  setActive: active => set({ active }),
  eventHandler: {
    updateDomElement: domElement => {
      const domSize = { w: domElement.clientWidth, h: domElement.clientHeight };
      set({ domElement: domElement, domSize: domSize });
    },
    beginFrame: () => {},
    onMouseMove: event => {
      const { domSize, active } = get();
      if (!active) {
        return;
      }
      const cursorPosition = { x: event.clientX / domSize.w, y: event.clientY / domSize.h };
      set({ cursorPosition });
    },
    onMouseDown: event => {
      const { domSize, active } = get();
      if (!active) {
        return;
      }
      const cursorDown = true;
      const cursorPosition = { x: event.clientX / domSize.w, y: event.clientY / domSize.h };
      set({ cursorDown, cursorPosition });
    },
    onMouseUp: () => {
      const { active } = get();
      if (!active) {
        return;
      }
      const cursorDown = false;
      set({ cursorDown, cursorUp: true });
    },
    onMouseLeave: () => {
      const { active } = get();
      if (!active) {
        return;
      }
      const cursorDown = false;
      set({ cursorDown });
    },
    onTouchMove: event => {
      const { domElement, active } = get();
      if (!active) {
        return;
      }
      const touch = Object.values(event.touches).find(t => t.target === domElement);
      if (!touch) {
        return;
      }
      const { domSize } = get();
      const cursorPosition = { x: touch.clientX / domSize.w, y: touch.clientY / domSize.h };
      set({ cursorPosition });
    },
    onTouchStart: event => {
      const { domElement, active } = get();
      if (!active) {
        return;
      }
      const touch = Object.values(event.touches).find(t => t.target === domElement);
      if (!touch) {
        return;
      }
      const { domSize } = get();
      const cursorDown = true;
      const cursorPosition = { x: touch.clientX / domSize.w, y: touch.clientY / domSize.h };
      set({ cursorDown, cursorPosition });
    },
    onTouchEnd: event => {
      const { domElement, active } = get();
      if (!active) {
        return;
      }
      const touch = Object.values(event.touches).find(t => t.target === domElement);
      if (touch) {
        return;
      }
      const cursorDown = false;
      set({ cursorDown, cursorUp: true });
    },
  },
}));
