import React from 'react';
import Hub from 'components/Play/Hub';

const config = [
  {
    room: 'hub',
    title: 'Hub',
    isDevContent: true,
    material: 'IpsenMaterial',
    scene: <Hub url={'https://d38oh1si3euxck.cloudfront.net/assets/hub/glb/Ipsen_v09_1_compressed.glb'} />,
  },
];

export default config.map(c => {
  return { ...c };
});
