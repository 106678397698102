import create from 'utilities/zustand/create';

export const [useModalService, modalApi] = create(module, (set /*, get*/) => ({
  modal: null,

  setModal: modal => {
    set({ modal });
  },

  closeModal: () => {
    set({ modal: null });
  },
}));
