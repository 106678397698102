import React from 'react';
import { MessageIcon } from './styles';
import { Transition, Message, AwSnapMessage } from 'components/PreEventScreen/styles';
import { useApplicationStore } from 'services/ApplicationService';
import PreEventScreen from 'components/PreEventScreen';
import TimeIcon from 'common/assets/schedule-time.png';

export default function StartMessage() {
  const applicationState = useApplicationStore(state => state.state);

  let components, headline;

  switch (applicationState) {
    case 'WELCOME':
    case 'NONE_AVAILABLE':
      headline = 'Herzlich Willkommen';
      components = (
        <Transition animIndex={3}>
          <Message>
            <MessageIcon src={TimeIcon} />
            Zurzeit ist hier kein Event geplant. Aber das kann sich jederzeit ändern. Kommen Sie bald wieder vorbei.
          </Message>
        </Transition>
      );
      break;
    default:
      headline = '';
      components = (
        <Transition animIndex={3}>
          <AwSnapMessage>
            Entschuldigung, hier ist leider ein Fehler aufgetreten! Versuchen Sie es später nochmal.
          </AwSnapMessage>
        </Transition>
      );
      break;
  }

  return <PreEventScreen headline={headline}>{components}</PreEventScreen>;
}
