import create from 'utilities/zustand/create';

let socket = null;

export const [useActivityStore, activityApi] = create(module, (set, get) => ({
  activities: [],
  activity: null,
  pending: false,
  next: null,

  init: managedSocket => {
    socket = managedSocket;
  },

  async setActivity(activity, force = false) {
    if (get().pending && !force) {
      set({ next: { activity } });
    } else {
      if (get().activity === activity) {
        return;
      }
      set({ activity, pending: true });
      await new Promise(resolve => {
        socket.emit('activity/set', activity, resolve);
      });
      const { setActivity, next } = get();
      if (next) {
        set({ next: null });
        await setActivity(next.activity, true);
      } else {
        set({ pending: false, next: null });
      }
    }
  },

  reset: () => {
    set({
      activities: [],
      activity: null,
      pending: false,
      next: null,
    });
  },
}));
