import styled, { css, keyframes } from 'styled-components';
import { baseColors } from 'styles';
import easings from 'utilities/easings';
import pictureMask from './picture-mask.png';
import profileMask from './profile-mask.png';

export const sizes = { s: 32, m: 48, l: 64 };

export const ImageContainer = styled.div`
  display: flex;
  width: ${props => sizes[props.size]}px;
  min-width: ${props => sizes[props.size]}px;
  height: ${props => sizes[props.size]}px;
  border-radius: ${props => (props.square ? '8px' : '50%')};
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`;

const badgePositions = { s: -3, m: 0, l: 2 };

export const FavoriteBadge = styled.div`
  position: absolute;
  right: ${props => badgePositions[props.size]}px;
  bottom: ${props => badgePositions[props.size]}px;
  width: 14px;
  height: 14px;
  background-color: ${baseColors.blue.light};
  border-radius: 50%;
  svg {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
  }
`;

const appear = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const ConfirmedBadge = styled.div`
  position: relative;
  left: ${props => sizes[props.size] - 20}px;
  width: 24px;
  height: 24px;
  background-color: ${baseColors.green.medium};
  border-radius: 50%;
  svg {
    position: absolute;
    top: 7px;
    left: 7px;
    width: 16px;
    height: 16px;
  }
  animation: ${appear} 0.3s ${easings.easeOutStrong} forwards;
  animation-delay: 0s;
  z-index: 1;
`;

export const ChatUnseenNotification = styled.div`
  margin-left: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fa5f5f;
`;

export const StackedContainer = styled.div`
  width: ${props => sizes[props.size]}px;
  margin-right: -8px;
  ${props =>
    props.size === 's' &&
    !props.first &&
    css`
      mask-image: url(${pictureMask});
      mask-size: 100%;
    `}
`;

export const NormalContainer = styled.div``;

export const StyledPersonImage = styled.div`
  position: relative;
  ${props =>
    props.size === 'm' &&
    props.hasIndicator &&
    css`
      mask-image: url(${profileMask});
      mask-size: 100%;
    `}
`;

export const IndicatorContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
