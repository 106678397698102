import create from 'utilities/zustand/create';
import { Euler, MathUtils, Matrix4, Quaternion, Vector2, Vector3 } from 'three';
import { runAverage } from 'utilities/math';
import EASINGS from 'utilities/easings';
import { betterLerpVec } from 'utilities/lerp';

const EPSILON = 0.0001;

const _zero = new Vector3(0, 0, 0);
const _up = new Vector3(0, 1, 0);
const _euler = new Euler();

const tmpVec3 = new Vector3();
const tmpQuat = new Quaternion();

// TODO: move this piece of art into the 🚮, then rewrite. use multiple camera systems, then interpolate between them
export const [useCameraStore, cameraApi] = create(module, (set, get) => ({
  screenFade: {
    color: 'white',
    alpha: 0.5,
  },
  camera: null,
  position: new Vector3(),
  quaternion: new Quaternion(0, 0, 0, 1),
  lockInput: false,
  setScreenFade: (alpha = 0.0, color = 'white') => {
    set({ screenFade: { alpha, color } });
  },
  init: camera => {
    get().setCursorVelocityDamping(2);
    set({ camera });
  },
  cursor: {
    cursorVelocity: new Vector2(),
    maxCursorSpeed: 0.2,
    velocityDamping: 0,
    smoothCursorPosition: new Vector2(),
    previousCursorPosition: new Vector2(),
    cursorDown: false,
    cursorUp: false,
    cursorDownDistanceTravelled: 0,
    velocitySamples: {
      x: [],
      y: [],
    },
    positionSamples: {
      x: [],
      y: [],
    },
    update: (delta, cursorPosition, cursorDown, cursorUp) => {
      const { cursor } = get();
      const {
        cursorVelocity,
        maxCursorSpeed,
        velocityDamping,
        smoothCursorPosition,
        previousCursorPosition,
        velocitySamples,
      } = cursor;

      // if (transition.enabled) return;

      const cursorDelta = new Vector2();
      const rawCursorPosition = new Vector2(cursorPosition.x, cursorPosition.y);
      const cursorChanged = cursor.cursorDown != cursorDown;
      cursor.cursorDown = cursorDown;
      cursor.cursorUp = cursorUp;
      // update cursor velocity
      if (cursorDown) {
        if (cursorChanged) {
          cursor.cursorDownDistanceTravelled = 0;
          smoothCursorPosition.copy(rawCursorPosition);
          previousCursorPosition.copy(rawCursorPosition);
          cursorVelocity.set(0, 0);
          velocitySamples.x = [];
          velocitySamples.y = [];
          return;
        }

        betterLerpVec(smoothCursorPosition, rawCursorPosition, 0.15, delta);
        cursorDelta.subVectors(smoothCursorPosition, previousCursorPosition);
        previousCursorPosition.copy(smoothCursorPosition);

        if (!cursorChanged) {
          cursor.cursorDownDistanceTravelled += cursorDelta.length();
        }

        cursorVelocity.set(
          runAverage(3, velocitySamples.x, cursorDelta.x),
          runAverage(3, velocitySamples.y, cursorDelta.y)
        );
        cursorVelocity.copy(cursorDelta);
      } else {
        if (cursorVelocity.length() < EPSILON) {
          cursorVelocity.set(0, 0);
        } else {
          betterLerpVec(cursorVelocity, _zero, velocityDamping / 60, delta);
        }
      }

      cursorVelocity.clampLength(0, maxCursorSpeed);
    },
  },
  orbit: {
    enabled: false,
    forceToAngle: false,
    forceToAngleTimer: 0,
    forceToAngleEulers: null,
    origin: new Vector3(0, 0, 0),
    distance: 8,
    verticalShift: null,
    orbitLockIndex: 0,
    orbitLockCount: 3,
    forceOrbitLockIndex: null,
    orbitLockEnabled: true,
    orbitEuler: new Euler(0, 0, 0, 'YXZ'),
    orbitEulerLimits: {
      xMin: -0.05 * Math.PI,
      xMax: 0.2 * Math.PI,
      yMin: null,
      yMax: null,
      yMargin: null,
      xMargin: null,
    },
    quaternion: new Quaternion(0, 0, 0, 1),
    init: () => {
      get().setOrbitOrigin(new Vector3(0.0, 2.0, 0.0));
      get().setOrbitEuler(new Euler(0.04 * Math.PI, 0.5 * Math.PI, 0, 'YXZ'));
    },
    getCurrentLockCandidate: (cursorRequired = true) => {
      const { orbit, cursor } = get();
      if (cursorRequired && !cursor.cursorDown) {
        return null;
      }
      const { orbitEuler, orbitLockCount, orbitLockOffset } = orbit;
      let lock;
      lock = ((orbitEuler.y - orbitLockOffset) * orbitLockCount) / (Math.PI * 2);
      lock = Math.round(lock);
      lock %= orbitLockCount;
      if (lock < 0) {
        lock += orbitLockCount;
      }
      return lock;
    },
    updateLock: () => {
      const { orbit, cursor } = get();
      const { orbitEuler, orbitLockCount, orbitLockOffset } = orbit;
      let lock;
      const velocityThreshold = 0.005;
      if (cursor.cursorVelocity.x < -velocityThreshold) {
        lock = Math.floor(((orbitEuler.y - orbitLockOffset) * orbitLockCount) / (Math.PI * 2)) + 1;
      } else if (cursor.cursorVelocity.x > velocityThreshold) {
        lock = Math.ceil(((orbitEuler.y - orbitLockOffset) * orbitLockCount) / (Math.PI * 2)) - 1;
      } else {
        lock = ((orbitEuler.y - orbitLockOffset) * orbitLockCount) / (Math.PI * 2);
        lock = Math.round(lock);
      }
      lock %= orbitLockCount;
      if (lock < 0) {
        lock += orbitLockCount;
      }
      orbit.orbitLockIndex = lock;
    },
    update: delta => {
      const { orbit, cursor, transition } = get();
      const {
        enabled,
        setOrbiting,
        origin,
        orbitEulerLimits,
        quaternion,
        orbitEuler,
        orbitLockCount,
        orbitLockOffset,
      } = orbit;
      if (!enabled) {
        return;
      }

      let vX, vY;

      if (!cursor.cursorDown && orbit.orbitLockEnabled) {
        vX = cursor.cursorVelocity.y * 6.0;
        if (orbit.forceOrbitLockIndex != null) {
          orbit.orbitLockIndex = orbit.forceOrbitLockIndex;
          orbit.forceOrbitLockIndex = null;
        } else if (cursor.cursorUp) {
          orbit.updateLock();
        }
        let lockEuler = orbitLockOffset + (orbit.orbitLockIndex / orbitLockCount) * Math.PI * 2;
        while (lockEuler - orbitEuler.y > Math.PI) {
          lockEuler -= Math.PI * 2.0;
        }
        while (lockEuler - orbitEuler.y < -Math.PI) {
          lockEuler += Math.PI * 2.0;
        }
        vY = (lockEuler - orbitEuler.y) * 0.075;
      } else {
        vX = cursor.cursorVelocity.y * 8.0;
        vY = -cursor.cursorVelocity.x * 8.0;
      }

      // soft camera limits
      if (!transition.enabled) {
        if (orbitEulerLimits.xMax !== null && orbitEulerLimits.xMin !== null && orbitEulerLimits.xMargin !== null) {
          if (orbitEuler.x > orbitEulerLimits.xMax - orbitEulerLimits.xMargin) {
            const overshoot = orbitEuler.x - orbitEulerLimits.xMax + orbitEulerLimits.xMargin;
            orbitEuler.x -= Math.pow(overshoot * 0.4, 1.7);
          }
          if (orbitEuler.x < orbitEulerLimits.xMin + orbitEulerLimits.xMargin) {
            const overshoot = orbitEulerLimits.xMin + orbitEulerLimits.xMargin - orbitEuler.x;
            orbitEuler.x += Math.pow(overshoot * 0.4, 1.7);
          }
        }
        if (orbitEulerLimits.yMax !== null && orbitEulerLimits.yMin !== null && orbitEulerLimits.yMargin !== null) {
          if (orbitEuler.y > orbitEulerLimits.yMax - orbitEulerLimits.yMargin) {
            const overshoot = orbitEuler.y - orbitEulerLimits.yMax + orbitEulerLimits.yMargin;
            orbitEuler.y -= 10 * Math.pow(overshoot * 0.4, 1.7) * delta;
          }
          if (orbitEuler.y < orbitEulerLimits.yMin + orbitEulerLimits.yMargin) {
            const overshoot = orbitEulerLimits.yMin + orbitEulerLimits.yMargin - orbitEuler.y;
            orbitEuler.y += 10 * Math.pow(overshoot * 0.4, 1.7) * delta;
          }
        }
      }

      orbitEuler.x += vX * delta * (cursor.cursorDown || orbitEulerLimits.xMargin !== null ? 20.0 : 60.0);
      orbitEuler.y += vY * delta * (cursor.cursorDown || orbitEulerLimits.yMargin !== null ? 20.0 : 60.0);

      // hard camera limits
      if (
        orbitEulerLimits.xMax !== null &&
        orbitEulerLimits.xMin !== null &&
        (orbitEulerLimits.yMargin === null || transition.enabled)
      ) {
        orbitEuler.x = MathUtils.clamp(orbitEuler.x, orbitEulerLimits.xMin, orbitEulerLimits.xMax);
      }
      if (
        orbitEulerLimits.yMin !== null &&
        orbitEulerLimits.yMax !== null &&
        (orbitEulerLimits.yMargin === null || transition.enabled)
      ) {
        orbitEuler.y = MathUtils.clamp(orbitEuler.y, orbitEulerLimits.yMin, orbitEulerLimits.yMax);
      }

      if (orbitEuler.y >= Math.PI * 2) orbitEuler.y -= Math.PI * 2;
      if (orbitEuler.y <= -Math.PI * 2) orbitEuler.y += Math.PI * 2;
      if (orbitEuler.x >= Math.PI * 2) orbitEuler.x -= Math.PI * 2;
      if (orbitEuler.x <= -Math.PI * 2) orbitEuler.x += Math.PI * 2;

      const orbitQuat = new Quaternion();
      orbitQuat.setFromEuler(orbitEuler);
      quaternion.copy(orbitQuat);

      setOrbiting(origin, quaternion, orbit.distance);
    },
    setOrbiting: (orbitOrigin, orbitDirQuaternion, orbitDistance) => {
      const camDirection = new Vector3(0, 0.0, -1.0);
      camDirection.applyQuaternion(orbitDirQuaternion);
      const offset = camDirection.clone();
      offset.multiplyScalar(orbitDistance);
      const { position, lookAt } = get();
      const v = new Vector3();
      v.addVectors(orbitOrigin, offset);
      position.copy(v);
      lookAt(orbitOrigin);
    },
  },
  transition: {
    enabled: false,
    lerp: 1,
    speed: 1,
    start: duration => {
      const { transition, camera } = get();
      const from = { position: camera.position.clone(), quaternion: camera.quaternion.clone(), fov: camera.fov };
      transition.lerp = 0;
      transition.enabled = true;
      transition.from = from;
      transition.speed = 1 / duration;
      set({ lockInput: true, transition: { ...transition } });
    },
    update: delta => {
      const { transition } = get();
      if (transition.lerp < 1) {
        transition.lerp += delta * transition.speed;
      } else {
        transition.lerp = 1;
        transition.enabled = false;
      }
    },
  },
  setCursorVelocityDamping: velocityDamping => {
    set({
      cursor: {
        ...get().cursor,
        velocityDamping,
      },
    });
  },
  setOrbitDistance: distance => {
    set({
      orbit: {
        ...get().orbit,
        distance: distance,
      },
    });
  },
  setOrbitOrigin: origin => {
    set({
      orbit: {
        ...get().orbit,
        origin: origin,
      },
    });
  },
  setOrbitEuler: orbitEuler => {
    set({
      orbit: {
        ...get().orbit,
        orbitEuler: orbitEuler,
      },
    });
  },
  setOrbitEulerLimits: limits => {
    set({
      orbit: {
        ...get().orbit,
        orbitEulerLimits: {
          ...limits,
          xMargin: limits.xMargin === undefined ? null : limits.xMargin,
          yMargin: limits.yMargin === undefined ? null : limits.yMargin,
        },
      },
    });
  },
  setOrbitLockIndex: forceOrbitLockIndex => {
    set({
      orbit: {
        ...get().orbit,
        forceOrbitLockIndex,
      },
    });
  },
  setOrbitLock: ({ enabled, orbitLockCount = 3, orbitLockIndex = null, orbitLockOffset = 0 }) => {
    const orbit = {
      ...get().orbit,
      orbitLockCount,
      orbitLockOffset,
      orbitLockEnabled: enabled,
    };
    if (orbitLockIndex !== null) {
      orbit.orbitLockIndex = orbitLockIndex;
    }
    set({ orbit });
  },
  setFieldOfView: fov => {
    set({
      fov,
    });
  },
  lookInDirection: dir => {
    const { quaternion } = get();
    const mat = new Matrix4();
    mat.lookAt(_zero, dir, _up);
    quaternion.setFromRotationMatrix(mat);
    _euler.setFromRotationMatrix(mat, 'YXZ');
  },
  lookAt: target => {
    const { position, lookInDirection } = get();
    const v = new Vector3();
    v.subVectors(target, position);
    lookInDirection(v);
  },
  updateCamera: params => {
    const { camera, delta, cursorPosition, cursorDown, cursorUp } = params;
    const { cursor, orbit, transition, lockInput } = get();
    cursor.update(delta, cursorPosition, cursorDown, cursorUp);
    orbit.update(delta);

    const { fov, position, quaternion } = get();

    if (transition.enabled) {
      transition.update(delta);
      const l = transition.lerp;
      if (lockInput && l > 0.6) {
        set({ lockInput: false });
      }
      const from = transition.from;
      const t = EASINGS.easeInOutQuad(l);
      tmpVec3.lerpVectors(from.position, position, t);
      tmpQuat.copy(from.quaternion).slerp(quaternion, t);
      camera.position.copy(tmpVec3);
      camera.quaternion.copy(tmpQuat);
      camera.fov = MathUtils.lerp(from.fov, fov, t);
    } else {
      camera.position.copy(position);
      camera.quaternion.copy(quaternion);
      camera.fov = fov;
    }

    camera.updateProjectionMatrix();
    camera.updateMatrixWorld();
  },
}));
