import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { Button } from 'common/components/Button';
import { mediaMin } from 'common/layout';

export const Form = styled.form`
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;
export const Container = styled.div`
  margin-top: 8px;
  padding: 0 24px;
  ${mediaMin(
    'medium',
    css`
      padding: 0 40px;
      padding-bottom: 56px;
    `
  )}
`;

export const ButtonContainer = styled.div`
  margin-left: 16px;
  ${Button} {
    padding: 0;
    width: 48px;
    height: 48px;

    svg {
      fill: ${colors.accentLight};
    }
  }
`;
