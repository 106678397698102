import io from 'socket.io-client';
import schemapack from './schemapack-parser';
import create from 'utilities/zustand/create';
import { DEVELOPMENT, LOCAL, PRODUCTION, MEDICAL, buildApi } from 'services/BuildService';
import debugStore from 'storage/debug';

const TARGETS = ['hub', 'strapi'];

const ENDPOINTS = {
  [LOCAL]: { hub: `http://${location.hostname}:5000/`, strapi: `http://${location.hostname}:1337/` },
  [DEVELOPMENT]: {
    hub: 'https://develop.hub.ipsen.dmdr.io/',
    strapi: 'https://develop.strapi.ipsen.dmdr.io/',
  },
  [PRODUCTION]: {
    hub: 'https://hub.ipsen.dmdr.io/',
    strapi: 'https://strapi.ipsen.dmdr.io/',
  },
  [MEDICAL]: {
    hub: 'https://hub.medical.dmdr.io/',
    strapi: 'https://strapi.medical.dmdr.io/',
  },
};

const INTEGRATION_TARGETS = {
  [LOCAL]: ENDPOINTS[LOCAL],
  [DEVELOPMENT]: ENDPOINTS[DEVELOPMENT],
  [PRODUCTION]: ENDPOINTS[PRODUCTION],
  [MEDICAL]: ENDPOINTS[MEDICAL],
};

let socket = null;
export const [useSocketStore, socketApi] = create(module, set => ({
  url: '',

  getRestUrl: target => {
    if (!TARGETS.includes(target)) {
      throw 'Invalid target: ' + target;
    }
    const { integration } = buildApi.getState();
    const debugSocketTarget = debugStore.getSocketTarget();
    const url =
      debugSocketTarget === 'AUTO' ? INTEGRATION_TARGETS[integration][target] : ENDPOINTS[debugSocketTarget][target];
    return url;
  },

  connect: () => {
    if (socket) {
      try {
        socket.removeAllListeners();
        socket.disconnect();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    }

    const { integration } = buildApi.getState();
    const debugSocketTarget = debugStore.getSocketTarget();
    const url = debugSocketTarget === 'AUTO' ? INTEGRATION_TARGETS[integration].hub : ENDPOINTS[debugSocketTarget].hub;
    set({ url });
    socket = io(url + '?protocol=v1', {
      parser: schemapack,
      reconnectionAttempts: 2,
      reconnectionDelay: 250,
    });
    return socket;
  },

  disconnect: () => {
    if (!socket) {
      return;
    }
    socket.removeAllListeners();
    socket.disconnect();
    socket = null;
  },
}));
