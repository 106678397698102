import React, { useEffect, useState } from 'react';
import { useUserStore } from 'services/UserService';
import { useLoginStatus } from '../helper';
import { StyledPanelHeadline, CodeCaption, StyledInput, StyledButton } from './styles';
import PreEventScreen from 'components/PreEventScreen';
import { Transition, Message } from 'components/PreEventScreen/styles';

const isValidEmail = identifier => identifier.match(/\S+@\S+\.\S+/);

function LoginForm() {
  const waitForCode = useUserStore(state => state.waitForCode);
  const [identifier, setIdentifier] = useState('');
  const [code, setCode] = useState('');
  const [joinButtonEnabled, setJoinButtonEnabled] = useState(false);
  const login = useUserStore(state => state.login);
  const loginStatus = useLoginStatus();
  const submitted = loginStatus.isPending;

  useEffect(() => {
    if (!waitForCode) {
      setCode('');
    }
  }, [waitForCode]);

  useEffect(() => {
    setJoinButtonEnabled(identifier.length > 0 && isValidEmail(identifier));
  }, [identifier]);

  const handleUsernameChange = e => {
    const identifier = e.nativeEvent.target.value;
    setIdentifier(identifier);
  };

  const handleCodeChange = e => {
    const code = e.nativeEvent.target.value;
    setCode(code);
  };

  const submit = e => {
    e.preventDefault();
    const credentials = { identifier };
    if (code) {
      credentials.code = code;
    } else {
      credentials.location = {
        protocol: window.location.protocol,
        hostname: window.location.hostname,
        port: window.location.port,
      };
    }
    login({ credentials });
  };

  return (
    <form onSubmit={submit} noValidate style={{ maxWidth: '390px' }}>
      <Transition animIndex={1.5}>
        <StyledPanelHeadline light>{waitForCode ? 'Sie haben Post' : 'Login'}</StyledPanelHeadline>
      </Transition>
      <Transition animIndex={2}>
        <Message>
          {waitForCode
            ? `Wir haben Ihnen eine E-Mail an ${identifier} geschickt. Klicken Sie einfach auf den Link, um sich automatisch einzuloggen.`
            : 'Bitte geben Sie Ihre E-Mail Adresse ein'}
        </Message>
      </Transition>
      <Transition animIndex={2.5}>
        {!waitForCode && (
          <StyledInput
            type="email"
            border
            placeholder="E-Mail Adresse"
            value={identifier}
            onChange={handleUsernameChange}
            disabled={submitted}
          />
        )}
        {waitForCode && (
          <>
            <CodeCaption>Alternativ geben Sie bitte den Code ein.</CodeCaption>
            <StyledInput border placeholder={'Code'} value={code} onChange={handleCodeChange} disabled={submitted} />
          </>
        )}
      </Transition>
      <Transition animIndex={3}>
        {loginStatus.message.copy && (
          <Message error noTopMargin>
            {loginStatus.message.copy}&nbsp;
          </Message>
        )}
      </Transition>
      <Transition animIndex={4}>
        {waitForCode ? (
          <StyledButton primary disabled={code.length < 1 || submitted} type="submit">
            Weiter
          </StyledButton>
        ) : (
          <StyledButton primary disabled={!joinButtonEnabled || submitted} type="submit">
            Weiter
          </StyledButton>
        )}
      </Transition>
    </form>
  );
}

export default function Join() {
  return (
    <PreEventScreen>
      <LoginForm />
    </PreEventScreen>
  );
}
