import styled, { css } from 'styled-components';
import { H3 } from 'common/text';
import { BG_TRANSPARENT, easings } from 'styles';
import { TransitionComponent } from '../styles';
import { ActionButton } from 'common/components/ActionButton';
import { mediaMax } from 'common/layout';

export const StyledNetworking = styled(TransitionComponent)`
  display: ${props => (props.show ? 'inherit' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 50px;
  object-fit: cover;
  pointer-events: none;
`;

export const StyledTooltipContainer = styled.div`
  cursor: pointer;
  position: relative;
  ${mediaMax(
    'small',
    css`
      position: absolute;
      left: 0;
    `
  )}
`;

export const StyledContentType = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  ${mediaMax(
    'small',
    css`
      bottom: 24px;
      left: 24px;
      transform: translateX(0);
      width: auto;
    `
  )}
`;

export const StyledContentTypeBackground = styled.div`
  pointer-events: initial;
  background: ${BG_TRANSPARENT};
  padding: 12px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;

  ${ActionButton}:not(:first-child) {
    margin-left: 12px;
  }
`;

export const StyledRoomName = styled(H3)`
  transform: ${props => (props.show ? 'none' : 'translateY(-100%)')};
  transition: transform 1.5s ${easings.easeOutStrong};
  color: white;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: initial;
`;
