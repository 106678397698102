import * as THREE from 'three';

/* lerp that is framerate independent
 * same behavior as lerp(v0,v1,coef) at 60fps */

export const betterLerp = (v0, v1, coef, dt) => {
  const rate = -60.0 * Math.log(1.0 - coef);
  return THREE.MathUtils.lerp(v0, v1, 1.0 - Math.exp(-rate * dt));
};
export const betterLerpVec = (v0, v1, coef, dt) => {
  const rate = -60.0 * Math.log(1.0 - coef);
  return v0.lerp(v1, 1.0 - Math.exp(-rate * dt));
};
export const betterSlerpQuat = (v0, v1, coef, dt) => {
  const rate = -60.0 * Math.log(1.0 - coef);
  return v0.slerp(v1, 1.0 - Math.exp(-rate * dt));
};
