import styled, { css } from 'styled-components';
import backgroundImage from './assets/joinBackground.jpg';
import { mediaMax, mediaMin, styledScrollbar } from 'common/layout';
import { animations, backdropBlurOrGradient, baseColors, colors, SolidBackdropGradient } from 'styles';
import { Body1, Body2, H1, Overline1 } from 'common/text';
import easings from 'utilities/easings';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  background-image: url(${backgroundImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
  flex-wrap: wrap;
  ${styledScrollbar};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  width: 45%;
  height: 100%;
  padding: 0px 50px 0 75px;
  ${backdropBlurOrGradient(20)};
  overflow-y: auto;

  ${mediaMax(
    'small',
    css`
      align-items: start;
      justify-content: start;
      flex-direction: column;
      ${SolidBackdropGradient};
      width: 100%;
      padding: 20px 24px 0;
    `
  )};
`;

export const Transition = styled.div`
  opacity: 0;
  ${animations.showFromBottom};
  ${props =>
    props.animIndex &&
    css`
      animation-delay: ${props.animIndex * 0.1 + 0.2}s;
      animation-timing-function: ${easings.easeOutStrong};
      animation-duration: ${props.animIndex * 0.1 + 0.3}s;
    `};
`;

export const IpsenLogoDesktop = styled.img`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  margin: 20px;
  ${mediaMin(
    'medium',
    css`
      display: block;
    `
  )}
`;

export const IpsenLogoMobile = styled.img`
  display: none;
  margin-left: -10px;
  width: 85px;
  ${mediaMax(
    'small',
    css`
      display: block;
    `
  )}
`;

export const WelcomeHeadline = styled(H1)`
  color: white;
  margin: 130px 0 40px;
  ${mediaMax(
    'small',
    css`
      margin: 70px 0 70px;
    `
  )}
`;

export const CenteredContent = styled.div`
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 100%;
  ${mediaMax(
    'small',
    css`
      justify-content: start;
    `
  )}
`;

export const Message = styled(Body2)`
  color: ${props => (props.error ? colors.alertRed : baseColors.white)};
  ${props =>
    props.error
      ? css`
          font-size: 14px;
          ${mediaMax(
            'small',
            css`
              font-size: 12px;
            `
          )};
        `
      : ''};
  display: flex;
  margin: ${props => (props.noTopMargin ? '0 0 15px' : '15px 0')};
  align-items: center;

  max-width: 464px;
`;
export const AwSnapMessage = styled(Body1)`
  color: ${baseColors.white};
`;

export const BottomLinkContainerContainer = styled.div`
  padding: 0 0 40px;
  margin-top: 80px;
  width: 100%;
  text-align: right;
  ${mediaMax(
    'small',
    css`
      padding: 0 0 24px;
    `
  )};
`;
export const BottomLinkContainer = styled.div`
  display: inline-flex;
`;

export const BottomLink = styled(Overline1)`
  display: flex;
  color: ${colors.interactiveBlue};
  margin: 0 0 0 20px;
  line-height: 40px;
  cursor: pointer;
`;
