import React, { useEffect, useState } from 'react';
import { menuApi, useMenuStore } from 'services/MenuService';
import { useChatStore } from 'services/ChatService';
import MenuButton from '../components/MenuButton/index';
import Indicator from '../components/Indicator';
import IconChat from '../assets/chat.svg';

export default function ChatButton() {
  const activeMenuComponent = useMenuStore(state => state.activeMenuComponent);
  const chat = useMenuStore(state => state.chat);
  const chats = useChatStore(state => state.chats);
  const [showBubble, setShowBubble] = useState(false);

  useEffect(() => {
    setShowBubble(!!chats.find(c => c.seen === false));
  }, [chats]);

  const onClick = () => {
    menuApi.getState().setActiveMenuComponent(activeMenuComponent === chat ? null : chat);
  };

  return (
    <MenuButton active={activeMenuComponent === chat} onClick={onClick} hoverLabel="Chat">
      {showBubble && <Indicator />}
      <IconChat />
    </MenuButton>
  );
}
