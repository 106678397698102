import React, { useEffect, useMemo, useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import { AdditiveBlending, Color, InstancedBufferAttribute, InstancedBufferGeometry, PlaneGeometry } from 'three';
import { fragmentShader, vertexShader } from './glsl/shader.glsl';

import { useCameraStore } from 'services/CameraService';
import { useContentStore } from 'services/ContentService';
import { useSpring } from 'react-spring';

const BOKEH_PARTICLE_COUNT = 7;

const particles = [];

let lastAngle = 0;

export default function BokehParticles() {
  const uniforms = { ratio: { value: 1.0 } };

  const cameraOrbit = useCameraStore(state => state.orbit.orbitEuler);
  const instancedMeshRef = useRef();
  const content = useContentStore(state => state.activeContent);
  const contentType = content && content.type.id;

  const [open, setOpen] = useSpring(() => ({
    open: 0,
    config: { duration: 650 },
  }));

  const [offsetAttribute, scaleAttribute, alphaAttribute, geom] = useMemo(() => {
    const bokehOffsetArray = Float32Array.from(new Array(BOKEH_PARTICLE_COUNT * 3).fill(0));
    const bokehScaleArray = Uint8Array.from(new Array(BOKEH_PARTICLE_COUNT * 1).fill(0));
    const bokehAlphaArray = Float32Array.from(new Array(BOKEH_PARTICLE_COUNT * 1).fill(0));
    const offsetAttribute = new InstancedBufferAttribute(bokehOffsetArray, 3);
    const scaleAttribute = new InstancedBufferAttribute(bokehScaleArray, 1);
    const alphaAttribute = new InstancedBufferAttribute(bokehAlphaArray, 1);

    const geom = new InstancedBufferGeometry();
    geom.fromGeometry(new PlaneGeometry(1, 1, 1, 1));

    geom.setAttribute('offset', offsetAttribute);
    geom.setAttribute('scale', scaleAttribute);
    geom.setAttribute('alpha', alphaAttribute);

    return [offsetAttribute, scaleAttribute, alphaAttribute, geom];
  }, []);

  useEffect(() => {
    lastAngle = cameraOrbit.y;
    for (let i = 0; i < BOKEH_PARTICLE_COUNT; i++) {
      const particle = {};
      particle.posX = Math.random() * 2.0 - 1.0;
      particle.posY = Math.random() - 1.1;
      particle.speedX = Math.random() * 0.0002 - 0.0001;
      particle.speedMod = Math.random() * 0.0005;
      particle.mSpeedX = particle.speedX;
      particle.age = Math.random() * i * 2;
      particle.lifespan = BOKEH_PARTICLE_COUNT * 2;
      particle.speedY = Math.random() * 0.0001 + 0.0001;
      particle.scale = Math.floor(Math.random() * 200 + 55);
      particle.angle = Math.floor(Math.random() * 255);
      particle.alpha = Math.random() * 0.1 + 0.1;
      particles.push(particle);
    }
  }, []);

  useEffect(() => {
    if (contentType === null) {
      setOpen({ open: 0 });
    } else {
      setOpen({ open: 1 });
    }
  }, [contentType]);

  const newPosition = p => {
    p.posX = Math.random() * 2.0 - 1.0;
    p.posY = Math.random() - 1.1;
  };

  const round = x => {
    return Math.round((x + Number.EPSILON) * 100) / 100;
  };

  useFrame((context, dt) => {
    let speed = round(lastAngle) - round(cameraOrbit.y);
    if (Math.abs(speed) > 6) speed = 0;
    lastAngle = cameraOrbit.y;

    for (let i = 0; i < BOKEH_PARTICLE_COUNT; i++) {
      const p = particles[i];

      p.mSpeedX = p.speedX + Math.pow(p.scale, 2.0) * speed * 0.000001;

      p.posX += p.mSpeedX + Math.sin(p.posX) * 0.0005 + p.posX * 0.015 * open.open.value;
      p.posY += p.speedY;
      p.age += dt;
      if (p.posY < -1 || p.posY > 1 || p.posX > 1.25 || p.posX < -1.25 || p.age > p.lifespan) {
        newPosition(p);
        p.age = 0;
      }

      const alpha = p.alpha * Math.pow(Math.sin((p.age / p.lifespan) * Math.PI), 0.5) * (1.0 - open.open.value);

      offsetAttribute.setXYZ(i, p.posX, p.posY, 0);
      scaleAttribute.setX(i, Math.min(255, p.scale + Math.floor(50 * open.open.value)));
      alphaAttribute.setX(i, Math.max(0.0, -p.posY * alpha));
    }

    offsetAttribute.needsUpdate = true;
    scaleAttribute.needsUpdate = true;
    alphaAttribute.needsUpdate = true;
  }, 0);

  return (
    <instancedMesh ref={instancedMeshRef} args={[null, null, BOKEH_PARTICLE_COUNT]} geometry={geom}>
      <shaderMaterial
        attach="material"
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        uniforms={uniforms}
        uniformsNeedUpdate={true}
        color={new Color(1, 1, 1)}
        transparent={true}
        blending={AdditiveBlending}
        depthTest={false}
        depthWrite={false}
      />
    </instancedMesh>
  );
}
