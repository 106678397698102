import React from 'react';
import ThreeLoader from 'three/ThreeLoader';
import ThreeCanvas from 'three/ThreeCanvas';
import PointerControls from 'components/Play/PointerControls';
import CameraController from 'components/Play/CameraController';
import IntroCameraController from 'components/Play/CameraController/IntroCameraController';
import Environment from 'components/Play/Environment';
import DNAHelix from 'components/Play/DNAHelix';
import { sceneApi } from 'services/SceneService';
import { districtApi } from 'services/DistrictService';
import { useDebugStore } from 'storage/debug';
import { profilingApi } from 'services/ProfilingService';
import styled from 'styled-components';
import RenderTexture from 'three/RenderTexture';
import Measurement from '../Stats/Measurement';
import Wisps from 'components/Play/Wisps';
import WispParticipants from '../WispParticipants';
import LibraryCategories from '../LibraryCategories';
import WispRooms from '../WispRooms';
import { cameraApi } from 'services/CameraService';
import Renderer from 'three/Renderer';
import BokehParticles from '../BokehParticles';
import { useOnboardingStore } from 'services/OnboardingService';

const Container = styled.div`
  position: relative;
  left: 0;
  transition: left 0.5s ease-out;
  height: 100%;
`;

export default function PlayMap({ district }) {
  const debugStore = useDebugStore();
  const introRunning = useOnboardingStore(state => state.introPlaying && !state.introCompleted);

  const use3d = !debugStore.get3dDisabled();

  return (
    <Container>
      <ThreeCanvas>
        <ThreeLoader
          onLoad={() => {
            profilingApi.getState().mark('Three Loading Start');
            sceneApi.setState({ isLoaded: false, init: false });
          }}
          onInit={() => {
            profilingApi.getState().mark('Three Loading Complete');
            sceneApi.setState({ init: true });
            cameraApi.getState().setScreenFade(0.0);
            const { setDistrictLoaded } = districtApi.getState();
            setDistrictLoaded();
          }}
          onFadeIn={() => {
            profilingApi.getState().mark('Three Loading Show Blend');
            sceneApi.setState({ isLoaded: true });
          }}
        >
          {/*<PostEffect />*/}
          <Renderer />
          <RenderTexture />
          {debugStore.getStatsEnabled() && <Measurement />}
          <district.scene.type {...district.scene.props}>
            <PointerControls />
            {introRunning ? <IntroCameraController /> : <CameraController />}
            {use3d && (
              <>
                <Environment />
              </>
            )}
            <DNAHelix />
            <Wisps />
            <BokehParticles />
            <WispRooms />
            <WispParticipants />
            <LibraryCategories />
          </district.scene.type>
        </ThreeLoader>
      </ThreeCanvas>
    </Container>
  );
}
