import create from 'utilities/zustand/create';
import { userApi } from 'services/UserService';
import { contentApi } from 'services/ContentService';
import { wispApi } from 'services/WispService';

let socket = null;
let sequencer = 0;

const initialState = () => {
  return {
    emoji: null,
  };
};

export const [useEmojiStore, emojiApi] = create(module, (set /*, get*/) => ({
  ...initialState(),

  init: managedSocket => {
    socket = managedSocket;

    const userId = userApi.getState().user.id;
    socket.on('emoji/receive', emoji => {
      if (emoji.userId === userId) {
        // ignore own emoji, it is handled locally in sendEmoji below
        return;
      }
      const activeContent = contentApi.getState().activeContent;
      if (activeContent !== null && activeContent.type.id === 'LIVESTREAM') {
        const wisp = wispApi.getState().wisps.find(w => w.id === emoji.userId);
        const isArenaOrLivestream = wisp.activity === 'ARENA' || wisp.activity === 'LIVESTREAM';
        if (!wisp || !isArenaOrLivestream) {
          return;
        }
      }
      emoji.id = sequencer++;
      set({ emoji });
    });
  },

  sendEmoji: async emoji => {
    const userId = userApi.getState().user.id;
    const id = sequencer++;
    set({ emoji: { id, userId, emoji } });
    await new Promise(resolve => {
      socket.emit('emoji/send', emoji, resolve);
    });
  },

  spawnEmoji: (userId, emojiType) => {
    const id = sequencer++;
    set({ emoji: { id, userId, emoji: emojiType } });
  },
}));
