import styled from 'styled-components';
import { colors } from 'styles';
import { Overline2 } from 'common/text';
import { StyledInput } from 'common/components/Input';

export const StyledEditProfileLabel = styled(Overline2)`
  display: block;
  color: ${colors.highlightCyan};
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const StyledEditProfileInput = styled(StyledInput)``;

export const StyledEditProfileFileInput = styled.input`
  font-family: 'PF Highway Sans Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  display: block;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  outline: none;
`;

export const StyledPreviewImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
  pointer-events: none;
  margin-bottom: 8px;
  object-fit: cover;
`;
