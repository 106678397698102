import { BackSide } from 'three';
import React from 'react';

export default function BackgroundEvents() {
  return (
    <mesh name={'Background'} onClick={() => {}} position={[0, 0, 0]} scale={[500, 500, 500]}>
      <boxBufferGeometry attach="geometry" />
      <meshStandardMaterial attach="material" color={'hotpink'} side={BackSide} visible={false} />
    </mesh>
  );
}
