import create from 'utilities/zustand/create';
import debugStorage from 'storage/debug';
import React from 'react';

const materialOptions = [];

export const [useMaterialStore, materialApi] = create(module, (set, get) => ({
  activeHoverID: null,
  materialsOptions: materialOptions,
  materials: materialOptions[0],

  setHoverID: activeHoverID => {
    set({ activeHoverID });
  },

  setMaterialsIndex: index => {
    debugStorage.setMaterialsIndex(index);
    if (index === -1) {
      set({ materials: { name: null, materials: <></> } });
    } else {
      set({ materials: materialOptions[index % materialOptions.length] });
    }
  },

  setMaterialsOption: materialOption => {
    if (debugStorage.getMaterialsIndex() === -1) {
      set({ materials: materialOption });
    } else {
      get().setMaterialsIndex(debugStorage.getMaterialsIndex());
    }
  },
}));
