import { iconFillAndStroke } from 'common/icon';
import { Caption1, H4 } from 'common/text';
import styled from 'styled-components';
import { baseColors, colors } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: linear-gradient(105.41deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.0375) 100%);

  margin: 12px 0;

  @media only screen and (min-width: 608px) {
    width: calc(50% - 24px);
    margin: 12px;
  }

  @media only screen and (min-width: 1078px) {
    width: calc(33.333% - 24px);
  }

  @media only screen and (min-width: 1856px) {
    width: calc(25% - 24px);
  }
`;

export const Img = styled.div`
  height: 190px;
  width: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`;

export const Headline = styled(H4)`
  color: white;
  margin: 24px 24px 10px;
`;

export const Subline = styled(Caption1)`
  color: white;
  margin: 0 24px 30px;
  flex-grow: 1;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: rgba(255, 255, 255, 0.05);
  height: 32px;
`;

export const LinkButton = styled.div`
  height: 32px;
  padding-left: 13px;
  background-color: ${colors.interactiveBlue};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: ${baseColors.grey.dark};
    svg {
      ${iconFillAndStroke(baseColors.grey.dark, baseColors.grey.dark)}
    }
  }
  span {
    font-size: 12px;
    line-height: 32px;
    font-family: PF Highway Sans Pro;
  }
`;
