import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import { normalize } from 'styled-normalize';
import 'react-dat-gui/dist/index.css';
import HighwayLightTtf from 'fonts/PFHighwaySansPro-Light.ttf';
import HighwayThinTtf from 'fonts/PFHighwaySansPro-Thin.ttf';
import HighwayBoldTtf from 'fonts/PFHighwaySansPro-Bold.ttf';
import { hot } from 'react-hot-loader';

export const BG_SOLID_GRADIENTSTOP0 = '#0f377d';
export const BG_SOLID = '#041431';
export const BG_TRANSPARENT = 'rgba(3, 18, 48, 0.8)';

export const baseColors = {
  application: '#FFF', // #001C2F
  white: '#FFF',
  grey: {
    base: '#F7F7F7',
    veryLight: '#F2F2F2',
    light: '#8D9399',
    medium: '#5F5F5F',
    dark: '#242424',
    veryDark: '#212121',
    border: '#EBEBEB',
  },
  green: {
    light: '#B0E1CE',
    medium: '#37A42C',
    dark: '#293E40',
  },
  blue: {
    light: '#72D0E2',
    medium: '#005489',
    dark: '#001C2F',
    interactive: '#2870E6',
  },
  aquamarine: '#64DDAC',
  red: '#E01B22',
  darkOrange: '#CC7600',
  yellow: '#ffc15e',
  cyan: {
    highlight: '#6FF3FA',
  },
};

export const colors = {
  font: { strong: baseColors.grey.veryDark, light: baseColors.grey.light },
  background: {
    application: baseColors.application,
    base: baseColors.grey.base,
  },
  accent: '#156c9c', // TODO: remove
  accentLight: '#00AAC9', // TODO: remove
  interactiveBlue: baseColors.blue.interactive,
  highlightCyan: baseColors.cyan.highlight,
  alertRed: '#FA5F5F',
};

export const easings = {
  easeOutSemiStrong: `cubic-bezier(0.32, 0.87, 0.69, 1)`,
  easeOutStrong: `cubic-bezier(0.12, 0.2, 0.09, 0.99)`,
};

export const SolidBackdropGradient = css`
  background: linear-gradient(177.31deg, ${BG_SOLID_GRADIENTSTOP0} 3.77%, ${BG_SOLID} 89.98%);
`;

export const backdropBlurOrGradient = blurAmount => css`
  ${SolidBackdropGradient};
  @supports (backdrop-filter: blur(${blurAmount}px)) {
    background: ${BG_TRANSPARENT};
    backdrop-filter: blur(${blurAmount}px);
  }

  @supports (-webkit-backdrop-filter: blur(${blurAmount}px)) {
    background: ${BG_TRANSPARENT};
    -webkit-backdrop-filter: blur(${blurAmount}px);
  }
`;

const slideUpIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0px); }
`;

const fadeOut1 = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const animations = {
  showFromBottom: css`
    animation: ${slideUpIn} 0.15s ease forwards;
  `,
  fadeOut: css`
    animation: ${fadeOut1} 1s ease forwards;
  `,
};

export const TransitionItem = styled.div`
  opacity: 0;
  ${animations.showFromBottom};
  animation-delay: ${props => props.index * 0.05 + 0.15}s;
  animation-duration: 0.45s;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0, 0.98);
`;

const fullViewport = css`
  width: 100%;
  height: 100vh;
  min-height: 100%;
  min-height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;
`;

export const zIndex = {
  intro: 10000,
  navigation: 10100,
  fullscreenContent: 10300,
  submenu: 10400,
  emoji: 10500,
  menu: 10600,
  fx: 10700,
  modalAndNotifications: 10999,
  tooltip: 11100,
  loader: 20010,
};

export const chat = {
  chatWidth: 400,
  barWidth: 56 + 15 + 11,
  defaultHeight: 360,
  textInputHeight: 62,
  titleHeight: 48,
};

export const GlobalStyle = hot(module)(createGlobalStyle`
  ${normalize};
  
  html {
    height: -webkit-fill-available;
  }
  body {
    // PF Highway Sans Pro
    @font-face {
      font-family: 'PF Highway Sans Pro';
      src: url(${HighwayThinTtf}) format('truetype');
      font-weight: 300;
    }
    @font-face {
      font-family: 'PF Highway Sans Pro';
      src: url(${HighwayLightTtf}) format('truetype');
      font-weight: 400;
    }
    @font-face {
      font-family: 'PF Highway Sans Pro';
      src: url(${HighwayBoldTtf}) format('truetype');
      font-weight: 700;
    }
    background: ${colors.background.application};

    position: ${props => {
      if (props.fixed) return 'fixed';
      return 'relative';
    }};

    ${fullViewport};

    user-select: none;
    overflow: hidden;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;

    ul, li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
  
  * {
    touch-action: pan-x pan-y;
  }
  
  *, *:before, *:after {
    box-sizing: border-box;
  }
  
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
  }

  .application {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  
  #onetrust-consent-sdk {
    font-family: Arial, Helvetica, sans-serif;
  }
`);

export const center = {
  horizontal: () => css`
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  `,
  vertical: () => css`
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  `,
  absolute: () => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  `,
};
