import React from 'react';
import { useChatStore } from 'services/ChatService';
import styled from 'styled-components';
import { colors } from 'styles';
import { hexToRgba } from 'utilities/color';

export const StyledNewMessagesBelowHint = styled.div`
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 50px;
  background: rgb(249, 249, 249);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, ${hexToRgba(colors.accentLight, 0.5)} 100%);
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity: 0.15s ease;
  pointer-events: none;
`;

const ChatMessageHint = () => {
  const activeChatSeen = useChatStore(state => {
    return state.activeChat ? state.activeChat.seen : false;
  });

  return <StyledNewMessagesBelowHint show={!activeChatSeen} />;
};

export default ChatMessageHint;
